import React,{useEffect, useState} from "react";
import {FormattedMessage, FormattedDate, FormattedNumber, FormattedPlural, FormattedTime} from 'react-intl';
import { useHistory } from "react-router";
import { Applieduser } from "../Applieduser/Applieduser"
import "./dasdhboard.scss"
import TabsUI from "../../commoncomponent/TabsUI.js"
import { ScheduleInterview } from "../Jobseekerinterviwshedule/ScheduleInterview";
import * as UserActions from "redux/actions/UserActions";
import * as JobActions from "redux/actions/JobActions";
import { useDispatch, useSelector } from "react-redux";
import storage from "utils/storage";

export const Dashboardpage=()=>{
  const [jobCount, setJobCount] = useState({});
  const dispatch = useDispatch();
  const { interviewList } = useSelector((state) => state.jobReducer);
  let userId = storage.get("humlog_user");
  useEffect(() => {
    fetchUserDetails();
  }, [dispatch]);
  /**
   * Method for fetching user details
   */
  const fetchUserDetails = async () => {
    try{
      const response = await dispatch(UserActions.getUserData(storage.get('humlog_user')));
      dispatch(JobActions.getInterviewByUserId(userId));
      if(response.value.success) {
        const jobCounts = updateJobCounts(response.value.data.jobs);
        setJobCount(jobCounts);
      }
    } catch(err) {
      console.log("error in fetching user details", err);
    }
  }
  /**
   * Method for counting jobs of different status
   * @param {Array} jobs list of jobs of a job seeker
   * @returns counts of different job status
   */
  const updateJobCounts = (jobs) => {
    let jobCounts = {
      applied : 0,
      saved : 0,
      selected: 0,
      shortlisted : 0,
      rejected : 0
    };
    for(let job of jobs) {
      if(job.job_status == 0) {
        switch (job.status) {
          case 'Applied' :
            jobCounts.applied = jobCounts.applied + 1
            break;
          case 'Saved' :
            jobCounts.saved = jobCounts.saved + 1
            break;
          case 'Selected' :
            jobCounts.selected = jobCounts.selected + 1
            break;
          case 'Shortlisted' :
            jobCounts.shortlisted = jobCounts.shortlisted + 1
            break;
          case 'Rejected' :
            jobCounts.rejected = jobCounts.rejected + 1
            break;
          default :
            continue;
        }
      }
    }
    return jobCounts;
  }
  const history = useHistory();
  const labelsData = [
    <p>
      <FormattedMessage id="applied" defaultMessage="Applied" />
      <span className="tabcount">{jobCount.applied}</span>
    </p>,
    <p>
      <FormattedMessage id="saved" defaultMessage="Saved" />
      <span className="tabcount">{jobCount.saved}</span>
    </p>,
    <p>
      <FormattedMessage id = "interview" defaultMessage="Interview" />
      <span className="tabcount">{interviewList && interviewList.length ? interviewList.length : 0}</span>
    </p>,
    <p>
      <FormattedMessage id="shortListed" defaultMessage="Shortlisted" />
      <span className="tabcount">{jobCount.shortlisted}</span>
    </p>,
    <p>
      <FormattedMessage id="notInterested" defaultMessage="notInterested" />
      <span className="tabcount">{jobCount.rejected}</span>
    </p>,
    ];
    const tabsData = {
      0: <Applieduser status='Applied'/>,
      1: <Applieduser status='Saved'/>,
      2: <ScheduleInterview />,
      3: <Applieduser status='Shortlisted'/>,
      4: <Applieduser status='Rejected'/>,  
    };
  
  return(
    <>
      <div className="st  m-0 p-0" >
        <h5 className="page_title pb-0">
          <FormattedMessage id="dashboard" defaultMessage="Dashboard" />
        </h5>
        <TabsUI
          labels={labelsData}
          tabs={tabsData}
          tabValue={history.location.state ? history.location.state.tab : 0}
        />
      </div>
    </>
  );
}