import React, { useEffect, useState } from "react";
import {
  FormattedMessage,
  FormattedDate,
  FormattedNumber,
  FormattedPlural,
  FormattedTime,
} from "react-intl";

import { useForm } from "react-hook-form";
import { serialize } from "object-to-formdata";
import storage from "utils/storage";
import { useHistory } from "react-router-dom";
import "./indutry.scss";
import { BiFilterAlt } from "react-icons/bi";
import * as userActions from "redux/actions/UserActions";
import { useDispatch, useSelector } from "react-redux";
import others from "../../../assets/images/other.svg";
import classNames from "classnames";
import { ContactsOutlined } from "@material-ui/icons";
import { useIntl } from "react-intl";
import * as commonService from "utils/CommonService.js";
import { jobCategoryData } from "../../recruiter/jobCategoryData"; // job category data

export const Industryinterested = ({ handelClose }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const translate = useIntl();
  const [input, setInput] = useState(false);
  const [otherInput, setOtherInput] = useState("");
  const { JobCategoryList } = useSelector((state) => state.jobCategoryReducer);
  const { userDetails } = useSelector((state) => state.userReducer);
  const [JobIntrestedList, setJobIntrested] = useState([]);
  const [jobDisabled, setJobDisabled] = useState([]);
  const [currentJobCategoryData, setJobCategoryData] = useState([]); // storing job category values
  const [usersJob, setUsersJob] = useState(
    userDetails && userDetails.job_intrested && userDetails.job_intrested.length
      ? userDetails.job_intrested
      : []
  );
  // get list of job categories of the user
  const getJobIntrestedList = () => {
    let jobIntrestedList = [];
    if (
      userDetails &&
      userDetails.job_intrested &&
      userDetails.job_intrested.length
    ) {
      for (let val of userDetails.job_intrested) {
        jobIntrestedList.push(val.job_category);
      }
    }
    return jobIntrestedList;
  };
  const { watch, register, handleSubmit, reset, errors } = useForm({
    mode: "onChange",
  });
  let job_intrested = watch("job_intrested", []);
  // TODO : this is a patch which needs to be fixed
  if(typeof(job_intrested) === "boolean"){
    job_intrested = [];
  }
  const checkedCount = job_intrested.filter(Boolean).length;
  let jobSelected = [];
  jobSelected = JobIntrestedList;
  let count = jobSelected.length;
  // get all selected intrested jobs
  const setIndustry = (e) => {
    if (jobSelected.includes(e.target.value) || input) {
      const index = jobSelected.indexOf(e.target.value);
      jobSelected.splice(index, 1);
      count = count - 1;
    } else {
      if (e.target.value !== "") {
        jobSelected.push(e.target.value);
        count = count + 1;
      }
    }
  };
  // Method to set other job category field
  const onClickOther = (e) => {
    setIndustry(e);
    setInput(!input);
  };
  /**
   * method to update ntrsted job categories
   * @param {Object} val selected job categories
   */
  const update = (val) => {
    if (val.others && count < 3) {
      jobSelected.push(val.others);
    }
    let job_intrested = usersJob;
    const jobintrestedList = getJobIntrestedList();
    //update job_intrested data
    for (let job of jobSelected) {
      if (!jobintrestedList.includes(job)) {
        let jobData = { job_category: job, skills: [] };
        job_intrested.push(jobData);
      }
    }
    let data = {
      job_intrested: job_intrested,
      _id: storage.get("humlog_user"),
    };
    const formData = serialize(data);
    dispatch(userActions.updateUserData(data))
      .then((res) => {
        if (res.value.success) {
          dispatch(userActions.getUserData(storage.get("humlog_user")));
        }
      })
      .catch((err) => console.log(err));
    handelClose();
  };
  useEffect(() => {
    handleJobCategoryData(jobCategoryData, JobCategoryList);
    const jobIntrestedList = getJobIntrestedList();
    setJobIntrested(jobIntrestedList);
    setJobDisabled([...jobIntrestedList]);
  }, [JobCategoryList]);
  const loadProfile = () => {
    reset(userDetails);
  };
  // Matching job category values
  const handleJobCategoryData = async (jobCategoryData, JobCategoryList) => {
    if(JobCategoryList && JobCategoryList.length) {
      let data = await commonService.commonJobCategoryData(jobCategoryData, JobCategoryList);
      if (data) {
        setJobCategoryData(data);
      }
    }
  }
  return (
    <>
      <div className=" fixed fixedHeight">
        <div className="d-flex justify-content-between pt-1 fixed positionFixed ">
          <h6 className="in">
            <FormattedMessage
              id="jobInterest"
              defaultMessage="Job Interested In"
            />
          </h6>
        </div>
        <div className="d-flex justify-content-end pt-1"></div>
        <div className="afterPositio">
          <hr className="hrr" className="p-0 m-0" />
          <p className="in_a text-left mb-1">
            <FormattedMessage
              id="selectThree"
              defaultMessage="Select maximum 3"
            />{" "}
          </p>
        </div>
        <form onSubmit={handleSubmit(update)} className="fixed">
          <div className="job_cat">
            {currentJobCategoryData.map((val, index) => {
              return (
                <>
                  <input
                    type="checkbox"
                    id={val._id}
                    className="d-none"
                    name="job_intrested"
                    disabled={
                      jobDisabled.includes(val.name)
                        ? true
                        : !jobSelected.includes(val.name) && count >= 3
                        ? true
                        : false
                    }
                    checked={JobIntrestedList.includes(val.name) ? true : false}
                    value={val.name}
                    ref={register({
                      required: translate.formatMessage({id : "pleaseSelectAtleast"}),
                    })}
                    onClick={(e) => setIndustry(e)}
                  />
                  <label for={val._id} className="d-inline">
                    <div className="col-4 d-inline-flex justify-content-around ">
                      <div>
                        <div className="d-flex justify-content-center">
                          <img
                            src={val.image}
                            style={{ width: "80px" }}
                            className=" filter-green"
                          />{" "}
                        </div>
                        <h6
                          style={{ fontSize: "12px" }}
                          className="text-center"
                        >
                          {" "}
                          {val.name}{" "}
                        </h6>
                      </div>
                    </div>
                  </label>
                </>
              );
            })}
            <input
              type="checkbox"
              id="other"
              className="d-none w-75 border-primary"
              name="job_intrested"
              disabled={
                !job_intrested.includes(otherInput) && count >= 3 ? true : false
              }
              value={otherInput}
              ref={register({
                required: translate.formatMessage({id : "pleaseSelectAtleast"}),
              })}
              onClick={(e) => onClickOther(e)}
            />
            <label for="other" className="d-inline">
              <div className="col-4 d-inline-flex justify-content-around ">
                <div disabled={count >= 3 ? true : false}>
                  <div className="d-flex justify-content-center">
                    <img
                      src={others}
                      style={{ width: "80px" }}
                      className=" filter-green"
                    />{" "}
                  </div>
                  <h6 style={{ fontSize: "12px" }} className="text-center">
                    {" "}
                    other{" "}
                  </h6>
                </div>
              </div>
            </label>
            {input && (
              <div className="mt-1 px-3 pb-4">
                <input
                  type="text"
                  name="others"
                  onInput={(e) => {
                    setOtherInput(e.target.value);
                  }}
                  placeholder={translate.formatMessage({id: "enterYourJob"})}
                  autocomplete="off"
                  className={classNames("other_input py-1 pl-2 form-control", {
                    "is-invalid": errors.others,
                  })}
                  ref={register({
                    required: translate.formatMessage({id: "required"}),
                    maxLength: {
                      value: 30,
                      message: translate.formatMessage({id : "enterMaximumThirtyDigit"}),
                    },
                  })}
                />
                {errors.others && (
                  <p className="text-danger oom text-center">
                    {errors.others.message}
                  </p>
                )}
              </div>
            )}
          </div>

          {errors.job_intrested && (
            <p className="text-danger oom text-center">
              {errors.job_intrested.message}
            </p>
          )}
          <div className="mt-3 pstt fixed">
            <button className="common_btn" type="submit">
              {" "}
              <FormattedMessage id="submit" defaultMessage="Submit" />{" "}
            </button>
          </div>
        </form>
      </div>
    </>
  );
};
