import React, { useState, useEffect } from "react";
import avatar from "../../../assets/images/male_avatar.png";
import { useDispatch, useSelector } from "react-redux";
import { FaCamera } from "react-icons/fa";
import { serialize } from "object-to-formdata";
import * as authActions from "redux/actions/AuthActions";
import * as userActions from "redux/actions/UserActions";
import { useHistory } from "react-router-dom";
import { CompanyBasicDetailsForm } from "../Companybasicdetailform/CompanyBasicDetailsForm";
import { uploadFile } from "../../../utils/fileService";
import storage from "utils/storage";
import { Image } from "components/shared/imagecomponent/image";
import ConfirmDialog from "components/shared/ui-components/common-dialog";
import { FormattedMessage } from 'react-intl';

export const CompanyprofileDetails = (props) => {
  const { userDetails } = useSelector((state) => state.userReducer);
  const history = useHistory();
  const [picture, setPicture] = useState(userDetails && userDetails.profile_pic && userDetails.profile_pic.length > 0 ? userDetails.profile_pic : avatar);
  const [uploadFile, setUploadFile] = useState();
  const dispatch = useDispatch();
  const [loaded, setLoaded] = useState(false);
  const [dialog, setDialog] = useState({open: false, message: ""});
  const [finishStatus, setfinishStatus] = useState(false);
  // back button workflow
  const onBackButtonEvent = (e) => {
      e.preventDefault();
      if (!finishStatus) {
        setDialog({open: true, message: "Are you sure, you want to exit without creating profile?"});
      }
  }

  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname);
    // listen event of back button pressed
    window.addEventListener('popstate', onBackButtonEvent);
    return () => {
      window.removeEventListener('popstate', onBackButtonEvent);  
    };
  }, []);
  // onConfirm logout
  const onConfirm = () => {
    console.log("onconfirm");
    setfinishStatus(true)
    dispatch(authActions.logout);
    setTimeout(()=>{
      window.location.reload();
      history.push('/');
      // clear local storage on log out
      storage.clear();
    },200);
  }
  //oncancel remain on the same page
  const onCancel = () => {
    window.history.pushState(null, null, window.location.pathname);
    setfinishStatus(false)
  }
  useEffect(() => {
    if (!loaded) {
      setLoaded(true);
      dispatch(userActions.getUserData(storage.get("humlog_user"))).then( (response) => {
        if (response.value.success) {
          if(response.value.data.profile_pic) {
            setPicture(`${response.value.data.profile_pic}?${Date.now()}`);
          }
        }
      })
    }
  }, [loaded, dispatch]);
  //change your profile picture
  const onChangePicture = async (e) => {
    setPicture(URL.createObjectURL(e.target.files[0]));
    setUploadFile(e.target.files[0]);
  };
  const [choice, setchoice] = useState("");
  const update = (e) => {
    console.log(e.target.value);
  };

  return (
    <>
    {dialog.open && (
          <ConfirmDialog
            open={dialog.open}
            // val={state.val}
            setState={setDialog}
            message={dialog.message}
            confirm={onConfirm}
            cancel={onCancel}
          ></ConfirmDialog>
        )}
      <div className="main_user st">
        <div className="">
          <div className="row p-0 m-0 no-gutters">
            <div className="col-12 main-section text-center">
              <div className="  px-2 px-lg-4">
                <h5 className="page_title">
                  <FormattedMessage id="myProfile" defaultMessage="My Profile" />
                </h5>
                <div className="">
                  {/*THIS CODE COMMENTED BECAUSE USE FOR FUTURE REFERENCE START FROM HERE*/}
                  {/* <div className="d-flex justify-content-center ">
                    <div className="img_style_prev main_pic_box">
                    {picture && (
                      <Image picture={picture} alt="Profile Picture"></Image>
                  )}
                      <input
                        type="file"
                        accept=".png, .jpg, .jpeg"
                        className="d-none"
                        id="cam"
                        onChange={onChangePicture}
                      />
                      <label for="cam">
                        {" "}
                        <FaCamera className="camera_position" />{" "}
                      </label>
                    </div>
                  </div>
                  <h5 className="mt-1 font-weight-bold">
                    {" "}
                    {userDetails &&
                    userDetails.company_name &&
                    userDetails.name.length > 0
                      ? userDetails.company_name
                      : "Company Name"}
                  </h5>
                  <h5 className="ltr_clr mt-2 text-left user_m"></h5>
                  <div className=" ">
                    <div className="d-flex justify-content-between"></div>
                  </div> */}
                  {/* company basic detail form start */}
                  {/*THIS CODE COMMENTED BECAUSE USE FOR FUTURE REFERENCE END*/}
                  <CompanyBasicDetailsForm company_logo={uploadFile}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
