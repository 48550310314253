import { BASE_URL} from "config";
import axios from "axios";
import storage from "./storage";
/**
 * method for handling notification click event
 * @param {String} notification_type 
 * @param {number} userRole [0: job_seeker, 2: recruiter]
 * @returns redirction path 
 */
export const handleNotification = (notification_type, userRole) => {
    switch (notification_type) {
      case 'INCOMPLETE_PROFILE':{
        return '/profile';
      }
      case 'INACTIVE' :
        if(userRole === 2) {
          return '/jobs';
        }
        break;
      case 'JOB_POST' :
        if(userRole === 0) {
          return '/jobs';
        }
        if(userRole === 2) {
          return '/dashboard';
        }
        break;
      case 'JOB_APPLIED' :
        if(userRole === 0) {
          return {pathname:'/dashboard',state:{tab:0}};
        }
        if(userRole === 2) {
          return '/dashboard';
        }
        break;
      case 'APPLY_LATER':{
        if(userRole === 0){
          return {pathname:'/dashboard',state:{tab:1}};
        }
        break;
      }
      case 'INTERVIEW_SHORTLISTED' :
        if(userRole === 0) {
          return {pathname:'/dashboard',state:{tab:3}};
        }
        if(userRole === 2) {
          return '/dashboard';
        }
        break;
      case 'INTERVIEW_NOT_SHORTLISTED' :
        if(userRole === 0) {
          return '';
        }
        break;
      case 'INTERVIEW_CONFIRM' :
        if(userRole === 0) {
          return {pathname:'/dashboard',state:{tab:2}};
        }
        if(userRole === 2) {
          return '/dashboard';
        }
        break;
      case 'INTERVIEW_DENIED' :
        if(userRole === 2) {
          return '';
        }
        break;
      case 'JOB_HIRED' :
        if(userRole === 0) {
          return {pathname:'/dashboard',state:{tab:3}};
        }
        if(userRole === 2) {
          return '/dashboard';
        }
        break;
      case 'JOB_NOT_HIRED' :
        if(userRole === 0) {
          return {pathname:'/dashboard',state:{tab:4}};
        }
        break;
      default :
       return ''
    }
  }
/**
 * method for updating notification status to viewed
 * @param id Notification Id
 */
export const updateNotification = (id) => {
    const baseURL = BASE_URL();
    const token = storage.get("humlog_authToken");
    axios.put(`${baseURL}/notification/${id}`, '', {headers : {"Authorization" : `Bearer ${token}`}});
}