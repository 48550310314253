import React,{useEffect, useState} from "react";
import {FormattedMessage, FormattedDate, FormattedNumber, FormattedPlural, FormattedTime} from 'react-intl';
import "./education.scss";
import { useIntl } from 'react-intl';
import { Form, Row, Col } from "react-bootstrap";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import { AiOutlineUser } from "react-icons/ai";
import { useForm } from "react-hook-form";
import { serialize } from "object-to-formdata";
import storage from "utils/storage";
import { useHistory } from "react-router-dom";
import * as userActions from "redux/actions/UserActions";
import { useDispatch ,useSelector} from "react-redux";
import { UserActionTypes } from "redux/actions/UserActions/actionType";
import classNames from "classnames";

export const Educatiofrm = ({ handelClose }) => {
  const { userDetails } = useSelector((state) => state.userReducer);
  const [graduate, setGraduate] = useState({open:userDetails&&userDetails.qualification&&userDetails.qualification>2 ? true : false});
  const [otherText, setOtherText] = useState(false);

  useEffect(() => {
    loadProfile();
    if(userDetails.qualification === 'Other') {
      setGraduate({ open: !graduate.open });
    }
  }, []);
  const loadProfile = () => {
    let data = {
      qualification: userDetails.qualification.toString(),
      school_name: userDetails.educational_info[0]?.institution_name,
      collage_name: userDetails.educational_info[1]?.institution_name
      
    }
    reset(data);
  };

  // method to open graduation and above tab
  const openGraduation = (event) => {
    setGraduate({ open: !graduate.open });
    event.target.classList.toggle('graduation');
  }
  // method to close graduation and above tab
  const closeGraduation = (event) => {
    setGraduate({ open: false });
    document.getElementById("Graduate & above").classList.remove('graduation');
  }
  const openOtherText = (event) => {
    setOtherText(!otherText);
  }

  const dispatch = useDispatch();
  const history = useHistory();

  const { register, handleSubmit, reset,errors } = useForm({
    
  });
  /**
   * Method to update qualification field of user
   * @param {Object} val values filled by user
   */
  const update = (val) => {
    const dataval = {
      qualification: val.qualification,
    };
    if (val.otherqualification) {
      dataval.qualification = val.otherqualification;
    }

    let data = { ...dataval, _id: storage.get("humlog_user") };
    const formData = serialize(data);

    dispatch(userActions.updateUserData(data))
      .then((res) => {
        if (res.value.success) {
          dispatch(userActions.getUserData(storage.get("humlog_user")))
          console.log(res.value.message);     
        }
      })
      .catch((err) => console.log(err));
    handelClose();
  };

  const intl = useIntl();


  return (
    <>
      <div className="sup_pp">
        <div className="row no-gutters">
          <div className="col-12  pt-2">
            <h2 className="tttle prnl_head_aa">
              <FormattedMessage id = "educationQualification" efaultMessage="Education & Qualification"/>
            </h2>
            <div className="mt-4 ">
              <Form onSubmit={handleSubmit(update)}>
              <div className="d-flex justify-content-between">
                    <div className="form-check  detail_btn_ccaa text-center">
                      <input
                        className="form-check-input d-none"
                        type="radio"
                        name="qualification"
                        value="0"
                        onClick={(event) => {closeGraduation(event);}}
                        id="below 10th"
                        ref={register({
                          required: intl.formatMessage({ id: 'pleaseSelectQualification' }),
                        })}
                      />
                      <label
                        className="form-check-label text-capitalize  lw"
                        for="below 10th"
                      >
                        below 10th
                      </label>
                    </div>

                    <div className="form-check  detail_btn_ccaa text-center">
                      <input
                        className="form-check-input d-none"
                        type="radio"
                        name="qualification"
                        id="10th pass"
                        onClick={(event) => {closeGraduation(event);}}
                        value="1"
                        ref={register({
                          required: intl.formatMessage({ id: 'pleaseSelectQualification' }),
                        })}
                      />
                      <label
                        className="form-check-label text-capitalize  lw"
                        for="10th pass"
                      >
                        10th Pass
                      </label>
                    </div>

                    <div className="form-check detail_btn_ccaa text-center">
                      <input
                        className="form-check-input d-none"
                        type="radio"
                        name="qualification"
                        id="12th Pass"
                        onClick={(event) => {closeGraduation(event);}}
                        ref={register}
                        value="2"
                        ref={register({
                          required: intl.formatMessage({ id: 'pleaseSelectQualification' }),
                        })}
                      />
                      <label
                        className="form-check-label  text-capitalize lw "
                        for="12th Pass"
                      >
                        12th Pass
                      </label>
                    </div>

                    <div className="form-check detail_btn_ccaa text-center">
                      <input
                        className="form-check-input d-none"
                        type="radio"
                        id="Graduate & above"
                        name="qualification"
                        value="Other"
                        onClick={(event) => {openGraduation(event);}
                      }
                        ref={register({
                          required: intl.formatMessage({ id: 'pleaseSelectQualification' }),
                        })}
                      />
                      <label
                        className="form-check-label text-capitalize w "
                        for="Graduate & above"
                      >
                        <p className="grad"> Graduate & above </p>
                      </label>
                    </div>
                  </div>
                  {graduate.open && <div className="d-flex justify-content-between mt-2">
                    <div className="form-check  detail_btn_ccaa text-center">
                      <input
                        className="form-check-input d-none"
                        type="radio"
                        name="qualification"
                        value="3"
                        id="B.A"
                        ref={register({
                          required: intl.formatMessage({ id: 'pleaseSelectQualification' }),
                        })}
                      />
                      <label
                        className="form-check-label text-capitalize  lw"
                        for="B.A"
                      >
                        B.A
                      </label>
                    </div>

                    <div className="form-check  detail_btn_ccaa text-center">
                      <input
                        className="form-check-input d-none"
                        type="radio"
                        name="qualification"
                        id="B.Sc"
                        value="4"
                        ref={register({
                          required: intl.formatMessage({ id: 'pleaseSelectQualification' }),
                        })}
                      />
                      <label
                        className="form-check-label text-capitalize  lw"
                        for="B.Sc"
                      >
                        B.Sc
                      </label>
                    </div>

                    <div className="form-check detail_btn_ccaa text-center">
                      <input
                        className="form-check-input d-none"
                        type="radio"
                        name="qualification"
                        id="B.Com"
                        ref={register}
                        value="5"
                        ref={register({
                          required: intl.formatMessage({ id: 'pleaseSelectQualification' }),
                        })}
                      />
                      <label
                        className="form-check-label  text-capitalize lw "
                        for="B.Com"
                      >
                        B.Com
                      </label>
                    </div>

                    <div className="form-check detail_btn_ccaa text-center">
                      <input
                        className="form-check-input d-none"
                        type="radio"
                        id="otherGrad"
                        name="qualification"
                        value="otherQualification"
                        readOnly
                        onClick={(event) => {openOtherText(event);}
                      }
                        ref={register({
                          required: intl.formatMessage({ id: 'pleaseSelectQualification' }),
                        })}
                      />
                      <label
                        className="form-check-label text-capitalize w "
                        for="otherGrad"
                      >
                        <p className="grad"> Other </p>
                      </label>
                    </div>
                  </div>}

                  {otherText && (
                  <Form.Group controlId="exampleForm.ControlSelect1">
                    <Form.Label className="d-flex justify-content-start prnl_head_aa mt-2 mb-2">
                      <FormattedMessage id="otherEducation" defaultMessage="Other Qualification"/>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      autocomplete="off"
                      placeholder="Add your Qualification"
                      name="otherqualification"
                      className={classNames("form-control")}
                      ref={register({
                        required: "Please Enter Qualification",
                        maxLength: {
                          value: 30,
                          message: "maximum 30 characters allowed"
                        },
                        pattern: {
                          value: /^[a-zA-z.\s]+$/,
                          message: "Enter only alphabets",
                        },
                      })}
                    />
                    {errors.qualification && (
                    <p className="text-danger  oom p-0 m-0">
                      {errors.qualification.message}
                    </p>
                  )}
                  </Form.Group>
                )}

                <div className="py-2 px-2">
                  <button className="common_btn" type="submit">
                    <FormattedMessage id = "save" defaultMessage="Save" />
                  </button>
                </div>

                
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
