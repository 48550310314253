module.exports = {
  _privateFirebaseConfig: {
    apiKey: "AIzaSyDpEW9fxbwNeAXlxEYe2WWlfGeUN54lMCY",
    authDomain: "humlogjobs-firebase.firebaseapp.com",
    projectId: "humlogjobs-firebase",
    storageBucket: "humlogjobs-firebase.appspot.com",
    messagingSenderId: "943457608432",
    appId: "1:943457608432:web:bf4089d5f2538dd57b1835"
  },
  _privateVapidKey: {
    vapidKey: "BCNp2tECk-Y5hhDfapHdCyfjNuXT1Xd2mNMM30cv1B8Y84SR7u9JNtGQY8JTqFS4wLgBJ1P20DeRQer5_KadF7Q"
  },
  _authKey: {
    SITE_KEY: '6Lc6ymEhAAAAAJ8ixujM1rOs1sAtNT7E-xG5uWI4'
  },
  question: {
    WELCOME: 'Greetings from Humlogjobs. Before moving forward, Please let us know if you are Job seeker or Employer',
    GREET_JOB_SEEKER: 'Hey Job Seeker, how can we help you today?',
    GREET_EMPLOYER: 'Welcome Employer, What can we assist you with today?',
    ENTER_PHONE: 'Please Enter your 10-digits Phone Number',
    ENTER_QUERY: 'Please enter you query',
    ENTER_REVIEW: 'Please rate us on the scale of 1 to 10',
    ENTER_NAME: 'Please enter your name',
    CONNECT_SOON:'Thank you for contacting us, Please call on 8104685480. We will be happy to help'
  },
  SALARY: {
    MINIMUM: 7000,
    MAXIMUM: 50000
  },
  JOB_TYPE : {
    0 : "Office",
    1 : "Field",
    2 : "Shift",
    3 : "PartTime"
  }, 
  JOB_SHIFT : {
    0 : "General",  
    1 : "Rotation"
  },
  SALARY_FROM_TO : {
    0 : "Eg. ₹ 7000",
    1 : "Eg. ₹ 50000"
  },
  JOB_TYPE_MODAL : {
    0 : "jobType",
    1 : "jobShift"
  },
  selectedType: {
    FEEDBACK: 'feedback',
    QUERY: 'query'
  },
  queryType:{
    'feedback':0,
    'query':1
  },
  common: {
    GENDER: 'Gender',
    EXP_REQUIRED: 'Experience Required',
    JOB_LOCATION: 'Job Location',
    SELECT_DISTRICT: 'Select District',
    SELECT_CATEGORY: 'Select Category',
    SELECT_SKILLS: 'Select Skills',
    SALARY_RANGE: 'Job Salary Range',
    EG_7000: 'Eg. ₹ 7000',
    EG_50000: 'Eg. ₹ 50000',
    JOB_CATEGORY: 'Job Category',
    SKILLS: 'Skills',
    JOB_TYPE: 'Job Type',
    JOB_SHIFT: 'Job Shift',
    JOB_POST_TIME: 'Job Posted Time',
    APPLY_FILTERS: 'Apply Filters',
    LIMIT_30: 30,
    LOAD_MORE_JOBS: 'Load More Jobs',
    LOAD_MORE_JOB_SEEKERS: 'Load More JobSeekers',
    TICK_CHECKBOX: 'Please tick the checkbox above',
    SUBMIT_CAPTCHA: 'Submit Captcha',
    GET_CONTACT_AFTER_SHORTLIST: 'Get contact number of the applicants after shortlisting',
    VIEW_MORE_JS: 'View More JobSeekers',
    SALARY_EXPECTATION_RANGE: 'Salary Expectation Range',
    SALARY_EXPECTATIONS: 'Salary Expectations',
    JOB_SEEKER_LOCATION: 'Job-Seeker Location',
    AGE: 'Age',
    SPEAK_ENGLISH: 'Speak English',
    COMPLETED_PROFILE: 'Completed Profile',
    JOB_SEEKERS_LIST: `Job seeker's List`,
    LOCATION: 'Location',
    EXPERIENCE: 'Experience',
    QUALIFICATION: 'Qualification',
    FILTER_JOB_SEEKER: 'Filter Job-Seekers',
	FILTER_JOBS: 'Filter Jobs',
    NA:'N/A',
	LIMIT_7000: 7000,
    LIMIT_50000: 50000,
	CONTACT: 'Mobile Number',
	HAPPY_TO_HELP: 'Please call on 8104685480. We will be happy to help',
	CUSTOMER_SUPPORT: 'Customer Support',
    THANKYOU_FOR_CONTACT: 'Thank you for contacting us'
  },
  educationalQualificationList: [
    { name: "Select Educational Qualification", value: "" },
    { name: "Below 10th", value: "0" },
    { name: "10th Pass", value: "1" },
    { name: "12th Pass", value: "2" },
    { name: "Graduate & Above", value: "Other" }
  ],
  genderList: [
    { name: "Select Gender preference", value: "" },
    { name: "All", value: 0 },
    { name: "Female", value: 1 },
    { name: "Male", value: 2 },
    { name: "Other", value: 3 }
  ],
  experienceList: [
    { name: "Select work Experience requirement", value: "" },
    { name: "Fresher", value: 0 },
    { name: "Less Than 1 Year", value: 1 },
    { name: "1-2 Years", value: 2 },
    { name: "2-4 Years", value: 3 },
    { name: "4-6 Years", value: 4 },
    { name: "6-8 Years", value: 5 },
    { name: "8-10 Years", value: 6 },
    { name: "10+ Years", value: 7 }
  ],
  jobTypeList: [
    { name: "Select Job Type preference", value: "" },
    { name: "Office", value: 0 },
    { name: "Field", value: 1 },
    { name: "Shift", value: 2 },
    { name: "Part-Time", value: 3 }
  ],
  jobShiftList: [
    { name: "Select Job Shift preference", value: "" },
    { name: "General", value: 0 },
    { name: "Rotational", value: 1 },
  ],
  jobPostTimeList: [
    { name: "Select Job Post Time", value: "" },
    { name: "Today", value: "0" },
    { name: "3 Days ago", value: "1" },
    { name: "One Week ago", value: "2" },
    { name: "One Month ago", value: "3" },
    { name: "2 Months ago", value: "4" },
  ],
  jobSeekerGenderList: [
    { name: "Select Gender preference", value: "" },
    { name: "Female", value: 0 },
    { name: "Male", value: 1 },
    { name: "Other", value: 2 }
  ],
  ageList: [
    { name: "Select Age preference", value: "" },
    { name: '18-24 Yrs', value: 0 },
    { name: '25-30 Yrs', value: 1 },
    { name: '31-35 Yrs', value: 2 },
    { name: '36+ Yrs', value: 3 }
  ],
  speakEnglishList: [
    { name: 'Can speak English', value: '' },
    { name: 'No', value: 'No' },
    { name: 'Thoda', value: 'Thoda' },
    { name: 'Good', value: 'Good' },
  ],
  completedProfileList: [
    { name: 'select completed profile', value: '' },
    { name: '100% Completed', value: 100 },
    { name: 'Not Completed', value: '' },
  ],
  userAge: {
    0: '18-24 Yrs',
    1: '25-30 Yrs',
    2: '31-35 Yrs',
    3: '36+ Yrs'
  },
  userGender: {
    0: 'Female',
    1: 'Male',
    2: 'Other'
  },
  userExperience: {
    0: "Fresher",
    1: "Less than 1 Yr",
    2: "1-2 Years",
    3: "2-4 Years",
    4: "4-6 Years",
    5: "6-8 Years",
    6: "8-10 Years",
    7: "10+ Years",
  },
  userQualification: {
    0: "Below 10th",
    1: "10th Pass",
    2: "12th Pass",
    'Other': "Graduate & Above",
  },
  errorMessage: {
    SOMETHING_WRONG: 'Something went Wrong',
    ALREADY_REGISTERED: 'You have Already registered',
    INVALID_OTP: 'Sorry! Invalid OTP',
    INVALID_RATINGS: 'Ratings should be between 1 to 10',
    INVALID_PHONE: 'Please a valid 10-digits Phone number',
    INVALID_NAME: 'Please Enter a valid Name',
    INVALID_CAPTCHA: 'Please Verify Captcha',
    MIN_QUERY_LENGTH: 'Content should be greater than 5 characters',
    NO_RECORD: 'Sorry! No records found',
    TRY_AGAIN_FILTER: 'Try Again with another filters'
  },
  jobDetails: {
    EDU_QUALIFICATION: 'Educational Qualification',
    BELOW_10: 'Below 10th',
    PASS_10: '10th Pass',
    PASS_12: '12th Pass',
    ABOVE_GRADUATE: 'Graduate & Above'
  },
  userType: {
    JOB_SEEKER: 'Jobseeker',
    EMPLOYER: 'Employer',
    JOB_SEEKER_ROLE:0,
    EMPLOYER_ROLE:1
  },
  endPoint: {
    JOB_SEEKER_LOGIN: '/login/user',
    EMPLOYER_LOGIN: '/login/v2',
    OTP_VERIFY: '/login/v2/confirm',
    JOB_SEEKER_BASIC_DETAILS: '/user/basicdetail',
    EMPLOYER_BASIC_DETAILS: '/companydetail'
  },
  successMessage: {
    REDIRECT_SUCCESS: 'You have registered successfully!! Please wait... redirecting to Login Page',
    SUBMIT_QUERY: 'We will connect with you soon, Thank you for your precious time',
    SUBMIT_REVIEW: 'Thank you for your valuable feedback',
    CAPTCHA_VERIFIED: 'Captcha verified'
  },
  whatsappUrl: {
    WHATSAPP_URL: 'https://api.whatsapp.com/send?phone=919509459157&text=Hey'
  },
  stepsId: {
    WELCOME_QUESTION: 'welcomeQuestion',
    USER_SELECTION: 'userSelection',
    JS: 'js',
    EMP: 'emp',
    QUESTION4: 'question4',
    SIGNUP: 'signup',
    CONTACT_NUMBER: 'contactNumber',
    OTP: 'otp',
    ENTER_OTP: 'enterOtp',
    VERIFY_OTP: 'verifyotp',
    QUERY_NAME: 'queryName',
    SUBMIT_QUERY_NAME: 'submitQueryName',
    QUERY_CONTACT: 'queryContact',
    SUBMIT_QUERY_CONTACT: 'submitQueryContact',
    FEEDBACK_NAME: 'feedbackName',
    SUBMIT_FEEDBACK_NAME:'submitFeedbackName',
    FEEDBACK_CONTACT:'feedbackContact',
    SUBMIT_FEEDBACK_CONTACT: 'submitFeedbackContact',
    QUERY: 'query',
    SUBMIT_QUERY: 'submitQuery',
    REVIEW: 'review',
    SUBMIT_REVIEW: 'submitReview',
    OPEN_CAPTCHA:'openCaptcha',
    CONNECT_SOON: 'connectSoon'
  },
  triggerValue: {
    USER_SELECTION: 'userSelection',
    JS: 'js',
    EMP: 'emp',
    QUESTION4: 'question4',
    SIGNUP: 'signup',
    CONNECT_SOON: 'connectSoon',
    FEEDBACK_NAME: 'feedbackName',
    CONTACT_NUMBER: 'contactNumber',
    OTP: 'otp',
    ENTER_OTP: 'enterOtp',
    VERIFY_OTP: 'verifyotp',
    SUBMIT_QUERY_NAME: 'submitQueryName',
    QUERY_CONTACT: 'queryContact',
    SUBMIT_QUERY_CONTACT: 'submitQueryContact',
    QUERY: 'query',
    SUBMIT_FEEDBACK_NAME: 'submitFeedbackName',
    FEEDBACK_CONTACT: 'feedbackContact',
    SUBMIT_FEEDBACK_CONTACT: 'submitFeedbackContact',
    REVIEW: 'review',
    SUBMIT_QUERY: 'submitQuery',
    OPEN_CAPTCHA: 'openCaptcha',
    SUBMIT_REVIEW: 'submitReview',
  },
  otpVerification: {
    CHAT_BOT: 'chatBot',
    CONTACT: 'contact'
  }
};
