import "./jobdetail.scss";
import React, { useState, useEffect, useRef } from "react";
import { FormattedMessage } from "react-intl";
import { useIntl } from "react-intl";
import { Form, Col } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import * as JobAction from "redux/actions/JobActions";
import storage from "utils/storage";
import { useHistory } from "react-router-dom";
import classNames from "classnames";
import * as userActions from "redux/actions/UserActions";
import TextField from "@material-ui/core/TextField";
import DistrictList from "./customDistricts";
// import { DistrictList } from "components/jobseeker/DistrictList";
import Autocomplete from "@material-ui/lab/Autocomplete";
import * as JobActions from "redux/actions/JobActions";
import ConfirmDialog from "components/shared/ui-components/common-dialog";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import avatar from "../../../assets/images/male_avatar.png";
import { uploadFile } from "../../../utils/fileService";
import { Image } from "components/shared/imagecomponent/image";
import { FaCamera } from "react-icons/fa";
import { Multiselect } from "multiselect-react-dropdown"; // dropdowns for skills
import * as jobCategoryActions from "redux/actions/jobcategory";
import { regexValidation } from "utils/regexValidation";
import * as commonService from "utils/CommonService.js";
import { jobCategoryData } from "../jobCategoryData"; // job category values
import JobTitleField from "./JobTitleField";

export const JobDetails = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { JobCategoryList } = useSelector((state) => state.jobCategoryReducer);
  const { userSuscriptionDetails } = useSelector((state) => state.userReducer);
  const [count, setCount] = useState(500);
  const [dialog, setDialog] = useState({open: false, message: "", type: "", confirm_text: "", cancel_text: ""});
  const [AnimatedDialog, setAnimatedDialog] = useState({isOpen: false});
  const [picture, setPicture] = useState(avatar);
  const [jobLogo, setJobLogo] = useState();
  let jobPostData = useRef(null);
  const [loaded, setLoaded] = useState(false);
  const [category, setcategory] = useState('');
  const [skills, setskills] = useState([]); // storing skills value
  const [currentSkills, setcurrentSkills] = useState([]); // storing current value of skills
  const [skillsError, setSkillsError] = useState(); // storing skills values
  const [salaryFrom, setSalaryFrom] = useState(); // storing salary from value
  const [salaryTo, setSalaryTo] = useState(); // storing salary to value
  const [showOtherSkills, setShowOtherSkills] = useState(false);
  const [currentJobCategoryData, setJobCategoryData] = useState([]); // storing job category values
  const [isChecked, setIsChecked] = useState(true); // variable use for checkbox is checked or not
  const [logoHover, setOpenLogoTooltip] = useState(false); // variable use for open and closing tooltip
  const { watch, register, handleSubmit, reset, errors, setError, setValue } = useForm({
    mode: "onBlur",
  });
  const multiSelectDropdown = React.createRef();
  const translate = useIntl();
  useEffect(() => {
    const fetch = async () => {
      try {
        if (!loaded) {
          setLoaded(true);
          await dispatch(JobActions.getJobListDataById(storage.get("humlog_user")));
          await dispatch(userActions.getUserData(storage.get("humlog_user")));
          await dispatch(userActions.getSubscriptionDetails());
      }
      } catch(err) {
        console.log("error", err)
      }
    }
    handleJobCategoryData(jobCategoryData, JobCategoryList);
    fetch ()
  }, [loaded, JobCategoryList, dispatch]);
  // Matching job category values
  const handleJobCategoryData = async (jobCategoryData, JobCategoryList) => {
    if(JobCategoryList && JobCategoryList.length) {
      let data = await commonService.commonJobCategoryData(jobCategoryData, JobCategoryList);
      if (data) {
        setJobCategoryData(data);
      }
    }
  }
  // handling skills dropdowns according to job category
  const handleSkillsDrop = async(value) => {
    setcategory(value);
    multiSelectDropdown.current.resetSelectedValues(); // reset skills
    await _handleDropDowns(value);
  }
   // Handling checkbox is checked or not
   const handleOnChange = () => {
    setIsChecked(!isChecked);
  };
  // get all skills list
  const _handleDropDowns = async(value) => {
    let jobCategoryList;
    const res = await dispatch(jobCategoryActions.getJobCategoryListData())
      if (res.value.success) {
        jobCategoryList = res.value.data;
    }
    let skillsData;
    if (jobCategoryList.length) {
      skillsData = jobCategoryList.filter(
      (element) => element._id === value
    );
    skillsData[0].skills.push('Other');
    setskills(skillsData[0].skills);
    }
  };
  const jobPostingLimit = 2 // Variable to set Job posting limit for Employer having Varification status:'Approved'
  useEffect( () => {
    // show alert message when employer created profile but document not uploaded for verification
    if( jobList && jobList.length !==0 && userDetails && userDetails.verification_status === "Not Initiated") {
      setDialog({
        open: true,
        message: intl.formatMessage({id: "pleasecompleteAuthentication"}),
        type: "Not_Initiated",
        confirm_text: intl.formatMessage({id: "authenticateNow"}),
        cancel_text: intl.formatMessage({id : "authenticateLater"})
      });
      return;
    }
    // show alert message when verification is under process and admin neither approved nor declined your document
    if( jobList && jobList.length !==0 && userDetails && userDetails.verification_status === "In Progress") {
      setDialog({
        open: true,
        message: intl.formatMessage({id:"yourVerification"}),
        type: "In_Progress",
        confirm_text:intl.formatMessage({id:'okay'})
      });
      return;
    }
    // show alert message when verification document is declined by admin
    if (userDetails && userDetails.verification_status === "Declined") {
      const feedback = userDetails.verification_feedback;
      setDialog({
        open: true,
        message: `${intl.formatMessage({id: "yourDocumentDeclined" }, {reason: userDetails && userDetails.verification_feedback})}`,
        type: "Declined",
        confirm_text: intl.formatMessage({ id: "reSubmitText" }),
        cancel_text: intl.formatMessage({ id: "authenticateLater" }),
      });
      return;
    }
    // show alert message when verification is approved by admin and plan is not subscribed
    if(jobList && jobList.length >= jobPostingLimit && userDetails && userDetails.verification_status === "Approved" && userDetails.is_premium === false) {
      setDialog({
        open: true,
        message: intl.formatMessage({id: "chooseplanverification"}),
        type: "Choose Plan",
        confirm_text: intl.formatMessage({id : "choosePlan"})
      });
      return;
    }
    // show alert message when all subscription plan expired
    if(userSuscriptionDetails.hasOwnProperty("job_post_usage") && userSuscriptionDetails.job_post_usage < 1) {
      setDialog({
        open: true,
        message: intl.formatMessage({id: "jobPostUsage"}),
        confirm_text: intl.formatMessage({id : "choosePlan"}),
        cancel_text: intl.formatMessage({id : "cancel"})
      });
      return;
    }
  })
  const watchSalary_from = watch("salary_from");
  // Function to compare salaryFrom and salaryTo [ salaryTo must be greater than salaryFrom ]
  const isGreater = (salary_to) => {
    if (salary_to) {
      if (salary_to > 50000) {
        return false
      } else {
        return parseInt(salary_to) > parseInt(watchSalary_from);
      }
    } return true
  }
  const { userDetails } = useSelector((state) => state.userReducer);
  const { jobList } = useSelector((state) => state.jobReducer);
  const redirectToVerification = () => {
      if(userDetails && (userDetails.verification_status === "Not Initiated" || userDetails.verification_status === "Declined")){
        history.push("/companyverification");
      } else if (userDetails && (userDetails.verification_status === "Approved") && (userDetails.is_premium === false)) {
        history.push("/chooseplan");
      } else if (userSuscriptionDetails.hasOwnProperty("job_post_usage") && userSuscriptionDetails.job_post_usage < 1) {
        history.push("/chooseplan");
      } else {
        history.push("/dashboard");
      }
  }
  const onCancel = () => {
    history.push("/dashboard");
  }
 /* method to handle job post */ 
  const handleJobPost = (val, event) => {
    jobPostData.current = val;
    if(currentSkills.length === 0){
      setSkillsError(intl.formatMessage({id: 'required'})); // set skills error
      return;
    }
    // open dialog for isAnimatedJdREquired if user has more than 0 animated jd to post
    if(userSuscriptionDetails && userSuscriptionDetails.hasOwnProperty("animated_jd_usage") && userSuscriptionDetails.animated_jd_usage > 0) {
      openAnimatedJd(event);
    } else {
      jobPostWithAnimatedJd(false);
    }

  }
  /* open Animated jd dialog box */
  const openAnimatedJd = (event) => {
    setAnimatedDialog({isOpen: true});
    event.preventDefault();
  };

  /**
   * method to post a job
   * @param {String} isAnimatedJdRequired 
   */
  const jobPostWithAnimatedJd = async (isAnimatedJdRequired) => {
    try {
      const val = jobPostData.current;
      setAnimatedDialog({isOpen: false});
      let imageUrl;
      if(val.hasOwnProperty('salary_from') && !val.salary_from.length) {
        delete val.salary_from
      }
      if(val.hasOwnProperty('salary_to') && !val.salary_to.length) {
        delete val.salary_to
      }
      if(val.other_skills) {
        const index = currentSkills.indexOf('Other');
        setcurrentSkills(currentSkills.splice(index, 1));
        setcurrentSkills(currentSkills.push(val.other_skills));
        delete val.other_skills;
      }
      if(val.hasOwnProperty('job_location') && !val.job_location.length) {
        delete val.job_location
      }
      let data = {
        ...val,
        company_logo: userDetails.profile_pic,
        created_by: userDetails._id,
        skills: currentSkills.join(), // post skills values
      };
      // upload company_logo
      if(jobLogo) {
        imageUrl = await uploadFile(jobLogo,'job_logo');
        data.company_logo = imageUrl;
      }
      if (isAnimatedJdRequired) {
        data.animated_jd_required = true;
      }
      if (val.can_contact_emp === 'true') {
          data.can_contact_emp = true  
      }
      let res = await dispatch(JobAction.createJobData(data));
      if (res.value.success) {
        if(userDetails && userDetails.verification_status !== "Not Initiated") {
          history.push("/dashboard");
        }
        setDialog({open: true, message: intl.formatMessage({id : "pleasecompleteAuthentication"}), type: "Not_Initiated", 
          confirm_text: intl.formatMessage({id: "authenticateNow"}), cancel_text: intl.formatMessage({id : "authenticateLater"})});
      }
      reset();
    } catch (e) {
        console.log("error occurred in job post", e);
        reset();
    }
  };
  const DistrictListt = DistrictList.sort();

  const intl = useIntl();
  // Close deactivate dialog
  const closeAnimatedJD = () => {
    setAnimatedDialog(false);
  };

  // change profile picture
  const onChangePicture = async (e) => {
    setPicture(URL.createObjectURL(e.target.files[0]));
    setJobLogo(e.target.files[0]);
  };
  const handleSelectSkills = (e) => {
    if (e.includes('Other')) {
      setShowOtherSkills(true);
    }
    setcurrentSkills(e);
    setSkillsError(''); // set skills error
  }
  // Removed Skills value
  const handleRemovedSkills = (e) => {
    if (!e.includes('Other')) {
      setShowOtherSkills(false);
    }
    setcurrentSkills(e);
  }
  // Checking Salary Input field validation
  const handleSalaryTo = (salaryTo, salaryFrom) => {
    if(salaryTo?.length && !salaryFrom?.length) {
      return(
        register({
          pattern: {
            value: /^[0-9\b]+$/,
            message: intl.formatMessage({id: "enterOnlyNumbers"}),
          },
          minLength: {
            value: 4,
            message: intl.formatMessage({id: "minimumFourDigitAllowed"}),
          },
          maxLength: {
            value: 5,
            message: intl.formatMessage({id: "maximumFiveDigit"}),
          },
          validate: {
            validation: (value) =>
              (value >= 7000 && value <= 50000 )||
              intl.formatMessage({id: "salaryMinimum"}),
          },
        })
      )
    } else if(salaryTo?.length && salaryFrom?.length) {
      return(
        register({
          pattern: {
            value: /^[0-9\b]+$/,
            message: intl.formatMessage({id: "enterOnlyNumbers"}),
          },
          minLength: {
            value: 4,
            message: intl.formatMessage({id: "minimumFourDigitAllowed"}),
          },
          maxLength: {
            value: 5,
            message: intl.formatMessage({id: "maximumFiveDigit"}),
          },
          validate: {
            validation: (value) =>
            (value >= 7000 && value <= 50000 ) ||
              intl.formatMessage({id: "salaryMinimum"}),
          },
          validate: isGreater,
        })
      )
    } else if(!salaryFrom?.length && !salaryTo?.length) {
      return(
        register({
          pattern: {
            value: /^[0-9\b]+$/,
            message: intl.formatMessage({id: "enterOnlyNumbers"}),
          },
          minLength: {
            value: 4,
            message: intl.formatMessage({id: "minimumFourDigitAllowed"}),
          },
          maxLength: {
            value: 5,
            message: intl.formatMessage({id: "maximumFiveDigit"}),
          },
          validate: {
            validation: (value) =>
              value >= 0 ||
              intl.formatMessage({id: "minimumSalary"}),
          },
        })
      )
    }
  } 
  const handleSalaryFrom = (salaryFrom) => {
    if(salaryFrom) {
      return(
        register({
          minLength: {
            value: 4,
            message: intl.formatMessage({id: "minimumFourDigitAllowed"}),
          },
          pattern: {
            value: /^[0-9\b]+$/,
            message: intl.formatMessage({id: "enterOnlyNumbers"}),
          },
          validate: {
            validation: (value) =>
            (value >= 7000 && value <= 50000 ) ||
              intl.formatMessage({id: "minimumSalary"}),
          },
          maxLength: {
            value: 5,
            message: intl.formatMessage({id: "maximumFiveDigit"}),
          },
        })
      )
    } else if(!salaryFrom) {
      return(
        register({
          validate: {
            validation: (value) =>
              value >= 0 ||
              intl.formatMessage({id: "minimumSalary"}),
          },
        })
      )
    }
  }
  // Showing Consultancy && Company Input Field
  const _handleConsultancyName = (userDetails) => {
    if (userDetails.organization_type === 'consultancy') {
      return (
        <Form.Group>
          <Form.Label className="d-flex justify-content-start llbl">
            <FormattedMessage
              id="consultancyName"
              defaultMessage="Consultancy Name"
            />
            <font className="text-danger">*</font>
          </Form.Label>
          <Form.Control
            type="text"
            autocomplete="off"
            placeholder={intl.formatMessage({
              id: "companyNameHiring",
              defaultMessage: "Company name hiring for"
            })}
            name="company_name"
            className={classNames("form-control", {
              "is-invalid": errors.company_name,
            })}
            ref={register({
              required: intl.formatMessage({ id: "enterCompanyName" }),
              pattern: {
                value: regexValidation.CLIENT_NAME,
                message: intl.formatMessage({ id: "companyNameSpecialCharacter" }),
              },
              minLength: {
                value: 5,
                message: intl.formatMessage({ id: "minimumDigitFive" }),
              },
              maxLength: {
                value: 100,
                message: intl.formatMessage({ id: "maximumHundred" }),
              },
            })}
          />
          {errors.company_name && (
            <p className="text-danger oom p-0 m-0">
              {errors.company_name.message}
            </p>
          )}
        </Form.Group>
      )
    } else {
      if (userDetails.organization_type === 'company') {
        return (
          <Form.Group>
            <Form.Label className="d-flex justify-content-start llbl">
              <FormattedMessage id="companyName" defaultMessage="Company Name" />
              <font className="text-danger">*</font>
            </Form.Label>
            <Form.Control
              type="text"
              autocomplete="off"
              placeholder={intl.formatMessage({
                id: "companyNameHiring",
                defaultMessage: "Company name hiring for"
              })}
              name="company_name"
              className={classNames("form-control readOnly", {
                "is-invalid": errors.company_name,
              })}
              ref={register()}
              defaultValue={userDetails.company_name}
              readOnly
            />
          </Form.Group>
        )
      } else {
        return (
          <Form.Group>
            <Form.Label className="d-flex justify-content-start llbl">
              <FormattedMessage
                id="companyName"
                defaultMessage="Company Name"
              />
              <font className="text-danger">*</font>
            </Form.Label>
            <Form.Control
              type="text"
              autocomplete="off"
              placeholder={intl.formatMessage({
                id: "companyNameHiring",
                defaultMessage: "Company name hiring for"
              })}
              name="company_name"
              className={classNames("form-control", {
                "is-invalid": errors.company_name,
              })}
              ref={register({
                required: intl.formatMessage({ id: "enterCompanyName" }),
                pattern: {
                  value: regexValidation.CLIENT_NAME,
                  message: intl.formatMessage({ id: "allowSpecialCharacter" }),
                },
                minLength: {
                  value: 5,
                  message: intl.formatMessage({ id: "minimumDigitFive" }),
                },
                maxLength: {
                  value: 100,
                  message: intl.formatMessage({ id: "maximumHundred" }),
                },
              })}
            />
            {errors.company_name && (
              <p className="text-danger txt">
                {errors.company_name.message}
              </p>
            )}
          </Form.Group>
        )
      }
    }
  }
  // Show Logo Tooltip
  const handleMouseIn = () => {
    setOpenLogoTooltip(true);
  }
  // Close Logo Tooltip
 const handleMouseOut = () => {
    setOpenLogoTooltip(false);
  }
  // Handling logo Tooltip 
  const _handleTooltip = (logoHover, userDetails) => {
    if (logoHover === true) {
      return (
        <div className="tooltip-container">
          <div className="company-logo">
            <div>
              {
                userDetails.profile_pic ? (
                  <img src={userDetails.profile_pic} className="company-profile" onError={(e) => {     
                    e.target.onError = null;
                    e.target.src = avatar;
                  }} alt="company-logo" />
                ) : (
                  <img src={avatar} className="client-profile" alt="company-logo" />
                )
              }
            </div>
          </div>
          <div className="hiring-for-text">
            {
              userDetails.organization_type === 'company' ? (
                <p>Hiring for Company</p>
              ) : (
                <p>Hiring for Client</p>
              )
            }
          </div>
        </div>
      )
    }
  }
  return (
    <>
      {dialog.open && (
          <ConfirmDialog
            open={dialog.open}
            setState={setDialog}
            message={dialog.message}
            noCancelbtn={["In_Progress","Approved","Choose Plan"].includes(dialog.type) ? true : false}
            confirm_text={dialog.confirm_text}
            cancel_text={dialog.cancel_text}
            confirm={redirectToVerification}
            cancel={onCancel}
          ></ConfirmDialog>
        )}
      <div className="px-3 st p_bottom">
        <div className="row no-gutters">
          <div className="col-12 ">
            <div className="">
              <div className="d-flex justify-content-center text-center">
                <div>
                  <div className="">
                    <h5 className="page_title">
                      <FormattedMessage id="jobPost" defaultMessage="Post Job" />
                    </h5>
                    <Form onSubmit={handleSubmit(handleJobPost)} className="jobPostForm">
                      <div className="d-flex justify-content-center position-relative" onMouseOver={handleMouseIn} onMouseOut={handleMouseOut}>
                        <div className="img_style_prev main_pic_box">
                          {picture && (
                            <Image picture={picture} alt="Profile Picture"></Image>
                          )}
                          <input
                            type="file"
                            className="d-none"
                            id="cam"
                            onChange={onChangePicture}
                            accept=".png, .jpg, .jpeg"
                          />
                          <label for="cam">
                            {" "}
                            <FaCamera className="camera_position" />{" "}
                          </label>
                        </div>
                        {/* Tooltip-section start from here */}
                          { userDetails.organization_type && (_handleTooltip(logoHover, userDetails))}
                        {/* Tooltip-section end */}
                      </div>
                      {/* consultancy-name section start from here */}
                        {_handleConsultancyName(userDetails)}
                      {/* consultancy-name section end */}
                      {/* Job-title section start from here */}
                      <Form.Group controlId="job-title">
                        <JobTitleField
                          errors={errors}
                          register={register}
                          classNames={classNames}
                          setValue={setValue}
                          onTitleSelect={(_job) => {
                            setValue("description", _job.description);
                          }}
                        />
                      </Form.Group>
                      {/* Job-title section end */}
                      {/* Job-category section start from here */}                  
                      <Form.Group controlId="job-category">
                        <Form.Label className="d-flex justify-content-start llbl">
                          <FormattedMessage id="jobCategory" defaultMessage="Job Category" />
                          {" "}<font className="text-danger ml-1">*</font>{" "}
                        </Form.Label>
                        <Form.Control
                          as="select"
                          autocomplete="off"
                          name="category"
                          value={category}
                          onChange={(e) => {
                            handleSkillsDrop(e.target.value)
                          }}
                          ref={register({
                            required: intl.formatMessage({id: 'enterJobDetails'}),
                          })}
                        >
                          <option value="" disabled="disabled">Select</option>
                          {currentJobCategoryData && currentJobCategoryData.length && currentJobCategoryData.map((val) => {
                          return (
                              <>
                                <option value={val._id}> {val.name}</option>
                              </>
                            );
                          })}
                        </Form.Control>
                        {errors.category && (
                          <p className="text-danger oom p-0 m-0">
                            {errors.category.message}
                          </p>
                        )}
                      </Form.Group>
                      {/* Job-category section end */}
                      <Form.Group controlId="salary">
                        <Form.Label className="d-flex justify-content-start llbl">
                          <FormattedMessage id="monthlySalary" defaultMessage="Monthly In-hand Salary" />
                          <b>{" "}<font className="text-danger ml-1">*</font>{" "}</b>
                        </Form.Label>
                        <Form.Row>
                          <Col>
                            <Form.Control
                              placeholder="Eg. ₹ 7000 "
                              name="salary_from"
                              autocomplete="off"
                              className={classNames("form-control", {
                                "is-invalid": errors.salary_from,
                              })}
                              onChange={(e) => setSalaryFrom(e.target.value)}
                              ref={handleSalaryFrom(salaryFrom)}
                            />
                            {errors.salary_from && (
                              <p className="text-danger  oom p-0 m-0">
                                {errors.salary_from.message}
                              </p>
                            )}
                          </Col>
                          <FormattedMessage id="to" defaultMessage="to" />
                          <Col>
                            <Form.Control
                              placeholder="Eg. ₹ 50000 "
                              name="salary_to"
                              autocomplete="off"
                              className={classNames("form-control", {
                                "is-invalid": errors.salary_to, 
                              })}
                              onChange={(e) => setSalaryTo(e.target.value)}
                              ref={handleSalaryTo(salaryTo, salaryFrom)}
                            />
                            {errors.salary_to &&
                              errors.salary_to.type === "validate" && (
                                <p className="text-danger  oom p-0 m-0">
                                  <FormattedMessage id="salaryMinimum"/>
                                </p>
                              )}

                            {errors.salary_to && (
                              <p className="text-danger  oom p-0 m-0">
                                {errors.salary_to.message}
                              </p>
                            )}
                          </Col>
                        </Form.Row>
                      </Form.Group>

                      {/* job-location-section start from here */}
                      <Form.Group controlId="job-location">
                        <Form.Label className="d-flex justify-content-start llbl">
                          <FormattedMessage id="jobLocation" defaultMessage="Job Location" />
                        </Form.Label>
                        <Form.Control
                          type="text"
                          autocomplete="off"
                          maxLength={100}
                          placeholder={intl.formatMessage({
                            id: "jobEnterLocation",
                            defaultMessage:"Enter your location"
                          })}
                          name="job_location"
                          className={classNames("form-control", {
                            "is-invalid": errors.job_location,
                          })}
                          ref={register({
                            maxLength: {
                              value: 100,
                              message: intl.formatMessage({id: "maximumHundredCharacter"})
                            },
                          })}
                        />
                        {errors.job_location && (
                          <p className="text-danger  txt">
                            {errors.job_location.message}
                          </p>
                        )}
                      </Form.Group>
                      {/* job-location-section end */}
          
                      
                      {/* district-section start from here */}
                      <Form.Group controlId="district">
                        <Form.Label className="d-flex justify-content-start district-text">
                          <FormattedMessage id="districtText" defaultMessage="District"/>
                          {" "}<font className="text-danger ml-1">*</font>{" "}
                        </Form.Label>
                        <Autocomplete
                          id="combo-box-demo"
                          name="district"
                          ref={register}
                          options={DistrictListt}
                          defaultValue={
                            userDetails.job_location?.length > 0
                              ? userDetails.job_location
                              : null
                          }
                          getOptionLabel={(option) => option}
                          style={{ width: "100%" }}
                          className="mt-2 p-0 m-0"
                          renderInput={(params) => (
                            <TextField
                              name="district"
                              placeholder={intl.formatMessage({
                                id: "enterYourDistrict",
                              })}
                              inputRef={register({
                                required: intl.formatMessage({
                                  id: "pleaseEnterDist",
                                }),
                              })}
                              {...params}
                              variant="outlined"
                            />
                          )}
                        />
                        {errors.district && (
                          <p className="text-danger  oom p-0 m-0">
                            {errors.district.message}
                          </p>
                        )}
                      </Form.Group>
                      {/* district-section end */}

                      <Form.Group controlId="job-info">
                        <Form.Label className="d-flex justify-content-start llbl">
                          <FormattedMessage id="jobInfo" defaultMessage="Job Info / Job Description"/>
                          {" "}<font className="text-danger ml-1">*</font>{" "}
                        </Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={3}
                          name="description"
                          maxLength={500}
                          autocomplete="off"
                          placeholder={intl.formatMessage({
                            id: "MaxCharacterLimit",
                          })}
                          onChange={(e) =>
                            setCount(500 - e.target.value.length)
                          }
                          className={classNames("form-control", {
                            "is-invalid": errors.description,
                          })}
                          ref={register({
                            required: intl.formatMessage({
                              id: "pleaseEnterJobDesc",
                            }),

                            maxLength: {
                              value: 500,
                              message:
                                intl.formatMessage({id: "descriptionMaximum"}),
                            },
                          })}
                        />
                        <div className="text-right row">
                          <div className="col-md-8">
                            {errors.description && (
                              <p className="text-danger  oom p-0 m-0">
                                {errors.description.message}
                              </p>
                            )}
                          </div>
                          <div className="col-md-4">
                            <p className="p-0 m-0">
                              {" "}
                              {`${count}`}{" "}
                            </p>
                          </div>
                        </div>
                      </Form.Group>

                      <Form.Group controlId="skills">
                        <Form.Label className="d-flex justify-content-start llbl">
                          <FormattedMessage id="skillRequired" defaultMessage="Skill Required"/>
                          {" "}<font className="text-danger ml-1">*</font>{" "}
                        </Form.Label>
                        <Multiselect
                          options={skills}
                          isObject={false}
                          selectionLimit={5}
                          onSelect={ (e)=> handleSelectSkills(e)}
                          onRemove={ (e)=> handleRemovedSkills(e)}
                          ref={multiSelectDropdown}
                          className={classNames("", {
                            "is-invalid": errors.skills,
                          })}
                          placeholder={intl.formatMessage({ id: "addSkills" })}
                          style={{
                            multiselectContainer: {
                              marginBottom: "10px",
                            },
                            optionContainer: { maxHeight: "140px" },
                            chips: {
                              whiteSpace: "break-spaces",
                            },
                          }}
                        />
                        {skillsError && (
                          <p className="text-danger  oom p-0 m-0">
                            {skillsError}
                          </p>
                        )}
                      </Form.Group>
                      {showOtherSkills === true ? 
                      <Form.Group controlId="other-skills">
                        <Form.Label className="d-flex justify-content-start llbl">
                          <FormattedMessage id="otherSkills" defaultMessage="Other Skills"/>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder={intl.formatMessage({ id: "addOtherSkills" })}
                          name="other_skills"
                          autocomplete="off"
                          maxLength={50}
                          className={classNames("form-control", {
                            "is-invalid": errors.other_skills,
                          })}
                          ref={register({
                            maxLength: {
                              value: 50,
                              message: intl.formatMessage({id: "maximumFiftyDigit"})
                            },
                            pattern: {
                              value: /^[a-zA-Z\s.,_-]*$/,
                              message: intl.formatMessage({id: "specialCharacterNotAllowed"}),
                            },
                          })}
                        />
                        {errors.other_skills && (
                          <p className="text-danger  txt">
                            {errors.other_skills.message}
                          </p>
                        )}
                      </Form.Group> : ''}
                      
                      
                      <Form.Group controlId="no-of-opening">
                        <Form.Label className="d-flex justify-content-start llbl">
                          <FormattedMessage id="noOfOpenings" defaultMessage="No. of Openings"/>
                          {" "}<font className="text-danger ml-1">*</font>{" "}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          autocomplete="off"
                          placeholder={intl.formatMessage({
                            id: "requiredCandidate",
                          })}
                          name="openings"
                          className={classNames("form-control", {
                            "is-invalid": errors.openings,
                          })}
                          ref={register({
                            required: intl.formatMessage({ id: "required" }),
                            pattern: {
                              value: /^[0-9\b]+$/,
                              message: intl.formatMessage({id: "enterOnlyNumbers"}),
                            },
                            maxLength: {
                              value: 2,
                              message: intl.formatMessage({id: "maxJobOpening"}),
                            },
                            validate: {
                              validation: (value) =>
                                value > 0 ||
                                intl.formatMessage({id: "greaterThanZero"}),
                            },
                          })}
                        />
                        {errors.openings && (
                          <p className="text-danger  oom p-0 m-0">
                            {errors.openings.message}
                          </p>
                        )}
                      </Form.Group>

                      <div className="form-group mt-2">
                        <Form.Label className="d-flex justify-content-start llbl" htmlFor="job-shift">
                          <FormattedMessage id="jobShift" defaultMessage="Job Shift"/>
                          {" "}<font className="text-danger ml-1">*</font>{" "}
                        </Form.Label>

                        <div className="d-flex">
                          <div className="form-check detail_btn_cca text-center mr-2">
                            <input
                              className="form-check-input d-none"
                              type="radio"
                              name="job_timings"
                              id="timingGeneral"
                              value="0"
                              ref={register({
                                required: intl.formatMessage({
                                  id: "pleaseSelectTiming",
                                }),
                              })}
                            />
                            <label
                              className="form-check-label  lw  text-capitalize"
                              for="timingGeneral"
                            >
                              <FormattedMessage
                                id="general"
                                defaultMessage="General"
                              />
                            </label>
                          </div>

                          <div className="form-check detail_btn_cca text-center mr-2">
                            <input
                              className="form-check-input d-none"
                              type="radio"
                              name="job_timings"
                              id="timingRotation"
                              value="1"
                              ref={register({
                                required: intl.formatMessage({
                                  id: "pleaseSelectTiming",
                                }),
                              })}
                            />
                            <label
                              className="form-check-label  lw  text-capitalize"
                              for="timingRotation"
                            >
                              <FormattedMessage
                                id="rotation"
                                defaultMessage="Rotation"
                              />
                            </label>
                          </div>
                        </div>
                        {errors.job_timings && (
                          <p className="text-danger  oom p-0 m-0">
                            {errors.job_timings.message}
                          </p>
                        )}
                      </div>

                      <div className="form-group mt-2">
                        <Form.Label className="d-flex justify-content-start llbl" htmlFor="job-type">
                          <FormattedMessage
                            id="jobType"
                            defaultMessage="Job Type"
                          />{" "}
                          <font className="text-danger ml-1">*</font>{" "}
                        </Form.Label>

                        <div className="d-flex justify-content-between">
                          <div className="form-check detail_btn_cca text-center mr-2">
                            <input
                              className="form-check-input d-none"
                              type="radio"
                              name="job_type"
                              id="Office"
                              value="0"
                              ref={register({
                                required: intl.formatMessage({
                                  id: "pleaseSelectShift",
                                }),
                              })}
                            />
                            <label
                              className="form-check-label  lw  text-capitalize"
                              for="Office"
                            >
                              <FormattedMessage
                                id="office"
                                defaultMessage="Office"
                              />
                            </label>
                          </div>

                          <div className="form-check detail_btn_cca text-center mr-2">
                            <input
                              className="form-check-input d-none"
                              type="radio"
                              name="job_type"
                              id="Field"
                              value="1"
                              ref={register({
                                required: intl.formatMessage({
                                  id: "pleaseSelectShift",
                                }),
                              })}
                            />
                            <label
                              className="form-check-label  lw  text-capitalize"
                              for="Field"
                            >
                              <FormattedMessage
                                id="field"
                                defaultMessage="Field"
                              />
                            </label>
                          </div>

                          <div className="form-check detail_btn_cca text-center mr-2">
                            <input
                              className="form-check-input d-none"
                              type="radio"
                              name="job_type"
                              id="shift"
                              value="2"
                              ref={register({
                                required: intl.formatMessage({
                                  id: "pleaseSelectShift",
                                }),
                              })}
                            />
                            <label
                              className="form-check-label lw  text-capitalize"
                              for="shift"
                            >
                              <FormattedMessage
                                id="shift"
                                defaultMessage="Shift"
                              />
                            </label>
                          </div>
                          <div className="form-check detail_btn_cca text-center ">
                            <input
                              className="form-check-input d-none"
                              type="radio"
                              name="job_type"
                              id="partTime"
                              value="3"
                              ref={register({
                                required: intl.formatMessage({
                                  id: "pleaseSelectShift",
                                }),
                              })}
                            />
                            <label
                              className="form-check-label lw  text-capitalize"
                              for="partTime"
                            >
                              <FormattedMessage
                                id="partTime"
                                defaultMessage="Part Time"
                              />
                            </label>
                          </div>
                        </div>
                        {errors.job_type && (
                          <p className="text-danger  oom p-0 m-0">
                            {errors.job_type.message}
                          </p>
                        )}
                      </div>

                      <Form.Label className="d-flex justify-content-start llbl" htmlFor="experience">
                        <FormattedMessage
                          id="experienceRequired"
                          defaultMessage="Experience Required"
                        />
                        <font className="text-danger ml-1">*</font>{" "}
                      </Form.Label>
                      <div className="d-flex justify-content-between ">
                        <div className="form-check detail_btn_cca text-center">
                          <input
                            className="form-check-input d-none"
                            type="radio"
                            name="experience"
                            id="Fresher"
                            value="0"
                            ref={register({
                              required: intl.formatMessage({
                                id: "pleaseSelectWorkExp",
                              }),
                            })}
                          />
                          <label
                            className="form-check-label lw py-1"
                            for="Fresher"
                          >
                            <FormattedMessage id="fresher"/>
                          </label>
                        </div>

                        <div className="form-check detail_btn_cca text-center">
                          <input
                            className="form-check-input d-none"
                            type="radio"
                            name="experience"
                            id="Less than 1"
                            value="1"
                            ref={register({
                              required: intl.formatMessage({
                                id: "pleaseSelectWorkExp",
                              }),
                            })}
                          />
                          <label
                            className="form-check-label lw py-1"
                            for="Less than 1"
                          >
                            <FormattedMessage id="lessThan"/>
                          </label>
                        </div>

                        <div className="form-check detail_btn_cca text-center">
                          <input
                            className="form-check-input d-none"
                            type="radio"
                            name="experience"
                            id="1-2"
                            value="2"
                            ref={register({
                              required: intl.formatMessage({
                                id: "pleaseSelectWorkExp",
                              }),
                            })}
                          />
                          <label className="form-check-label lw py-1" for="1-2">
                            1-2
                          </label>
                        </div>

                        <div className="form-check  detail_btn_cca text-center">
                          <input
                            className="form-check-input d-none"
                            type="radio"
                            name="experience"
                            id="2-4"
                            value="3"
                            ref={register({
                              required: intl.formatMessage({
                                id: "pleaseSelectWorkExp",
                              }),
                            })}
                          />
                          <label className="form-check-label lw py-1" for="2-4">
                            2-4
                          </label>
                        </div>
                      </div>

                      <div className="d-flex justify-content-between mt-2">
                        <div className="form-check detail_btn_cca text-center">
                          <input
                            className="form-check-input d-none"
                            type="radio"
                            name="experience"
                            id="4-6"
                            value="4"
                            ref={register({
                              required: intl.formatMessage({
                                id: "pleaseSelectWorkExp",
                              }),
                            })}
                          />
                          <label className="form-check-label lw py-1" for="4-6">
                            4-6
                          </label>
                        </div>

                        <div className="form-check detail_btn_cca text-center">
                          <input
                            className="form-check-input d-none"
                            type="radio"
                            name="experience"
                            id="6-8"
                            value="5"
                            ref={register({
                              required: intl.formatMessage({
                                id: "pleaseSelectWorkExp",
                              }),
                            })}
                          />
                          <label className="form-check-label lw py-1" for="6-8">
                            6-8
                          </label>
                        </div>

                        <div className="form-check detail_btn_cca text-center">
                          <input
                            className="form-check-input d-none"
                            type="radio"
                            name="experience"
                            id="8-10"
                            value="6"
                            ref={register({
                              required: intl.formatMessage({
                                id: "pleaseSelectWorkExp",
                              }),
                            })}
                          />
                          <label
                            className="form-check-label lw py-1"
                            for="8-10"
                          >
                            8-10
                          </label>
                        </div>

                        <div className="form-check  detail_btn_cca text-center">
                          <input
                            className="form-check-input d-none"
                            type="radio"
                            name="experience"
                            id="10+"
                            value="7"
                            ref={register({
                              required: intl.formatMessage({
                                id: "pleaseSelectWorkExp",
                              }),
                            })}
                          />
                          <label className="form-check-label lw py-1" for="10+">
                            10+
                          </label>
                        </div>
                      </div>
                      {errors.experience && (
                        <p className="text-danger  oom p-0 m-0">
                          {errors.experience.message}
                        </p>
                      )}

                      <div className="form-group mt-2">
                        <Form.Label className="d-flex justify-content-start llbl" htmlFor="gender">
                          <FormattedMessage
                            id="gender"
                            defaultMessage="Gender"
                          />{" "}
                          <font className="text-danger ml-1">*</font>{" "}
                        </Form.Label>

                        <div className="d-flex justify-content-between">
                          <div className="form-check detail_btn_cca text-center mr-2">
                            <input
                              className="form-check-input d-none"
                              type="radio"
                              name="gender"
                              id="All"
                              value="0"
                              ref={register({
                                required: intl.formatMessage({
                                  id: "pleaseSelectGender",
                                }),
                              })}
                            />
                            <label
                              className="form-check-label lw py-1 text-capitalize"
                              for="All"
                            >
                              <FormattedMessage id="all" defaultMessage="All" />
                            </label>
                          </div>

                          <div className="form-check  detail_btn_cca text-center mr-2">
                            <input
                              className="form-check-input d-none"
                              type="radio"
                              name="gender"
                              id="Male"
                              value="2"
                              ref={register({
                                required: intl.formatMessage({
                                  id: "pleaseSelectGender",
                                }),
                              })}
                            />
                            <label
                              className="form-check-label  lw py-1 text-capitalize"
                              for="Male"
                            >
                              <FormattedMessage
                                id="male"
                                defaultMessage="Male"
                              />
                            </label>
                          </div>

                          <div className="form-check detail_btn_cca text-center mr-2">
                            <input
                              className="form-check-input d-none fw"
                              type="radio"
                              name="gender"
                              id="Female"
                              value="1"
                              ref={register({
                                required: intl.formatMessage({
                                  id: "pleaseSelectGender",
                                }),
                              })}
                            />
                            <label
                              className="form-check-label lw py-1 text-capitalize"
                              for="Female"
                            >
                              <FormattedMessage
                                id="female"
                                defaultMessage="Female"
                              />
                            </label>
                          </div>

                          <div className="form-check detail_btn_cca text-center mr-2">
                            <input
                              className="form-check-input d-none fw"
                              type="radio"
                              name="gender"
                              id="Other"
                              value="3"
                              ref={register({
                                required: intl.formatMessage({
                                  id: "pleaseSelectGender",
                                }),
                              })}
                            />
                            <label
                              className="form-check-label lw py-1 text-capitalize"
                              for="Other"
                            >
                              <FormattedMessage
                                id="others"
                                defaultMessage="Others"
                              />
                            </label>
                          </div>
                        </div>
                        {errors.gender && (
                          <p className="text-danger  oom p-0 m-0">
                            {errors.gender.message}
                          </p>
                        )}
                      </div>
                      {/* Employer Mobile Number visible/invisible section start from here  */}
                      <div className="contact-checkbox">
                        <input
                          className="form-check-input"
                          autocomplete="off"
                          type="checkbox"
                          name="can_contact_emp"
                          value={isChecked}
                          onChange={handleOnChange}
                          checked={isChecked}
                          ref={register}
                        />
                        <label>
                          <p className="call-me-text">
                            <FormattedMessage id="jobSeekerCallMe"
                              defaultMessage="Job Seekers can call me (Mobile Number will be visible only to shortlisted job seekers)"
                              />
                          </p>
                        </label>
                      </div>
                      {/* Employer Mobile Number visible/invisible section end  */}
                      <button className="common_btn mt-4" type="submit">
                        {" "}
                        <FormattedMessage id="submit" defaultMessage="Submit" />
                      </button>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {AnimatedDialog.isOpen && (
      <Dialog
        open={AnimatedDialog.isOpen}
        onClose={closeAnimatedJD}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="reason_modal animatedjd_modal"
      >
        <DialogContent>
          <p>
            Do you want to upgrade this Job to Animated Job?
          </p>
          <div className="animatedjd_btns">
            <button type="button" className="no" onClick={() => jobPostWithAnimatedJd(false)}>No</button>
            <button type="button" className="yes" onClick={() => jobPostWithAnimatedJd(true)}>Yes</button>
          </div>
        </DialogContent>
      </Dialog>
      )}
    </>
  );
};
