import React, { useEffect, useState, useRef } from "react";
import { FormattedMessage } from "react-intl";
import LinearProgress from "@material-ui/core/LinearProgress";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { FaCircle, FaGgCircle } from "react-icons/fa";
import * as commonService from "utils/CommonService";
import switchcamera from "../../../assets/images/switch-camera.svg";
import "./videoresume.scss";
import { PreviewScreen } from "./previewScreen";

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#1a90ff",
  },
}))(LinearProgress);

export const Recorder = () => {
  let intervalRef = useRef();
  let index = useRef(1);
  let mediaRecorder = useRef();
  const videoRef = useRef(null);
  let mediaRecorderRef = null;
  let chunks = [];
  const [videoFile, setVideoFile] = useState();
  const [facingMode, setFacingMode] = useState("user");
  const [quesCount, setQuesCount] = useState(0);
  const [videoAction, setVideoAction] = useState("Start");
  const [timer, setTimer] = useState(15);
  const [recorderState, setRecorderState] = useState();
  const [videoUrl, setVideoUrl] = useState();
  const [isRecorded, setIsRecorded] = useState(false);
  const [isFlipCameraDisabled, setIsFlipCameraDisabled] =useState(false);

// object to handle timer boundary
  const timerMap = { 0: 15, 1: 15, 2: 10 };

  useEffect(() => {
    // intiate 1st question slide
    viewSlides(index.current);
    getMedia();
  }, [facingMode]);
  useEffect(() => {
    // stop recording when timeout to 0
    if (timer <= 0) {
      clearInterval(intervalRef.current);
      onNext();
    }
  }, [timer]);
  useEffect(() => {
    return () => {
      mediaRecorderRef.getTracks().forEach((track) => track.stop());
    };
  }, [facingMode]);

  /* method to get userMedia */
  async function getMedia() {
    try{
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        const stream = await navigator.mediaDevices
          .getUserMedia({
            audio: true,
            video: {facingMode : facingMode},
          })
          // Success
          if(stream){
            // display stream in video tag
            mediaRecorderRef = stream;
            videoRef.current.srcObject = stream;
            // initialize media recorder object
            mediaRecorder.current = new MediaRecorder(stream);
            setRecorderState(mediaRecorder.current.state);
            // method to save the recorded data
            mediaRecorder.current.ondataavailable = function (e) {
              chunks.push(e.data);
            };
            // method to save the recorded data as a url
            mediaRecorder.current.onstop = function (e) {
              const blob = new Blob(chunks, { type: "video/mp4" });
              const fileOfBlob = new File([blob], "resume.mp4", {
                type: "video/mp4",
              });
              setVideoFile(fileOfBlob);
              chunks = [];
              const URL = window.URL.createObjectURL(blob);
              setVideoUrl(URL);
            };
        } 
      } else {
        console.log("getUserMedia not supported on your browser!");
      }
    } catch(e) {
      console.log("The following getUserMedia error occurred: " + e);
    }
  }

  const onNext = async () => {
    try {
      if (videoAction === "Start") {
        startTimer();
        if (quesCount === 0) {
      // start recording when question is 1st
          setIsFlipCameraDisabled(true);
          startRecording();
          setVideoAction("Next");
          return;
        }
      // Resume Recording
        if (quesCount === 2) {
          resumeRecording();
          setVideoAction("Stop");
          return;
        }
        resumeRecording();
        setVideoAction("Next");
        return;
      }
      if (videoAction === "Next") {
      /**
       * change question when clicked on next
       * stop Timer
       * pause Recording
       */
        commonService.isLoading.onNext(true); // start loader
        setTimeout(()=>{commonService.isLoading.onNext(false);},500) // stop loader

        if (quesCount === 1) {
          setTimer(10);
        }else {
          setTimer(15);
        }
        
        slideChange(1);
        stopTimer();
        setQuesCount(quesCount + 1);
        pauseRecording();
        setVideoAction("Start");
        return;
      }
      if (videoAction === "Stop") {
      /**
      * stop Timer
      * stop Recording
      */
        stopTimer();
        setQuesCount(quesCount + 1);
        stopRecording();
        setIsRecorded(true);
        return;
      }
    } catch (e) {
      console.log("error", e);
    }
  };
  /* Method to start Recording */
  const startRecording = () => {
    // e.preventDefault();
    mediaRecorder.current.start();
    setRecorderState(mediaRecorder.current.state);
  };
  /* Method to stop Recording */
  const stopRecording = () => {
    // e.preventDefault();
    mediaRecorder.current.stop();
    setRecorderState(mediaRecorder.current.state);
  };
  /* Method to pause Recording */
  const pauseRecording = () => {
    // e.preventDefault();
    mediaRecorder.current.pause();
    setRecorderState(mediaRecorder.current.state);
  }
  /* Method to resume Recording */
  const resumeRecording = () => {
    // e.preventDefault();
    mediaRecorder.current.resume();
    setRecorderState(mediaRecorder.current.state);
  };

  /* timer function*/
  /* Start Timer */
  const startTimer = () => {
    intervalRef.current = setInterval(() => {
      setTimer((prev) => prev - 1);
    }, 1000);
    return () => clearInterval(intervalRef.current);
  };
  /* Stop Timer*/
  const stopTimer = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
  };
  /* Method to change questions */
  function slideChange(n) {
    viewSlides((index.current += n));
  }
  /* Method to view questions on click*/
  function viewSlides(n) {
    var i;
    var slides = document.getElementsByClassName("slides");
    if (n > slides.length) {
      index.current = 1;
    }
    if (n < 1) {
      index.current = slides.length;
    }
    for (i = 0; i < slides.length; i++) {
      slides[i].style.display = "none";
    }
    slides[index.current - 1].style.display = "block";
  }
  /* Method to switch camera*/
  const switchCamera = () => {
    setFacingMode((prev) => (prev === "user" ? "environment" : "user"));
  };
  if (isRecorded) {
    return <PreviewScreen src={videoUrl} file={videoFile} />;
  }
  return (
    <>
      <div className="main_user st p_bottom video_resume">
        <div className="container-fluid resume_container">
          {!isFlipCameraDisabled && <img
            src={switchcamera}
            alt="switch-camera"
            className="switch_camera_icon"
            onClick={switchCamera}
          />}
          <div className="slider">
            <div className="slides">
              <div className="resume_title">
                <h5>
                  <FormattedMessage
                    id="introduction"
                    defaultMessage="Introduction"
                  />
                </h5>
              </div>
              <div className="resume_Ques">
                <FormattedMessage
                  id="resumeIntoduction"
                  defaultMessage="Introduction"
                />
              </div>
            </div>
            <div className="slides">
              <div className="resume_title">
                <h5>
                  <FormattedMessage
                    id="skillsAndQualities"
                    defaultMessage="Skills & Qualities"
                  />
                </h5>
              </div>
              <div className="resume_Ques">
                <FormattedMessage
                  id="resumeSkills"
                  defaultMessage="Skills & Qualities"
                />
              </div>
            </div>
            <div className="slides">
              <div className="resume_title">
                <h5>
                  <FormattedMessage id="ExperienceAndPreferredJobLocation" defaultMessage="Experience & Preferred Job Location" />
                </h5>
              </div>
              <div className="resume_Ques">
                <FormattedMessage id="ExperiencePreferredLocaion" defaultMessage="Experience & Preferred Job Location" />
              </div>
            </div>
            {/* <div className="slides">
              <div className="resume_title">
                <p>
                  <FormattedMessage
                    id="whyGoodFit"
                    defaultMessage="Why Good Fit"
                  />
                </p>
              </div>
              <div className="resume_Ques">
                <FormattedMessage
                  id="resumeGoodFit"
                  defaultMessage="Why Good Fit"
                />
              </div>
            </div> */}
          </div>
          <div className="timer text-center">
            <BorderLinearProgress
              variant="determinate"
              value={(timer /timerMap[quesCount]) * 100}
            />
            <p><FormattedMessage
                  id="availableTime"
                  defaultMessage="Available Time"
                /> : {timer} secs</p>
          </div>
          <div className="mt-4 video_recorder">
            <div className=" recorder_inner">
            {recorderState === "recording" ? <p> Recording <FaCircle className="blink text-danger"/></p> : " "}
              <video
                ref={videoRef}
                autoPlay
                muted
                className="video_player"
                id="videoPlayer"
              />
            </div>
          </div>
          <div className="video_controls">
            <div className="controls_inner">
              <button
                className="control_btn"
                id="nextBtn"
                onClick={(e) => onNext(e)}
              >
                {videoAction}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
