import React, { useEffect, useState } from "react";
import {
  FormattedMessage,
  FormattedDate,
  FormattedNumber,
  FormattedPlural,
  FormattedTime,
} from "react-intl";
import { useIntl } from "react-intl";
import "../companyprofileform/companyprofile.scss";
import industry from "../../../assets/images/industry.png";
import check from "../../../assets/images/check.png";
import { Form } from "react-bootstrap";
import { AiOutlineHome } from "react-icons/ai";
import classNames from "classnames";
import { useForm } from "react-hook-form";
import { MdPhoneInTalk } from "react-icons/md";
import { BsPerson } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import * as userActions from "redux/actions/UserActions";
import storage from "utils/storage";
import { serialize } from "object-to-formdata";
import { useHistory } from "react-router-dom";
import { FaIndustry, FaMapPin, FaBuilding, FaRegPlayCircle } from "react-icons/fa";
import { AiOutlineMail } from "react-icons/ai";
import uploadi from "../../../assets/images/uploadi.png";
import Alert from "@material-ui/lab/Alert";
import { IndustryData } from "../IndustryData";
import { BsBriefcase, BsPencil, BsBook } from "react-icons/bs";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { DistrictList } from "components/jobseeker/DistrictList";
import * as commonService from "utils/CommonService.js";
import { uploadFile } from "../../../utils/fileService";
import { regexValidation } from "utils/regexValidation";
import { Player } from "components/shared/videoplayer/player";

export const CompanyBasicDetailsForm = (props) => {
  const { register, handleSubmit, errors, reset } = useForm({
    mode: "onBlur",
  });
  const history = useHistory();
  const dispatch = useDispatch();
  const { userDetails } = useSelector((state) => state.userReducer);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [vedioErr, setVedioErr] = useState(null);
  const [companyVideo, setCompanyVideo] = useState();
  const [isError, setisError] = useState({open: false, message: ''});
  const [modal, setModal] = useState(false);

  useEffect(() => {
    loadProfile();
  }, []);
  const loadProfile = () => {
    let data = userDetails;
    reset(data);
  };
  const onToggleModal = () => {
    setModal(!modal);
  };
  // save company video
  const uploadVedioResume = async (e) => {
    let video = e.target.files[0];
    if(!commonService.validateFileContentTypeAndSize(video.type, 'video')){
      setisError({open: true, message : 'Please upload the correct format file.'});
      setTimeout( () => {setisError(false)}, 5000);
      return;
    }
    if(!commonService.validateFileSize(video.size, 200)){
      setisError({open: true, message : 'File size should be less than 200 MB'});
      setTimeout( () => {setisError(false)}, 5000);
      return;
    }
    let videoUrl = await uploadFile(video,'video');
    setCompanyVideo(videoUrl);
  };
  const update = async (val) => {
    try {
      val.email = val.email.toLowerCase();
      let imageUrl;
      let data = { ...val, _id: storage.get("humlog_user"), profile_status: 2 };
      // upload company_logo if uploaded
      if(props.company_logo) {
        imageUrl = await uploadFile(props.company_logo,'profile');
        data.profile_pic = imageUrl;
      }
      // upload companyvideo if uploaded
      if(companyVideo){
        data.video_resume = companyVideo;
      }
      try {
        let res = await dispatch(userActions.updateUserData(data))
        if (res.value.success) {
          // set value of profile status in storage
          storage.set("profile_status", data.profile_status);
          setSuccessMessage("Your profile created successfully.");
          commonService.forSuccess("Your profile created successfully.", "success");
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event:'successfulregistered_employer',
            category:'Form',
            action : 'Submit|Employer',
            label: 'Registration Successful',
            userId: storage.get("humlog_user")
          });
          setTimeout( () => {
            history.push("/jobs");
          }, 1500);
        }
      } catch (error) {
        setErrorMessage(error.data.message);
        console.log("failed to update profile", error);
      }
    } catch (e) {
      setErrorMessage(e);
      console.log("failed to upload file", e);
    }
  };

  const intl = useIntl();

  return (
    <>
      <div className="mt-">
        <div className="row no-gutters">
          <div className="col-12 ">
            <div className="">
              <div className=" text-center ">
                <div>
                  <div className="mt-3">
                    <Form onSubmit={handleSubmit(update)} className="employerBasicDetails">
                    {/*THIS CODE COMMENTED BECAUSE USE FOR FUTURE REFERENCE START FROM HERE*/}

                      {/* <Form.Group controlId="exampleForm.ControlInput1 ">
                        <Form.Label className="d-flex justify-content-start llbl">
                          {" "}
                          <img
                            src={industry}
                            alt="industry"
                            width="16px"
                            height="20px"
                            className="mr-1"
                          />{" "}
                          <FormattedMessage
                            id="industry"
                            defaultMessage="Industry"
                          />{" "}
                          <font className="text-danger">*</font>
                        </Form.Label>
                        <Form.Control
                          as="select"
                          ref={register}
                          name="company_category"
                        >
                          {IndustryData.map((val) => {
                            return (
                              <>
                                <option> {val}</option>
                              </>
                            );
                          })}
                        </Form.Control>
                      </Form.Group> */}

                      {/* <Form.Group controlId="exampleForm.ControlSelect1">
                        <Form.Label className="d-flex justify-content-start llbl">
                          <FaIndustry className="iconn_pro mr-1" />
                          <FormattedMessage
                            id="companyName"
                            defaultMessage="Company Name"
                          />
                          <font className="text-danger">*</font>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          autocomplete="off"
                          placeholder={intl.formatMessage({
                            id: "addCompanyName",
                          })}
                          name="company_name"
                          className={classNames("form-control", {
                            "is-invalid": errors.company_name,
                          })}
                          ref={register({
                            required: intl.formatMessage({id: "enterCompanyName"}),
                            minLength: {
                              value: 4,
                              message: intl.formatMessage({id: "minimumFourDigit"}),
                            },
                            maxLength: {
                              value: 50,
                              message: intl.formatMessage({id: "maximumFiftyDigit"}),
                            },

                            pattern: {
                              value: regexValidation.COMPANY_NAME,
                              message: intl.formatMessage({id: "allowSpecialCharacter"}),
                            },
                          })}
                        />
                        {errors.company_name && (
                          <p className="text-danger  txt">
                            {errors.company_name.message}
                          </p>
                        )}
                      </Form.Group> */}

                      {/* <Form.Group controlId="exampleForm.ControlSelect1">
                        <Form.Label className="d-flex justify-content-start llbl">
                          <FaBuilding className="iconn_pro mr-1" />
                          <FormattedMessage
                            id="companyAddress"
                            defaultMessage="Company Address"
                          />
                          <font className="text-danger">*</font>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          autocomplete="off"
                          placeholder={intl.formatMessage({
                            id: "addCompanyAddress",
                            defaultMessage: "Add your company address"
                          })}
                          name="company_address"
                          className={classNames("form-control", {
                            "is-invalid": errors.company_address,
                          })}
                          ref={register({
                            required: intl.formatMessage({id: "enterCompanyAddress"}),
                            minLength: {
                              value: 4,
                              message: intl.formatMessage({id: "minimumFourDigit"}),
                            },
                            maxLength: {
                              value: 100,
                              message: intl.formatMessage({id: "maximumHundredCharacter"}),
                            },
                            pattern: {
                              value: regexValidation.COMPANY_ADDRESS,
                              message: "Company Address can contain alphanumeric characters and special character('.', ',', '-'). Company Address can not start with special characters",
                            },
                          })}
                        />
                        {errors.company_address && (
                          <p className="text-danger  txt">
                            {errors.company_address.message}
                          </p>
                        )}
                      </Form.Group> */}

                      {/* <Form.Group controlId="exampleForm.ControlSelect1">
                        <Form.Label className="d-flex justify-content-start llbl">
                          <AiOutlineHome className="iconn_pro mr-1" />
                          <FormattedMessage
                            id="address"
                            defaultMessage="Address"
                          />
                          <font className="text-danger">*</font>
                        </Form.Label>
                        <Autocomplete
                          id="combo-box-demo"
                          name="address_home"
                          ref={register}
                          className={classNames("form-control", {
                            "is-invalid": errors.address_home,
                          })}
                          options={DistrictList}
                          getOptionLabel={(option) => option}
                          style={{ width: "100%" }}
                          className="mt-2 p-0 m-0"
                          renderInput={(params) => (
                            <TextField
                              placeholder={intl.formatMessage({
                                id: "addAddress",
                              })}
                              name="address_home"
                              inputRef={register({
                                required: intl.formatMessage({id: "enterDistrict"}),
                              })}
                              {...params}
                              variant="outlined"
                              require
                            />
                          )}
                        />
                        {errors.address_home && (
                          <p className="text-danger  txt">
                            {errors.address_home.message}
                          </p>
                        )}
                      </Form.Group> */}

                      {/* <Form.Group controlId="exampleForm.ControlSelect1">
                        <Form.Label className="d-flex justify-content-start llbl">
                          <FaMapPin className="iconn_pro mr-1" />
                          <FormattedMessage
                            id="pinCode"
                            defaultMessage="Pincode"
                          />
                          <font className="text-danger">*</font>
                        </Form.Label>
                        <Form.Control
                          type="number"
                          autocomplete="off"
                          placeholder={intl.formatMessage({
                            id: "addPincode",
                            defaultMessage: "Enter Pincode"
                          })}
                          name="pincode"
                          className={classNames("form-control", {
                            "is-invalid": errors.pincode,
                          })}
                          ref={register({
                            required: intl.formatMessage({id: "enterPincode"}),
                            minLength: {
                              value: 6,
                              message: intl.formatMessage({id: "minimumSixDigit"}),
                            },
                            maxLength: {
                              value: 6,
                              message: intl.formatMessage({id: "maximumSixDigit"}),
                            },
                            pattern: {
                              value: /^\d/,
                              message: intl.formatMessage({id: "enterNumericValue"})
                            }
                          })}
                        />
                        {errors.pincode && (
                          <p className="text-danger  txt">
                            {errors.pincode.message}
                          </p>
                        )}
                      </Form.Group> */}
                     {/*THIS CODE COMMENTED BECAUSE USE FOR FUTURE REFERENCE END*/}
                      <Form.Group controlId="exampleForm.ControlSelect1">
                        <Form.Label className="d-flex justify-content-start llbl">
                          <BsPerson className="iconn_pro mr-1" />
                          <FormattedMessage
                            id="name"
                            defaultMessage="Name"
                          />
                          <font className="text-danger">*</font>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          autocomplete="off"
                          placeholder={intl.formatMessage({
                            id: "employerNamePlaceholder",
                          })}
                          value={userDetails.name}
                          className={classNames("form-control", {
                            "is-invalid": errors.name,
                          })}
                          ref={register({
                            required: intl.formatMessage({id: "enterEmployerNameError"}),
                            pattern: {
                              value: regexValidation.PERSON_NAME,
                              message: intl.formatMessage({id: "specialCharacterNotAllowed"}),
                            },
                            maxLength: {
                              value: 30,
                              message: intl.formatMessage({id: "maximumThirtyCharacter"}),
                            },
                            minLength: {
                              value: 3,
                              message: intl.formatMessage({id: "minimumThree"}),
                            },
                          })}
                          name="name"
                        />
                        {errors.name && (
                          <p className="text-danger  txt">
                            {errors.name.message}
                          </p>
                        )}
                      </Form.Group>

                      {userDetails.email ? (
                        <Form.Group controlId="exampleForm.ControlSelect1">
                        <Form.Label className="d-flex justify-content-start llbl">
                          <AiOutlineMail className="iconn_proo mr-1" />
                          <FormattedMessage
                            id = "emailId"
                            defaultMessage="Email Id"
                          />
                          <font className="text-danger">*</font>
                          {userDetails.email_verified ? (
                            <img
                            src={check}
                            alt="verified"
                            width="16px"
                            height="16px"
                            className="mr-1 ml-1"
                            />) : (
                            <img
                            src={check}
                            alt="not verified"
                            width="16px"
                            height="16px"
                            className="mr-1 ml-1"
                            style={{opacity:'0.25'}}
                            />
                          )}
                        </Form.Label>
                        <Form.Control
                          type="email"
                          require
                          autocomplete="off"
                          placeholder={intl.formatMessage({id: "employerEmailId"})}
                          className={classNames("form-control", {
                            "is-invalid": errors.email,
                          })}
                          value = {userDetails.email}
                          ref={register}
                          name="email"
                          readOnly
                        />
                      </Form.Group>
                      ) : (
                        <Form.Group controlId="exampleForm.ControlSelect1">
                        <Form.Label className="d-flex justify-content-start llbl">
                          <AiOutlineMail className="iconn_pro mr-1" />
                          <FormattedMessage
                            id = "emailId"
                            defaultMessage="Email Id"
                          />
                          <font className="text-danger">*</font>
                        </Form.Label>
                        <Form.Control
                          type="email"
                          require
                          autocomplete="off"
                          placeholder={intl.formatMessage({id: "employerEmailId"})}
                          className={classNames("form-control", {
                            "is-invalid": errors.email,
                          })}
                          ref={register({
                            required: intl.formatMessage({id: "enterEmail"}),
                            pattern: {
                              value: regexValidation.EMAIL,
                              message: intl.formatMessage({id: "emailValid"}),
                            }
                          })}
                          name="email"
                        />
                        {errors.email && (
                          <p className="text-danger txt">
                            {errors.email.message}
                          </p>
                        )}
                      </Form.Group>
                      )}

                      {userDetails.contact ? (
                        <Form.Group controlId="exampleForm.ControlSelect1">
                        <Form.Label className="d-flex justify-content-start llbl">
                          <MdPhoneInTalk className="iconn_pro mr-1" />
                          <FormattedMessage
                            id="mobileNumber"
                            defaultMessage="Mobile Number"
                          />
                          <font className="text-danger">*</font>
                          {userDetails.contact_verified ? (
                            <img
                            src={check}
                            alt="verified"
                            width="16px"
                            height="16px"
                            className="mr-1 ml-1"
                            />) : (
                            <img
                            src={check}
                            alt="not verified"
                            width="16px"
                            height="16px"
                            className="mr-1 ml-1"
                            style={{opacity:'0.25'}}
                            />
                          )}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          autocomplete="off"
                          placeholder={intl.formatMessage({
                            id: "employerMobileNumber",
                            defaultMessage:"Enter your mobile number"
                          })}
                          name="contact"
                          className={classNames("form-control", {
                            "is-invalid": errors.contact,
                          })}
                          disabled
                          value={userDetails.contact}
                        />
                      </Form.Group>
                      ) : (
                        <Form.Group controlId="exampleForm.ControlSelect1">
                        <Form.Label className="d-flex justify-content-start llbl">
                          <MdPhoneInTalk className="iconn_pro mr-1" />
                          <FormattedMessage
                            id="mobileNumber"
                            defaultMessage="Mobile Number"
                          />
                          <font className="text-danger">*</font>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          autocomplete="off"
                          placeholder={intl.formatMessage({
                            id: "employerMobileNumber",
                            defaultMessage:"Enter your mobile number"
                          })}
                          name="contact"
                          className={classNames("form-control", {
                            "is-invalid": errors.contact,
                          })}
                          ref={register({
                            required: intl.formatMessage({id: "enterContactNumber"}),
                            pattern: {
                              value: regexValidation.PHONE,
                              message:
                                intl.formatMessage({id: "enterValidContact"}),
                            },
                          })}
                        />
                        {errors.contact && (
                          <p className="text-danger  txt">
                            {errors.contact.message}
                          </p>
                        )}
                      </Form.Group>
                      )}
                      
                      {/*THIS CODE COMMENTED BECAUSE USE FOR FUTURE REFERENCE START FROM HERE*/}
                      {/* <div className="profile_field_box">
                        <div className="label_box">
                          <h6 className="user_one">
                            <img src={uploadi} className="user_icon mt-1" />
                            <FormattedMessage id="companyVideo" defaultMessage="Company Video" />
                          </h6>
                          <div>
                            <input type="file" className="d-none" id="vedio-resume" accept="video/*" onChange={uploadVedioResume} />
                            <label for="vedio-resume" className="mt-2">
                              <BsPencil className="user_icon pencil" />
                            </label>
                          </div>
                        </div>
                      </div>
                      {vedioErr?.length > 0 && (
                        <div className="text-danger">{vedioErr}</div>
                      )}
                      <a className="word_brkk" target="_blank">
                      {
                        companyVideo && companyVideo.length
                          ? <div className="text-left">
                            <p className="user_tt video_box">
                              Video uploaded successfully
                            </p>
                            <button type="button" className="video_btn" onClick={onToggleModal} disabled={companyVideo && companyVideo.length ? false : true}><FaRegPlayCircle className="btn_icon" /></button>
                          </div>
                          : ""
                      }
                      <Player open={modal} toggleModal={onToggleModal} src={companyVideo}/>
                      </a> */}
                      {/*THIS CODE COMMENTED BECAUSE USE FOR FUTURE REFERENCE END*/}

                      <div className="mt-2 sticky-top">
                        {successMessage && (
                          <Alert severity="success">{successMessage}</Alert>
                        )}
                        {errorMessage && errorMessage.length > 0 && (
                          <Alert severity="error">{errorMessage} !</Alert>
                        )}
                        {isError.open && <Alert severity="error" className="alert-toast">{isError.message}</Alert>}
                      </div>

                      <button className="common_btn mt-4 mb-2" type="submit">
                        <FormattedMessage id="submitAndPostJob" defaultMessage="Save & Post Job" />
                      </button>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
