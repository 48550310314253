import "./jobmatch.scss";
import { FormattedMessage, FormattedDate, FormattedNumber, FormattedPlural, FormattedTime } from "react-intl";
import { FiMoreVertical } from "react-icons/fi";
import { IoIosCloseCircle } from "react-icons/io";
import { HiOutlineStar } from "react-icons/hi";
import { BsCheck } from "react-icons/bs";
import { GoVerified } from "react-icons/go";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import storage from "utils/storage";
import { useDispatch, useSelector } from "react-redux";
import * as JobActions from "redux/actions/JobActions";
import * as UserActions from "redux/actions/UserActions";
import { Image } from "components/shared/imagecomponent/image";
import CommonModal from "components/shared/ui-components/common-modal";
import { JobDetailModal } from "../JobDetailsmodal/JobDetailModal";
import ReactTooltip from 'react-tooltip';
import { useIntl } from "react-intl";
import { FaAngleDown } from "react-icons/fa";
import { common } from "config/app.constant";

export const JobLists = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { userDetails } = useSelector((state) => state.userReducer);
  const [modalData, setModalData] = useState(false);
  const [open, setOpen] = useState(false);
  const [state, setState] = useState({ open: false });
  const { count } = useSelector((state) => state.jobReducer);
  const { jobFilterList } = useSelector((state) => state.jobReducer);
  const [page, setPage] =useState(0); // storing page value
  const [jobsListData, setjobsListData] = useState([]); // storing jobs card data
  const [jobsCardCounts, setJobsCardCount] = useState(); // storing jobs length value
  const [totalJobCount, setTotalJobCount] = useState(); // storing total jobs count
  const [jobOptionShow, setjobOptionShow] = useState(false);
  let val = props.jobList;
  let userId = storage.get("humlog_user"); // get user Id
  const translate = useIntl();
  /**
   * @description function to close modal
   */
  const handelCloseModal = () => {
    setOpen(false);
    setState({ open: false });
  };
  /**
   * @description function to open modal
   * @param {param} data 
   */
  const handelOpenModal = (data) => {
    setModalData(data)
    setOpen(true);
    setjobOptionShow(true);
  };
  // Handling Load More Jobs Button
  const loadJobsCards = () => {
    setPage((prev) => prev + 1 );
  }
  /**
   * method to change the status of the job
   * @param {Array} job
   * @param {String} status status of job [Applied, Saved, Visited]
   */
  const changeJobStatus = (job, status) => {
    const selectedJob = {
      company_logo: job.company_logo,
      company_name: job.company_name,
      job_id: job._id,
      job_title: job.job_title,
      salary_from: job.salary_from,
      salary_to: job.salary_to,
      job_location: job.job_location,
      category: job.category,
      jobStatus: job.status,
      openings: job.openings,
      skills: job.skills,
      job_experience: job.experience,
      job_timings: job.job_timings,
      job_type: job.job_type,
      description: job.description,
      animated_description: job.animated_description,
      audio_description: job.audio_description,
      status: status,
      can_contact_emp: job.can_contact_emp,
      company_contact: job.company_contact
    };
    let data = {
      _id: userDetails._id,
      jobs: selectedJob
    };
    dispatch(UserActions.updateUserData(data))
      .then((res) => {
        if (res.value.success) {
          updateJobsDetails(); // calling api for get all jobs list
          dispatch(UserActions.getUserData(storage.get("humlog_user")));
        }
      })
      .catch((err) => console.log(err));
  };
  
  useEffect(() => {
    fetchJobsDetails();
  }, [dispatch, page]);

  /**
   * @description method to refresh the component after modal is closed.
   */
  const reload = () => {
    setjobsListData([]);
    fetchJobsDetails();
  }
  // API to Fetching Jobs Data
  const fetchJobsDetails = async () => {
    try {
      dispatch(JobActions.getJobListData(userId, page)).then((response) => {
        if (response && response.value.success) {
          setJobsCardCount(response.value.data.length); // get jobs counts
          setTotalJobCount(response.value.count); // get total job count
          setjobsListData((prev) => prev.concat(response.value.data)); // concat jobs here
        }
      });
    } catch (err) {
      console.log("error in fetching jobs", err);
    }
  };
  // API to Fetching Jobs Data
  const updateJobsDetails = async () => {
    setPage(0); // page number set zero
    setjobsListData([]);
    try {
      dispatch(JobActions.getJobListData(userId, page)).then((response) => {
        if (response && response.value.success) {
          setJobsCardCount(response.value.data.length); // get jobs counts
          setTotalJobCount(response.value.count); // get total job count
          setjobsListData(response.value.data); // get all jobs list
        }
      });
    } catch (err) {
      console.log("error in fetching jobs", err);
    }
  };
    /**
   * @description method to open/close the menu options (Apply Now, Apply Later, Not Apply)
   * @param {param} e event 
   */
     const toggleMenu = (e) => {
      const dropdowns = document.getElementsByClassName('dropdown-content');
      const thisDropdown = e.target.nextSibling;
      if (!thisDropdown.classList.contains('show')) {
        let i;
        for (i = 0; i < dropdowns.length; i++) {
          dropdowns[i].classList.remove('show');
        }
      }
      thisDropdown.classList.toggle('show');
    }
    /* function to close the dropdown when clicked outside */
    window.onclick = function (event) {
      if (!event.target.matches('.dropbtn')) {
        var dropdowns = document.getElementsByClassName('dropdown-content');
        var i;
        for (i = 0; i < dropdowns.length; i++) {
          var openDropdown = dropdowns[i];
          if (openDropdown.classList.contains('show')) {
            openDropdown.classList.remove('show');
          }
        }
      }
    }
  // no more jobs to apply for
  if (props.jobList && props.jobList.length === 0 && userDetails && userDetails.jobs && userDetails.jobs.length > 0) {
    setTimeout(() => {
      history.push("/dashboard");
    }, 15000);
    return (
      <div className="st ">
        <div className=" text-center pt-5 text-primary ">
          You visited all posted jobs
          <h3>Thank you</h3>{" "}
        </div>
      </div>
    )
  }
  // no jobs found.
  if (props.jobList && props.jobList.length === 0 && userDetails && userDetails.jobs && userDetails.jobs.length === 0) {
    setTimeout(() => {
      history.push("/profile");
    }, 90000);
    return (
      <div className="st ">
        <div className=" text-center pt-5 text-primary ">
          <FormattedMessage id="noMatchFound" defaultMessage=" No matching job found, please complete profile on My Profile page."/>
          <h3><FormattedMessage id="thankYou" defaultMessage="Thank you"/></h3>
        </div>
      </div>
    )
  }
  // when both salary value is null (Max and Min) then shown Disclosed
  const _handleSalary = (salary_from, salary_to) => {
    if (salary_from && salary_to) {
      return (
        <p>
          ₹ {salary_from}  - ₹ {salary_to} /month
        </p>
      )
    } else if (salary_from && !salary_to) {
      return (
        <p>
         ₹ {salary_from} and Above /month  
        </p>
      )
    } else if (salary_to && !salary_from) {
      return (
        <p>
          Upto ₹ {salary_to} /month
        </p>
      )

    } else  {
      return (
        <p>
          Not Disclosed
        </p>
      )
    }
  }
  return (props.jobList && props.jobList.length && (
    <div className="joblistviewbox">
      <p className="counts"><FormattedMessage id="showing" /> {props.jobList.length} <FormattedMessage id="results" /></p>
      {
        props.jobList.map((joblistdata, index) => {
          joblistdata.job_experience = joblistdata.experience;
          return (
            <div className="joblistview_content">
              <p className="verification"><GoVerified /></p>
              <div className="job_content">
                <div className="joblogo">
                  <Image picture={`${joblistdata.company_logo}?${Date.now()}`} alt="user_profile"></Image>
                </div>
                <div className="job_details">
                  <h3>{joblistdata.job_title}</h3>
                  <h4>{joblistdata.company_name}</h4>
                  <p>{joblistdata.district}</p>
                  {/* salary-section */}
                  {_handleSalary(joblistdata.salary_from, joblistdata.salary_to)}
                </div>
              </div>

              <div className="viewdeatilsbtn">
                <button type="button" className="viewdetails" onClick={() => handelOpenModal(joblistdata)} >View More</button>
                <div className="dropdownbox">
                  <button type="button" className="moreoptns dropbtn" onClick={toggleMenu}>
                  <FiMoreVertical />
                  </button>
                  <div className="dropdownmenu option_menu dropdown-content">
                    <ul>
                      <li>
                        <span onClick={() => changeJobStatus(joblistdata, "Applied")}>
                          <BsCheck className="view_menu_icons applynow" />
                          Apply Now
                        </span>
                      </li>
                      <li>
                        <span onClick={() => changeJobStatus(joblistdata, "Saved")}>
                          <HiOutlineStar className="view_menu_icons applylater" />
                          Apply Later
                        </span>
                      </li>
                      <li>
                        <span onClick={() => changeJobStatus(joblistdata, "Visited")}>
                          <IoIosCloseCircle className="view_menu_icons notapply" />
                          Not Apply
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          )
        })
      }
      {/* load-more-jobs button*/}
       {props.jobList.length === common.LIMIT_30 && <div className="load_more" onClick={loadJobsCards}><p>{common.LOAD_MORE_JOBS} <FaAngleDown/></p></div>}
      <CommonModal open={open} handelClose={handelCloseModal}>
        <JobDetailModal modalData={modalData} jobOption={jobOptionShow} handelClose={handelCloseModal} onHide={reload}/>
      </CommonModal>
    </div>
  )
  )
};
