import "./footer.scss";
import logof from "../../../assets/images/logo_footer.png";
import React, { useRef, Component, useState, useEffect } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faTwitter, faYoutube, faLinkedinIn, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { HashLink } from 'react-router-hash-link';
import { NavHashLink } from 'react-router-hash-link';
import { FormattedMessage, FormattedDate, FormattedNumber, FormattedPlural, FormattedTime, } from "react-intl";
import { useForm } from "react-hook-form";
import { regexValidation } from '../../../utils/regexValidation';
import * as userAuthAction from "redux/actions/AuthActions";
import { useIntl } from "react-intl";
import { getSource } from '../../../utils/CommonService';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { AiOutlineCloseCircle } from "react-icons/ai";
import { FaMapMarkerAlt, FaEnvelope, FaPhoneAlt } from "react-icons/fa";

export const FooterV1 = (props) => {
  const location = useLocation();
  const history = useHistory();
  const { register, handleSubmit, errors, watch, reset } = useForm();
  const dispatch = useDispatch();
  const { userDetails } = useSelector((state) => state.userReducer);
  const [isToastShow, setToastShow] = useState(false);
  const [contactUsModal, setcontactUsModal] = useState(false);
  const translate = useIntl();
  /* fixed scroll button on every screen */
  const [isScrollbtnShow, isScrollbtnSet] = useState(false);
  document.addEventListener("scroll", function (e) {
    toggleVisibility();
  });
  // open contact us modal
  const openSaarthiModal = () => {
    setcontactUsModal(true);
  };
  // close contact us modal
  const closeSaarthiModal = () => {
    setcontactUsModal(false);
  };
  /* fixed button show/hide when scroll upto 50px */
  const toggleVisibility = () => {
    if (window.pageYOffset > 50) {
      isScrollbtnSet(true);
    } else {
      isScrollbtnSet(false);
    }
  };

  /* when click on scrolltop button page scroll to top */
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }

  /* scroll with offset function */
  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -71;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
    // Create a media condition that targets viewports at 575px wide
    const mediaQuery = window.matchMedia('(max-width: 575px)')
    // Check if the media query is true
    if (mediaQuery.matches) {
      // Then trigger an alert
      const yOffset = -113;
      window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
    }
  }
  /**
   * @description method to submit enquiry form
   */
  const [isSubmitting, setIsSubmitting] = useState(false);
  const enquirySubmit = async (data) => {
    try {
      const loginSource = getSource(); // getting source from where user comes
      let contactData = {
        name: data.name,
        contact_details: data.email.toLowerCase(),
        message: data.message,
        lead_source: loginSource
      };
      let res = await dispatch(userAuthAction.enquiryForm(contactData));
      if (res.value.success) {
        setIsSubmitting(true);
        setcontactUsModal(false); // close contact us modal
        setToastShow(!isToastShow);
        setTimeout(() => {
          setToastShow(isToastShow => !isToastShow);
        }, 2000);
      }
    } catch (error) {
      setIsSubmitting(false);
    }
    reset();
  }

  return (
    <>
      <footer className="footer" id="contact">
        <div className="container">
          <div className="f_inner">
            <div className="row">
              <div className="col-lg-3 col-md-4">
                <a className="footer_logo_link" onClick={() => history.push("/homepage")}>
                  <div className="footer_logo">
                    <img src={logof} alt="Humlogjobs Logo" className="img-fluid" />
                  </div>
                </a>
                <div className="about">
                  <p>
                    <FormattedMessage
                      id="aboutTagLine"
                      defaultMessage="Your Sarthi in Journey of Job Success & Worklife Happiness"
                    />
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-4">
                <div className="footer_menu">
                  <div className="fm-list">
                    <ul>
                      <li><NavHashLink smooth to='/homepage' scroll={el => scrollWithOffset(el)}><FormattedMessage id="home" /></NavHashLink></li>
                      <li><NavHashLink smooth to='/about' scroll={el => scrollWithOffset(el)}><FormattedMessage id="about" /></NavHashLink></li>
                      <li><NavHashLink smooth to='/homepage#jobexplorer' scroll={el => scrollWithOffset(el)}><FormattedMessage id="jobs" /></NavHashLink></li>
                      <li><NavHashLink smooth to='/homepage#work' scroll={el => scrollWithOffset(el)}><FormattedMessage id="work" /></NavHashLink></li>
                    </ul>
                    <ul>
                      <li><NavHashLink smooth to='/homepage#blogs' scroll={el => scrollWithOffset(el)} className={(props.activeblog) ? "activeblog" : ""}><FormattedMessage id="blogs" /></NavHashLink></li>
                      <li><NavHashLink smooth to='/homepage#faq' scroll={el => scrollWithOffset(el)}>FAQs</NavHashLink></li>
                      <li><NavHashLink smooth to='/privacypolicy' scroll={el => scrollWithOffset(el)}><FormattedMessage id="privacy" /></NavHashLink></li>
                      <li><NavHashLink smooth to='/terms&conditions' scroll={el => scrollWithOffset(el)}><FormattedMessage id="terms" /></NavHashLink></li>
                      {/* <li><NavHashLink smooth to='' scroll={el => scrollWithOffset(el)}>Sitemap</NavHashLink></li> */}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="footer_social">
                  <h3><FormattedMessage id="followUs" /></h3>
                  <div className="socialicon">
                    <ul>
                      <li><a href="https://www.facebook.com/Humlogjobs-104496931645739" target="_blank" className="facebook"><FontAwesomeIcon icon={faFacebookF} /></a></li>
                      <li><a href="https://twitter.com/humlog_jobs" target="_blank" className="twitter"><FontAwesomeIcon icon={faTwitter} /></a></li>
                      <li><a href="https://www.youtube.com/channel/UCMHUWjNiInbLlLD7vv-9kyA" target="_blank" className="youtube"><FontAwesomeIcon icon={faYoutube} /></a></li>
                      <li><a href="https://www.linkedin.com/company/humlogjobs" target="_blank" className="linkedin"><FontAwesomeIcon icon={faLinkedinIn} /></a></li>
                      <li><a href="https://www.instagram.com/humlogjobs" target="_blank" className="instagram"><FontAwesomeIcon icon={faInstagram} /></a></li>
                    </ul>
                  </div>
                  <div className="contact-button">
                    <button className="contact-us" onClick={openSaarthiModal}>Contact Us</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="f_bottom">
          <div className="container">
            <div className="fbottom_inner">
              <p><FormattedMessage id="copyRight" /></p>
            </div>
          </div>
        </div>
      </footer>

      <a onClick={scrollToTop} id="scrollbtn" className={isScrollbtnShow ? 'back-top back-top-visible' : 'back-top'}>
        <i className="fas fa-chevron-up"></i>
      </a>

      <div className={isToastShow ? 'toastmain show' : 'toastmain'} role="alert" aria-live="assertive" aria-atomic="true">
        <div className="toast-body">
          <FormattedMessage id="thanksForContacting" />
        </div>
      </div>
      {/* Contact us modal start from here */}
      <Dialog
        open={contactUsModal}
        onClose={closeSaarthiModal}
        className="contact-modal"
      >
        <div className="close-icon">
          <AiOutlineCloseCircle onClick={closeSaarthiModal} />
        </div>
        <DialogContent className="contact-us-modal">
          <div className="row">
            <div className="col-sm-12">
              <div className="contact-us-heading text-center">
                <h5><FormattedMessage id="contactus" /></h5>
              </div>
            </div>
            <div className="col-sm-12">
              <div className="contact_form mt-3">
                <form onSubmit={handleSubmit(enquirySubmit)}>
                  <div className="form-group">
                    <input autoComplete="off" className="form-control" type="text" name="name" placeholder={translate.formatMessage({ id: "name" })}
                      ref={register({
                        required: translate.formatMessage({ id: "required" }),
                        maxLength: {
                          value: 50,
                          message: translate.formatMessage({ id: "nameCanContain" }),
                        },
                      })} />
                    {errors.name && (
                      <p className="help is-danger">{errors.name.message}</p>
                    )}
                  </div>
                  <div className="form-group">
                    <input autoComplete="off" className="form-control" type="text" name="email" placeholder={translate.formatMessage({ id: "phoneEmail" })}
                      ref={register({
                        required: translate.formatMessage({ id: "required" }),
                        validate: {
                          validation: (value) =>
                            regexValidation.PHONE.test(value) ||
                            regexValidation.EMAIL.test(value) ||
                            translate.formatMessage({ id: "enterValidEmail" }),
                        },
                      })} />
                    {errors.email && (
                      <p className="help is-danger">{errors.email.message}</p>
                    )}
                  </div>
                  <div className="form-group">
                    <textarea autoComplete="off" className="form-control" name="message" rows="3" placeholder={translate.formatMessage({ id: "message" })}
                      ref={register({
                        required: translate.formatMessage({ id: "required" }),
                        maxLength: {
                          value: 500,
                          message: translate.formatMessage({ id: "messageCanContain" }),
                        },
                      })} />
                    {errors.message && (
                      <p className="help is-danger">{errors.message.message}</p>
                    )}
                  </div>
                  <div className="submit-button">
                    <button type="submit" className="custombtn"><FormattedMessage id="submit" /></button>
                  </div>
                </form>
              </div>
              <div className="footer_address">
                <p><span><FaMapMarkerAlt /></span>Parallel Platform Private Limited</p>
                <p>Ground Floor 3A/281, Rachna Vaishali Ghaziabad,</p>
                <p>Uttar Pradesh, India, 201301</p>
                <p><span><FaEnvelope /></span><a href="mailto:humlogjobs@gmail.com">humlogjobs@gmail.com</a></p>
                <p><span><FaPhoneAlt /></span><a href="tel:+919999454366">(+91) - 9999454366</a></p>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
      {/* Contact us modal end */}
    </>
  );
};
