import React, { useState, useEffect } from "react";
import {FormattedMessage, FormattedDate, FormattedNumber, FormattedPlural, FormattedTime} from 'react-intl';
import { useIntl } from 'react-intl';

import { useForm } from "react-hook-form";
import { serialize } from "object-to-formdata";
import storage from "utils/storage";
import { useHistory } from "react-router-dom";
import * as userActions from "redux/actions/UserActions";
import * as authActions from "redux/actions/AuthActions";
import { useDispatch, useSelector } from "react-redux";
import "./jobintres.scss";
import { UserActionTypes } from "redux/actions/UserActions/actionType";
import { BiFilterAlt } from "react-icons/bi";
import others from "../../../assets/images/other.svg";
import classNames from "classnames";
import ConfirmDialog from "components/shared/ui-components/common-dialog";
import Alert from "@material-ui/lab/Alert";
import * as commonService from "utils/CommonService.js";
import { jobCategoryData } from "../../recruiter/jobCategoryData"; // job category data

export const Jobintrested = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { JobCategoryList } = useSelector((state) => state.jobCategoryReducer);
  const [input, setInput] = useState(false);
  const [otherInput, setOtherInput] = useState("");
  const [dialog, setDialog] = useState({open: false, message: ""});
  const [finishStatus, setfinishStatus] = useState(false);
  const [isSuccess, setIssuccess] = useState(false);
  const [currentJobCategoryData, setJobCategoryData] = useState([]); // storing job category values

  // back button workflow
  const onBackButtonEvent = (e) => {
    e.preventDefault();
    if (!finishStatus) {
      setDialog({open: true, message: "Are you sure, you want to exit without creating profile?"});
    }
  }

  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname);
    // listen event of back button pressed
    window.addEventListener('popstate', onBackButtonEvent);
    handleJobCategoryData(jobCategoryData, JobCategoryList);
    return () => {
      window.removeEventListener('popstate', onBackButtonEvent);  
    };
  }, [JobCategoryList]);
  // Matching job category values
  const handleJobCategoryData = async (jobCategoryData, JobCategoryList) => {
    if(JobCategoryList && JobCategoryList.length) {
      let data = await commonService.commonJobCategoryData(jobCategoryData, JobCategoryList);
      if (data) {
        setJobCategoryData(data);
      }
    }
  }
  // onConfirm logout
  const onConfirm = () => {
    setfinishStatus(true)
    dispatch(authActions.logout);
    setTimeout(()=>{
      window.location.reload();
      history.push('/');
      // clear local storage on log out
      storage.clear();
    },200);
  }
  //oncancel remain on the same page
  const onCancel = () => {
    window.history.pushState(null, null, window.location.pathname);
    setfinishStatus(false)
  }

  const { errors, watch, register, handleSubmit, reset } = useForm({
    mode: "onchange",
  });
  let job_intrested = watch("job_intrested", []);
  // TODO : this is a patch which needs to be fixed
  if(typeof(job_intrested) === "boolean"){
    job_intrested = [];
  }
  const checkedCount = job_intrested.filter(Boolean).length;
  /**
   * Method to update JobIntrested of user
   * @param {Object} val Jobintrested selected by user
   */
  const update = (val) => {
    let job_intrested = [];
    for (val of val.job_intrested) {
      job_intrested.unshift({job_category : val, skills: []});
    }
    let data = { job_intrested : job_intrested, profile_status: 2, _id: storage.get("humlog_user") };
    const redirect_url = history.location.state;
    dispatch(userActions.updateUserData(data))
      .then((res) => {
        if (res.value.success) {
          setIssuccess(true);
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event:'successfulregistered_jobseeker',
            category:'Form',
            action : 'Submit|Job Seeker',
            label: 'Registration Successful',
            userId: storage.get("humlog_user")
          });
          storage.set("profile_status", data.profile_status);
          setTimeout(()=>{
            if(redirect_url) {
              history.push(redirect_url);
              return;
            }
            history.push("/jobs");
          },2000);
        }
      })
      .catch((err) => console.log(err));
    reset();
  };
  const intl = useIntl();
  return (
    <>
      {dialog.open && (
        <ConfirmDialog
          open={dialog.open}
          // val={state.val}
          setState={setDialog}
          message={dialog.message}
          confirm={onConfirm}
          cancel={onCancel}
        ></ConfirmDialog>
      )}
      {isSuccess && <Alert severity="success" className="alert-toast"><FormattedMessage id="youHaveSuccessFully"/></Alert>}
      <div className=" st fixed fixedHeight">
        <div className="d-flex justify-content-between pt-1 fixed positionFixed ">
          <p className="in"></p>
          <div className="d-flex justify-content-right pr-2">
      
            <p className="job_icon_b"> </p>
          </div>
        </div>

        <div className="d-flex justify-content-end pt-1">
         
        </div>
        <div className="afterPositio">
          <hr className="hrr" className="p-0 m-0" />
          <p className="in_a text-center mb-1"> <FormattedMessage
                         id = "selectThree"
                         defaultMessage="Select maximum 3"
                    /></p>
        </div>
        <form onSubmit={handleSubmit(update)} className="fixed">
          <div className="job_cat">
            {currentJobCategoryData && currentJobCategoryData.length && currentJobCategoryData.map((val, index) => {
              return (
                <>
                  <input
                    type="checkbox"
                    id={val._id}
                    className="d-none"
                    name="job_intrested"
                    disabled={
                      !job_intrested.includes(val.name) && checkedCount >= 3
                    }
                    value={val.name}
                    ref={register({
                      required: intl.formatMessage({ id: 'pleaseSelectOne' }),
                    })}
                  />
                  <label for={val._id} className="d-inline">
                    <div className="col-4 d-inline-flex justify-content-around ">
                      <div>
                        <div className="d-flex justify-content-center">
                          <img
                            src={val.image}
                            style={{ width: "80px" }}
                            className=" filter-green"
                          />{" "}
                        </div>
                        <h6
                          style={{ fontSize: "12px" }}
                          className="text-center"
                        >
                          {" "}
                          {val.name}{" "}
                        </h6>
                      </div>
                    </div>
                  </label>
                </>
              );
            })}
            <input
              type="checkbox"
              id="other"
              className="d-none w-75 border-primary"
              name="job_intrested"
              disabled={
                !job_intrested.includes(otherInput) && checkedCount >= 3
              }
              value={otherInput}
              ref={register({
                required: intl.formatMessage({ id: 'pleaseSelectOne' }),
              })}
            />
            <label for="other" className="d-inline">
              <div className="col-4 d-inline-flex justify-content-around ">
                <div onClick={() => setInput(!input)}>
                  <div className="d-flex justify-content-center">
                    <img
                      src={others}
                      style={{ width: "80px" }}
                      className=" filter-green"
                    />{" "}
                  </div>
                  <h6 style={{ fontSize: "12px" }} className="text-center">
                    {" "}
                    other{" "}
                  </h6>
                </div>
              </div>
            </label>
            {input && (
              <div className="mt-1 px-3 pb-4">
                <input
                  type="text"
                  onInput={(e) => setOtherInput(e.target.value)}
                  placeholder={intl.formatMessage({id: "required"})}
                  value={otherInput}
                  name="others"
                  autocomplete="off"
                  className="other_input py-1 pl-2"
                  className={classNames("form-control", {
                    "is-invalid": errors.others,
                  })}
                  ref={register({
                    required: intl.formatMessage({id: "required"}),
                    maxLength: {
                      value: 30,
                      message: intl.formatMessage({id : "enterMaximumThirtyDigit"}),
                    },
                  })}
                />
                {errors.others && (
                  <p className="text-danger  oom text-center">
                    {errors.others.message}
                  </p>
                )}
              </div>
            )}
          </div>

          {errors.job_intrested && (
            <p className="text-danger  oom text-center">
              {errors.job_intrested.message}
            </p>
          )}
          <div className="mt-3 pstt fixed">
            <button className="common_btn" type="submit">
              {" "}
              <FormattedMessage
                         id = "submit"
                         defaultMessage="Submit"
                    />{" "}
            </button>
          </div>
        </form>
      </div>
    </>
  );
};
