import { Link, useHistory } from "react-router-dom";
import { HeaderV1 } from '../../commoncomponent/header/headerv1'
import { FooterV1 } from '../../commoncomponent/footer/footerv1';
import storage from "utils/storage";
// import backgroundimg from  "../../../assets/images/privacyimg.png";
export const Condition=(hideMenuItems)=>{
const history= useHistory();
const userRole = storage.get("humlog_user_role");
  return(
    <>
      {userRole>=0 ? "" : <HeaderV1 hideMenuItems={hideMenuItems}/>}
      <section className="privacy">
        <div className="container">
          <div className="privacy_inner">
            <div className="privacy_heading">
              <h3>Terms & Conditions</h3>
            </div>
            <div className="privacy_content condition_content">
              <h3>You must take the time to read and understand the Terms and Conditions before registering for our services. By registering, you accept that you are entering into a contract with us based on these Terms and Conditions.</h3>

              <h3>Visitors to Humlogjobs.com who do not register to become a Member (Employer or Employee) similarly affirm that they are bound by these Terms and Conditions each time they access the Humlogjobs.com website.</h3>

              <h3>If you do not accept the Terms and Conditions stated below, please refrain from using Humlogjobs.com</h3>

              <ul>
                <li>The use of the Website by a Employer or Employee shall be deemed acceptance of and agreement to these terms only.</li>
                <li>The Website has been established to allow Employer to contact Employees and to view their profiles detailing a Employee's experience. We do not issue any experience certificate to our registered members.</li>
                <li>Any profile created showing political or illegal material would not be accepted under any circumstances.</li>
                <li><span><Link to="/homepage">Humlogjobs.com</Link></span> will take all reasonable precautions to keep the details of Employers and Employees secure but will not be liable for unauthorized access to the information provided by any party whatsoever.</li>
                <li>The Members warrant that their e-mail and other contact addresses are valid and up to date when using the Website.</li>
                <li>Members agree not to impersonate any other person or entity or to use a false name or a name that they have no authority to use.</li>
                <li>Members acknowledge that <span><Link to="/homepage">Humlogjobs.com</Link></span> is not liable for any form of loss or damage that may be suffered by a Member through the use of the Website including loss of data or information or any kind of financial or physical loss or damage.</li>
                <li><span><Link to="/homepage">Humlogjobs.com</Link></span> privacy policy forms part of these Terms and Conditions, and by agreeing to these Terms and Conditions, you also give your consent to the manner in which we may handle your personal data as detailed in that policy.</li>
                <li>The management reserves the right to modify the Terms and Conditions at any time without any prior notification.</li>
                <li>These Terms will be subject to Indian Law and the jurisdiction of Indian Courts.</li>
              </ul>
              {/* Refund and cancellation policy section start from here */}
              <h3 className="refund_content">REFUND AND CANCELLATION POLICY</h3>
              <ul>
                <li><span className="black-color">Cancellation policy:</span> If any job provider or Employer, who publishes/advertises their job vacancies. We may cancel the job (s) if we found it goes against our Terms & Conditions.</li>
                <li><span className="black-color">Return policy:</span> Since we are not selling any goods, so the return policy is not applicable here.</li>
                <li><span className="black-color">Refund policy: </span> Under no circumstances the payment made to Humlogjobs for publishing any job advertisement on this job portal <span><Link to="/homepage">www.humlogjobs.com</Link></span> will not be refunded.</li>
              </ul>
              {/* Refund and cancellation policy end */}
            </div>
          </div>
        </div>
      </section>
      {userRole>=0 ? "" : <FooterV1 />}
    </>
  )
}


