import "style/common/interview.scss"
import { useLocation, useHistory } from "react-router-dom";
import { FormattedMessage, FormattedDate, FormattedNumber, FormattedPlural, FormattedTime } from 'react-intl';
import { useIntl } from 'react-intl';
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Form, Col } from "react-bootstrap";
import classNames from "classnames";
import * as JobAction from "redux/actions/JobActions";
import * as UserActions from "redux/actions/UserActions"
import moment from "moment";
import { useState, useEffect } from "react";
import { regexValidation } from "utils/regexValidation"; // common regex file
import { FaPlusCircle } from "react-icons/fa";


export const Interview = () => {
  const { handleSubmit, errors, register } = useForm({
    mode: "onBlur",
  }
  );
  const dispatch = useDispatch();
  const history = useHistory();
  const nowmin = moment().format("YYYY-MM-DD");
  let location = useLocation();
  const [start_time, setStart_time] = useState();
  const [documentsExistsError, setDocumentsExistsError] = useState(false);
  const [end_time, setEnd_time] = useState();
  const { userDetails } = useSelector((state) => state.userReducer);
  let company_address = userDetails.company_address;
  const intl = useIntl();

  /**
   * @description method to schedule interview for job seeker
   * @param {param} val schedule interview form data 
   */
  const update = async (val) => {
    try {
      if (val.hasOwnProperty('req_docs_input')) {
        delete val.req_docs_input
      }
      let data = {
        ...val,
        recruiter_id: location.state.recruiter_id,
        seeker_id: location.state.seeker_id,
        title: location.state.jobs.job_title,
        job_id: location.state.jobs.job_id,
        contact_person: location.state.scheduledUser
      };
      let res = await dispatch(JobAction.scheduleInterview({ data }))
      if (res.value.success) {
        try {
          let jobData = location.state.jobs;
          jobData.status = "Selected";
          let jobUpdate = { _id: location.state.seeker_id, jobs: jobData }
          let res = await dispatch(UserActions.updateUserData(jobUpdate))
          if (res.value.success) {
            history.goBack();
          }
        } catch (error) {
          console.log(error);
        }
      }
    } catch (err) {
      console.log(err);
    }
  }

  // Scheduling Interview time interval of 1hr

  const handleStartTime = (e) => {
    let endTime = document.getElementById("endTime");
    let time = e.target.value;
    let increasedTime = time.split(":");
    increasedTime[0] = +increasedTime[0] + 1;
    increasedTime[0] = ("0" + increasedTime[0]).slice(-2);
    increasedTime = increasedTime[0] + ":" + increasedTime[1];
    setStart_time((endTime.value = increasedTime));
  };

  //  Calender date Validation
  function validateDate() {
    let userdate = new Date(document.getElementById("mydate").value).toJSON().slice(0, 10);
    let today = new Date().toJSON().slice(0, 10);
    if (userdate < today) {
      alert("Please Select a valid date");
    }
  }
  // useEffect(() => {

  // }, [Array]);

  let documentList = [
    {name: "Educational certificate", checked: false},
    {name: "Personal Id Proof", checked:false},
    {name: "Experience document (if any)", checked: false},
    {name: "Driving licence", checked:false}]
   

  const [requiredDocumentValue, setRequiredDocuments] = useState(documentList);
  //  adding other Documents to list
  const HandleDocument = (errors) => {
    if (!errors) {
      let addDocument = document.getElementById("addDocument");
      let text = {
        name: addDocument.value,
        checked: true
      }
      function documentExists(name) {
        return requiredDocumentValue.some((val) => {
          return val.name === name;
        });
      }
      if (text.name.length >= 2 && !documentExists(text.name)) {
        setRequiredDocuments(prev => [...prev, text]);
        setDocumentsExistsError(false);
        addDocument.value = "";
      } else {
        setDocumentsExistsError(true);
      }
    }
  };

  return (
    <>
      <div className="st p_bottom">
        <div className="row no-gutters p-0 m-0">
          <div className="col-12 px-3 interview_form_box">
            <Form onSubmit={handleSubmit(update)}>
              <Form.Group controlId="job_title">
                <Form.Label>
                  <FormattedMessage id="jobTitle" defaultMessage="Job Title" />
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Add your title here"
                  name="title"
                  value={location.state.jobs.job_title}
                  disabled
                  autoComplete="off"
                  className={classNames("form-control", {
                    "is-invalid": errors.title,
                  })}
                  ref={register({
                    required: intl.formatMessage({ id: "enterJobTitle" }),
                  })}
                />

                {errors.title && (
                  <p className="text-danger  oom p-0 m-0">
                    {errors.title.message}
                  </p>
                )}
              </Form.Group>

              <Form.Group controlId="mydate">
                <Form.Label>
                  <FormattedMessage id="date" defaultMessage="Date" />
                </Form.Label>
                <Form.Control
                  type="date"
                  name="date"
                  id="mydate"
                  onChange={validateDate}
                  min={nowmin}
                  placeholder="Add your title here"
                  className={classNames("form-control", {
                    "is-invalid": errors.date,
                  })}
                  ref={register({
                    required: intl.formatMessage({ id: 'pleaseSelectDate' }),
                  })}
                />

                {errors.date && (
                  <p className="text-danger oom p-0 m-0">
                    {errors.date.message}
                  </p>
                )}
              </Form.Group>

              <Form.Group controlId="interview_schedule_for">
                <Form.Label>
                  <FormattedMessage id="interviewFor" defaultMessage="Scheduling interview for" />
                </Form.Label>
                <Form.Control
                  type="text"
                  name="contact_person"
                  value={location.state.scheduledUser}
                  disabled
                  placeholder={intl.formatMessage({ id: 'enterJobSeekerName' })}
                  className={classNames("form-control", {
                    "is-invalid": errors.contact_person,
                  })}
                  ref={register({
                    required: intl.formatMessage({ id: 'pleaseEnterJobSeekerName' }),
                    pattern: {
                      value: regexValidation.PERSON_NAME,
                      message: intl.formatMessage({ id: "specialCharacterNotAllowed" }),
                    },
                    maxLength: {
                      value: 25,
                      message: intl.formatMessage({ id: "maximumSeventyFiveDigit" }),
                    },
                  })}
                />
                {errors.contact_person && (
                  <p className="text-danger oom">
                    {errors.contact_person.message}
                  </p>
                )}
              </Form.Group>

              <Form.Group controlId="time">
                <Form.Label className="d-flex justify-content-start">
                  <FormattedMessage id="startTime" defaultMessage="Start Time" />
                  <b><font className="text-danger ml-1">*</font></b>
                </Form.Label>
                <Form.Row>
                  <Col>
                    <Form.Control
                      placeholder="Eg. 08:00"
                      onChange={handleStartTime}
                      id="startTime"
                      name="start_time"
                      type="time"
                      min="08:00"
                      max="20:00"
                      className={classNames("form-control", {
                        "is-invalid": errors.date,
                      })}
                      ref={register({
                        required: intl.formatMessage({ id: 'pleaseEnterTime' }),
                      })}
                    />
                    {errors.start_time && (
                      <p className="text-danger oom p-0 m-0">
                        {errors.start_time.message}
                      </p>
                    )}
                  </Col>
                  <span className="timelabel">
                    <FormattedMessage id="to" defaultMessage="to" />
                  </span>
                  <Col>
                    <Form.Control
                      placeholder="Eg. 20:00"
                      ref={register}
                      name="end_time"
                      id="endTime"
                      type="time"
                      min="08:00"
                      max="20:00"
                      className={classNames("form-control", {
                        "is-invalid": errors.end_time,
                      })}
                      ref={register({
                        required: intl.formatMessage({ id: 'pleaseEnterTime' }),
                      })}
                    />
                    {errors.end_time && (
                      <p className="text-danger oom p-0 m-0">
                        {errors.end_time.message}
                      </p>
                    )}
                  </Col>
                </Form.Row>
              </Form.Group>

              <Form.Group controlId="interview_location">
                <Form.Label>
                  <FormattedMessage id="location" defaultMessage="Interview Location" />
                </Form.Label>
                <Form.Control
                  type="text"
                  name="location"
                  defaultValue={company_address}
                  placeholder={intl.formatMessage({ id: 'enterLocation' })}
                  className={classNames("form-control", {
                    "is-invalid": errors.location,
                  })}
                  ref={register({
                    required: intl.formatMessage({ id: 'pleaseEnterLocation' }),
                    maxLength: {
                      value: 100,
                      message: intl.formatMessage({ id: "maximumHundredCharacter" }),
                    },
                  })}
                />
                {errors.location && (
                  <p className="text-danger oom">
                    {errors.location.message}
                  </p>
                )}
              </Form.Group>

              <Form.Group controlId="reuired_documents">
                <Form.Label><FormattedMessage id="requiredDocuments" defaultMessage="Required Documents" /></Form.Label>
                <div className="req_doc_box">
                  <Form.Control
                    type="text"
                    name="req_docs_input"
                    id="addDocument"
                    placeholder={intl.formatMessage({ id: 'enterDocument' })}
                    ref={register({
                      pattern: {
                        value: /^[a-zA-Z()\s]*$/,
                        message: intl.formatMessage({id: "specialCharacterNotAllowed"}),
                      }
                    })}
                    className={classNames("form-control", {
                      "is-invalid": errors.req_docs,
                    })}
                  />
                  <button type="button" onClick={() => HandleDocument(errors.req_docs_input)}>
                    <span className="addicon"><FaPlusCircle /></span>
                  </button>
                </div>
                {errors.req_docs_input && (
                  <p className="text-danger oom">
                    {errors.req_docs_input.message}
                  </p>
                )}
                {documentsExistsError === true && (
                  <p className="text-danger oom">
                    <FormattedMessage id="documentsExistsError" defaultMessage="Required Document name already exists" />
                  </p>
                )}
              </Form.Group>

              <div id="documentList">
                {requiredDocumentValue.map((items) => {
                  return (
                    <div className="form-check">
                      <input className="form-check-input" defaultChecked={items.checked}  type="checkbox" id="flexCheckDefault" name="req_docs" ref={register({
                        required:
                          intl.formatMessage({
                            id: "pleaseSelectDocuments",
                            defaultMessage: "Please select the required documents"
                          })
                      })} value={items.name} />
                      <label className="form-check-label" htmlFor="flexCheckDefault">{items.name}</label>
                    </div>
                  )
                }
                )}
              </div>
              {errors.req_docs && (
                <p className="text-danger p-0 m-0 tnc_alrt">
                  {errors.req_docs.message}
                </p>
              )}
              <button className="common_btn mt-4 mb-2" type="submit">
                <FormattedMessage id="submit" defaultMessage="Submit" />
              </button>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
}
