import "./blogdetails.scss"
import { Link, useHistory } from "react-router-dom";
import { HeaderV1 } from '../header/headerv1';
import { FooterV1 } from '../footer/footerv1';
import empower_women from "../../../assets/images/homepage/empower_women.png";
export const EmpowerWomen = (activeblog) => {
const history = useHistory();
  return(
    <>
      <HeaderV1 activeblog = {activeblog}/>
      <section className="blogs_main">
        <div className="container">
          <div className="blogs_main_inner">
            <div className="blogs_heading">
              <div className="blog_heading_img">
                <img src={empower_women} alt="blog" className="img-fluid d-block" />
              </div>
              <div className="blogs_heading_inner">
                <h3>Empower Women, Boost Growth</h3>
                <p>On average women do 75 percent of the world’s total unpaid care work, including childcare, caring for the elderly, cooking and cleaning.</p>
                <p>And in South Asia, the Middle East and North Africa that can be as high as 80 to 90 percent, according to McKinsey.</p>
                <p>As a result, although women make up 39 percent of global employment, they account for 54 percent of overall job losses, the report said.</p>
              </div>
            </div>
            <div className="blogs_content">
              <p>And yet, governments have a powerful incentive to end gender inequality.</p>
              <p>A recent analysis by economists at the Bloomberg news agency found that global economic growth could get a $20 trillion boost if women are educated to the same levels as men and hold the same number of jobs.</p>
              <p>For Indian women Jobseekers, COVID has been a mixed blessing - Remote work has allowed more Indian women to take up paid employment but also increased competition and their workload.</p>
              <p>On our platform <span><Link to="/homepage">www.HumLogJobs.com</Link></span> we are working on addressing these systemic challenges of <span>
                  <a href="https://www.linkedin.com/feed/hashtag/?keywords=genderequality&highlightedUpdateUrns=urn%3Ali%3Aactivity%3A6784805606088876032" target="_blank">
                #genderequality #genderpaygap and #womenempowerment</a>
                </span>
              </p>
              <p>
                <span>
                  <a href="https://www.linkedin.com/feed/hashtag/?keywords=bluecollarlife&highlightedUpdateUrns=urn%3Ali%3Aactivity%3A6784805606088876032" target="_blank">
                  #india #humlogjobs #bluecollarlife #collaboration
                  </a>
                </span>
              </p>
            </div>
          </div>
        </div>
      </section>
      <FooterV1 activeblog = {activeblog}/>
    </>
  )
}


