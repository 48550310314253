import moment from 'moment';

const defaultTime = 0;

export const getSecondsFromTime = (time) => {
  if (!time) return defaultTime;
  const [hours, minutes, seconds] = time.split(":") || [];
  const get = (h, m, s) => ((h * 3600) + (m * 60) + s);
  return get(
    hours || defaultTime,
    minutes || defaultTime,
    seconds || defaultTime
  );
};

export const getTimeZoneOffset = () => {
  return new Date().getTimezoneOffset() * 60;
};

export const getTimeStampFromGMTDate = (_date) => {
  if (!_date) return defaultTime;
  const date = moment(_date).valueOf();
  const offset = getTimeZoneOffset();
  const output = date + offset;
  return output;
};

export const checkTimeExpiry = (item) => {
  const { date, end_time } = item || {};
  const endTime =
    getTimeStampFromGMTDate(date) +
    getSecondsFromTime(end_time || "00:00");
  const currentTime = new Date().getTime();
  return currentTime > endTime;
};
