import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FaCamera } from "react-icons/fa";
import storage from "utils/storage";
import * as userActions from "redux/actions/UserActions";
import * as JobActions from "redux/actions/JobActions";
import * as commonService from "utils/CommonService.js";
import CommonModal from "components/shared/ui-components/common-modal";
import avatar from "../../../assets/images/male_avatar.png";
import { CompanyProfileform } from "../companyprofileform/CompanyProfileform";
import { useHistory } from "react-router-dom";
import { Image } from "components/shared/imagecomponent/image";
import Alert from "@material-ui/lab/Alert";
import { FormattedMessage } from 'react-intl';
// import { Select, MenuItem } from "@material-ui/core";
// import { MdLocationOn } from "react-icons/md";
// import group from "../../../assets/images/Group.png";
// import { serialize } from "object-to-formdata";
// import { UserActionTypes } from "redux/actions/UserActions/actionType";
// import { AiOutlineUser, AiOutlineSetting, AiOutlineYoutube } from "react-icons/ai";
// import { BsBriefcase, BsPencil, BsBook } from "react-icons/bs";
// import { GiPencilBrush, GiPencilRuler } from "react-icons/gi";
// import { IoBookOutline } from "react-icons/";
// import { FiPhoneCall } from "react-icons/fi";
// import { FaTransgender, FaLanguage } from "react-icons/fa";
// import { MdCake } from "react-icons/md";
// import { Educatiofrm } from "components/formmodal/education/Educationfrm";
// import { SkillsForm } from "components/formmodal/skills/skillsForm";
// import { Persnalinfomodal } from "components/commonmodal/Persnalinfomodal";
// import { Userprofileinput } from "components/formmodal/userprofie/Userprofileinput";
// import { Industryinterested } from "components/formmodal/industry/Industryinteresred";
// import { Aboutself } from "components/formmodal/aboutself/Aboutself";
// import { Experience } from "components/formmodal/experience/Experience";
// import { uploadFile } from "../../../utils/fileService";

export const CompanyProfileEdit = (props) => {
  const { userDetails } = useSelector((state) => state.userReducer);
  const history = useHistory();
  const [picture, setPicture] = useState(userDetails && userDetails.profile_pic && userDetails.profile_pic.length > 0 ? userDetails.profile_pic : avatar);
  const [uploadFile, setUploadFile] = useState();
  const dispatch = useDispatch();
  const [loaded, setLoaded] = useState(false);
  const [isError, setisError] = useState({ open: false, message: '' }); // storing errors for upload images failed
  useEffect(() => {
    if (!loaded) {
      setLoaded(true);
      dispatch(userActions.getUserData(storage.get("humlog_user"))).then((response) => {
        if (response.value.success) {
          if (response.value.data.profile_pic) {
            setPicture(`${response.value.data.profile_pic}?${Date.now()}`);
          }
        }
      })
      if (userDetails && !userDetails.company_name) {
        getJobDetails();
      }
    }
  }, [loaded, dispatch]);

  /**
   * @description get the job details from API
   */
  const [jobDetails, setJobDetails] = useState([]);
  const getJobDetails = async () => {
    try {
      let response = await dispatch(JobActions.getJobListDataById(storage.get("humlog_user")));
      if (response && response.value.success) {
        setJobDetails(response.value.data);
      }
    } catch (error) {
      console.log("failed to get Job Details",error)
    }
  }
  // change profile picture
  const onChangePicture = async (e) => {
    let image = e.target.files[0];
    // check image upload content format
    if (!commonService.validateFileContentTypeAndSize(image.type, 'profile')) {
      setisError({ open: true, message: 'Please upload the correct format file.' });
      setTimeout(() => { setisError({ open: false, message: 'Please upload the correct format file.' }) }, 5000);
      return;
    }
    // check image upload size 5 MB
    if (!commonService.validateFileSize(image.size, 5)) {
      setisError({ open: true, message: 'File size should be less than 5 MB' });
      setTimeout(() => { setisError({ open: false, message: '' }) }, 5000);
      return;
    }
    setPicture(URL.createObjectURL(e.target.files[0]));
    setUploadFile(e.target.files[0]);
  };
  let defaultForm = () => <div></div>;
  const [state, setState] = React.useState({ open: false });
  const [form, setForm] = React.useState({ defaultForm });
  const handelModal = (component) => {
    setState({ open: true });
    setForm(component);
  };
  const handelClose = () => {
    setState({ open: false });
  };
  const [choice, setchoice] = useState("");
  const update = (e) => {
    console.log(e.target.value);
  };
  return (
    <>

      <CommonModal open={state.open} handelClose={handelClose}>
        {form}
      </CommonModal>
      {/* Showing error for image upload failed */}
      {isError.open && <Alert severity="error" className="alert-toast">{isError.message}</Alert>}
      <div className="main_user st p_bottom">
        <div className="">
          <div className="row p-0 m-0 no-gutters">
            <div className="col-12 main-section text-center">
              <div className="  px-2 px-lg-4">
                <h5 className="page_title">
                  <FormattedMessage id="myProfile" defaultMessage="My Profile" />
                </h5>
                <div className="">
                  <div className="d-flex justify-content-center ">
                    <div className="img_style_prev main_pic_box">
                      {picture && (
                        <Image picture={picture} alt="Profile Picture"></Image>
                      )}
                      <input
                        type="file"
                        className="d-none"
                        id="cam"
                        onChange={onChangePicture}
                        onClick={(event) => {
                          event.target.value = null
                        }}
                        accept=".png, .jpg, .jpeg"
                      />
                      <label htmlFor="cam">
                        {" "}<FaCamera className="camera_position" />{" "}
                      </label>
                    </div>
                  </div> 
                  <h5 className="mt-1 font-weight-bold">
                    {
                      userDetails && userDetails.company_name ? 
                      userDetails.company_name : 
                      jobDetails && jobDetails.length ? 
                      jobDetails[0].company_name : 
                      "Company Name"
                    }
                  </h5>
                  <div className="mt-1 text-capitalize d-flex justify-content-center">
                    <p>
                      {
                        userDetails &&
                        userDetails.contact &&
                        userDetails.contact.length > 0
                        ? userDetails.contact
                        : ("")
                      }
                    </p>
                    {/*THIS CODE COMMENTED BECAUSE USE FOR FUTURE REFERENCE START FROM HERE*/}
                    {/* <p>
                      {userDetails &&
                        userDetails.contact &&
                        userDetails.contact.length > 0
                        ? userDetails.contact
                        : ""}{" "}
                      |{" "}
                      {userDetails &&
                        userDetails.address_home &&
                        userDetails.address_home.length > 0
                        ? userDetails.address_home
                        : ""}
                    </p> */}
                  </div>
                  <CompanyProfileform company_logo={uploadFile} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
