import React from "react";
import "./header.scss";
import { useLocation } from "react-router-dom";
import logowb from "../../../assets/images/logowhite.png";
import { GoThreeBars } from "react-icons/go";
import { GrFormClose } from "react-icons/gr";
import { useHistory } from "react-router-dom";
import { FiSettings } from "react-icons/fi";
import Tooltip from "@material-ui/core/Tooltip";
// import * as userActions from "redux/actions/UserActions";
// import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage, FormattedDate, FormattedNumber, FormattedPlural, FormattedTime } from "react-intl";

export const Header = (props) => {
  const location = useLocation();
  const history = useHistory();
  console.log(location);

  const opennav = () => {
    document.getElementById("mysidenavbar").style.width = "70%";
  };

  const closebtn = () => {
    document.getElementById("mysidenavbar").style.width = "0px";
  };

  return (
    <>
      {}

      <div className=" fixed-top">
        <div className="row">
          <div className="col-md-4"> </div>

          <div className="col-md-4 main_head  position-relative py-3">
            <div className="d-flex justify-content-center align-items-center">
              <img src={logowb} className="mx-auto" className="header_logo"  alt="img"/>
              {/* Commenting Code for future reference */}
              {/* <div className="ttl_heading d-flex">
              {location.pathname === `/profile` ? <FiUser className="profile_iconn"/> : ''}
                <h6 className="head_style_clr mx-1">{props.title} </h6>
              </div> */}
              {/* dashboard-screen-section start from here */}
              {location.pathname === `/dashboard` ? (
                <div className="text-right"
                    onClick={() => history.push("/setting")} className="setting_iconn">
                    <Tooltip title="Settings">
                        <div className="bars_icon d-flex">
                            <FiSettings className="setting_icon" />
                        </div>
                    </Tooltip>
                </div>
              ) : null}
              {/* dashboard-screen-section end */}
              {/* community-screen-section start from here */}
              {location.pathname === `/community` ? (
                <div className="text-right"
                    onClick={() => history.push("/setting")} className="setting_iconn">
                    <Tooltip title="Settings">
                        <div className="bars_icon d-flex">
                            <FiSettings className="setting_icon" />
                        </div>
                    </Tooltip>
                </div>
              ) : null}
              {/* community-screen-section end */}
              {/* profile-screen-section start from here */}
              {location.pathname === `/profile` ? (
                <div className="text-right" 
                      onClick={() => history.push("/setting")} className="setting_iconn">
                        <Tooltip title="Settings">
                            <div className="bars_icon d-flex">
                                <FiSettings className="setting_icon" />
                            </div>
                      </Tooltip>
                </div>
              ) : null}
              {/* profile-screen-section end */}
              {/* notification-screen-section start from here */}
              {location.pathname === `/Notification` ? (
                <div className="text-right" 
                      onClick={() => history.push("/setting")} className="setting_iconn">
                        <Tooltip title="Settings">
                            <div className="bars_icon d-flex">
                                <FiSettings className="setting_icon" />
                            </div>
                        </Tooltip>
                </div>
              ) : null}
              {/* notification-screen-section end */}
              {/* jobs-screen-section start from here */}
              {location.pathname === `/jobs` ? (
                <div className="text-right" 
                      onClick={() => history.push("/setting")} className="setting_iconn">
                        <Tooltip title="Settings">
                            <div className="bars_icon d-flex">
                                <FiSettings className="setting_icon" />
                            </div>
                        </Tooltip>
                </div>
              ) : null}
              {/* jobs-screen-section end */}
            </div>
          </div>
          <div className="col-md-4"> </div>
        </div>
      </div>

      <div className="sidenavbar" id="mysidenavbar">
        <div className="close_icon">
          {" "}
          <a onClick={closebtn}>
            {" "}<GrFormClose className="close_iconn" />{" "}
          </a>
          {" "}
        </div>
        <div className="p-5">
          <div onClick={() => history.push("/setting")}> setting </div>
        </div>
      </div>
    </>
  );
};
