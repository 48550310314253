import { CompareArrowsOutlined } from "@material-ui/icons";
import React from "react";
import {useSelector} from "react-redux";
import { Route, Redirect } from "react-router-dom";
import storage from "utils/storage";
// import page layout and container for private and public page;
import { PublicRouterLayout, PrivateRouterLayout } from "./routerLayout";

export const PrivateRoute = props => {
  
  const { token } = useSelector(state => state.authReducer);
  const { component: Component, ...restProps } = props;
  const profileStatus = storage.get("profile_status");
  const userRole = storage.get("humlog_user_role");

  if (!Component) return null;
  return (
    <Route
      {...restProps}
      render={routeRenderProps => {
        // check if token is present
        if(token) {
          // check if profile_status is 1
          if(profileStatus === 2){
            // if profile status is 1 then do not send user to basic details
            if((routeRenderProps.location.pathname === "/user/basicdetail"
                || routeRenderProps.location.pathname === "/user/basicdetail/jobinterested")
                && userRole === 0){
              return <Redirect
                to={{
                  pathname: "/jobs",
                  state: { from: routeRenderProps.location }
                }}
              />
            } 
            else if(routeRenderProps.location.pathname === "/companydetail" && userRole === 2) {
              return <Redirect
                to={{
                  pathname: "/dashboard",
                  state: { from: routeRenderProps.location }
                }}
              />
            }
            return ( // render component
              <PrivateRouterLayout>
                <Component  {...restProps} {...routeRenderProps} />
              </PrivateRouterLayout>
            )
          } 
          else if (profileStatus === 1) {
            // check if user is job seeker then redirect to basic detail
            if(userRole === 0) {
              // check if path is of details page
              if(["/user/basicdetail/jobinterested"].includes(routeRenderProps.location.pathname)){
                // stop there and render the component 
                return (
                  <PrivateRouterLayout>
                    <Component  {...restProps} {...routeRenderProps} />
                  </PrivateRouterLayout>
                )
              }
              return <Redirect
                to={{
                  pathname: "/user/basicdetail/jobinterested",
                  state: { from: routeRenderProps.location }
                }}
              />
            } 
            // redirect to company detail
            else { 
              return <Redirect
                to={{
                  pathname: "/dashboard",
                  state: { from: routeRenderProps.location }
                }}
              />
            }
          } 
          else {
            // check if path is of details page
            if(["/user/basicdetail", "/companydetail"].includes(routeRenderProps.location.pathname)){
              // stop there and render the component 
              return (
                <PrivateRouterLayout>
                  <Component  {...restProps} {...routeRenderProps} />
                </PrivateRouterLayout>
              )
            }
            // check if user is job seeker then redirect to basic detail
            if(userRole === 0){
              return <Redirect
                to={{
                  pathname: "/user/basicdetail",
                  state: { from: routeRenderProps.location }
                }}
              />
            }
            // redirect to company detail
            else{
              return <Redirect
                to={{
                  pathname: "/companydetail",
                  state: { from: routeRenderProps.location }
                }}
              />
            }
          }
        }
        // token not present redirect to home page
        else { 
          return (
          <Redirect
              to={{
                pathname: "/homepage",
                state: { from: routeRenderProps.location }
              }}
            />)
        }
      }}
    />
  );
};

export const PublicRoute = props => {
  const { token } = useSelector(state => state.authReducer);
  const { component: Component, ...restProps } = props;
  if (!Component) return null;
  return (
      <Route
        {...restProps}
          render={routeRenderProps =>{
            if(!token) {
              return (
                <PublicRouterLayout>
                  <Component {...routeRenderProps} />
                </PublicRouterLayout>
              )
            // token present redirect to home page
            } else if(routeRenderProps.location.pathname === '/shared/job') {
              return (
                <Redirect
                    to={{
                      pathname: "/job",
                      search: routeRenderProps.location.search,
                      state: { from: routeRenderProps.location }
                    }}
                  />)
            } else if (routeRenderProps.location.pathname === "/shared/community") {
            return <Redirect
                to={{
                  pathname: "/shared/community-video",
                  search: routeRenderProps.location.search,
                  state: { from: routeRenderProps.location }
                }}
              />
            } else if(!["/recruiter/VerifyOTP", "/login/VerifyOTP"].includes(window.location.pathname)) {
            return (
              <Redirect
                  to={{
                    pathname: "/dashboard",
                    state: { from: routeRenderProps.location }
                  }}
                />
              )
            }
          }
        }
      />
  );
};
