import React,{ useState, useContext } from "react";
import { BiBookOpen } from "react-icons/bi";
import { useForm } from "react-hook-form";
import "./setting.scss";
import { useDispatch, useSelector } from "react-redux";
import * as userActions from "redux/actions/UserActions";
import * as authActions from "redux/actions/AuthActions";
import {useHistory} from "react-router-dom";
import {BiArrowBack} from "react-icons/bi";
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import whats from "../../../assets/images/whatsapp-logo.png";
import {FormattedMessage, FormattedDate, FormattedNumber, FormattedPlural, FormattedTime} from 'react-intl';
import {Context} from "Languages/Wrapper/Wrapper";
import storage from "utils/storage";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { AiOutlineCloseCircle } from "react-icons/ai";
import PremiumMemberImage from '../../../assets/images/premium-member-star.png'; // Premium Members Image
import WhatsAppImage from '../../../assets/images/whatsapp.png'; // WhatsApp Image
import { common } from "config/app.constant";
import { DialogTitle } from "@material-ui/core";
import { FaTimes } from "react-icons/fa";

export const Setting = () => {
  const { register, handleSubmit, errors } = useForm({ mode: "onChange" });
  const dispatch = useDispatch();
  const history = useHistory();
  const [state, setState] = useState({ checkedA: true, checkedB: true });
  const userRole = storage.get("humlog_user_role"); // get humlog user role
  let userId = storage.get("humlog_user"); // get humlog user id
  const { userDetails } = useSelector((state) => state.userReducer); // get jobseeker profile data
  const [premiumMemberModal, setpremiumMemberModal] = useState(false); // show and hide premium membership modal
  const [whatsAppModal, setWhatsAppModal] = useState(false); // show and hide whatsapp modal
  const [isChecked, setIsChecked] = useState(userDetails.opt_in ? userDetails.opt_in : false); // variable use for checkbox is checked or not
  const [customerSupportModal, setCustomerSupportModal] = useState(false); // customer support modal

//  function SwitchLabels() {
  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const handelLogout = () => {
    dispatch(authActions.userLogout);
    dispatch(authActions.logout);
    setTimeout(()=>{
      window.location.reload();
      history.push('/JobSeekerlogin');
      // clear local storage on log out
      storage.clear();
    },200);
 }
  // open premium member modal
  const openPremiumModal = () => {
    setpremiumMemberModal(true);
  };
  // close premium member modal
  const closePremiumModal = () => {
    setpremiumMemberModal(false);
  };
  // open whatsapp modal
  const openWhatsAppModal = () => {
    setIsChecked(userDetails?.opt_in ? userDetails.opt_in : '');
    setWhatsAppModal(true);
  };
  // close whatsapp modal
  const closeWhatsAppModal = () => {
    setWhatsAppModal(false);
  };
  const context = useContext(Context);
  const changeLang = (lang) => {
    storage.set("lang",lang);
    context.selectLanguage(lang)
  }

  const choosePlans = () => {
    history.push('/chooseplan');
  }
  // Show-Hide Update Email Address button according to user roles
  const _updateEmailAddress = () => {
    if(userRole === 2) {
      return (
        <div
          className=" mt-2 form-check setting_pp text-center form-check-label text-capitalize lw py-2 px-5"
          onClick={() => history.push("/update-email")}
        >
          <FormattedMessage
            id="updateEmailAddressText"
            defaultMessage="Update Email Address"
          />
        </div>
      );
    }
  };
  // Show-Hide Choose plan text according to user roles
  const _choosePlanText = () => {
    if (userRole === 2) {
      return (
        // showing choose plan 
        <div
          className="form-check setting_pp text-center form-check-label text-capitalize lw py-2 px-5"
          onClick={choosePlans}
        >
          <FormattedMessage id="choosePlan" defaultMessage="Choose Plan" />
        </div>
      );
    } else {
      if(userDetails.is_premium === true) {
      return(
        <div
            className="form-check setting_pp text-center form-check-label  lw py-2 px-5"
            onClick={openPremiumModal}
          >
            <FormattedMessage
              id="alreadyPremiumMembershipText"
              defaultMessage="Already a Premium Member"
            />
          </div>
      )
      } else{
        return (
          // showing upgrade to premimum membership
          <div
            className="form-check setting_pp text-center form-check-label text-capitalize lw py-2 px-5"
            onClick={choosePlans}
          >
            <FormattedMessage
              id="upgradeToPremium"
              defaultMessage="Upgrade to Premium Membership"
            />
          </div>
        );
      }
      
    }
  };
  // API to update whatsapp notification value
  const updateWhatsAppNotification = (value) => {
    try {
      let data = {
        opt_in: value
      };
      dispatch(userActions.updateWhatsApp(data, userId))
        .then((res) => {
          if (res.value.success) {
            setWhatsAppModal(false); // whatsapp modal close
            dispatch(userActions.getUserData(userId)); // api hitting for get user data
          }
        })
        .catch((err) => console.log(err));
    } catch (err) {
      console.log(err);
    }
  }
  // Handling checkbox is checked or not
  const handleOnChange = () => {
    setIsChecked(!isChecked);
  };
  return (
    <>
      <div className="">
        <div className="row p-0 m-0">
          <div className="col-md-12 st ">
            <div className="d-flex">
              <BiArrowBack className="text-center back_icon" onClick={history.goBack} />
              <h5 className="page_title w-100">
                <FormattedMessage id="settings" defaultMessage="Settings" />
              </h5>
            </div>
            <div>
              <div className="form-group mt-2">
                <label for="email" className="cols-sm-2 control-label">
                  <BiBookOpen className="detail_icon_user" />

                  <b><FormattedMessage 
                        id = "language" 
                        defaultMessage="Language" 
                        /></b>
                </label>

                <div className="d-flex justify-content-between">
                  <div className="form-check  setting_op text-center">
                    
                    <label
                      className={context.locale == 'hi' ? "form-check-label text-capitalize lw py-2 bg_cll" : "form-check-label text-capitalize lw py-2"}
                      onClick={()=>changeLang('hi')}
                    >
                      <FormattedMessage 
                        id = "hindi" 
                        defaultMessage="Hindi" 
                        />
                    </label>
                  </div>

                  <div className="form-check  setting_op text-center">
                    {/* <input
                      className="form-check-input d-none"
                      type="radio"
                      name="qualification"
                      id="10th pass"
                      value="1"
                      ref={register({
                        required: "Please  Select  Qualification Field",
                      })}
                    /> */}
                    <label
                      className={context.locale == 'en' ? "form-check-label text-capitalize lw py-2 bg_cll" : "form-check-label text-capitalize lw py-2"}
                      onClick={()=>changeLang('en')}
                    >
                      <FormattedMessage 
                        id = "english" 
                        defaultMessage="English" 
                        />
                    </label>
                  </div>
                </div>
              </div>
            </div>
            {/* Choose Plan Text */}
            {_choosePlanText()}
            {/* Update Email Address Button */}
            {_updateEmailAddress()}
            {/* whatsapp-notification-section start from here */}
            <div className=" mt-2 form-check setting_pp text-center form-check-label text-capitalize lw py-2 px-5" onClick={openWhatsAppModal}>
              <p><FormattedMessage
                id="whatsAppNotification"
                defaultMessage="WhatsApp Notification"
              /></p>
            </div>
            {/* whatsapp-notification-section end */}

            {/* Customer Support Section starts */}
            <div className="setting_pp text-center text-capitalize lw py-2 px-5 mt-3" onClick={() => { setCustomerSupportModal(true) }}>
              <FormattedMessage
                id="customerSupport"
                defaultMessage={common.CUSTOMER_SUPPORT}
              />
            </div>
            {/* Customer Support Section Ends */}

            <div className="form-check  setting_pp text-center form-check-label text-capitalize lw py-2 px-5 mt-3" onClick={handelLogout}>
            <FormattedMessage 
                        id = "logout" 
                        defaultMessage="Logout" 
                        /> 
            </div>

           <a  className="text-light"> <div className="form-check setting_pp text-center form-check-label text-capitalize  lw py-2 px-5 mt-3" onClick={()=>history.push("/t&c")}>
           <FormattedMessage 
                        id = "terms&cond" 
                        defaultMessage="Terms and Condition" 
                        />
            </div> </a>
                      
            {/* <h6 className="p-0 m-0 mt-2"> Notifications</h6> */}
            {/* <div className="d-flex justify-content-between mt-2">
                <h6 className="p-0 m-0 mt-2"> SMS</h6>
              
              <FormControlLabel
        control={
          <Switch
            checked={state.checkedB}
            onChange={handleChange}
            name="checkedB"
            color="primary"
          />
        }
        label=""
      />
      </div> */}

              {/* <div className="d-flex justify-content-between mt-2">
                <h6 className="p-0 m-0 mt-1"> Whatsapp</h6>
              
              <FormControlLabel
        control={
          <Switch
            checked={state.checkedB}
            onChange={handleChange}
            name="checkedB"
            color="primary"
          />
        }
        label=""
      />
      </div> */}
       {/* <img src={whats} width="50px"/> */}
       
          </div>
        </div>
      </div>
       {/* Premium membership modal start from here */}
      <Dialog
        open={premiumMemberModal}
        onClose={closePremiumModal}
        className="premium-modal"
      >
        <div className="close-icon">
          <AiOutlineCloseCircle onClick={closePremiumModal} className="cross-image" />
        </div>
        <DialogContent className="premium-member-modal">
          <div className="premium-member-container">
            <div className="premium-member-image">
              <img src={PremiumMemberImage} width="20%" height="20%" alt="image" />
              <p><FormattedMessage
                id="premiumMembership"
                defaultMessage="Premium Membership"
              /></p>
            </div>
            <div className="congrats-message">
              <p><FormattedMessage
                id="congratsPremiumMembership"
                defaultMessage="Congrats!! You are a Premium Member now"
              /></p>
            </div>
          </div>

        </DialogContent>
      </Dialog>
      {/* Premium membership modal end */}
        {/* WhatsApp-Notification modal start from here */}
		<Dialog
        open={whatsAppModal}
        onClose={closeWhatsAppModal}
        className="premium-modal"
      >
        <div className="close-icon">
          <AiOutlineCloseCircle onClick={closeWhatsAppModal} className="cross-image" />
        </div>
        <DialogContent className="premium-member-modal">
          <div className="premium-member-container">
            <div className="whatsapp-container">
              <img src={WhatsAppImage} width="20%" height="20%" alt="image" />
              <p className="whatsapp-main-heading"><FormattedMessage
                id="getUpdatesOnWhatsApp"
                defaultMessage="Get Updates on WhatsApp"
              /></p>
              <p className="whatsapp-text"><FormattedMessage
                id="whatsAppMessage"
                defaultMessage="Do you want to receive important information and updates over WhatsApp ?"
              /></p>
              {/* toggle-button-section start from here */}
              <div className="toggle-container">
                <form>
                <input
                  autocomplete="off"
                  type="checkbox"
                  id="gridCheck"
                  checked={isChecked}
                  ref={register()}
                  onChange={handleOnChange}
                  name="opt_in"
                  className="whatsapp-notification-button"
                />
                </form>
              </div>
              {/* toggle-button-section end */}
              {/* save-button-section start from here */}
              <button
                type="button"
                className="whatsapp-button" onClick={() => updateWhatsAppNotification(isChecked, userId)}
                name="opt_in">
                <FormattedMessage
                  id="save"
                  defaultMessage="Save"
                />
              </button>
              {/* save-button-section end */}
            </div>
          </div>
        </DialogContent>
      </Dialog>
      {/* WhatsApp-Notification modal end */}


      {/*---------- Customer Support modal-section start from here ----------*/}
      <Dialog open={customerSupportModal} onClose={() => { setCustomerSupportModal(false) }}>
        <DialogTitle className="filter-title text-center"> <b>{common.CUSTOMER_SUPPORT}</b></DialogTitle>
        <button className="modal-close-btn" onClick={() => { setCustomerSupportModal(false) }}>
          <FaTimes />
        </button>
        <DialogContent className="mb-3">
          <b>
            <p className="text-center">
              <FormattedMessage
                id="thanksForContacting"
                defaultMessage={common.THANKYOU_FOR_CONTACT}
              />
            </p>
            <p className="text-center">
              <FormattedMessage
                id="happyToHelp"
                defaultMessage={common.HAPPY_TO_HELP}
              />
            </p>
          </b>
        </DialogContent>
      </Dialog>
      {/*---------- Customer Support modal-section ends from here ----------*/}
    </>
  );
};