import { useIntl } from 'react-intl';
import {FormattedMessage, FormattedDate, FormattedNumber, FormattedPlural, FormattedTime} from 'react-intl';
// public routes
import { JobSeekerlogin } from "components/auth/jobseeker/Login/Jobseekerlogin";
import VerifyOTP from "components/auth/jobseeker/otp/verifyOtp";
import RecruiterVerifyOTP from "components/auth/recruiter/otp/verifyOtp";
import { BasicDetails } from "components/jobseeker/basicdetail/BasicDetails";
import { Userprofile } from "components/jobseeker/userprofile/Userprofile";
import { Aboutself } from "components/formmodal/aboutself/Aboutself";
import { Educatiofrm } from "components/formmodal/education/Educationfrm";
import { Industryinterested } from "components/formmodal/industry/Industryinteresred";
import { Userprofileinput } from "components/formmodal/userprofie/Userprofileinput";
import { Progressbar } from "components/formmodal/progressbar/Progressbar";
import { Jobintrested } from "components/jobseeker/jobinterested/Jobinterested";
import { Community } from "components/mainpage/Community";
import { PhotosCommunity } from "components/community/photosCommunity";
import { Dashboard } from "components/mainpage/Dashboard";
import { Profile } from "components/mainpage/Profile";
import { Jobs } from "components/mainpage/Jobs";
import { Notification } from "components/mainpage/Notification";
import { Livecommunity } from "components/community/Livecommunity";
import { Eventcommunity } from "components/community/Eventcommunity";
import { UserprofileEdit } from "components/jobseeker/userprofileEdit/Userprofile";
import { RecruiterLogin } from "components/auth/recruiter/Loginpage/RecruiterLogin";
import { CompanyProfile } from "components/recruiter/companyprofileform/CompanyProfileform";
import { JobDetails } from "components/recruiter/jobformdetails/JobDetails";
import { Recruiterdashboard } from "components/recruiter/recruiterdashboard/Recruiterdashboard";
import { Recruiterprofile } from "components/recruiter/recruiterprofile/Recruiterprofile";
import { Recruiterjob } from "components/recruiter/recruiterjob/Recruiterjob";
import { CompanyProfileEdit } from "components/recruiter/recruiterEditable/CompanyProfileEdit";
import { Condition } from "components/auth/conditionpage/ConditionPage";
import { Landingpage } from "components/commoncomponent/Landingpage/Landingpage";
import { Aboutpage } from "components/commoncomponent/about/aboutpage";
import { Setting } from "components/commoncomponent/setting/Setting";
import { RecruiterApplied } from "components/recruiter/RecruiterListing/RecruiterApplied";
import { Interview } from "components/recruiter/Interview/Interview";
import { SaarthiPage } from "components/auth/saarthi/Sarthipage/SaarthiPage";
import { SaarthiProfile } from "Saarthi/saarthiprofile/SaarthiProfile";
import { PrivacyPage } from "components/auth/privacypage/PrivacyPage";
import { CreateUser } from "Saarthi/createUser/createUser";
import { UserprofileEditBySarthi } from "Saarthi/userprofileEdit/Userprofile";
import { CompanyprofileDetails } from "components/recruiter/companyprofiledetails/CompanyprofileDetails";
import { CompanyVerification } from "components/recruiter/companyverification/CompanyVerification";
import { VideoResume } from "components/jobseeker/VideoResume/VideoResume";
import { Job } from "components/jobseeker/sharedJob/job";
import { SharedCommunityMain } from "components/community/sharedCommunityMain";
import { SharedCommunityVideo } from "components/community/sharedCommunity";

import { IndianElectronic } from "components/commoncomponent/blogs/indian-electronic";
import { NewJobs } from "components/commoncomponent/blogs/new-jobs";
import { EnsureJobs } from "components/commoncomponent/blogs/ensure-jobs-for-young-people";
import { EmpowerWomen } from "components/commoncomponent/blogs/empower-women";
import { CovidInitiative } from "components/commoncomponent/blogs/covid-19-undp-initiative";

import { ChoosePlan } from "components/mainpage/chooseplan";
import { UpdateEmail } from "components/auth/updateEmail/updateEmail"; // update email address component
import { User } from "components/commoncomponent/user";

export const publicRoutes = [
  
  {
    key: "JobSeekerlogin",
    path: "/JobSeekerlogin",
    component: JobSeekerlogin,
    exact: true,
  },

  {
    key: "Conditionpub",
    path: "/terms&conditions",
    component: Condition,
    exact: true,
  },

  {
    key: "privacyplocy",
    path: "/privacypolicy",
    component: PrivacyPage,
    exact: true,
  },

  {
    key: "frontpage",
    path: "/",
    component: Landingpage,
    exact: true,
  },

  {
    key: "about",
    path: "/about",
    component: Aboutpage,
    exact: true,
  },

  {
    key: "frontpage",
    path: "/homepage",
    component: Landingpage,
    exact: true,
  },

  {
    key: "VerifyOTP",
    path: "/login/VerifyOTP",
    component: VerifyOTP,
    exact: true,
  },

  {
    key: "recruiter",
    path: "/recruiter",
    component: RecruiterLogin,
    exact: true,
  },

  {
    key: "RecruiterVerifyOTP",
    path: "/recruiter/VerifyOTP",
    component: RecruiterVerifyOTP,
    exact: true,
  },

  {
    key: "saarthi",
    path: "/saarthi",
    component: SaarthiPage,
    exact: true,
  },


  {
    key: "saarthiprofile",
    path: "/saarthiprofile",
    component: SaarthiProfile,
    exact: true,
  },

  {
    key: "blogs/indian-electronics",
    path: "/blogs/indian-electronics",
    component: IndianElectronic,
    exact: true,
  },  

  {
    key: "blogs/new-jobs",
    path: "/blogs/new-jobs",
    component: NewJobs,
    exact: true,
  },

  {
    key: "blogs/ensure-jobs-for-young-people",
    path: "/blogs/ensure-jobs-for-young-people",
    component: EnsureJobs,
    exact: true,
  },

  {
    key: "blogs/empower-women",
    path: "/blogs/empower-women",
    component: EmpowerWomen,
    exact: true,
  },

  {
    key: "blogs/covid-19-undp-initiative",
    path: "/blogs/covid-19-undp-initiative",
    component: CovidInitiative,
    exact: true,
  },
  {
    key: "SharedJobPage",
    path: "/shared/job",
    component: Job,
    exact: true
  },
  {
    key: "user",
    path: "/user",
    component: User,
    exact: true
  },
  {
    key: "SharedCommunityPage",
    path: "/shared/community",
    component: SharedCommunityMain,
    exact: true
  },
];

export const privateRoutes = [
  {
    key: "Dashboard1",
    path: "/",
    title:"Dashboard",
    component: Dashboard,
    exact: true,
  },

  {
    key: "Dashboard2",
    path: "/Dashboard",
    title:<FormattedMessage id = "dashboard" defaultMessage="Dashboard"/>,
    component: Dashboard,
    exact: true,
  },

  {
    key: "Community1",
    path: "/Community",
    title:<FormattedMessage id = "community" defaultMessage="Community"/>,
    component: Community,
    exact: true,
  },
  {
    key: "photos",
    path: "/Community/photos",
    component: PhotosCommunity,
    exact: true,
  },

  {
    key: "live",
    path: "/Community/live",
    component: Livecommunity,
    exact: true,
  },

  {
    key: "event",
    path: "/Community/event",
    component: Eventcommunity,
    exact: true,
  },

  {
    key: "jobs",
    path: "/jobs",
    title:<FormattedMessage id = "job" defaultMessage="Jobs"/>,
    component: Jobs,
    exact: true,
  },

  {
    key: "Notification",
    path: "/Notification",
    title: <FormattedMessage id = "notification" defaultMessage="Notification"/>,
    component: Notification,
    exact: true,
  },

  {
    key: "Profile",
    path: "/Profile",
    title:<FormattedMessage id = "myProfile" defaultMessage="My Profile"/>,
    component: Profile,
    exact: true,
  },
  {
    key: "BasicDetails",
    path: "/user/basicdetail",
    component: BasicDetails,
    title:<FormattedMessage id = "basicDetail" defaultMessage="Basic Detail"/>,
    exact: true,
  },

  {
    key: "Jobintrested ",
    path: "/user/basicdetail/jobinterested",
    title:<FormattedMessage id = "jobInterestHeader"/>,
    component: Jobintrested,
    exact: true,
  },

  {
    key: "Userprofile",
    path: "/user/Userprofile",
    component: Userprofile,
    title:"User profile",
    exact: true,
  },

  {
    key: "Editrofile",
    path: "/Editprofile",
    component: UserprofileEdit,
    exact: true,
  },

  {
    key: "companyEdit",
    path: "/recruiterEdit",
    component: CompanyProfileEdit,
    exact: true,
  },

  {
    key: "companydetails",
    path: "/companydetail",
    component:CompanyprofileDetails,
    title:<FormattedMessage id = "basicDetail" defaultMessage="Basic Detail"/>,
    exact: true,
  },

  {
    key: "setting",
    path: "/setting",
    title:<FormattedMessage id = "settings" defaultMessage="Setting"/>,
    component:Setting,
    exact: true,
  },

  {
    key: "Conditionpri",
    path: "/terms&condition",
    component: Condition,
    exact: true,
  },

  
  {
    key: "userapply",
    path: "/userapplied",
    component: RecruiterApplied,
    exact: true,
  },

  {
    key: "Schedule",
    path: "/scheduleinterview",
    title:<FormattedMessage id = "scheduleInterview" defaultMessage="Schedule Interview"/>,
    component:Interview,
    exact: true,
  },

  {
    key: "saarthiprofile",
    path: "/saarthiprofile",
    component: SaarthiProfile,
    exact: true,
  },
  {
    key: "createUser",
    path: "/sarthi/newUser",
    component: CreateUser,
    exact: true,
  },
  {
    key: "VerifyOTP",
    path: "/VerifyOTP",
    component: VerifyOTP,
    exact: true,
  },
  {
    key: "userEdit",
    path: "/user/editProfile",
    component: UserprofileEditBySarthi,
    exact: true,
  },

  {
    key: "Conditionpop",
    path: "/t&c",
    component: Condition,
    exact: true,
  },
  // routing for update email address
  {
    key: "UpdateEmail",
    path: "/update-email",
    title:<FormattedMessage id="updateemial" defaultMessage="Upadte Email"/>,
    component: UpdateEmail,
    exact: true,
  },
  {
    key: "companyVerification",
    path: "/companyverification",
    title:<FormattedMessage id = "companyVerification" defaultMessage="Company Verification"/>,
    component: CompanyVerification,
    exact: true,
  },
  {
    key: "videoResume",
    path: "/videoresume",
    component: VideoResume,
    exact: true,
  },
  {
    key: "chooseplan",
    path: "/chooseplan",
    component: ChoosePlan,
    exact: true,
  },
  {
    key: "JobPage",
    path: "/job",
    component: Job,
    exact: true
  },
  {
    key: "SharedCommunityVideo",
    path: "/shared/community-video",
    component: SharedCommunityVideo,
    exact: true
  },
];
