import axios from "config";
import * as API from "api";
import storage from "utils/storage";

import { UserActionTypes } from "./actionType";

export const getUserData = (id) => (dispatch) =>
  dispatch({
    type: UserActionTypes.GET_User,
    payload: API.getUser(id),
  });

export const getUserDataBySarthi = (query) => (dispatch) =>
  dispatch({
    type: UserActionTypes.GET_USER_BY_SARTHI,
    payload: API.getUserByJobID(query),
  });


export const updateUserData = (body) => (dispatch) =>
  dispatch({
    type: UserActionTypes.UPDATE_USER_DATA,
    payload: API.updateUser(body),
  });

  // export const updateSocialUserData = (body) => (dispatch) =>
  // dispatch({
  //   type: UserActionTypes.UPDATE_SOCIAL_USER_DATA,
  //   payload: API.updateUser(body),
  // });
export const upsertUserData = (body) => (dispatch) =>
  dispatch({
    type: UserActionTypes.UPSERT_USER_DATA,
    payload: API.createUserBySarthi(body),
  });
export const varifyOTP = (body) => (dispatch) =>
  dispatch({
    type: UserActionTypes.CONFIRM_OTP,
    payload: API.varifyOTP(body),
  });

/**
 * Method for Fetching notification list from API
 */
export const getNotificationsList = (page) => (dispatch) =>
  dispatch({
    type: UserActionTypes.GET_NOTIFICATIONS_LIST,
    payload: API.getNotificaitonsList(page),
});
/* method to create payment order */
export const createPaymentOrder = (body) => (dispatch) =>
  dispatch({
    type: UserActionTypes.CREATE_PAYMENT_ORDER,
    payload: API.createPaymentOrder(body),
  });
/* method to verify payment */
export const verifyPayment = (body, userId) => (dispatch) =>
  dispatch({
    type: UserActionTypes.VERIFY_PAYMENT,
    payload: API.verifyPayment(body, userId),
  });
/**
 * Method to fetch user's subscription details
 */
export const getSubscriptionDetails = (page) => (dispatch) =>
  dispatch({
    type: UserActionTypes.GET_SUBSCRIPTION_DETAILS,
    payload: API.getSubscriptionDetails(),
});
/**
 * Method to post job view count
 */
export const postJobViewCount = (body) => (dispatch) =>
  dispatch({
    type: UserActionTypes.JOB_VIEW_COUNT,
    payload: API.postJobViewCount(body),
});

// method to get the community videos list
export const getCommunityVideoList = (query) => dispatch => dispatch({
  type: UserActionTypes.GET_COMMUNITY_VIDEOS_LIST,
  payload: API.getCommunityVideoList(query).then(response => {
      return response;
  })
});

// method to get the community videos by id
export const getCommunityVideoById = (communityId) => dispatch => dispatch({
  type: UserActionTypes.GET_COMMUNITY_VIDEO_BY_ID,
  payload: API.getCommunityVideoById(communityId).then(response => {
      return response;
  })
});
// method to update whatsapp notification value
export const updateWhatsApp = (body, userId) => dispatch => dispatch({
  type: UserActionTypes.UPDATE_WHATSAPP_DATA,
  payload: API.updateWhatsApp(body, userId).then(response => {
      return response;
  })
});

// API to get filtered Jobseekers
export const getFilterUser = ( body, page, jobId) => dispatch => dispatch({
    type: UserActionTypes.USER_FILTER,
    payload: API.filterUser(body, page, jobId)
  })