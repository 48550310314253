import React,{ useState } from 'react';
import { useLocation } from "react-router-dom";
import toastr from "toastr";
import { BehaviorSubject } from "rx";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import Cookies from 'universal-cookie';
import jwt_decode from "jwt-decode";
import storage from "utils/storage";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));
export default function CustomizedSnackbars(isOpen) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <Snackbar open={isOpen} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          This is a success message!
        </Alert>
      </Snackbar>
    </div>
  );
}
toastr.options = {
  positionClass: "toast-top-right",
  showDuration: "300",
  timeOut: 3000,
  closeButton: true,
  showEasing: "swing",
  hideEasing: "linear",
  showMethod: "fadeIn",
  hideMethod: "fadeOut"
};
// for global loader service
export const isLoading = new BehaviorSubject(false);

export const forSuccess = (message, title) => toastr.success(message, title);

export const forError = (message, title) => toastr.error(message, title);

export const forWarning = (message, title) => toastr.warning(message, title);

export const isDialogOpen = new BehaviorSubject(false);

export const getFullName = obj => {
  if (obj) return obj.first_name + " " + obj.last_name;
  else return "";
};

export const getSearchParams = (history, key) => {
  const queryParamsString = history.location.search.substring(1), // remove the "?" at the start
    searchParams = new URLSearchParams(queryParamsString);
  return searchParams.get(key)
};

export const currencyFormat = number => {
  let fixedTwo = parseFloat(number.toFixed(2));
  return new Intl.NumberFormat("en-IN").format(fixedTwo);
};

export const secondsToMinutes = (time) => {
  let sec = Math.floor(time % 60) < 10 ? `0${Math.floor(time % 60)}` : Math.floor(time % 60);
  return `${Math.floor(time / 60)}:${sec}`;
}
/**
 * Common method getting search parameters from url
 * @returns object containinn search parameters
 */
export const useQuery = () => {
  return new URLSearchParams(useLocation().search);
}
/**
 * Method for validating file content length
 * @param {number} fileSize file size in bytes
 * @param {number} sizeLimit allowed size limit ( in MB)
 * @param {string} unit size unit ( KB, MB) default MB
 * @returns boolean
 */
export const validateFileSize = (fileSize, sizeLimit, unit) => {
  //convert fileSize as per the given unit ( default MB )
    if(unit == 'KB') {
      return ((fileSize/Math.pow(1024,1)).toFixed(3)) < sizeLimit;
    }
    return ((fileSize / Math.pow(1024, 2)).toFixed(3)) < sizeLimit;
};
/**
 * Method for validating file content or mime type along with file size
 * @param {string} mimeType file content or mime type
 * @param {string} fileCategory file category
 * @returns boolean
 */
export const validateFileContentTypeAndSize = (mimeType,fileCategory) => {
    switch(fileCategory){
    case 'profile': {
        return ['image/png','image/jpeg','image/jpg'].includes(mimeType);
    }
    case 'documents': {
        return ['application/pdf','application/msword','application/vnd.openxmlformats-officedocument.wordprocessingml.document','image/png','image/jpeg','image/jpg'].includes(mimeType);
    }
    case 'video': {
        return ['video/mp4','video/mpeg'].includes(mimeType);
    }
    case 'audio': {
        return mimeType === 'audio/mpeg';
    }
    default: {
        return false;
    }
    }
};

/**
 * @description method to get source URL from cookies
 * @returns 
 */
export const getSource = () => {
  const cookies = new Cookies();
  const sourceURL = cookies.get('refurl');
  if (sourceURL.indexOf("utm_source") > -1) {
    let params = (new URL(sourceURL)).searchParams;
    let utm_source = params.get('utm_source').split("-");
    return utm_source[1];
  } else {
    return "website";
  }
}


/**
 * @description method to get source URL from cookies
 * and from URL get the user token, decode and save it to storage
 * @returns 
 */
export const getUserToken = () => {
  const cookies = new Cookies();
  const sourceURL = cookies.get('refurl');
  if (sourceURL.indexOf("user_token") > -1) {
    let params = (new URL(sourceURL)).searchParams;
    let user_token = params.get('user_token');
    const token = user_token;
    const decoded = jwt_decode(token);
    storage.set("humlog_user", decoded._id);
    storage.set("humlog_user_role", decoded.role);
    storage.set("humlog_authToken", token);
    storage.set("humlog_refresh_token", token);
    storage.set("profile_status", decoded.profile_status);
  }
}
// Matching job category value
export const commonJobCategoryData = (jobCategoryData, JobCategoryList) => {
  let data = jobCategoryData.map((data) => JobCategoryList.filter((element) => element.name === data)[0]);
  return data;
}

/**
 * @description : method to store data in local storage
 * @param {Object} : res (Respose of the API in object form)
 * @param {Object} : UserDetails 
 * @returns : Stores the following details in Local Storage
 */
 export const saveLocalStorage = (res, userDetails) => {
    storage.set("humlog_user_contact", userDetails.user_name);
    storage.set("user_tnc", true);
    storage.set("humlog_user", res._id);
    storage.set("humlog_user_role", res.role);
    storage.set("humlog_authToken", res.token);
    storage.set("humlog_refresh_token", res.token);
    storage.set("profile_status", res.profile_status);
    storage.set("user_name", userDetails.user_name);
    storage.set("login_type", userDetails.login_type);
    storage.set("lang", 'en');
  }

// Function to move to the top of the page
export const moveToTop = () => { window.scrollTo({ top: 0, behavior: 'smooth' }) } 