import "../../mainpage/chooseplan.scss";
import React, { useState, useContext } from "react";
import { AiFillCheckCircle } from "react-icons/ai";
import { AiFillCloseCircle } from "react-icons/ai";
import { useHistory } from "react-router-dom";
import * as UserActions from "redux/actions/UserActions";
import { useDispatch, useSelector } from "react-redux";
import company_logo from "../../../assets/images/resume_logo.svg";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import config from "../../../config/index";
import {FormattedMessage} from 'react-intl';

export const RecruiterPlans = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { userDetails } = useSelector((state) => state.userReducer);
  const [paymentStatus, setpaymentStatus] = React.useState({isOpen: false, id: ''});
  let transactionID = "";
  /**
   * @description method to open razorpay payment popup
   * @param {string} value plan name
   * @param {event} e event
   * @returns 
   */
   const displayRazorpay = async (value, e) => {
    try {
      const res = await loadScript(
        "https://checkout.razorpay.com/v1/checkout.js"
      );
      // creating a new order
      const orderDetails = await createPaymentOrder(value);
      transactionID = orderDetails.transaction_id; 
      // Getting the order details back
      const option = buildPaymentOption(orderDetails, value);
      initiatePayment(option, e);
    }
    catch (err) {
      console.log(err);
    }
  }

  /**
   * @description method to create script tag in body for loading the razorpay script loading at runtime
   * @param {sting} src
   * @returns 
   */
  function loadScript(src) {
    return new Promise((resolve, reject) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        reject(false);
        console.log('failed to load razarpay script');
      };
      document.body.appendChild(script);
    });
  }

  /**
   * @description method to create payment order
   * @param {string} planName plan name 
   * @returns order_id, transaction_id
   */
  const createPaymentOrder = async (planName) => {
    try {
      let body = {
        amount: config['SUBSCRIPTION'].employer_plan[planName].amount,
        plan_code: config['SUBSCRIPTION'].employer_plan[planName].plan_code,
      }
      let res = await dispatch(UserActions.createPaymentOrder(body))
      if (res.value.success) {
        return {
          order_id: res.value.data.order_id,
          transaction_id: res.value.data.transaction_id,
          key_id : res.value.data.key_id,
          description : res.value.data.description,
        }
      } else {
        return Promise.reject(res); 
      }
    }
    catch (err) {
      console.log(err);
      return Promise.reject(err);
    }
  }
  /**
   * @description method to create options for razorpay
   * @param {object} data order_id, transaction_id
   * @param {number} value amount
   * @returns options for razorpay
   */
  const buildPaymentOption = (data, value) => {
    const options = {
      key: data.key_id, // Enter the Key ID generated from the Dashboard
      amount: ((value)*config['RAZORPAY'].currency_unit), // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      currency: config['RAZORPAY'].currency,
      name: config['RAZORPAY'].company_name,
      description: data.description,
      image: company_logo,
      order_id: data.order_id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
      handler: verifyPayment,
      prefill: {
        name: userDetails.name,
        email: userDetails.email ? userDetails.email : "",
        contact: userDetails.contact ? userDetails.contact : ""
      },
      theme: {
        "color": config['RAZORPAY'].theme
      },
      readonly: {
        contact: true,
        email: true
      },
      allow_rotation: true,
      retry: {
        enabled: false,
        max_count: 0
      },
      modal: {
        escape: false,
        ondismiss: () => {
          history.push("/chooseplan");
        }
      },
      timeout: 600
    };
    return options;
  }
  /**
   * @description method to initialize payment
   * @param {object} option options created in 'buildPaymentOption' method
   * @param {event} e event
   */
  const initiatePayment = (option, e) => {
    const rzp = new window.Razorpay(option);
    if (paymentStatus.isOpen) {
      setpaymentStatus({isOpen: false});
    }
    rzp.open();
    e.preventDefault();
    rzp.on('payment.failed', function (response) {
      console.log('payment failed', response);
      _handleEventPopup('PAYMENT_FAILED');
    });
  }
  /**
   * @description method to verify payment which is supposed to be call in create option method after handler success
   * @param {object} response 
   */
  const verifyPayment = async (response) => {
    try {
      if (paymentStatus.isOpen) {
        setpaymentStatus({isOpen: false});
      }
      let body = {
        order_id: response.razorpay_order_id,
        payment_id: response.razorpay_payment_id,
        payment_signature: response.razorpay_signature,
      }
      //send payment details and transaction id for verfication
      let res = await dispatch(UserActions.verifyPayment(body, transactionID))
      if (res.value.success) {
        setTimeout(() => {
          history.push("/profile");
        }, 3000);
        _handleEventPopup('PAYMENT_SUCCESS');
      } else {
        _handleEventPopup('VERIFICATION_FAILED');
      }
    }
    catch (err) {
      _handleEventPopup('VERIFICATION_FAILED');
      console.log('VERIFICATION_FAILED',err);
    }
  }

  const _handleEventPopup = (event) => {
    switch (event) {
      case 'PAYMENT_SUCCESS': {
        openEventModal('SUCCESS');
        break;
      }
      case 'PAYMENT_FAILED': {
        openEventModal('FAILED');
      break;
      }
      case 'VERIFICATION_FAILED': {
        openEventModal('FAILED');
        break;
      }
      default: {
        console.log('invalid event', event);
      }
    }
  }
  const openEventModal = (eventType) => {
    switch (eventType) {
      case 'SUCCESS': {
        setpaymentStatus({isOpen: true, id: 'success'});
        break;
      }
      case 'FAILED': {
        setpaymentStatus({isOpen: true, id: 'failed'});
        break;
      }
      default: {
        console.log('invalid event type', eventType);
      }
    }
  }
  /**
   * @description method to close the payment status popup
   */
  const handleClose = () => {
    setpaymentStatus((prev)=> !prev.isOpen);
  };
  return (
    <>
      <div className="chooseplans_main">
        <div className="container">
          <div className="chooseplans_inner">
            <div className="plans_title">
              <h3><FormattedMessage id="humLogJobsPlan"/></h3>
            </div>
            {/* <div className="plans_heading">
              <p><FormattedMessage id="subscriptionOffering"/></p>
            </div> */}
            <div className="ribbon">
              <span className="inner-ribbon"><FormattedMessage id ="introductoryOffers"/></span>
            </div>
            <div className="plans_details_list">
              <div className="plans_details_inner recruiterplan">
              <div class="box display-none">
                  <div class="ribbon-side ribbon-top-left"><span><FormattedMessage id="introductoryOffers"></FormattedMessage></span></div>
              </div>
                <table>
                  <tr>
                    <th className="benefits"><FormattedMessage id ="benefits"/></th>
                    <th className="gold"><FormattedMessage id="gold"/><span className="delete-price"><del>₹2000</del></span><span className="current-price">₹600</span></th>
                    <th className="diamond"><FormattedMessage id ="diamond"/><span className="delete-price"><del>₹3500</del></span><span className="current-price">₹1000</span></th>
                    <th className="platinum"><FormattedMessage id="platinum"/><span className="delete-price"><del>₹12000</del></span><span className="current-price">₹3000</span></th>
                  </tr>
                  <tr>
                    <td><FormattedMessage id="videoResume"/>*</td>
                    <td><span className="icon"><AiFillCheckCircle className="available"/></span></td>
                    <td><span className="icon"><AiFillCheckCircle className="available"/></span></td>
                    <td><span className="icon"><AiFillCheckCircle className="available"/></span></td>
                  </tr>
                  <tr>
                    <td><FormattedMessage id="jobPosting"/></td>
                    <td><span className="text">2</span></td>
                    <td><span className="text">4</span></td>
                    <td><span className="text">15</span></td>
                  </tr>
                  <tr>
                    <td><FormattedMessage id="unlimitedJobApplication"/></td>
                    <td><span className="icon"><AiFillCheckCircle className="available" /></span></td>
                    <td><span className="icon"><AiFillCheckCircle className="available" /></span></td>
                    <td><span className="icon"><AiFillCheckCircle className="available" /></span></td>
                  </tr>
                  <tr>
                    <td><FormattedMessage id="freeAudioJd"/></td>
                    <td><span className="text">2</span></td>
                    <td><span className="text">4</span></td>
                    <td><span className="text">15</span></td>
                  </tr>
                  <tr>
                    <td><FormattedMessage id="freeeAnimatedJD"/></td>
                    <td><span className="icon"><AiFillCloseCircle className="unavailable" /></span></td>
                    <td><span className="text">1</span></td>
                    <td><span className="text">3</span></td>
                  </tr>
                  <tr className="buttonrow border_bottom_zero">
                    <td></td>
                    <td><span className="buynowbtn"><button type="button" 
                    onClick={(e)=> displayRazorpay('Gold',e)}><FormattedMessage id="buyNow"/></button></span></td>
                    <td><span className="buynowbtn"><button type="button" 
                    onClick={(e)=> displayRazorpay('Diamond',e)}><FormattedMessage id="buyNow"/></button></span></td>
                    <td><span className="buynowbtn"><button type="button" 
                    onClick={(e)=> displayRazorpay('Platinum',e)}><FormattedMessage id="buyNow"/></button></span></td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Dialog
        open={paymentStatus.isOpen}
        onClose={handleClose}
        className="payment_main"
      >
        <DialogContent className="payment_inner">
        {(paymentStatus.id === 'success') ? (
          <div className="payment_content success">
            <span><AiFillCheckCircle /></span>
            <h3>Payment Successful!</h3>
            <button type="button" onClick={handleClose}>GOT IT</button>
          </div>
        ) : ('') }
        {(paymentStatus.id === 'failed') ? (
          <div className="payment_content failed">
            <span><AiFillCloseCircle /></span>
            <h3>Payment Failed!</h3>
            <p>If any amount deducted it will be refunded in the next 5-7 working days.</p>
            <button type="button" onClick={handleClose}>GOT IT</button>
          </div>
        ) : ('') }
        </DialogContent>
      </Dialog>
    </>
  )
}