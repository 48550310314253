import firebase from 'firebase/app';
import 'firebase/messaging';
import { _privateFirebaseConfig, _privateVapidKey } from 'config/app.constant';
const firebaseConfig = {
  apiKey: _privateFirebaseConfig.apiKey,
  authDomain: _privateFirebaseConfig.authDomain,
  projectId: _privateFirebaseConfig.projectId,
  storageBucket: _privateFirebaseConfig.storageBucket,
  messagingSenderId: _privateFirebaseConfig.messagingSenderId,
  appId: _privateFirebaseConfig.appId,
};
// firebase.initializeApp(firebaseConfig);
export default firebase; // export default
let messaging;
const fireBaseInitialize = async () => {
  messaging = await firebase.messaging.isSupported() ? firebase.messaging() : null; // firebase message service
} 
// fireBaseInitialize();
/**
 * @description method to generate firebase token
 * @returns currentToken
 */
export const getToken = () => {
  return messaging.getToken({vapidKey: _privateVapidKey.vapidKey}).then((currentToken) => {
    if (currentToken) {
      // console.log('current token for client: ', currentToken);
      return currentToken;
    }
  }).catch((err) => {
    console.log('An error occurred while retrieving token. ', err);
    // catch error while creating client token
  });
}
export const onMessageListener = () =>
  new Promise((resolve) => {
    messaging.onMessage((payload) => {
      resolve(payload);
    });
});
