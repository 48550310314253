import './skills.scss'
import React, { useState, useEffect } from "react";
import {FormattedMessage, FormattedDate, FormattedNumber, FormattedPlural, FormattedTime} from 'react-intl';
import GrAddCircle from '@material-ui/icons/AddCircleOutline';
import { makeStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import {useForm} from "react-hook-form";
import {useDispatch, useSelector } from "react-redux"
import * as userActions from "redux/actions/UserActions"
import { serialize } from 'object-to-formdata';
import storage from "utils/storage"
import {useHistory} from "react-router-dom";
import { Multiselect } from "multiselect-react-dropdown";
const useStyles = makeStyles((theme) => ({
  chip: {
    margin: theme.spacing(0.5),
    color: '#ffffff',
    background: '#0096fb',
    height: '26px',
    borderRadius: '11px',
    marginTop: '0px'
  }
}));
export const SkillsForm = ({handelClose}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history= useHistory();
  const { JobCategoryList } = useSelector((state) => state.jobCategoryReducer);
  const { userDetails } = useSelector((state) => state.userReducer);
  const [skills,setSkills] = useState([])
  const {register,handleSubmit,reset,errors } = useForm();
  const [jobIntrested, setJobIntrested] =useState([]);
  const [input, setInput] = useState('');
  const [otherSkills,setOtherSkills] = useState([]);
  const [otherSkillErorr, setOtherSkillErorr] = useState(false);
  //get job_category List of the user
  const getJobCategoryList = () => {
    let job_category = [];
    if(userDetails &&
      userDetails.job_intrested &&
      userDetails.job_intrested.length) {
        for(let val of userDetails.job_intrested) {
          job_category.unshift(val.job_category);
        }
      }
    return job_category;
  }
  // get all skills list for job_categories of the user
  const getSkillsList = (job_category) => {
    let skillsList =[];
    for (let val of job_category ) {
      let jobCategoryIndex = 0;
      for(let category of JobCategoryList) {
        jobCategoryIndex = jobCategoryIndex + 1;
        if(val == category.name) {
          skillsList.unshift(category.skills);
          break;
        }
        if(jobCategoryIndex === JobCategoryList.length) {
          skillsList.unshift([]);
        }
      }
    }
    return skillsList;
  }
  /**
   * Method to handle skills entered for other job category
   * @param {String} value skill input entered by user
   * @param {String} job_category job category of user
   * @param {String} element_id
   */
  const handleInput = (value, job_category, element_id) => {
    setOtherSkillErorr(false);
    if(value === '') {
      setOtherSkillErorr(true);
      return;
    }
    let InputSkills;
    let job_interested =jobIntrested;
    for(let val of job_interested){
      if(val.job_category === job_category) {
        InputSkills = val.skills;
      }
    }
    InputSkills.push(value);
    setInput('');
    document.getElementById(element_id).value = '';
    onSkillChange(InputSkills, job_category);
  }
  // update job_intrested array onSelect
  const onSkillChange = (e,job_category) => {
    let job_interested =jobIntrested;
    for(let val of job_interested){
      if(val.job_category === job_category) {
        val.skills = e;
      }
    }
    setJobIntrested(job_interested);
  }
  useEffect(() => {
    const job_category = getJobCategoryList();
    const skillsList = getSkillsList(job_category);
    setSkills(skillsList);
    if(userDetails &&
      userDetails.job_intrested &&
      userDetails.job_intrested.length){
        setJobIntrested(userDetails.job_intrested);
      }
  }, []);
  
  const update=()=>{
  let data ={job_intrested: jobIntrested, _id:storage.get('humlog_user')}
  const formData = serialize(data);
  dispatch(userActions.updateUserData(data)).then(res=>{
    dispatch(userActions.getUserData(storage.get("humlog_user")))
   if(res.value.success){
     console.log(res.value.message)
   }
}).catch(err=> (console.log(err)))
 reset();
 handelClose();
}

  return (
    <>
    <div className="conatiner">
      <div className="row">
        <div className="col-12">
          <h2 className="prnl_head_aa">
            <FormattedMessage id = "skills" defaultMessage="Skills" />
          </h2>
          <div className="verticalscroll">
            {userDetails &&
            userDetails.job_intrested &&
            userDetails.job_intrested.length > 0 &&
            userDetails.job_intrested.map((val, index) => {
              return (
                <>
                  <div className="mt-3 mb-1 prnl_head_aa">{val.job_category}</div>
                  {skills && skills[index] && skills[index].length > 0 ? (
                    <Multiselect options={skills[index].sort()}
                    style={{multiselectContainer: {
                      "marginBottom": "10px"
                    },
                    optionContainer: {"maxHeight": "140px"},
                    chips: {
                      "whiteSpace": "break-spaces"},
                  }}
                    isObject={false}
                    onSelect={ (e)=>onSkillChange(e,val.job_category)}
                    onRemove={ (e)=>onSkillChange(e,val.job_category)}
                    selectedValues={val.skills}
                    selectionLimit={3}
                    />
                  ) : (
                    <div>
                      {val.skills.map(skill => {
                        return(
                          <Chip
                            key={skill}
                            label={skill}
                            className={classes.chip}
                          />
                        )
                      })}
                        <div className="add_other_field">
                          <input 
                            type='text' 
                            className='other_Skills_Input' 
                            name={index} 
                            id={index} 
                            placeholder='Enter skills' 
                            onInput={(e) => setInput(e.target.value)} 
                            disabled={val.skills.length < 3 ? false : true}
                          />
                          <GrAddCircle 
                            className={val.skills.length >= 3 ? "disabled" : ''} 
                            onClick={(e) => handleInput(input,val.job_category,index)}
                            style={{color:"#0678C1", fontSize:"30px", marginLeft:"15px"}}
                          />
                        {otherSkillErorr ? <p className="text-danger">This field is required </p> : ("")}
                        </div>
                    </div>
                  )}
                </>
              );
            })}
          </div>
        </div>
        <div className="col-12">
          <div className="py-2 px-2">
            <button onClick={update} className="common_btn">
              <FormattedMessage id="save" defaultMessage="Save" />
            </button>
          </div>
        </div>
      </div>
      
    </div>
  </>
);
};
