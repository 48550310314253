import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { serialize } from "object-to-formdata";
import { FormattedMessage, FormattedDate, FormattedNumber, FormattedPlural, FormattedTime } from "react-intl";
import "./jobmatch.scss";
import { MdAttachMoney } from "react-icons/md";
import { VscBriefcase, VscBell } from "react-icons/vsc";
import { IoIosCloseCircle } from "react-icons/io";
import { HiOutlineStar } from "react-icons/hi";
import { BsCheck } from "react-icons/bs";
import * as JobActions from "redux/actions/JobActions";
import * as UserActions from "redux/actions/UserActions";
import { useDispatch, useSelector } from "react-redux";
import genderi from "../../../assets/images/genderi.png";
import pencili from "../../../assets/images/pencili.png";
import sallaryi from "../../../assets/images/salary.png";
import storage from "utils/storage";
import { BiRupee } from "react-icons/bi";
import dummylogo from "../../../assets/images/dummylogo.png";
import { FaRegFileAudio, FaRegFileVideo } from "react-icons/fa";
import { Image } from "components/shared/imagecomponent/image";
import { Player } from "components/shared/videoplayer/player";
import ShareIcon from "components/shared/share-icons/index";

export const JobCard = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [jobIndex, setJobIndex] = useState(0);
  const { userDetails } = useSelector((state) => state.userReducer);
  const [modal, setModal] = useState({ open: false, src: "" });
  const [isViewDes, setViewDes] = useState(false);

  const changeview = () => {
    setViewDes((prev) => !prev);
  }

  const nextJob = () => {
    props.jobList.length !== jobIndex ? setJobIndex(jobIndex + 1) : setJobIndex(0);
  };

  let val = props.jobList[jobIndex];

  /* method to open and close player */
  const onToggleModal = (src) => {
    setModal({ open: !modal.open, src: src });
  };
  /**
   * method to change the status of the job
   * @param {Array} job
   * @param {String} status status of job [Applied, Saved, Visited]
   */
  const changeJobStatus = (job, status) => {
    const selectedJob = {
      company_logo: job.company_logo,
      company_name: job.company_name,
      job_id: job._id,
      job_title: job.job_title,
      salary_from: job.salary_from,
      salary_to: job.salary_to,
      job_location: job.job_location,
      category: job.category,
      jobStatus: job.status,
      openings: job.openings,
      skills: job.skills,
      job_experience: job.experience,
      job_timings: job.job_timings,
      job_type: job.job_type,
      description: job.description,
      animated_description: job.animated_description,
      audio_description: job.audio_description,
      status: status,
    };
    let data = {
      _id: userDetails._id,
      jobs: selectedJob
    };
    dispatch(UserActions.updateUserData(data))
      .then((res) => {
        if (res.value.success) {
          dispatch(JobActions.getJobListData(storage.get("humlog_user")));
          dispatch(UserActions.getUserData(storage.get("humlog_user")));
        }
      })
      .catch((err) => console.log(err));
  };

  // no more jobs to apply for
  if (props.jobList && props.jobList.length === 0 && userDetails && userDetails.jobs && userDetails.jobs.length > 0) {
    setTimeout(() => {
      history.push("/dashboard");
    }, 15000);
    return (
      <div className="st ">
        <div className=" text-center pt-5 text-primary ">
          You visited all posted jobs
          <h3>Thank you</h3>{" "}
        </div>
      </div>
    )
  }

  // no jobs found.
  if (props.jobList && props.jobList.length === 0 && userDetails && userDetails.jobs && userDetails.jobs.length === 0) {
    setTimeout(() => {
      history.push("/profile");
    }, 15000);
    return (
      <div className="st ">
        <div className=" text-center pt-5 text-primary ">
          <FormattedMessage id="noMatchFound" defaultMessage=" No matching job found, please complete profile on My Profile page."/>
          <h3><FormattedMessage id="thankYou" defaultMessage="Thank you"/></h3>
        </div>
      </div>
    )
  }
  // when both salary value is null (Max and Min) then shown Disclosed
  const _handleSalary = (salary_from, salary_to) => {
    if (salary_from && salary_to) {
      return (
        <h6 className="ca">
          ₹ {salary_from}  - ₹ {salary_to}
        </h6>
      )
    } else if (salary_from && !salary_to) {
      return (
        <h6 className="ca">
          <FormattedMessage id="moreThan" defaultMessage="More Than" values={{more : salary_from}}/>  
        </h6>
      )
    } else if (salary_to && !salary_from) {
      return (
        <h6 className="ca">
          <FormattedMessage id="upTo" defaultMessage="Upto" values={{upto : salary_to}}/>
        </h6>
      )

    } else  {
      return (
        <h6 className="ca">
          <FormattedMessage id="disClosed" defaultMessage="Disclosed"/>
        </h6>
      )
    }
  }
  return (props.jobList && props.jobList.length && (
    <div className="main_job main_job_container">
      <ShareIcon url={`${window.location.origin}/shared/job?id=${val.job_id}`} text="Your Sarthi in Journey of Job Success & Worklife Happiness" />
      <button type="button" className="viewdetails" onClick={changeview}>View <span>{isViewDes ? 'Less' : 'More'}</span></button>
      <Player open={modal.open} toggleModal={onToggleModal} src={modal.src} />
      <div className="row p-0 m-0 no-gutters">
        <div className="col-12">
          <div className="pt-2 d-flex justify-content-around">
            <button className="audio-video_btn" onClick={() => { onToggleModal(val.audio_description) }} disabled={val.audio_description.length === 0 ? true : false}><FaRegFileAudio /> </button>
            {val.company_logo && val.company_logo.length > 0 ? (
              <div style={{ width: "100px", height: "100px" }}>
                <Image picture={`${val.company_logo}?${Date.now()}`} alt="user_profile"></Image>
              </div>
            ) : (
              <div style={{ width: "100px", height: "100px" }}>
                <Image picture={dummylogo} alt="user_profile"></Image>
              </div>
            )}
            <button className="audio-video_btn" onClick={() => { onToggleModal(val.animated_description) }} disabled={val.animated_description.length === 0 ? true : false}><FaRegFileVideo /> </button>
          </div>
          <div className="d-flex justify-content-center">
            <div>
              <h4 className="Job_ta mt-2 text-capitalize text-center">
                {val && val.job_title ? val.job_title : ''}
              </h4>
              <h4 className="Job_ttb mt-1 text-capitalize text-center">
                {val && val.company_name ? val.company_name : ''}
              </h4>
              <h4 className="Job_ttb mt-1 text-capitalize text-center job_Location">
                {val && val.district ? val.district : ''}
              </h4>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row p-0 m-0 no-gutters">
          <div className="col-7 ">
            {" "}
            <div className="jobdetailsfield">
              <div className="d-flex mt-2">
                <img
                  src={sallaryi}
                  className="_job_ua"
                  width="16px"
                  height="16px"
                />
                <p className="_job_ub ml-1">
                  {" "}
                  <FormattedMessage id="salary" defaultMessage="Salary" />
                </p>
              </div>
              {/* salary-section */}
              {_handleSalary(val.salary_from, val.salary_to)}
            </div>
            <div className="jobdetailsfield">
              <div className="d-flex mt-2">
                <VscBriefcase className="_job_ua" />
                <p className="_job_ub ml-1">
                  {" "}
                  <FormattedMessage
                    id="experienceRequired"
                    defaultMessage="Experience Required"
                  />
                </p>
              </div>
              <h6 className="ch">
                {" "}
                {val.experience === 0
                  ? "Fresher"
                  : val.experience === 1
                    ? "Less than 1 year"
                    : val.experience === 2
                      ? "1-2 years"
                      : val.experience === 3
                        ? "2-4 years"
                        : val.experience === 4
                          ? "4-6 years"
                          : val.experience === 5
                            ? "6-8 years"
                            : val.experience === 6
                              ? "8-10 years"
                              : val.experience === 7
                                ? "10+ years"
                                : ""}
              </h6>
            </div>
            <div className="jobdetailsfield">
              <div className="d-flex mt-2">
                <VscBell className="_job_ua" />
                <p className="_job_ub ml-1">
                  {" "}
                  <FormattedMessage
                    id="jobTimings"
                    defaultMessage="Job Timings"
                  />
                </p>
              </div>
              <h6 className="ch">
                {" "}
                {val.job_timings === 0
                  ? "General"
                  : val.job_timings === 1
                    ? "Rotation"
                    : ""}
              </h6>
            </div>
          </div>

          <div className="col-5 ">
            {" "}
            <div className="jobdetailsfield">
              <div className="d-flex mt-2">
                <img src={genderi} className="_job_ua" />
                <p className="_job_ub ml-1">
                  {" "}
                  <FormattedMessage id="gender" defaultMessage="Gender" />
                </p>
              </div>
              <h6 className="ch">
                {" "}
                {val.gender === 0
                  ? "All"
                  : val.gender === 1
                    ? "Female"
                    : val.gender === 2
                      ? "Male"
                      : val.gender === 3
                        ? "Others"
                        : ""}
              </h6>
            </div>
            <div className="jobdetailsfield">
              <div className="d-flex mt-2 ">
                <img src={pencili} className="_job_ua" />
                <p className="_job_ub ml-1">
                  <FormattedMessage
                    id="skillRequired"
                    defaultMessage="Skill Required"
                  />
                </p>
              </div>
              <h6 className="ch skiils_width">{val.skills} </h6>{" "}
            </div>
            <div className="jobdetailsfield">
              <div className="d-flex mt-2 ">
                <VscBriefcase className="_job_ua" />
                <p className="_job_ub ml-1">
                  {" "}
                  <FormattedMessage id="jobType" defaultMessage="Job Type" />
                </p>
              </div>
              <h6 className="ch">
                {" "}
                {val.job_type === 0
                  ? "Office"
                  : val.job_type === 1
                    ? "Field"
                    : val.job_type === 2
                      ? "Shift"
                      : val.job_type === 3
                        ? "Part Time"
                        : ""}
              </h6>
            </div>
          </div>
        </div>
      </div>
      {isViewDes ? (
        <div className="container" >
          <div className="row p-0 m-0 no-gutters">
            {/* job-location-section start from here */}
            <div className="col-12 d-flex">
              <div className="jobdetailsfield">
                <div className="mt-2">
                  <p className="_job_ub">
                    <FormattedMessage id="jobLocation" defaultMessage="Job Location" />
                  </p>
                </div>
                <h6 className="ch jd job-details-text">
                  {val.job_location ? val.job_location : ""}
                </h6>
              </div>
            </div>
            {/* job-location-section end */}
            <div className="col-12 d-flex">
              <div className="d-flex mt-2">
                <p className="_job_ub">
                  {" "}
                  <FormattedMessage id="jobDescription" defaultMessage="Job Description" />
                </p>
              </div>
            </div>
            <div className="col-12 d-flex">
              <h6 className="ch jd">
                {" "}
                {val.description}
              </h6>
            </div>
          </div>
        </div>
      ) : ("")}

      <div className="container">
        <div className="row p-0 m-0 no-gutters">
          <div className="col-12  mt-3  d-flex justify-content-around">
            <button
              className="btn_va shadow"
              onClick={() => changeJobStatus(val, "Visited")}
            >
              <IoIosCloseCircle className="cross_icon" />
              <FormattedMessage id="notApply" defaultMessage="Not Apply" />
            </button>
            <button
              className="btn_vb d-flex shadow"
              onClick={() => changeJobStatus(val, "Saved")}
            >
              <HiOutlineStar className="box_ia job_pa" />
              <div className="jobResponse">
                <FormattedMessage
                  id="applyLater"
                  defaultMessage="Apply Later"
                />
              </div>
            </button>
            <button
              className="btn_vc d-flex shadow"
              onClick={() => changeJobStatus(val, "Applied")}
            >
              <BsCheck className="box_ib job_pb " />
              <div className="jobResponse">
                <FormattedMessage
                  id="applyNow"
                  defaultMessage="Apply Now"
                />
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
  )
};
