import Otp from "./Otp";
import { errorMessage, question, userType, stepsId, triggerValue } from "config/app.constant";
import { regexValidation } from "utils/regexValidation";
import Captcha from "./Captcha";
import Contact from "./Contact";

const steps = [
    {
        id: stepsId.WELCOME_QUESTION,
        message: question.WELCOME,
        trigger: triggerValue.USER_SELECTION,
    },
    {
        id: stepsId.USER_SELECTION,
        options: [
            { value: userType.JOB_SEEKER, label: "I am a Job-Seeker", trigger: triggerValue.JS },
            { value: userType.EMPLOYER, label: "I am an Employer", trigger: triggerValue.EMP },
        ],
    },
    {
        id: stepsId.JS,
        message: question.GREET_JOB_SEEKER,
        trigger: triggerValue.QUESTION4,
    },
    {
        id: stepsId.EMP,
        message: question.GREET_EMPLOYER,
        trigger: triggerValue.QUESTION4,
    },
    {
        id: stepsId.QUESTION4,
        options: [
            { value: "signup", label: "Registration", trigger: triggerValue.SIGNUP },
            { value: 'query', label: "Customer Support ", trigger: triggerValue.CONNECT_SOON },
            { value: 'feedback', label: "Feedback", trigger: triggerValue.FEEDBACK_NAME },
        ],
    },
    {
        id: stepsId.SIGNUP,
        message: question.ENTER_PHONE,
        trigger: triggerValue.CONTACT_NUMBER,
    },
    {
        id: stepsId.CONTACT_NUMBER,
        user: true,
        validator: (value) => {
            if (regexValidation.PHONE.test(value)) {
                return true;
            } else {
                return errorMessage.INVALID_PHONE
            }
        },
        trigger: triggerValue.OTP,
    },
    {
        id: stepsId.OTP,
        component: <Contact />,
        asMessage: true,
        trigger: triggerValue.ENTER_OTP,
    },
    {
        id: stepsId.ENTER_OTP,
        user: true,
        validator: (value) => {
            if (regexValidation.OTP.test(value)) {
                return true;
            } else {
                return errorMessage.INVALID_OTP;
            }
        },
        trigger: triggerValue.VERIFY_OTP,
    },
    {
        id: stepsId.VERIFY_OTP,
        component: <Otp />,
        end: true,
        asMessage: true,
    },
    {
        id: stepsId.QUERY_NAME,
        message: question.ENTER_NAME,
        trigger: triggerValue.SUBMIT_QUERY_NAME,
    },
    {
        id: stepsId.SUBMIT_QUERY_NAME,
        user: true,
        validator: (value) => {
            if (regexValidation.FULL_NAME.test(value)) {
                return true;
            } else {
                return errorMessage.INVALID_NAME;
            }
        },
        trigger: triggerValue.QUERY_CONTACT,
    },
    {
        id: stepsId.QUERY_CONTACT,
        message: question.ENTER_PHONE,
        trigger: triggerValue.SUBMIT_QUERY_CONTACT,
    },
    {
        id: stepsId.SUBMIT_QUERY_CONTACT,
        user: true,
        validator: (value) => {
            if (regexValidation.PHONE.test(value)) {
                return true;
            } else {
                return errorMessage.INVALID_PHONE
            }
        },
        trigger: triggerValue.QUERY,
    },
    {
        id: stepsId.FEEDBACK_NAME,
        message: question.ENTER_NAME,
        trigger: triggerValue.SUBMIT_FEEDBACK_NAME,
    },
    {
        id: stepsId.SUBMIT_FEEDBACK_NAME,
        user: true,
        validator: (value) => {
            if (regexValidation.FULL_NAME.test(value)) {
                return true;
            } else {
                return errorMessage.INVALID_NAME;
            }
        },
        trigger: triggerValue.FEEDBACK_CONTACT,
    },
    {
        id: stepsId.FEEDBACK_CONTACT,
        message: question.ENTER_PHONE,
        trigger: triggerValue.SUBMIT_FEEDBACK_CONTACT,
    },
    {
        id: stepsId.SUBMIT_FEEDBACK_CONTACT,
        user: true,
        validator: (value) => {
            if (regexValidation.PHONE.test(value)) {
                return true;
            } else {
                return errorMessage.INVALID_PHONE
            }
        },
        trigger: triggerValue.REVIEW,
    },
    {
        id: stepsId.QUERY,
        message: question.ENTER_QUERY,
        trigger: triggerValue.SUBMIT_QUERY,
    },
    {
        id: stepsId.SUBMIT_QUERY,
        user: true,
        validator: (value) => {
            if (value && value.length >= 5) {
                return true;
            } else {
                return errorMessage.MIN_QUERY_LENGTH
            }
        },
        trigger: triggerValue.OPEN_CAPTCHA,
    },
    {
        id: stepsId.REVIEW,
        message: question.ENTER_REVIEW,
        trigger: triggerValue.SUBMIT_REVIEW,
    },
    {
        id: stepsId.SUBMIT_REVIEW,
        user: true,
        validator: (value) => {
            if (regexValidation.RATING.test(value)) {
                return true;
            } else {
                return errorMessage.INVALID_RATINGS;
            }
        },
        trigger: triggerValue.OPEN_CAPTCHA,
    },
    {
        id: stepsId.OPEN_CAPTCHA,
        component: <Captcha />,
        asMessage: true,
        end: true 
    },
    {
        id: stepsId.CONNECT_SOON,
        message: question.CONNECT_SOON,
        end:true
    },
];

export default steps;