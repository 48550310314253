module.exports = {
    'RAZORPAY': {
        company_name: 'HumlogJobs',
        currency: 'INR',
        currency_unit: 100,
        theme: '#0678c1',
    },
    'SUBSCRIPTION': {
        employer_plan: {
            'Gold': {
                name: 'Gold',
                amount: 600,
                plan_code: 'HLJ-EMP-101'
            },
            'Diamond': {
                name: 'Diamond',
                amount: 1000,
                plan_code: 'HLJ-EMP-102'
            },
            'Platinum': {
                name: 'Platinum',
                amount: 3000,
                plan_code: 'HLJ-EMP-103'
            }
        },
        job_seeker_plan: {
            'Premium': {
                name: 'Premium',
                amount: 500,
                plan_code: 'HLJ-JS-101',
                default_saarthi_code: 'SAARTHI001'
            }
        }
    },
    ERROR_CODE :{
        social_login : {
            access_denied : 'Failed to access email or profile data, Please enable manage access from your app settings'
        }
    }
}