import React, { useEffect } from "react";
import store from 'redux/store/store';

import { Provider } from 'react-redux';
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { Router, Redirect, Route, Switch } from "react-router-dom";
import { createBrowserHistory as createHistory } from "history";
// private public routes for prevent internal page from unauthenticated user
import { PrivateRoute, PublicRoute } from "./routes";

import * as RouteConfig from "./routeConfig.js"; //configure all path in this file
import ErrorBoundary from "./ErrorBoundary";
import Loader from "components/shared/ui-components/loader/Loader.js";
import ConfirmDialog from "components/shared/ui-components/common-dialog";
import CommonModal from "components/shared/ui-components/common-modal";
// add font family for material component
const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#0678C1"
    },
    secondary: {
      main: "#262933"
    }
  },
  typography: {
    fontSize: 12,
    fontFamily: ["Poppins", "sans-serif"].join(",")
  }
});

export default function Main() {
  const { privateRoutes, publicRoutes } = RouteConfig;
  // scroll top on routes change
  const history = createHistory();
  history.listen(_ => {
    window.scrollTo(0, 0);
  });

  history.listen((location, action) => {
    let hashPath = window.location.pathname + window.location.hash;
    // Just to make sure that
    // hash is changed to slash 
    // on home page
    hashPath = hashPath.replace(/homepage#/g, 'homepage/');
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'pageview',
      pagePath: hashPath,
      pageTitle: 'Humlogjobs',
    });
  })
  useEffect(() => {
    //let hashPath = window.location.pathname + window.location.hash;
    // Just to make sure that
    // hash is changed to slash 
    // on home page
    // hashPath = hashPath.replaceAll('homepage#','homepage/');
    // window.dataLayer = window.dataLayer || [];
    // window.dataLayer.push({
    //   event: 'pageview',
    //   pagePath: hashPath,
    //   pageTitle: 'Humlogjobs',
    // });
  }, [])

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <ConfirmDialog />
        <CommonModal/>
        <Loader />
        <Router history={history}>
          <ErrorBoundary>
            {/* A <Switch> looks through its children <Route>s and
             renders the first one that matches the current URL. */}
            <Switch>
              
              {privateRoutes.map(privateRouteProps => (
                <PrivateRoute {...privateRouteProps} />
              ))}
              {publicRoutes.map(publicRouteProps => (
                <PublicRoute {...publicRouteProps} />
              ))}
              <Route render={() => <Redirect to={{ pathname: "/" }} />} />
            </Switch>
          </ErrorBoundary>
        </Router>
      </ThemeProvider>
      </Provider>
  );
}
