import "./blogdetails.scss"
import { Link, useHistory } from "react-router-dom";
import { HeaderV1 } from '../header/headerv1';
import { FooterV1 } from '../footer/footerv1';
import electronics from "../../../assets/images/homepage/electronics.png";
export const IndianElectronic = (activeblog) => {
  const history = useHistory();
  return (
    <>
      <HeaderV1 activeblog = {activeblog}/>
      <section className="blogs_main">
        <div className="container">
          <div className="blogs_main_inner">
            <div className="blogs_heading">
              <div className="blog_heading_img">
                <img src={electronics} alt="blog" className="img-fluid d-block" />
              </div>
              <div className="blogs_heading_inner">
                <h3>Indian Electronic repair market can create over 5M jobs</h3>
                <p>The electronics repair industry has the potential of becoming an emerging sector for employment growth in India. With the right regulatory support from the government of India, the domestic repair service sector in India can experience a tremendous boost</p>
                <p><span><a href="https://lnkd.in/ezu9RKM" target="_blank">https://lnkd.in/ezu9RKM</a></span></p>
                <p><span><a href="https://www.linkedin.com/feed/hashtag/?keywords=indiabusiness&highlightedUpdateUrns=urn%3Ali%3Aactivity%3A6792648194258989056" target="_blank">#indiabusiness #jobsfromindia #employmentopportunity #humlogjobs #bluecollarjobs </a></span></p>
              </div>
            </div>
            <div className="blogs_content">
              <p>Our portal <span><Link to="/homepage">Humlogjobs.com</Link></span> is the perfect place for such jobs postings with access to talent pools all over India.</p>
            </div>
          </div>
        </div>
      </section>
      <FooterV1 activeblog = {activeblog}/>
    </>
  )
}


