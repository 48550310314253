import React from "react";
import { FaCheckCircle } from "react-icons/fa";
import "./info.scss";
import { FormattedMessage } from "react-intl";

export const Info = ({ handelClose }) => {
  //** English hindi translate on the basic of Id **//
  const requiredItems = [
    "aboutMe",
    "jobInterest",
    "yearsOfExp",
    "skills",
    "qualification",
    "technicalCertification",
    "mobileNumber",
    "gender",
    "dob",
    "languageIKnow",
    "speakEnglish",
    "currentDist",
  ]; 
    return (
        <>
        <div className="resume_info">
          <div className="info_header">
          <h2><FormattedMessage id="requiredInformation" /></h2>
          <p><FormattedMessage id="provideResume" /></p>
          </div>
          <div className="info_body">
            {requiredItems.map( (val) => {
              return (
                <div className="info_card">
              <div className="check_icon">
                <FaCheckCircle/>
              </div>
              <div className="info_content">
                <p><FormattedMessage id={val}/></p>
              </div>
            </div>
              )
            })}
          </div>
        </div>
        </>
    )
}