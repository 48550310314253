import { UserActionTypes } from "../actions/UserActions/actionType";
import storage from "utils/storage";

export const initialState = {
  userList: [],
  notificationsList: [],
  isLoading: false,
  userDetails: {},
  userDetailsById: {},
  userSuscriptionDetails: {},
  count: 0,
};

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case UserActionTypes.GET_USER_PENDING:
      return Object.assign({}, state, {
        isLoading: true,
        userDetails: {},
      });
    case UserActionTypes.GET_USER_FULFILLED:
      return Object.assign({}, state, {
        isLoading: false,
        userDetails: action.payload.data,
      });

    case UserActionTypes.GET_USER_BY_SARTHI_PENDING:
      return Object.assign({}, state, {
        isLoading: true,
        userDetails: {},
      });
    case UserActionTypes.GET_USER_BY_SARTHI_FULFILLED:
      return Object.assign({}, state, {
        isLoading: false,
        userDetailsById: action.payload.data,
      });

    case UserActionTypes.UPDATE_USER_DATA_PENDING:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case UserActionTypes.UPDATE_USER_DATA_FULFILLED:
      return Object.assign({}, state, {
        isLoading: false,
        userDetails: action.payload.data,
      });

    case UserActionTypes.UPDATE_SOCIAL_USER_DATA_PENDING:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case UserActionTypes.UPDATE_SOCIAL_USER_DATA_FULFILLED:
      return Object.assign({}, state, {
        isLoading: false,
        userDetails: action.payload.data,
      });

    case UserActionTypes.UPSERT_USER_DATA_PENDING:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case UserActionTypes.UPSERT_USER_DATA_FULFILLED:
      return Object.assign({}, state, {
        isLoading: false,
        userDetailsById: action.payload.data,
      });

    case UserActionTypes.CONFIRM_OTP_PENDING:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case UserActionTypes.CONFIRM_OTP_FULFILLED:
      return Object.assign({}, state, {
        isLoading: false,
      });
    case UserActionTypes.GET_NOTIFICATIONS_LIST_PENDING:
      return Object.assign({}, state, {
        isLoading: true,
        count: 0,
        notificationsList: [],
      });
    case UserActionTypes.GET_NOTIFICATIONS_LIST_FULFILLED:
      return Object.assign({}, state, {
        isLoading: false,
        count : action.payload.data.count,
        notificationsList: action.payload.data.data,
      });
    case UserActionTypes.GET_SUBSCRIPTION_DETAILS_PENDING:
      return Object.assign({}, state, {
        isLoading: true,
        userSuscriptionDetails: {},
      });
    case UserActionTypes.GET_SUBSCRIPTION_DETAILS_FULFILLED:
      return Object.assign({}, state, {
        isLoading: false,
        userSuscriptionDetails: action.payload.data,
      });

    default:
      return state;
  }
};
