import React, { useState, useEffect, useCallback } from "react";
import {FormattedMessage, FormattedDate, FormattedNumber, FormattedPlural, FormattedTime} from 'react-intl';
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { serialize } from "object-to-formdata";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import * as UserActions from "redux/actions/UserActions";
import * as jobActions from "redux/actions/JobActions";
import TabsUI from "../../commoncomponent/TabsUI.js";
import { GrMore } from "react-icons/gr";
import Menu from "@material-ui/core/Menu";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { TiTickOutline } from "react-icons/ti";
import { MdTouchApp } from "react-icons/md";
import { FaRegListAlt, FaRegCalendarAlt } from "react-icons/fa";
import { FiPhoneCall } from "react-icons/fi";
import { MenuItem } from "@material-ui/core";
import { Userapplied } from "../UserAppliedjob/Userapplied";
import ViewMoreJobSeeker from "../viewMoreJS/ViewMoreJobSeeker";
import men from "assets/images/thumbnails/user1.png";
import storage from "utils/storage.js";
import avatar from "../../../assets/images/male_avatar.png";
import { Image } from "components/shared/imagecomponent/image";
import { useQuery } from "utils/CommonService";
import { RiMailSendLine } from "react-icons/ri"; // Send LOI Icon
import { BsPersonPlus, BsPersonDash } from "react-icons/bs"; // Joined & not-joined Icon
import ConfirmDialog from "components/shared/ui-components/common-dialog";
import CommonModal from "components/shared/ui-components/common-modal";
import { common } from "config/app.constant.js";

export const RecruiterApplied = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { userList } = useSelector((state) => state.jobReducer);
  const [userCount, setUserCount] = useState({applied : 0,
    rejected : 0,
    selected : 0,
    hired : 0,
    shortlisted : 0});
  const [anchorEl, setAnchorEl] = useState(null);
  const [jobDetails, setJobDetails] = useState(props && props.location.jobData ? props.location.jobData : '');
  const [userContactNumber,setUserContactNumber] = useState({
    contact:"xxx-xxx-xxxx",
    index:null
  }) // showing Job Seeker's no. on clicking Call icon [on Good Fit Page]
  const query = useQuery();
  // let userId = query.get("id");
  let jobId = query.get("id");
  
  useEffect(() => {
    fetchUserList();
  }, [dispatch]);
  /**
   * fetch user list by job Id
   */
  const fetchUserList = async () => {
    try{
      const response = await dispatch(jobActions.getUserListByJobId(jobId));
      if(response && response.value.success) {
        const jobCounts = updateUserCounts(response.value.data);
        setUserCount(jobCounts);
      }
    } catch(err) {
      console.log("error in fetching user details", err);
    }
  }
  /**
   * Set counts of users performed actions on a job
   * @param {Array} users list of users 
   * @returns count of different users [applied,rejected, hired, shortlisted]
   */
  const updateUserCounts = (users) => {
    let userCounts = {
      applied : 0,
      rejected : 0,
      selected: 0,
      hired : 0,
      shortlisted : 0
    };
    for(let user of users) {
      switch (user.jobs[0].status) {
        case 'Applied' :
          userCounts.applied = userCounts.applied + 1
          break;
        case 'Shortlisted' :
          userCounts.shortlisted = userCounts.shortlisted + 1
          break;
        case 'Selected' :
          userCounts.selected = userCounts.selected + 1
          break;
        case 'Hired' :
          userCounts.hired = userCounts.hired + 1
          break;
        case 'Rejected' :
          userCounts.rejected = userCounts.rejected + 1
          break;
        default :
          continue
      }
    }
    return userCounts
  }
  const changeUserJobStatus = (status, job, userId, popupState) => {
    let jobData = job;
    jobData.status = status;
    let data = { _id: userId, jobs: jobData };
    let formData = serialize(data);

    dispatch(UserActions.updateUserData(data))
      .then((res) => {
        if (res.value.success) {
          console.log(res.value.message);
          dispatch(jobActions.getUserListByJobId(job.job_id));
          popupState.close();
          setTabData(status);
        }
      })
      .catch((err) => console.log(err));
  };

  const labelsData = [
    <p>
      <FormattedMessage id="applied" defaultMessage="Applied" />
      <span className="tabcount">{userCount.applied}</span>
    </p>,
    <p>
      <FormattedMessage id="goodFit" defaultMessage="Good Fit" />
      <span className="tabcount">{userCount.shortlisted}</span>
    </p>,
    <p>
      <FormattedMessage id="scheduleInterview" defaultMessage="Schedule Interview" />
      <span className="tabcount">{userCount.selected}</span>
    </p>,
    <p>
      <FormattedMessage id="notFit" defaultMessage="Not a Fit" />
      <span className="tabcount">{userCount.rejected}</span>
    </p>,
    <p>
      <FormattedMessage id="viewMoreJobSeeker" defaultMessage={common.VIEW_MORE_JS} />
    </p>,
  ];
  {/*THIS CODE COMMENTED BECAUSE USE FOR FUTURE REFERENCE START FROM HERE*/}
  // <p>
  //   <FormattedMessage id="hired" defaultMessage="Hired" />
  //   <span className="tabcount">{userCount.hired}</span>
  // </p>
  const onError = (img) => {
    if (!img.errored) {
      img = men;
    }
  };
  /**
   * @description method to post the click count when employer try to see/call the jobseeker
   * @param {string} userId user id of particular jobseeker 
   */
  const viewJobCount = (userId) => {
    try {
      let data = {
        job_id: jobId,
        user_id: userId
      };
      dispatch(UserActions.postJobViewCount(data));
    } catch (err) {
      console.log("error in posting click counts on call button", err);
    }
  }
  useEffect(() => {}, []);

 // navigation to job seeker profile
  const _viewUserProfile = (status, userId, jobId, job) => {
    history.push({
      pathname: "/user/Userprofile",
      status: status,
      job: job,
      search: "?id=" + userId + "&job_id=" + jobId,
    })
  }
  const setTabData = (status) => {
    if (userList && userList.length > 0) {
      return userList
        .filter((user) => user.jobs[0].status == status)
        .map((user, index) => {
          return (
            <>
              <div
                className="col-12  d-flex pt-3 pb-3 justify-content-between border"
                key={user._id}
              >
                <div className="d-flex">
                  <div
                    className=""
                    onClick={() =>
                      _viewUserProfile(user.jobs[0].status, user._id, jobId, user.jobs[0])
                    }
                  >
                    {user.profile_pic && user.profile_pic.length ? (
                    <div style={{width:"80px",height:"80px"}}>
                      <Image picture={`${user.profile_pic}?${Date.now}`} alt="user_profile"></Image>
                    </div>) : (
                      <div style={{width:"80px",height:"80px"}}>
                      <Image picture={avatar} alt="user_profile"></Image>
                    </div>
                    )}
                    
                  </div>
                  <div className="ml-3">
                    <h2 className="user_nme mt-1 text-capitalize"
                      onClick={() =>
                        _viewUserProfile(user.jobs[0].status, user._id, jobId, user.jobs[0])
                      }
                    >
                      {user.name}
                    </h2>
                    <p className="user_ub">{user.jobs[0].job_location}</p>
                    <p className="user_uc">
                      Status:{" "}
                      {
                        user.jobs[0].status == "Selected"
                        ? "Interview Scheduled"
                        : user.jobs[0].status
                      }
                    </p>
                    {user.jobs[0].status == "Shortlisted" ? 
                    (
                      <p className="user_uc contact_color">
                      Contact: <span> {(userContactNumber.index == index) ? userContactNumber.contact : "xxx-xxx-xxxx"}</span>
                      </p>
                    ) : ("")
                    }
                  </div>
                </div>
                <div className="mb-2">
                {/* Note:- In this menu items dropdown the menu items is shown on the basis of its status and i.e. each items should be displayed in every section of tabs on the basis of status */}
                    <PopupState variant="popover" popupId="demo-popup-menu">
                      {(popupState) => (
                        <>
                          <GrMore {...bindTrigger(popupState)} />
                          <Menu {...bindMenu(popupState)}>
                            <MenuItem
                              onClick={() =>
                                _viewUserProfile(user.jobs[0].status, user._id, jobId, user.jobs[0])
                              }
                            >
                              <MdTouchApp className="fnt_icon" />
                              <FormattedMessage id = "view" defaultMessage="View" />
                            </MenuItem>
                            {status != "Selected" && status != "Applied" && status != "Rejected" && status != "Hired" && (
                              <MenuItem
                                onClick={() =>
                                  history.push({
                                    pathname: "/scheduleInterview",
                                    state: {
                                      recruiter_id: storage.get("humlog_user"),
                                      jobs: user.jobs[0],
                                      seeker_id: user._id,
                                      scheduledUser:user.name,
                                    },
                                  })
                                }
                              >
                                <FaRegListAlt className="fnt_icon" /> 
                                <FormattedMessage id = "scheduleInterview" defaultMessage="Schedule Interview" />
                              </MenuItem>
                            )}
                            {/* Reschedule-section start from here */}
                            {status != "Hired" && status != "Applied" && status != "Shortlisted" && status != "Rejected" && (
                              <MenuItem>
                                <FaRegCalendarAlt className="fnt_icon" /> 
                                <FormattedMessage id = "rescheduleInterview" defaultMessage="Reschedule Interview" />
                              </MenuItem>
                            )}
                            {/* Reschedule-section end */}

                            {/*THIS CODE COMMENTED BECAUSE USE FOR FUTURE REFERENCE START FROM HERE*/}

                            {/* Hired menus  start*/}
                            {/* Send-LOI-section start from here */}

                            {/* {status != "Applied" && status != "Shortlisted" && status != "Rejected" && status != "Selected" && (
                              <MenuItem>
                                <RiMailSendLine className="fnt_icon" /> 
                                <FormattedMessage id = "sendLOI" defaultMessage="Send LOI" />
                              </MenuItem>
                            )} */}

                            {/* Send-LOI-section end */}

                            {/* Joined-section start from here */}
                            
                            {/* {status != "Applied" && status != "Shortlisted" && status != "Rejected" && status != "Selected" && (
                              <MenuItem>
                                <BsPersonPlus className="fnt_icon" /> 
                                <FormattedMessage id = "joined" defaultMessage="Joined" />
                              </MenuItem>
                            )} */}
                            
                            {/* Joined-section end */}
                            
                            {/* Not-Joined-section start from here */}
                            
                            {/* {status != "Applied" && status != "Shortlisted" && status != "Rejected" && status != "Selected" && (
                              <MenuItem>
                                <BsPersonDash className="fnt_icon" /> 
                                <FormattedMessage id = "notJoined" defaultMessage="Not Joined" />
                              </MenuItem>
                            )} */}
                            
                            {/* Not-Joined-section end */}
                          </Menu>
                        </>
                      )}
                    </PopupState>
                    {
                      user.jobs[0].status == "Shortlisted" 
                      ? (
                          <p className="call-icon">
                            <a className="desktop" onClick={() => 
                              {
                                openDialogBox(user.contact);
                                viewJobCount(user._id);
                                setUserContactNumber({contact:user.contact, index:index})
                              }
                            }>
                              <FiPhoneCall/>
                            </a>
                            <a className="mobile" href={`tel:${user.contact}`} onClick={() => 
                              {
                                viewJobCount(user._id);
                                setUserContactNumber({contact:user.contact, index:index})
                              }
                            }>
                              <FiPhoneCall/>
                            </a>
                          </p>
                        )
                      : ("") 
                    }
                </div>
              </div>
            </>
          );
        });
    }
  };
  const [dialog, setDialog] = useState({
    open: false,
    message: "",
    type: "",
    confirm_text: "",
    cancel_text: "",
  });
  const openDialogBox = (contact) => {
    setDialog({
      open: true,
      message: `Contact Number is ${contact}`,
      type: "Desktop",
      confirm_text: "Okay",
    });
  };
  const closeDialogBox = () => {
    setDialog({ open: false, message: "" });
  };

  const tabsData = {
    0: <Userapplied status = "Applied"> {setTabData("Applied")} </Userapplied>,
    1: <Userapplied>{setTabData("Shortlisted")}</Userapplied>,
    2: <Userapplied>{setTabData("Selected")}</Userapplied>,
    3: <Userapplied>{setTabData("Rejected")}</Userapplied>,
	4: <ViewMoreJobSeeker jobDetails={jobDetails} />
  };
  // THIS CODE COMMENTED BECAUSE USE FOR FUTURE REFERENCE START FROM HERE
  // 4: <Userapplied>{setTabData("Hired")}</Userapplied>,
  <Userapplied />;
  return (
    <>
      <div className="st m-0 p_bottom">
        <TabsUI labels={labelsData} tabs={tabsData} />
      </div>
      {dialog.open && (
        <ConfirmDialog
          open={dialog.open}
          confirm_text={dialog.confirm_text}
          cancel_text={dialog.cancel_text}
          setState={setDialog}
          message={dialog.message}
          confirm={closeDialogBox}
        ></ConfirmDialog>
      )}
    </>
  );
};
