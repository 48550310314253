import React, { useEffect, useState } from "react";
import axios from "axios";
import { BASE_URL } from "../../config";
import { errorMessage, successMessage, endPoint, otpVerification } from "config/app.constant";
import { saveLocalStorage } from "utils/CommonService";

const Otp = (props) => {
    const [contactNumber, setContactNumber] = useState(props && props.steps.contactNumber.value ? props.steps.contactNumber.value : '')
    const [otp, setOtp] = useState(props && props.steps.enterOtp.value ? props.steps.enterOtp.value : '')
    const [message, setMessage] = useState('')

    // Function to API call for OTP verification
    const verifyOtp = () => {
        const URL = BASE_URL()
        const userDetails = {
            lead_source: otpVerification.CHAT_BOT,
            login_type: otpVerification.CONTACT,
            otp: otp,
            user_name: contactNumber
        };
        axios
            .post(`${URL}${endPoint.OTP_VERIFY}`, userDetails)
            .then((res) => {
                if (res.status) {
                    saveLocalStorage(res.data.data, userDetails)
                    setMessage(successMessage.REDIRECT_SUCCESS)
                } else {
                    setMessage(res.data.message ? res.data.message : errorMessage.SOMETHING_WRONG)
                }
            })
            .catch((error) => {
                setMessage(errorMessage.INVALID_OTP);
            });
    }

    useEffect(() => {
        verifyOtp();
    }, []);
    return (
        <>{message ? message : errorMessage.SOMETHING_WRONG}</>
    )
}

export default Otp