import React, { useState, useEffect } from "react";
import {
  FormattedMessage,
  FormattedDate,
  FormattedNumber,
  FormattedPlural,
  FormattedTime,
} from "react-intl";
import { useIntl } from "react-intl";
import moment from "moment";
import { Form } from "react-bootstrap";
import "./userprofile.scss";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import * as userActions from "redux/actions/UserActions";
import { serialize } from "object-to-formdata";
import storage from "utils/storage";
import { useHistory } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { DistrictList } from "components/jobseeker/DistrictList";
import classNames from "classnames";
import { regexValidation } from "utils/regexValidation";
import ConfirmDialog from "components/shared/ui-components/common-dialog";
import Alert from "@material-ui/lab/Alert";
import appConstant from "config/app.constant";
export const Userprofileinput = ({ section, handelClose }) => {
  const dispatch = useDispatch();
  const { userDetails } = useSelector((state) => state.userReducer);
  const [langInput, setLangInput] = useState(false);
  const [otherInput, setOtherInput] = useState("");
  const nowmin = moment().subtract(55, "years").format("YYYY-MM-DD");
  const nowmax = moment().subtract(18, "years").format("YYYY-MM-DD");
  const [state, setState] = useState({ open: false });
  const [error, setError] = useState({open: false, message: ''});

  useEffect(() => {
    loadProfile();
  }, []);
  const loadProfile = () => {
    let data = userDetails;
    data.gender = String(userDetails.gender);
    data.address_current = userDetails.address_current;
    data.job_type = String(userDetails.job_type);
    data.job_timings = String(userDetails.job_timings);
    reset(data);
  };
  const DistrictListt = DistrictList.sort();
  const { register, handleSubmit, reset, errors } = useForm({
    mode: "onBlur",
  });
  const openDialog = (val) => {
    setState({ open: true, val: val });
  };

  const update = (val) => {
    if(val.hasOwnProperty('other')) {
     delete val.other
    }
    let data = { ...val, _id: storage.get("humlog_user") };
    const formData = serialize(data);

    dispatch(userActions.updateUserData(data))
      .then((res) => {
        if (res.value.success) {
          dispatch(userActions.getUserData(storage.get("humlog_user")));
          reset();
          handelClose();
        }
      })
      .catch((err) => {
        console.log(err);
        setError({open: true, message: err.data.message})
      });
      setState({open : false});
  };

  const intl = useIntl();
  if (section === "mobile") {
    return (
      <>
        {state.open && (
          <ConfirmDialog
            open={state.open}
            val={state.val}
            setState={setState}
            message="It can not be edited once saved"
            confirm={update}
          ></ConfirmDialog>
        )}
        {error.open && <Alert severity="error" className="alert-toast">{error.message}</Alert>}
        <div className="personal-info-form">
          <div className="row no-gutters">
            <div className="col-12  pt-1 pb-2">
              <Form onSubmit={handleSubmit(openDialog)}>
                <div className="px-3">
                  <Form.Group controlId="exampleForm.ControlSelect1">
                    <Form.Label className="d-flex justify-content-start lbl_a row_padding_bottom">
                      <h2 className="prnl_head_aa">
                        <FormattedMessage
                          id="mobileNumber"
                          defaultMessage="Mobile Number"
                        />
                      </h2>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      autocomplete="off"
                      placeholder={intl.formatMessage({
                        id: "enterTenDigit",
                      })}
                      name="contact"
                      className={classNames("form-control", {
                        "is-invalid": errors.contact,
                      })}
                      ref={register({
                        required: "Please enter contact number",
                        pattern: {
                          value: regexValidation.PHONE,
                          message: "Please enter 10 digit valid mobile number",
                        },
                      })}
                    />
                    {errors.contact && (
                      <p className="text-danger  txt">
                        {errors.contact.message}
                      </p>
                    )}
                  </Form.Group>
                </div>
                <div className="btn_bd py-2 px-2  bb mb-5">
                  <button className="common_btn">
                    {" "}
                    <FormattedMessage id="save" defaultMessage="Save" />
                  </button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <div className="personal-info-form">
        <div className="row no-gutters">
          <div className="col-12  pt-1 pb-2">
            <div></div>

            <div>
              <Form onSubmit={handleSubmit(update)}>
                <div className="px-3">
                  {section === "dob" ? (
                    <Form.Group controlId="formGroupEmail">
                      <Form.Label className="lbl_a">
                        <h2 className="prnl_head_aa">
                          <FormattedMessage
                            id="dob"
                            defaultMessage="Date of Birth"
                          />
                        </h2>
                      </Form.Label>
                      <Form.Control
                        type="date"
                        placeholder=""
                        className="input_fld"
                        name="dob"
                        min={nowmin}
                        max={nowmax}
                        ref={register({
                          required: "Please  select date of birth",
                        })}
                      />
                      {errors.dob && (
                        <p className="text-danger  oom p-0 m-0">
                          {errors.dob.message}
                        </p>
                      )}
                    </Form.Group>
                  ) : section === "language" ? (
                    <div>
                      {" "}
                      <div className="form-group mt-2 mb-2">
                        <Form.Label className="d-flex justify-content-start row_padding_bottom lbl_a">
                          <h2 className="prnl_head_aa">
                            <FormattedMessage
                              id="languageIKnow"
                              defaultMessage="Languages I Know"
                            />
                          </h2>
                        </Form.Label>

                        <div className="d-flex justify-content-between">
                          <div className="form-check detail_btn_cm text-center mr-2">
                            <input
                              className="form-check-input d-none"
                              type="checkbox"
                              name="language_known"
                              id="Hindi"
                              value="Hindi"
                              ref={register({
                                required: "Please  select atleast 1 language",
                              })}
                            />
                            <label
                              className="form-check-label lw py-1 text-capitalize"
                              for="Hindi"
                            >
                              <FormattedMessage id="hindi"/>
                            </label>
                          </div>

                          <div className="form-check detail_btn_cm text-center mr-2">
                            <input
                              className="form-check-input d-none"
                              type="checkbox"
                              name="language_known"
                              id="English"
                              value="English"
                              ref={register({
                                required: "Please  select atleast 1 language",
                              })}
                            />
                            <label
                              className="form-check-label  lw py-1 text-capitalize"
                              for="English"
                            >
                              <FormattedMessage id="english"/>
                            </label>
                          </div>

                          <div className="form-check detail_btn_cm text-center mr-2">
                            <input
                              className="form-check-input d-none fw"
                              type="checkbox"
                              name="language_known"
                              id="Punjabi"
                              value="Punjabi"
                              ref={register({
                                required: "Please  select atleast 1 language",
                              })}
                            />
                            <label
                              className="form-check-label lw py-1 text-capitalize"
                              for="Punjabi"
                            >
                              <FormattedMessage id="punjabi"/>
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex justify-content-between">
                        <div className="form-check detail_btn_cm text-center mr-2">
                          <input
                            className="form-check-input d-none fw"
                            type="checkbox"
                            name="language_known"
                            id="Haryanvi"
                            value="Haryanvi"
                            ref={register({
                              required: "Please  select atleast 1 language",
                            })}
                          />
                          <label
                            className="form-check-label lw py-1 text-capitalize"
                            for="Haryanvi"
                          >
                            <FormattedMessage id="haryanvi"/>
                          </label>
                        </div>

                        <div className="form-check detail_btn_cm text-center mr-2">
                          <input
                            className="form-check-input d-none fw"
                            type="checkbox"
                            name="language_known"
                            id="Rajasthani"
                            value="Rajasthani"
                            ref={register({
                              required: "Please  select atleast 1 language",
                            })}
                          />
                          <label
                            className="form-check-label lw py-1 text-capitalize"
                            for="Rajasthani"
                          >
                            <FormattedMessage id="rajasthani"/>
                          </label>
                        </div>

                        <div className="form-check detail_btn_cm text-center mr-2">
                          <input
                            className="form-check-input d-none fw"
                            type="checkbox"
                            name="language_known"
                            id="Other"
                            value={otherInput}
                            ref={register({
                              required: "Please  select atleast 1 language",
                            })}
                          />
                          <label
                            className="form-check-label lw py-1 text-capitalize"
                            for="Other"
                            onClick={() => setLangInput(!langInput)}
                          >
                            <FormattedMessage id="other"/>
                          </label>
                        </div>
                      </div>
                      {langInput && (
                        <div className="mt-1">
                          <Form.Group controlId="formBasicEmail">
                            <Form.Control
                              type="text"
                              onChange={(e) => setOtherInput(e.target.value)}
                              placeholder="Enter your language"
                              value={otherInput}
                              autocomplete="off"
                              name="other"
                              ref={register({
                                required: "  this field is required ",
                              })}
                            />
                          </Form.Group>
                        </div>
                      )}
                      {errors.language_known && (
                        <p className="text-danger  oom p-0 m-0">
                          {errors.language_known.message}
                        </p>
                      )}
                      {errors.otherss && (
                        <p className="text-danger  oom p-0 m-0">
                          {errors.otherss.message}
                        </p>
                      )}
                    </div>
                  ) : section === "english" ? (
                    <div className="form-group mt-2">
                      <Form.Label className="d-flex justify-content-start row_padding_bottom lbl_a">
                        <h2 className="prnl_head_aa">
                          <FormattedMessage
                            id="speakEnglish"
                            defaultMessage="Speak English"
                          />
                        </h2>
                      </Form.Label>
                      <div className="d-flex justify-content-between">
                        <div className="form-check detail_btn_cm text-center mr-2">
                          <input
                            className="form-check-input d-none"
                            type="radio"
                            name="speak_english"
                            id="No"
                            value="No"
                            ref={register({
                              required: "Please  select atleast 1 field",
                            })}
                          />
                          <label
                            className="form-check-label lw py-1 text-capitalize"
                            for="No"
                          >
                            <FormattedMessage id="no"/>
                          </label>
                        </div>

                        <div className="form-check detail_btn_cm text-center mr-2">
                          <input
                            className="form-check-input d-none"
                            type="radio"
                            name="speak_english"
                            id="Thoda"
                            value="Thoda"
                            ref={register({
                              required: "Please  select atleast 1 field",
                            })}
                          />
                          <label
                            className="form-check-label lw py-1 text-capitalize"
                            for="Thoda"
                          >
                              <FormattedMessage id="thoda"/>
                          </label>
                        </div>

                        <div className="form-check detail_btn_cm text-center mr-2">
                          <input
                            className="form-check-input d-none"
                            type="radio"
                            name="speak_english"
                            id="Good"
                            value="Good"
                            ref={register({
                              required: "Please  select atleast 1 field",
                            })}
                          />
                          <label
                            className="form-check-label lw py-1 text-capitalize"
                            for="Good"
                          >
                            <FormattedMessage id="good"/>
                          </label>
                        </div>
                      </div>
                      {errors.speak_english && (
                        <p className="text-danger  oom p-0 m-0">
                          {errors.speak_english.message}
                        </p>
                      )}
                    </div>
                  ) : section === "currentAddress" ? (
                    <div>
                      <Form.Group controlId="formGroupEmail" className="mt-3">
                        <Form.Label className="lbl_a row_padding_bottom">
                          {" "}
                          <h2 className="prnl_head_aa">
                            <FormattedMessage
                              id="currentDist"
                              defaultMessage="Current District"
                            />
                          </h2>{" "}
                        </Form.Label>
                        <Autocomplete
                          id="combo-box-demo"
                          name="address_current"
                          ref={register}
                          options={DistrictList}
                        //   getOptionLabel={(option) => option}
                          defaultValue={
                            userDetails.address_current?.length > 0
                              ? userDetails.address_current
                              : null
                          }
                          getOptionLabel={(option) => option}
                          style={{ width: "100%" }}
                          className="mt-1 p-0 m-0"
                          renderInput={(params) => (
                            <TextField
                              name="address_current"
                              placeholder={intl.formatMessage({
                                id: "currentDist",
                              })}
                              value={DistrictList}
                              inputRef={register({
                                required: "Please enter current district",
                                maxLength: {
                                  value: 50,
                                  message: "Maximum 50 characters can be entered",
                                },
                              })}
                              {...params}
                              variant="outlined"
                            />
                          )}
                        />
                        {errors.address_current && (
                          <p className="text-danger  oom p-0 m-0">
                            {errors.address_current.message}
                          </p>
                        )}
                      </Form.Group>
                    </div> ) :
                    // JOB-TYPE-MODAL SECTION START 
                    section === appConstant.JOB_TYPE_MODAL[0] ? ( 
                    <div className="form-group mt-2">
                      <Form.Label className="d-flex justify-content-start row_padding_bottom lbl_a">
                        <h2 className="prnl_head_aa">
                          <FormattedMessage
                            id="jobType"
                          />
                        </h2>
                      </Form.Label>
                      <div className="d-flex justify-content-between">
                          <div className="form-check detail_btn_cca text-center mr-2">
                            <input
                              className="form-check-input d-none"
                              type="radio"
                              name="job_type"
                              id="Office"
                              value="0"
                              ref={register({
                                required: intl.formatMessage({
                                  id: "pleaseSelectShift",
                                }),
                              })}
                            />
                            <label
                              className="form-check-label lw text-capitalize"
                              for="Office"
                            >
                             <FormattedMessage
                                id="office"
                                defaultMessage="Office"
                              />
                            </label>
                          </div>

                          <div className="form-check detail_btn_cca text-center mr-2">
                            <input
                              className="form-check-input d-none"
                              type="radio"
                              name="job_type"
                              id="Field"
                              value="1"
                              ref={register({
                                required: intl.formatMessage({
                                  id: "pleaseSelectShift",
                                }),
                              })}
                            />
                            <label
                              className="form-check-label lw text-capitalize"
                              for="Field"
                            >
                              <FormattedMessage
                                id="field"
                                defaultMessage="Field"
                              />
                            </label>
                          </div>

                          <div className="form-check detail_btn_cca text-center mr-2">
                            <input
                              className="form-check-input d-none"
                              type="radio"
                              name="job_type"
                              id="Shift"
                              value="2"
                              ref={register({
                                required: intl.formatMessage({
                                  id: "pleaseSelectShift",
                                }),
                              })}
                            />
                            <label
                              className="form-check-label lw text-capitalize"
                              for="Shift"
                            >
                              <FormattedMessage
                                id="shift"
                                defaultMessage="Shift"
                              />
                            </label>
                          </div>
                          <div className="form-check detail_btn_cca text-center ">
                            <input
                              className="form-check-input d-none"
                              type="radio"
                              name="job_type"
                              id="partTime"
                              value="3"
                              ref={register({
                                required: intl.formatMessage({
                                  id: "pleaseSelectShift",
                                }),
                              })}
                            />
                            <label
                              className="form-check-label lw text-capitalize"
                              for="partTime"
                            >
                              <FormattedMessage
                                id="partTime"
                                defaultMessage="Part Time"
                              />
                            </label>
                          </div>
                        </div>
                        {errors.job_type && (
                          <p className="text-danger  oom p-0 m-0">
                            {errors.job_type.message}
                          </p>
                        )}
                      </div>
                    ):
                    // JOB-SHIFT-MODAL SECTION START FROM HERE
                    section === appConstant.JOB_TYPE_MODAL[1] ? (
                      <div className="form-group mt-2">
                        <Form.Label className="d-flex justify-content-start row_padding_bottom lbl_a">
                          <h2 className="prnl_head_aa">
                            <FormattedMessage
                              id="jobShift"
                            />
                          </h2>
                        </Form.Label>
                        <div className="d-flex">
                          <div className="form-check detail_btn_cca text-center mr-2">
                            <input
                              className="form-check-input d-none"
                              type="radio"
                              name="job_timings"
                              id="timingGeneral"
                              value="0"
                              ref={register({
                                required: intl.formatMessage({
                                  id: "pleaseSelectTiming",
                                }),
                              })}
                            />
                            <label
                              className="form-check-label lw text-capitalize"
                              for="timingGeneral"
                            >
                              <FormattedMessage
                                id="general"
                                defaultMessage="General"
                              />
                            </label>
                          </div>

                          <div className="form-check detail_btn_cca text-center mr-2">
                            <input
                              className="form-check-input d-none"
                              type="radio"
                              name="job_timings"
                              id="timingRotation"
                              value="1"
                              ref={register({
                                required: intl.formatMessage({
                                  id: "pleaseSelectTiming",
                                }),
                              })}
                            />
                            <label
                              className="form-check-label lw text-capitalize"
                              for="timingRotation"
                            >
                              <FormattedMessage
                                id="rotation"
                                defaultMessage="Rotation"
                              />
                            </label>
                          </div>
                        </div>
                        {errors.job_timings && (
                          <p className="text-danger p-0 m-0">
                            {errors.job_timings.message}
                          </p>
                        )}
                      </div>
                      ): (
                    " "
                  )}
                </div>
                <div className="btn_bd py-2 px-2  bb mb-5">
                  <button className="common_btn">
                    {" "}
                    <FormattedMessage id="save" defaultMessage="Save" />
                  </button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};