import { JobActionTypes } from "../actions/JobActions/actionType";
export const initialState = {
  jobList: [],
  interviewList: [],
  isLoading: false,
  jobDetails: {},
  sharedJobDetails: {},
  count: 0,
  userList: []
};

export const jobReducer = (state = initialState, action) => {
  switch (action.type) {
    case JobActionTypes.GET_JOB_PENDING:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case JobActionTypes.GET_JOB_FULFILLED:
      return Object.assign({}, state, {
        isLoading: false,
        jobDetails: action.payload.data,
      });

    case JobActionTypes.GET_JOB_LIST_PENDING:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case JobActionTypes.GET_JOB_LIST_FULFILLED:
      return Object.assign({}, state, {
        isLoading: false,
        jobList: action.payload.data,
        count: action.payload.count
      });

    case JobActionTypes.CREATE_JOB_DATA_PENDING:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case JobActionTypes.CREATE_JOB_DATA_FULFILLED:
      return Object.assign({}, state, {
        isLoading: false,
        jobDetails: action.payload.data,
      });

    case JobActionTypes.UPDATE_JOB_DATA_PENDING:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case JobActionTypes.UPDATE_JOB_DATA_FULFILLED:
      return Object.assign({}, state, {
        isLoading: false,
        userDetails: action.payload.data,
      });

    case JobActionTypes.GET_USER_LIST_PENDING:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case JobActionTypes.GET_USER_LIST_FULFILLED:
      return Object.assign({}, state, {
        isLoading: false,
        userList: action.payload.data,
      });
    case JobActionTypes.GET_USER_LIST_REJECTED:
      return Object.assign({}, state, {
        isLoading: false,
        userList: [],
      });

    case JobActionTypes.SCHEDULE_INTERVIEW_DATA_PENDING:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case JobActionTypes.SCHEDULE_INTERVIEW_DATA_FULFILLED:
      return Object.assign({}, state, {
        isLoading: false,
      });

    case JobActionTypes.GET_INTERVIEW_LIST_PENDING:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case JobActionTypes.GET_INTERVIEW_LIST_FULFILLED:
      return Object.assign({}, state, {
        isLoading: false,
        interviewList: action.payload.data,
      });
    case JobActionTypes.GET_SHARED_JOB_DETAILS_PENDING:
      return Object.assign({}, state, {
        isLoading: true,
        sharedJobDetails: {}
      });
    case JobActionTypes.GET_SHARED_JOB_DETAILS_FULFILLED:
      return Object.assign({}, state, {
        isLoading: false,
        sharedJobDetails: action.payload.data,
      });
    case JobActionTypes.GET_JOB_DETAILS_PENDING:
      return Object.assign({}, state, {
        isLoading: true,
        jobDetails: {}
      });
    case JobActionTypes.GET_JOB_DETAILS_FULFILLED:
      return Object.assign({}, state, {
        isLoading: false,
        jobDetails: action.payload.data,
      });
    default:
      return state;
  }
};
