import React, { useEffect, useState } from "react";
import { FormattedMessage, FormattedDate, FormattedNumber, FormattedPlural, FormattedTime } from "react-intl";
import "./../education/education.scss";
import { useIntl } from "react-intl";
import { Form, Row, Col } from "react-bootstrap";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import { AiOutlineUser } from "react-icons/ai";
import { useForm } from "react-hook-form";
import { serialize } from "object-to-formdata";
import storage from "utils/storage";
import { useHistory } from "react-router-dom";
import * as userActions from "redux/actions/UserActions";
import { useDispatch, useSelector } from "react-redux";
import { UserActionTypes } from "redux/actions/UserActions/actionType";
import classNames from "classnames";

export const Certificationform = ({ technical_cert, index, handelClose, addtechcert }) => {
  // const { userDetails } = useSelector((state) => state.userReducer);
  const dispatch = useDispatch();
  const history = useHistory();
  const { register, handleSubmit, reset, errors } = useForm({});
  
  const [branch, setBranch] = useState(technical_cert && technical_cert[index] && technical_cert[index].branch ? technical_cert[index].branch : "");

  const [other, setOther] = useState(false);
  const [dropdown, setDropdown] = useState(true);

  const [cert, setCert] = useState(technical_cert && technical_cert && technical_cert[index] && technical_cert[index].cert && !(technical_cert[index].cert === "other") ? technical_cert[index].cert : "");

  const [otherCert, setOtherCert] = useState((technical_cert && technical_cert[index] && ["ITI","Diploma","B.tech/B.E"].includes(technical_cert[index].cert)) ? false : (addtechcert ? false : true));

  const iti_branchList = ["Plumber", "Electrician", "Fitter", "Other"];
  const branchList = ["Electrical", "Civil", "Mechanical", "Other"];
  const userID = storage.get("humlog_user");

  useEffect(() => {
    loadProfile();
  }, []);
  const loadProfile = () => {
    if (technical_cert) {
      let data = {
        technical_cert: technical_cert.cert,
      };
      reset(data);
    }
  };
  const setCertValue = (val) => {
    if (val === "other") {
      setOtherCert(true);
      setCert("");
      return;
    }
    setCert(val);
    setBranch("");
    setOtherCert(false);
  }
  const handleDropdown = () => {
    setDropdown(false);
  }
  const setBranchValue = (val) => {
    if (val === "Other") {
      setOther(!other);
      setBranch(val)
      return;
    }
    setBranch(val);
    setOther(false);
  };

  const update = (val) => {
    if (addtechcert) {
      technical_cert.push(
        {
          cert: cert,
          branch: branch
        }
      )
    } else {
      technical_cert[index].cert = cert;
      technical_cert[index].branch = branch;
    }
    const dataval = {
      technical_cert: technical_cert,
    };
    let data = { ...dataval, _id: userID };
    dispatch(userActions.updateUserData(data))
      .then((res) => {
        if (res.value.success) {
          dispatch(userActions.getUserData(userID))
          console.log(res.value.message);
        }
      })
      .catch((err) => console.log(err));
    handelClose();
  };
  const intl = useIntl();
  return (
    <>
      <div className="sup_pp">
        <div className="row no-gutters">
          <div className="col-12  pt-2">
            <h2 className="tttle prnl_head_aa  ">
              <FormattedMessage
                id="technicalCertification"
                defaultMessage="Technical Certification"
              />
            </h2>
            <div className="mt-4 ">
              <Form onSubmit={handleSubmit(update)}>
                <div className="d-flex justify-content-between mt-2">
                  <div className="form-check  detail_btn_ccaa text-center">
                    <input
                      className="form-check-input d-none"
                      type="radio"
                      name="technical_cert"
                      value="ITI"
                      id="ITI"
                      checked={"ITI" === cert}
                      ref={register}
                      readOnly
                      onChange={ (e) => setCertValue(e.target.value)}
                    />
                    <label className={"form-check-label text-capitalize lw checkboxlabel" + (!addtechcert ? " cert" : "")} htmlFor="ITI" onClick={handleDropdown}>
                      ITI
                    </label>
                  </div>

                  <div className="form-check  detail_btn_ccaa text-center">
                    <input
                      className="form-check-input d-none"
                      type="radio"
                      name="technical_cert"
                      id="Diploma"
                      value="Diploma"
                      checked={"Diploma" === cert}
                      ref={register}
                      readOnly
                      onChange={ (e) => setCertValue(e.target.value)}
                    />
                    <label className={"form-check-label text-capitalize lw checkboxlabel" + (!addtechcert ? " cert" : "")} htmlFor="Diploma" onClick={handleDropdown}>
                      Diploma
                    </label>
                  </div>

                  <div className="form-check detail_btn_ccaa text-center">
                    <input
                      className="form-check-input d-none"
                      type="radio"
                      name="technical_cert"
                      id="B.tech/B.E"
                      ref={register}
                      value="B.tech/B.E"
                      checked={cert === "B.tech/B.E" }
                      readOnly
                      onChange={ (e) => setCertValue(e.target.value)}
                    />
                    <label className={"form-check-label text-capitalize lw checkboxlabel" + (!addtechcert ? " cert" : "")} htmlFor="B.tech/B.E" onClick={handleDropdown}>
                      B.tech/B.E
                    </label>
                  </div>

                  <div className="form-check detail_btn_ccaa text-center">
                    <input
                      className="form-check-input d-none"
                      type="radio"
                      id="other"
                      name="technical_cert"
                      value="other"
                      checked={otherCert && !["ITI","Diploma","B.tech/B.E"].includes(cert)}
                      ref={register}
                      readOnly
                      onChange={ (e) => setCertValue(e.target.value)}
                    />
                    <label className={"form-check-label text-capitalize w checkboxlabel" + (!addtechcert ? " cert" : "")} htmlFor="other">
                      <p className="grad"> Other </p>
                    </label>
                  </div>
                </div>

                {otherCert ? (
                  <Form.Group controlId="exampleForm.ControlSelect1">
                    <Form.Label className="d-flex justify-content-start prnl_head_aa mt-3 mb-2">
                      <FormattedMessage
                        id="OtherCertification"
                        defaultMessage="Other Certification"
                      />{" "}
                      <font className="text-danger ml-1"></font>{" "}
                    </Form.Label>
                    <Form.Control
                      type="text"
                      autoComplete="off"
                      placeholder={intl.formatMessage({
                        id: "notAvailable" 
                      })}
                      name="othercertification"
                      value={cert}
                      onChange={(e) => {setCert(e.target.value);
                                        setBranch("")}}
                      className={classNames("form-control")}
                      ref={register({
                        required: "Please enter Certification",
                        maxLength: {
                          value: 25,
                          message: "maximum 25 characters allowed"
                        },
                        pattern: {
                          value: /^[a-zA-z\s]+$/,
                          message: "Enter only alphabets",
                        },
                      })}
                    />
                    <p className="text-danger oom p-0 mt-2">
                      <FormattedMessage
                        id="certificationhint"
                        defaultMessage="*Please add your certification or mention 'Not available' if no certification available"
                      />
                    </p>
                    {errors.othercertification && (
                    <p className="text-danger  oom p-0 m-0">
                      {errors.othercertification.message}
                    </p>
                    )}
                  </Form.Group>
                ) : (
                  <Form.Group controlId="exampleForm.ControlSelect1">
                  <Form.Label className="d-flex justify-content-start prnl_head_aa mt-3 mb-2">
                    <FormattedMessage
                      id="discipline"
                      defaultMessage="Discipline"
                    />{" "}
                    <font className="text-danger ml-1"></font>{" "}
                  </Form.Label>
                  <Form.Control
                    as="select"
                    autoComplete="off"
                    name="discipline"
                    ref={register}
                    value={branch}
                    disabled={dropdown}
                    onChange={(e) => setBranchValue(e.target.value)}
                  >
                    <option value="">Select Branch</option>
                    {cert === "ITI" ? (
                      iti_branchList.map((val) => {
                        return (
                          <>
                            <option value={val}> {val}</option>
                          </>
                        );
                      })
                    ) : (
                      branchList.map((val) => {
                        return (
                          <>
                            <option value={val}> {val}</option>
                          </>
                        );
                      })
                    )}
                  </Form.Control>
                  
                  {errors.discipline && (
                    <p className="text-danger  oom p-0 m-0">
                      {errors.discipline.message}
                    </p>
                  )}
                </Form.Group>
                )}
                {other && (
                  <Form.Group controlId="exampleForm.ControlSelect1">
                    <Form.Label className="d-flex justify-content-start prnl_head_aa mb-2">
                      <FormattedMessage
                        id="OtherDiscipline"
                        defaultMessage="Other Branch"
                      />{" "}
                      <font className="text-danger ml-1"></font>{" "}
                    </Form.Label>
                    <Form.Control
                      type="text"
                      autocomplete="off"
                      placeholder="Add your Branch"
                      name="otherdiscipline"
                      onChange={(e) => setBranch(e.target.value)}
                      className={classNames("form-control")}
                      ref={register({
                        maxLength: {
                          value: 25,
                          message: "maximum 25 characters allowed"
                        },
                        pattern: {
                          value: /^[a-zA-z\s]+$/,
                          message: "Enter only alphabets",
                        },
                      })}
                    />
                    {errors.otherdiscipline && (
                    <p className="text-danger  oom p-0 m-0">
                      {errors.otherdiscipline.message}
                    </p>
                  )}
                  </Form.Group>
                )}

                <div className="py-2 px-2">
                  <button className="common_btn" type="submit">
                    <FormattedMessage id="save" defaultMessage="Save" />
                  </button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};