import React, { useState, useEffect } from "react";
import avatar from "../../../assets/images/male_avatar.png";
import { Image } from "components/shared/imagecomponent/image";
import { GrMore } from "react-icons/gr";
import FilterIcon from "../../../assets/images/job_filter_Icon.svg";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { FaTimes } from "react-icons/fa";
import { Button, Form, Col } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { common, jobDetails, educationalQualificationList, jobSeekerGenderList, experienceList, userAge, userGender, userExperience, ageList, userQualification, speakEnglishList, completedProfileList, errorMessage } from "config/app.constant";
import { DistrictList } from "components/jobseeker/DistrictList";
import "./viewMoreJS.scss";
import { FormattedMessage } from "react-intl";
import * as JobCategoryActions from "redux/actions/jobcategory";
import * as UserActions from "redux/actions/UserActions";
import Multiselect from "multiselect-react-dropdown";
import { useDispatch } from "react-redux";
import { FaAngleDown } from "react-icons/fa";
import { moveToTop } from "utils/CommonService";
import { useIntl } from "react-intl";
import classNames from "classnames";
import { regexValidation } from "utils/regexValidation";

const ViewMoreJobSeeker = (props) => {
    const intl = useIntl();
    const sortedDistrictList = DistrictList.sort();
    const dispatch = useDispatch();
    const { watch, errors, register, handleSubmit } = useForm({ mode: "onBlur" });
    const [searchModal, setSearchModal] = useState(false);
    const [category, setCategory] = useState({});
    const [skills, setSkills] = useState([]);
    const [jobCategoryId, setJobCategoryId] = useState(props && props.jobDetails.category ? props.jobDetails.category : "");
    const [jobId, setJobId] = useState(props && props.jobDetails._id ? props.jobDetails._id : "");
    const [district, setDistrict] = useState([]);
    const [users, setUsers] = useState([]);
    const [page, setPage] = useState(0); // storing page value for pagination
    const [salaryTo, setSalaryTo] = useState()
    const [salaryFrom, setSalaryFrom] = useState()

    // Handle open filter Modal
    const openFilters = () => {
        setSearchModal(true);
    };

    // Handle close filter Modal
    const closeFilters = () => {
        setSearchModal(false);
    };

    // Handle multi selected District Value in Jobs filter Modal
    const onChangeDistrict = (list) => {
        setDistrict(list);
    };

    // Handle multi selected Skills Value in Jobs filter Modal
    const onChangeSkills = (list) => {
        setSkills(list);
    };

    // Handling Load More Job seekers Button
    const loadJobsCards = () => {
        setPage((prev) => prev + 1);
        setTimeout(() => {
            moveToTop() // move to top after loading more data
        }, 500);
    }

    // Function to fetch job category details by category Id
    const getJobCategoryById = async (id) => {
        await dispatch(JobCategoryActions.getJobCategoryData(id))
            .then((res) => {
                if (res.value.success) {
                    setCategory(res.value.data);
                    getAllJobSeeker({ 'job_intrested': res.value.data.name }, page, jobId) // Passing job's category, page, Job_id
                }
            })
            .catch((err) => console.log("err", err));
    };

    // Function to compare salaryFrom and salaryTo [ salaryTo must be greater than salaryFrom ]
    const watchSalary_from = watch("salary_from");
    const isGreater = (salary_to) => {
        if (salary_to) {
            if (salary_to > common.LIMIT_50000) {
                return false
            } else {
                return parseInt(salary_to) > parseInt(watchSalary_from);
            }
        } return true
    }

    // Checking Salary Input field validation
    const handleSalaryTo = (salaryTo, salaryFrom) => {
        if (salaryTo?.length && !salaryFrom?.length) {
            return (
                register({
                    pattern: {
                        value: regexValidation.SALARY_EXPECTATION,
                        message: intl.formatMessage({ id: "enterOnlyNumbers" }),
                    },
                    minLength: {
                        value: 4,
                        message: intl.formatMessage({ id: "minimumFourDigitAllowed" }),
                    },
                    maxLength: {
                        value: 5,
                        message: intl.formatMessage({ id: "maximumFiveDigit" }),
                    },
                    validate: {
                        validation: (value) =>
                            (value >= common.LIMIT_7000 && value <= common.LIMIT_50000) ||
                            intl.formatMessage({ id: "salaryMinimum" }),
                    },
                })
            )
        } else if (salaryTo?.length && salaryFrom?.length) {
            return (
                register({
                    pattern: {
                        value: regexValidation.SALARY_EXPECTATION,
                        message: intl.formatMessage({ id: "enterOnlyNumbers" }),
                    },
                    minLength: {
                        value: 4,
                        message: intl.formatMessage({ id: "minimumFourDigitAllowed" }),
                    },
                    maxLength: {
                        value: 5,
                        message: intl.formatMessage({ id: "maximumFiveDigit" }),
                    },
                    validate: {
                        validation: (value) =>
                            (value >= common.LIMIT_7000 && value <= common.LIMIT_50000) ||
                            intl.formatMessage({ id: "salaryMinimum" }),
                    },
                    validate: isGreater,
                })
            )
        } else if (!salaryFrom?.length && !salaryTo?.length) {
            return (
                register()
            )
        }
    }
    // Handling salary from field
    const handleSalaryFrom = (salaryFrom) => {
        if (salaryFrom) {
            return (
                register(
                    {
                        pattern: {
                            value: regexValidation.SALARY_EXPECTATION,
                            message: intl.formatMessage({ id: "enterOnlyNumbers" }),
                        },
                        validate: {
                            validation: (value) =>
                                (value >= common.LIMIT_7000 && value <= common.LIMIT_50000) ||
                                intl.formatMessage({ id: "minimumSalary" }),
                        },
                    }
                )
            )
        }
        else if (!salaryFrom) {
            return (
                register()
            )
        }
    }

    // Function to submit Filter values to get users list based on applied filters
    const submitFilters = async (value) => {
        value["address_current"] = district; // sending selected districts value
        value["skills"] = skills; // sending selected Skills value
        await dispatch(UserActions.getFilterUser(value, page, jobId))
            .then((res) => {
                if (res.value.success) {
                    setUsers(res.value.data ? res.value.data : "");
                    setDistrict(''); //setting district empty after removing filters
                    setSkills('') //setting skills empty after removing filters
                    setSalaryFrom(''); //setting salary field empty after removing filters
                    setSalaryTo('');//setting salary field empty after removing filters
                }
            })
            .catch((err) => console.log(err.message));
        closeFilters(); // closing filter modal on submitting filter values
    };

    // Function to fetch All job seekers without filter (based on Job's category)
    const getAllJobSeeker = async (value, page, jobId) => {
        await dispatch(UserActions.getFilterUser(value, page, jobId))
            .then((res) => {
                if (res.value.success) {
                    setUsers(res.value.data ? res.value.data : "");
                }
            })
            .catch((err) => console.log(err.message));
    }

    useEffect(async () => {
        await getJobCategoryById(jobCategoryId);   // Function to fetch job category details by category Id
    }, [dispatch, page]);
    return (
        <>
            <div>
                <div className="d-flex">
                    <h2 className="page_title w-100">{common.JOB_SEEKERS_LIST}</h2>
                    <img
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title={common.APPLY_FILTERS}
                        className="filter-icon"
                        src={FilterIcon}
                        onClick={openFilters}
                        alt={common.APPLY_FILTERS}
                    />
                </div>
                {users && users.length > 0 ? (<span className="counts"><FormattedMessage id="showing" /> <b>{users && users.length ? users.length : ''}</b> <FormattedMessage id="results" /></span>) : ''}
                <div>
                    {users && users.length
                        ? users.map((user) => {
                            return (
                                <div className="col-12 user-card-main py-3 my-2 border">
                                    <div className="d-flex word-break">
                                        <div>
                                            <div className="user-card-image">
                                                <Image picture={user.profile_pic ? user.profile_pic : avatar} alt="user_profile"></Image>
                                            </div>
                                        </div>
                                        <div className="ml-3">
                                            <h2 className="user-name mt-1 text-capitalize">
                                                {user.name}
                                            </h2>
                                            <p className="card-fields word-break">
                                                <b>{common.LOCATION}: </b>
                                                {user.address_current ? user.address_current : common.NA}
                                            </p>
                                            <p className="card-fields">
                                                <b>{common.AGE}: </b>
                                                {userAge[user.age] ? userAge[user.age] : common.NA}
                                            </p>
                                            <p className="card-fields">
                                                <b>{common.GENDER}: </b>
                                                {userGender[user.gender]}
                                            </p>
                                            <p className="card-fields">
                                                <b>{common.EXPERIENCE}: </b>
                                                {userExperience[user.experience]}
                                            </p>
                                            <p className="card-fields">
                                                <b>{common.QUALIFICATION}: </b>
                                                {userQualification[user.qualification]}
                                            </p>
                                            {user.salary_from || user.salary_to ? (<p className="card-fields">
                                                <b>{common.SALARY_EXPECTATIONS}: </b>
                                                {user.salary_from ? user.salary_from : '-'} to {user.salary_to ? user.salary_to : '-'}
                                            </p>) : ''}

                                        </div>
                                    </div>
                                    {/* <GrMore />  commented for future reference*/}
                                </div>
                            );
                        })
                        : (<div>
                            <p className="no-record">
                                {errorMessage.NO_RECORD}
                                <h6 className="text-center" >
                                    {errorMessage.TRY_AGAIN_FILTER}
                                </h6>
                            </p>
                        </div>)}
                    {users && users.length >= common.LIMIT_30 ? <div className="load-more" onClick={loadJobsCards}><p>{common.LOAD_MORE_JOB_SEEKERS}<FaAngleDown /></p></div> : ''}
                </div>
            </div>

            {/*---------- filter modal-section start from here ----------*/}
            <Dialog
                fullWidth={true}
                open={searchModal}
                onClose={() => {
                    setSearchModal(false);
                }}
            >
                <DialogTitle className="page_title font-weight-bold"> <h3>{common.FILTER_JOB_SEEKER}</h3></DialogTitle>
                <button
                    className="modal-close-btn"
                    onClick={() => {
                        setSearchModal(false);
                    }}
                >
                    <FaTimes />
                </button>
                <DialogContent>
                    <DialogContentText>
                        <Form onSubmit={handleSubmit(submitFilters)}>
                            <div>
                                {/* ------ district-section start from here ------ */}
                                <Form.Group controlId="address_current">
                                    <Form.Label className="d-flex justify-content-start filter-title">
                                        <FormattedMessage
                                            id="jobSeekerLocation"
                                            defaultMessage={common.JOB_SEEKER_LOCATION}
                                        />
                                    </Form.Label>
                                    <Multiselect
                                        id="address_current"
                                        options={sortedDistrictList}
                                        isObject={false}
                                        selectionLimit={3}
                                        onSelect={onChangeDistrict}
                                        onRemove={onChangeDistrict}
                                        placeholder={common.SELECT_DISTRICT}
                                    />
                                </Form.Group>
                                {/* ------ district-section end ------ */}

                                {/* ------ Salary-section start ------ */}
                                <Form.Group controlId="salary">
                                    <Form.Label className="d-flex justify-content-start filter-title">
                                        <FormattedMessage
                                            id="salaryExpectationRange"
                                            defaultMessage={common.SALARY_EXPECTATION_RANGE}
                                        />
                                    </Form.Label>
                                    <Form.Row>
                                        <Col>
                                            <Form.Control
                                                placeholder={common.EG_7000}
                                                name="salary_from"
                                                autocomplete="off"
                                                className={classNames("form-control", {
                                                    "is-invalid": errors.salary_from,
                                                })}
                                                onChange={(e) => setSalaryFrom(e.target.value)}
                                                ref={handleSalaryFrom(salaryFrom)}
                                            />
                                            {errors.salary_from && (
                                                <p className="text-danger  oom p-0 m-0">
                                                    {errors.salary_from.message}
                                                </p>
                                            )}
                                        </Col>
                                        <FormattedMessage id="to" />
                                        <Col>
                                            <Form.Control
                                                placeholder={common.EG_50000}
                                                name="salary_to"
                                                autocomplete="off"
                                                className={classNames("form-control", {
                                                    "is-invalid": errors.salary_to,
                                                })}
                                                onChange={(e) => setSalaryTo(e.target.value)}
                                                ref={handleSalaryTo(salaryTo, salaryFrom)}
                                            />
                                             {errors.salary_to &&
                                                errors.salary_to.type === "validate" && (
                                                    <p className="text-danger p-0 m-0">
                                                        <FormattedMessage id="salaryMinimum" />
                                                    </p>
                                                )}
                                            {errors.salary_to && (
                                                <p className="text-danger p-0 m-0">
                                                    {errors.salary_to.message}
                                                </p>
                                            )}
                                        </Col>
                                    </Form.Row>
                                </Form.Group>
                                {/* ------ Salary-section end ------ */}

                                {/* ------ Educational Qualification section start ------ */}
                                <Form.Group controlId="qualification">
                                    <Form.Label className="d-flex justify-content-start filter-title">
                                        <FormattedMessage
                                            id="educationalQualification"
                                            defaultMessage={jobDetails.EDU_QUALIFICATION}
                                        />
                                    </Form.Label>
                                    <Form.Control
                                        as="select"
                                        autocomplete="off"
                                        name="qualification"
                                        ref={register()}
                                    >
                                        {educationalQualificationList.map((list) => (
                                            <option value={list.value}>{list.name}</option>
                                        ))}
                                    </Form.Control>
                                </Form.Group>
                                {/* ------ Educational Qualification  section end ------ */}

                                {/* ------ Gender preference section start ------ */}
                                <Form.Group controlId="gender">
                                    <Form.Label className="d-flex justify-content-start filter-title">
                                        <FormattedMessage
                                            id="gender"
                                            defaultMessage={common.GENDER}
                                        />
                                    </Form.Label>
                                    <Form.Control
                                        as="select"
                                        autocomplete="off"
                                        name="gender"
                                        ref={register()}
                                    >
                                        {jobSeekerGenderList.map((item) => (
                                            <option value={item.value}>{item.name}</option>
                                        ))}
                                    </Form.Control>
                                </Form.Group>
                                {/* ------ Gender preference section end ------ */}

                                {/* ------ Work Experience section start ------ */}
                                <Form.Group controlId="experience">
                                    <Form.Label className="d-flex justify-content-start filter-title">
                                        <FormattedMessage
                                            id="experienceRequired"
                                            defaultMessage={common.EXP_REQUIRED}
                                        />
                                    </Form.Label>
                                    <Form.Control
                                        as="select"
                                        autocomplete="off"
                                        name="experience"
                                        ref={register()}
                                    >
                                        {experienceList.map((list) => (
                                            <option value={list.value}>{list.name}</option>
                                        ))}
                                    </Form.Control>
                                </Form.Group>
                                {/* ------ Work Experience section end ------ */}

                                {/* ------ Age preference section start ------ */}
                                <Form.Group controlId="age">
                                    <Form.Label className="d-flex justify-content-start filter-title">
                                        <FormattedMessage id="age" defaultMessage={common.AGE} />
                                    </Form.Label>
                                    <Form.Control
                                        as="select"
                                        autocomplete="off"
                                        name="age"
                                        ref={register()}
                                    >
                                        {ageList.map((list) => (
                                            <option value={list.value}>{list.name}</option>
                                        ))}
                                    </Form.Control>
                                </Form.Group>
                                {/* ------ Age preference section end ------ */}

                                {/* ------ Speak English section start ------ */}
                                <Form.Group controlId="speak_english">
                                    <Form.Label className="d-flex justify-content-start filter-title">
                                        <FormattedMessage
                                            id="speakEnglish"
                                            defaultMessage={common.SPEAK_ENGLISH}
                                        />
                                    </Form.Label>
                                    <Form.Control
                                        as="select"
                                        autocomplete="off"
                                        name="speak_english"
                                        ref={register()}
                                    >
                                        {speakEnglishList.map((list) => (
                                            <option value={list.value}>{list.name}</option>
                                        ))}
                                    </Form.Control>
                                </Form.Group>
                                {/* ------ speak English section end ------ */}

                                {/* ------ Completed Profile section start ------ */}
                                <Form.Group controlId="profile_completed_status">
                                    <Form.Label className="d-flex justify-content-start filter-title">
                                        <FormattedMessage
                                            id="completedProfile"
                                            defaultMessage={common.COMPLETED_PROFILE}
                                        />
                                    </Form.Label>
                                    <Form.Control
                                        as="select"
                                        autocomplete="off"
                                        name="profile_completed_status"
                                        ref={register()}
                                    >
                                        {completedProfileList.map((item) => (
                                            <option value={item.value}>{item.name}</option>
                                        ))}
                                    </Form.Control>
                                </Form.Group>
                                {/* ------ Completed Profile section start ------ */}

                                {/* ------ Job-category section start ------ */}
                                <Form.Group controlId="job_intrested">
                                    <Form.Label className="d-flex justify-content-start filter-title">
                                        <FormattedMessage
                                            id="jobCategory"
                                            defaultMessage={common.JOB_CATEGORY}
                                        />
                                    </Form.Label>
                                    <Form.Control
                                        as="select"
                                        autocomplete="off"
                                        name="job_intrested"
                                        ref={register()}
                                    >
                                        {category ? (
                                            <option selected value={category.name}>
                                                {category.name}
                                            </option>
                                        ) : (
                                            ""
                                        )}
                                    </Form.Control>
                                </Form.Group>
                                {/* ------ Job-category section end ------ */}

                                {/* ------ Skills section start ------ */}
                                <Form.Group controlId="skills">
                                    <Form.Label className="d-flex justify-content-start filter-title">
                                        <FormattedMessage
                                            id="skills"
                                            defaultMessage={common.SKILLS}
                                        />
                                    </Form.Label>
                                    <Multiselect
                                        id="skills"
                                        options={category ? category.skills : ""}
                                        isObject={false}
                                        selectionLimit={5}
                                        onSelect={onChangeSkills}
                                        onRemove={onChangeSkills}
                                        placeholder={common.SELECT_SKILLS}
                                    />
                                </Form.Group>
                                {/* ------ Skills section end ------ */}

                                {/* Apply filter button */}
                                <div className="text-center">
                                <Button className="apply-filter-btn" type="submit" onClick={() => { setPage(0) }}>{common.APPLY_FILTERS}</Button>
                                </div>
                            </div>
                        </Form>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
            {/* ---------- filter modal-section modal end ----------*/}
        </>
    );
};

export default ViewMoreJobSeeker;