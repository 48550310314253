import "./header.scss";
import logom from "../../../assets/images/logo_a.png";
import logoa from "../../../assets/images/fnlogo.jpeg";
import React, { useRef, Component, useState, useEffect } from 'react'
import { Link, useLocation, useHistory } from "react-router-dom";
import { useContext } from 'react'; 
import { Context } from "../../../Languages/Wrapper/Wrapper";
import storage from "utils/storage";
import { HashLink } from 'react-router-hash-link';
import { NavHashLink } from 'react-router-hash-link';
import { FormattedMessage } from 'react-intl'; // use for translating hindi english language

export const HeaderV1 = (props) => {
  const location = useLocation();
  const history = useHistory();

  /* language change function */
  const context = useContext(Context);
  const changeLang = (lang) => {
    storage.set("lang",lang);
    context.selectLanguage(lang)
  }

  /* sidenav open close function */
  const [isActiveClass, setActiveClass] = useState(false);
  const toggleBox = (e) => {
    e.stopPropagation();
    setActiveClass(isActiveClass ? false : true);
  };

  /* function to close sidebar when click outside the sidebar except sidebar and humburger button */
  const mySidenav = document.getElementById('mySidenav');
  const humburger = document.getElementById('humburger');
  document.addEventListener('click', function(e) {
    if (e.target != mySidenav && e.target != humburger) {
      setActiveClass(false);
    }
  });

  /* scroll with offset function */
  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -71; 
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' }); 
    // Create a media condition that targets viewports at 575px wide
    const mediaQuery = window.matchMedia('(max-width: 575px)')
    // Check if the media query is true
    if (mediaQuery.matches) {
      // Then trigger an alert
      const yOffset = -113;
      window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
    }
  }

  return (
    <>
      <header className="header">
        <div className="row">
          <div className="container-fluid">
            <div className="header_inner">
              <div className="row">
                <div className="container-fluid">
                  <div className="nav">
                    <div className="nav_lft">
                      <div className="logo">
                        <a onClick={() => history.push("/homepage")}>
                          <img src={logom} alt="Humlogjobs Logo" className="img-fluid logoicon" />
                          <img src={logoa} alt="Humlogjobs Logo" className="img-fluid logoname" />
                        </a>
                      </div>
                    </div>
                    <div className="nav_middle">
                      <div className="header_menu">
                        <ul>
                          <li>
                            <NavHashLink smooth to='/homepage' scroll={el => scrollWithOffset(el)}><FormattedMessage id="home"/></NavHashLink>
                            <NavHashLink smooth to='/about' scroll={el => scrollWithOffset(el)}><FormattedMessage id="about"/></NavHashLink>
                            <NavHashLink smooth to='/homepage#jobexplorer' scroll={el => scrollWithOffset(el)}><FormattedMessage id="jobs"/></NavHashLink>
                            <NavHashLink smooth to='/homepage#work' scroll={el => scrollWithOffset(el)}><FormattedMessage id="work"/></NavHashLink>
                            <NavHashLink smooth to='/homepage#blogs' scroll={el => scrollWithOffset(el)} className= {(props.activeblog) ? "activeblog" : ""}><FormattedMessage id="blogs"/></NavHashLink>
                            <NavHashLink smooth to='/homepage#faq' scroll={el => scrollWithOffset(el)}><FormattedMessage id="faqs"/></NavHashLink>
                            <NavHashLink smooth to='/homepage#contact' scroll={el => scrollWithOffset(el)}><FormattedMessage id="contactus"/></NavHashLink>
                          </li>
                        </ul>
                      
                      </div>
                    </div>
                    <div className="nav_right">
                      <ul>
                        <li>
                          <a href="" className="signupbtn" onClick={() => history.push("/recruiter")}><FormattedMessage id="postJobFree"/></a>
                        </li>
                        <li className={(props.activeblog || props.hideMenuItems) ? "res_hide hideOnBlog" : "res_hide"}>
                          <strong className= {context.locale == 'hi' ? "logo_cc" : "logo_bb"} onClick={()=>changeLang('hi')}>हिन्दी/</strong>
                          <strong className={context.locale == 'en' ? "logo_cc" : "logo_bb"} onClick={()=>changeLang('en')}>English</strong>
                        </li>
                      </ul>
                      <button className="hamburger hamburger--squeeze" id="humburger" type="button"
                      onClick={toggleBox} className={isActiveClass ? "hamburger hamburger--squeeze is-active" : "hamburger hamburger--squeeze"}>
                        <span className="hamburger-box">
                            <span className="hamburger-inner"></span>
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div className="sidenav" id="mySidenav" className={isActiveClass ? "sidenav is-active" : "sidenav"}>
        <ul>
          <li><NavHashLink smooth to='/homepage' scroll={el => scrollWithOffset(el)}>Home</NavHashLink></li>
          <li><NavHashLink smooth to='/about' scroll={el => scrollWithOffset(el)}>About</NavHashLink></li>
          <li><NavHashLink smooth to='/homepage#jobexplorer' scroll={el => scrollWithOffset(el)}>Jobs</NavHashLink></li>
          <li><NavHashLink smooth to='/homepage#work' scroll={el => scrollWithOffset(el)}>Work</NavHashLink></li>
          <NavHashLink smooth to='/homepage#blogs' scroll={el => scrollWithOffset(el)} className= {(props.activeblog) ? "activeblog" : ""}>Blogs</NavHashLink>
          <li>
          </li>
          <li><NavHashLink smooth to='/homepage#faq' scroll={el => scrollWithOffset(el)}>FAQs</NavHashLink></li>
          <li><NavHashLink smooth to='/homepage#contact' scroll={el => scrollWithOffset(el)}>Contact Us</NavHashLink></li>
          <li className={(props.activeblog || props.hideMenuItems) ? "res_hideshow hideOnBlog" : "res_hideshow"}>
            <strong className={context.locale == 'hi' ? "logo_cc" : "logo_bb"} onClick={() => changeLang('hi')}>हिन्दी/</strong>
            <strong className={context.locale == 'en' ? "logo_cc" : "logo_bb"} onClick={() => changeLang('en')}>English</strong>
          </li>
        </ul>
      </div>
    </>
  );
};
