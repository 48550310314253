import "./blogdetails.scss"
import { Link, useHistory } from "react-router-dom";
import { HeaderV1 } from '../header/headerv1';
import { FooterV1 } from '../footer/footerv1';
import young_peoples from "../../../assets/images/homepage/young_peoples.png";
export const EnsureJobs = (activeblog) => {
const history = useHistory();
  return(
    <>
      <HeaderV1 activeblog = {activeblog}/>
      <section className="blogs_main">
        <div className="container">
          <div className="blogs_main_inner">
            <div className="blogs_heading">
              <div className="blog_heading_img">
                <img src={young_peoples} alt="blog" className="img-fluid d-block" />
              </div>
              <div className="blogs_heading_inner">
                <h3>What India Needs to Correct to Ensure Jobs For Young People</h3>
                <p>India, through the center government, the state governments and the private sector - has spent billions on skills training and development.</p>
                <p>Yet, we still need an integrated blueprint which combines demand based on economic growth and development in a district with education and the skill levels of youth, supported with pillars of institutional enablement.</p>
              </div>
            </div>
            <div className="blogs_content">
              <p>Unless the three pillars stay connected, we will not make any progress toward solving the livelihood conundrum in India.</p>
              <p><span><Link to="/homepage">Humlogjobs.com</Link></span> has first-hand experience of these challenges and agrees with these lessons.</p>
              <ul>
                <li>The skills imparted should be primarily absorbed in local districts so that people do not need to travel far for employment.</li>
                <li>Skill program can only be tailored for the district if the demand for jobs or livelihood is built on the comparative and competitive advantages of the district, its expected growth and composition of GDP, government programs etc.</li>
                <li>The curricula of skill courses must be designed not only by academicians but also include inputs from people who have real experience in running businesses.</li>
              </ul>
              <p><span><a href="https://www.linkedin.com/feed/hashtag/?keywords=humlogjobs&highlightedUpdateUrns=urn%3Ali%3Aactivity%3A6786649598875238400" target="_blank">#humlogjobs #indianeconomy #skillsdevelopment</a></span></p>
            </div>
          </div>
        </div>
      </section>
      <FooterV1 activeblog = {activeblog}/>
    </>
  )
}


