export const regexValidation = {
  EMAIL: /^[A-Za-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/,
  NAME: /^[a-zA-Z\s]*$/,
  PHONE: /^[6-9]\d{9}$/,
  COMPANY_NAME: /^[a-zA-Z0-9\s]([a-zA-Z0-9]|[- '&'._%^$*+-?()[{}\\\]—@#"|/!`~<>])*$/,
  CLIENT_NAME: /^[a-zA-Z0-9\s]([a-zA-Z0-9]|[ '.])*$/,
  COMPANY_ADDRESS: /^[a-zA-Z0-9\s]([a-zA-Z0-9]|[- ,.])*$/,
  PERSON_NAME: /^[a-zA-Z\s._]*$/,
  FULL_NAME: /^[a-zA-Z]{3,}(?: [a-zA-Z]+){0,2}$/,
  PAN: /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/,
  GST: /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
  CRN: /^\d{6}$/,
  CIN: /^[UL]\d{5}[A-Z]{2}\d{4}[A-Z]{3}\d{6}$/,
  AADHAR: /^[1-9]\d{11}$/,
  ESTABLISHMENT_NUMBER: /^[A-Z0-9]{1,20}$/,
  FSSAI: /^[A-Z0-9]{1,14}$/,
  EMAILFILTERS: /\bgmail\b|\yahoo\b|\hotmail\b|\outlook\b|\zoho\b|\icloud\b|\aol\b|\aim\b/gi,
  OTP: /^[0-9]{4}$/,
  RATING: /^([1-9]|1[0])$/, // from 1-10 only
  SALARY_EXPECTATION: /^[0-9\b]+$/
};
