import React, { useState, useEffect } from "react";
import { FormattedMessage, FormattedDate, FormattedNumber, FormattedPlural, FormattedTime } from "react-intl";
import { useIntl } from "react-intl";
import "./../jobformdetails/jobdetail.scss";
import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import storage from "utils/storage";
import { useHistory } from "react-router-dom";
import * as userActions from "redux/actions/UserActions";
import * as JobActions from "redux/actions/JobActions";
import { uploadFile } from "../../../utils/fileService";
import Alert from "@material-ui/lab/Alert";
import { regexValidation } from '../../../utils/regexValidation';
import * as CommonService from '../../../utils/CommonService';

export const CompanyVerification = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [document, setDocument] = useState({});
  const [loaded, setLoaded] = useState(false);
  const [isSuccess, setIssuccess] = useState(false);
  const [isEmailSuccess, setIsEmailSuccess] = useState(false);
  const [isError, setisError] = useState({open: false, message: ''});
  const { userDetails } = useSelector((state) => state.userReducer);
  const intl = useIntl();
  const [verificationForm, setVerificationForm] = useState(false);
  const [clientName, setClientName] = useState();
  const [organizationType, setOrganizationType] = useState("");
  const [companyName, setCompnayName] = useState("");
  const [isDropdownSelect, setIsDropdownSelect] = useState(0);
  const [jobDetails, setJobDetails] = useState([]);
  const [isDeclined, setIsDeclined] = useState(false);



  useEffect(() => {
    if (userDetails && userDetails.verification_status == "Declined") {
      setIsDeclined(true);
    }
    fetchOrganizationType(); // fetch organization type from props if available
    fetchCompanyName(); // fetch company name from props if available
    fillOrganizationType(); // fill organization type from user details.
    
    if (!loaded) {
      setLoaded(true);
    }
  }, [loaded, userDetails, dispatch]);

  const fillOrganizationType = () => {
    let fillOrganizationType = userDetails.organization_type;
    setOrganizationType(fillOrganizationType);
    // CODE COMMENT FOR FURURE REFRENCE
    // if (!organizationType == "") {
    //   setVerificationForm(true);
    // }
  }

  const fetchOrganizationType = () => {
    if (props && props.organization_type) {
      setTimeout(() => {
        setOrganizationType(props.organization_type);
      }, 500);
      setVerificationForm(true);
    }
  }

  const fetchCompanyName = () => {
    if (props && props.company_name) {
      setTimeout(() => {
        setCompnayName(props.company_name);
      }, 500);
      setVerificationForm(true);
    } else {
      getJobDetails();
    }
  }

  /**
   * @description get the job details from API
   */
  const getJobDetails = async () => {
    try {
      let response = await dispatch(JobActions.getJobListDataById(storage.get("humlog_user")));
      if (response && response.value.success) {
        let setJobDetails = response.value.data;
        setCompnayName(setJobDetails[0].company_name);
      }
    } catch (error) {
      console.log("failed to get Job Details", error)
    }
  }

  const companyDocumentList = [
    { id: "email", name: "Company Email Id" },
    { id: "gst", name: "Company GST Certificate" },
    { id: "pan", name: "Company Pan Card" },
    { id: "crn", name: "Company Registration Document" },
    { id: "aadhar_card", name: "Company Udyog Aadhaar Card" },
    { id: "shop_est_act", name: "Shop and Establishment Act" },
    { id: "food_license", name: "Food License ( Restaurant/Cafe)" }
  ];

  const clientDocumentList = [
    { id: "email", name: "Consultancy Email Id" },
    { id: "gst", name: "Consultancy GST Certificate" },
    { id: "pan", name: "Consultancy Pan Card" },
    { id: "crn", name: "Consultancy Registration Document" },
    { id: "individualPan", name: "Individual PAN Card ( if working as Free Lancer)" },
  ];

  const [category, setcategory] = useState("");
  const { watch, register, handleSubmit, reset, setError, errors } = useForm({
    mode: "onBlur",
  });
  
  const documentUpload = (file) => {
      setDocument(file);
      setDocument((state) => {
          return state;
      })
  }
  const getData = (doc_no, fileId) => {
    return {
      doc_no: doc_no,
      files : [fileId],
    }
  }
  /**
   * Description : validate document number on the basis of category choosen
   * @param {String} value document number
   * @param {String} category type of document
   * @returns Boolean value
   */
  // CODE COMMENT FOR FURURE REFRENCE
  const documentValidation = (value, category) => {
    let isInvalid =false;
    switch(category) {
      case "gst" :
        if(!regexValidation.GST.test(value)) {
          setError("doc_number", {
            type: "manual",
            message: intl.formatMessage({id: "enterGSTNumber"})
          })
          isInvalid = true;
        }
        break;
      case "pan" :
        if(!regexValidation.PAN.test(value)) {
          setError("doc_number", {
            type: "manual",
            message: intl.formatMessage({id: "enterPANNumber"})
          })
          isInvalid = true;
        }
        break;
      case "crn" :
        if(!regexValidation.CRN.test(value)) {
          setError("doc_number", {
            type: "manual",
            message: intl.formatMessage({id: "enterCRNNumber"})
          })
          isInvalid = true;
        }
        break;
      case "cin" :
        if(!regexValidation.CIN.test(value)) {
          setError("doc_number", {
            type: "manual",
            message: intl.formatMessage({id: "enterCINNumber"})
          })
          isInvalid = true;
        }
        break;
      case "aadhar_card" :
        if(!regexValidation.AADHAR.test(value)) {
          setError("doc_number", {
            type: "manual",
            message: intl.formatMessage({id: "enterAadharNumber"})
          })
          isInvalid = true;
        }
        break;
      case "shop_est_act" :
        if(!regexValidation.ESTABLISHMENT_NUMBER.test(value)) {
          setError("doc_number", {
            type: "manual",
            message: intl.formatMessage({id: "enterEstablishmentNumber"})
          })
          isInvalid = true;
        }
        break;
      case "food_license" :
        if(!regexValidation.FSSAI.test(value)) {
          setError("doc_number", {
            type: "manual",
            message: intl.formatMessage({id: "enterFoodLicenseNumber"})
          })
          isInvalid = true;
        }
        break;
      default:
        isInvalid = false
    }
    return isInvalid;
  }
  /**
   * method to Update document for company verification
   * @param {Object} val document form values
   */
  const update = async (val) => {
    try{
      if (val.category == 'email') {
        verifyEmail(val.email, val.company_name);
        return;
      }
      // CODE COMMENT FOR FURURE REFRENCE
      // if doc_number available than send 
      // if not than delete from data
      // if(val.hasOwnProperty('doc_number') && !val.doc_number.length) {
      //   delete val.doc_number
      // } else {
      //   if(documentValidation(val.doc_number, val.category)) { // check validation here
      //     return;
      //   }
      // }
      if(!CommonService.validateFileContentTypeAndSize(document.type, 'documents')){
        setisError({open: true, message : intl.formatMessage({id: "pleaseUploadCorrect"})});
        setTimeout( () => {setisError(false)}, 5000);
        return;
      }
      if(!CommonService.validateFileSize(document.size, 5)){
        setisError({open: true, message : intl.formatMessage({id: "fileSizeShould"})});
        setTimeout( () => {setisError(false)}, 5000);
        return;
      }
      // upload document
      const fileId = await uploadFile(document,"documents","private");
      let documents;
      switch (val.category) {
        case "gst" :
          documents =  {
            gst : getData(val.doc_number, fileId)
          }
        break;
        case "cin" :
          documents =  {
            cin : getData(val.doc_number, fileId)
          }
        break;
        case "crn" :
          documents =  {
            crn : getData(val.doc_number, fileId)
          }
        break;
        case "pan" :
          documents =  {
            pan : getData(val.doc_number, fileId)
          }
        break;
        case "individualPan" :
          documents =  {
            pan : getData(val.doc_number, fileId)
          }
        break;
        case "aadhar_card" :
          documents =  {
            aadhar_card : getData(val.doc_number, fileId)
          }
        break;
        case "shop_est_act" :
          documents =  {
            shop_est_act : getData(val.doc_number, fileId)
          }
        break;
        case "food_license" :
          documents =  {
            food_license : getData(val.doc_number, fileId)
          }
        break;
        default  :
          documents =  {}
      }
      let data = {
        _id : userDetails._id,
        documents : documents
      }
      let res = await dispatch(userActions.updateUserData(data));
      if (res.value.success) {
        setIssuccess(true);
        console.log(res.value.message);
        setTimeout( () => {history.push("/dashboard");}, 5000);
      }
      let userdata = {
        _id : userDetails._id,
        organization_type : val.organization_type,
      }
      if (val.company_name?.length ) {
        userdata.company_name = val.company_name
      }
      let userres = dispatch(userActions.updateUserData(userdata));
      reset();
    } catch (e) {
      setisError({open: true, message : intl.formatMessage({id: "anErrorOccurred"})})
      setTimeout( () => {setisError(false)}, 5000);
      console.log("failed to update document", e);
    }
  };

  /**
   * @description method to choose organization type 
   * consultancy or company
   */
  const chooseOrganizationType = (val) => {
    setOrganizationType(val);
    setIsDropdownSelect(0); // set drowdown value to initial level
    setcategory(""); // set category to its intial level
    setVerificationForm(true);
  }

  /**
   * @description method to check verification type done by email or other documents
   * @param {string} val 
   */
  const handleChange = (val) => {
    if (val == "email") {
      setIsDropdownSelect(1); // 1 value represent if drodown value is email
    } else {
      setIsDropdownSelect(2); // 2 value represent if drodown value is otherthan email
    }
  }

  /**
   * @description method to check the validation for email in which 
   * gmail, yahoo, hotmail is not included
   * @param {string} val 
   * @returns true or false
   */
  const emailValidtion = (val) => {
    return !(val.match(regexValidation.EMAILFILTERS));
  }

  /**
   * @description method to verify by email only
   */
  const verifyEmail = async (email, companyname) => {
    try {
      let document =  {
        email_id : {
          doc_no: email,
        }
      }
      let data = {
        _id : userDetails._id,
        organization_type: organizationType,
        organization_email : email,
        documents : document
      }
      if (companyname) {
        data.company_name = companyname
      }
      let res = await dispatch(userActions.updateUserData(data));
      if (res.value.success) {
        setIsEmailSuccess(true);
        console.log(res.value.message);
        setTimeout( () => {history.push("/dashboard");}, 5000);
      }
      reset();
    } catch (error) {
      setisError({open: true, message : intl.formatMessage({id: "emailverifyfailed"})})
      setTimeout( () => {setisError(false)}, 5000);
      console.log("failed to verify by email", error);
    }
  }

  return (
    <>
    {isSuccess && <Alert severity="success" className="alert-toast"><FormattedMessage id="yourHaveSuccessfully"/></Alert>}
    {isEmailSuccess && <Alert severity="success" className="alert-toast"><FormattedMessage id="emailSentVerification"/></Alert>}
    {isError.open && <Alert severity="error" className="alert-toast">{isError.message}</Alert>}
      <div className="px-3 st p_bottom">
        <div className="row no-gutters">
          <div className="col-12">
            <h5 className="page_title">
              <FormattedMessage id="companyVerification" defaultMessage="Company Verification" />
            </h5>
            <div className="verification_type_box">
              <div className="verification_details_box">
                <div className="" style={{ width: "100%" }}>
                  <Form onSubmit={handleSubmit(update)}>
                    <p className="hiringfor">
                      <FormattedMessage id="hiringfor" defaultMessage="I am hiring staff for ?" />
                      <font className="text-danger ml-1">*</font>
                    </p>
                    <div className="hiring_type_box">
                      <div className="form-group margin-right">
                        <input 
                          type="radio" 
                          autoComplete="off" 
                          name="organization_type" 
                          id="mycompany" 
                          value="company" 
                          key="company" 
                          ref={register}
                          onChange={(e) => {
                            chooseOrganizationType(e.target.value)
                          }}
                          checked={organizationType == "company"}
                        />
                        <label htmlFor="mycompany">
                          <FormattedMessage id="mycompany" defaultMessage="My Company" />
                        </label>
                      </div>
                      <div className="form-group">
                        <input 
                          type="radio" 
                          autoComplete="off" 
                          name="organization_type" 
                          id="consultancy" 
                          value="consultancy" 
                          key="consultancy"
                          ref={register}
                          onChange={(e) => {
                            chooseOrganizationType(e.target.value)
                          }}
                          checked={organizationType == "consultancy"}
                        />
                        <label htmlFor="consultancy">
                          <FormattedMessage id="myclient" defaultMessage="My Client" />
                        </label>
                      </div>
                    </div>

                    {/* if found organization type is company than company input box is display */}
                    {organizationType == "company" ? (
                      <>
                        {/* organization name field start */}
                        <Form.Group controlId="organization_name">
                          <Form.Label className="">
                            <FormattedMessage id="companyname" defaultMessage="Company Name" />
                            <font className="text-danger ml-1">*</font>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            autoComplete="off"
                            placeholder={intl.formatMessage({ id: "addCompanyName" })}
                            name="company_name"
                            defaultValue={companyName}
                            ref={register({
                              required: intl.formatMessage({ id: "namerequired" }),
                              pattern: {
                                value: regexValidation.CLIENT_NAME,
                                message: intl.formatMessage({ id: "companyNameSpecialCharacter" }),
                              },
                              minLength: {
                                value: 5,
                                message: intl.formatMessage({ id: "minimumDigitFive" }),
                              },
                              maxLength: {
                                value: 100,
                                message: intl.formatMessage({ id: "maximumHundred" }),
                              },
                            })}
                            readOnly={companyName ? true : false}
                            >
                          </Form.Control>
                          {errors.company_name && (
                            <p className="text-danger oom p-0 m-0">
                              {errors.company_name.message}
                            </p>
                          )}
                        </Form.Group>
                        {/* organization name field end */}
                      </>
                    ) : ("")}

                    {/* if found organization type is consultancy than consultancy input box is display */}
                    {organizationType == "consultancy" ? (
                      <>
                      {/* organization name field start */}
                        <Form.Group controlId="organization_name">
                          <Form.Label className="">
                            <FormattedMessage id="consultancyname" defaultMessage="Consultancy Name" />
                            <font className="text-danger ml-1">*</font>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            autoComplete="off"
                            placeholder={intl.formatMessage({ id: "addConsultancyName" })}
                            name="company_name"
                            ref={register({
                              required: intl.formatMessage({ id: "namerequired" }),
                              pattern: {
                                value: regexValidation.CLIENT_NAME,
                                message: intl.formatMessage({ id: "companyNameSpecialCharacter" }),
                              },
                              minLength: {
                                value: 5,
                                message: intl.formatMessage({ id: "minimumDigitFive" }),
                              },
                              maxLength: {
                                value: 100,
                                message: intl.formatMessage({ id: "maximumHundred" }),
                              },
                            })}
                          >
                          </Form.Control>
                          {errors.company_name && (
                            <p className="text-danger oom p-0 m-0">
                              {errors.company_name.message}
                            </p>
                          )}
                        </Form.Group>
                        {/* organization name field end */}
                      </>
                    ) : ("")}
                    

                    {verificationForm  || isDeclined ? (
                      <>
                        {/* verification type field start */}
                        <Form.Group controlId="verification_selectbox">
                          <Form.Label className="">
                            <FormattedMessage id="verificationtype" defaultMessage="Verification Type" />
                            <font className="text-danger ml-1">*</font>
                          </Form.Label>
                          <Form.Control
                            as="select"
                            autoComplete="off"
                            name="category"
                            value={category}
                            onChange={(e) => {
                              setcategory(e.target.value)
                              handleChange(e.target.value)
                            }}
                            ref={register({
                              required: intl.formatMessage({ id: "selectone" }),
                            })}
                          >
                            <option value="" disabled="disabled">Select Options</option>
                            {
                              organizationType == "consultancy" ?
                                (
                                  clientDocumentList.map((val) => {
                                    return (
                                      <>
                                        <option value={val.id}> {val.name}</option>
                                      </>
                                    );
                                  })
                                )
                                :
                                (
                                  companyDocumentList.map((val) => {
                                    return (
                                      <>
                                        <option value={val.id}> {val.name}</option>
                                      </>
                                    );
                                  })
                                )
                            }
                          </Form.Control>
                          {errors.category && (
                            <p className="text-danger oom p-0 m-0">
                              {errors.category.message}
                            </p>
                          )}
                        </Form.Group>
                        {/* verification type field end */}

                        {/* if email is selected than email field is displayed. */}
                        {
                          isDropdownSelect && isDropdownSelect == 1 ?
                            (
                              <Form.Group controlId="email">
                                <Form.Label className="d-flex justify-content-start llbl">
                                  <FormattedMessage id="email" defaultMessage="Email" />
                                  <font className="text-danger ml-1">*</font>
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  autoComplete="off"
                                  defaultValue={userDetails.email}
                                  placeholder={intl.formatMessage({ id: "enterEmail" })}
                                  name="email"
                                  onInput={(e) => { e.target.value = ("" + e.target.value) }}
                                  ref={register({
                                    required: intl.formatMessage({ id: "emailValid" }),
                                    validate: {
                                      validation: (value) =>
                                        emailValidtion(value) ||
                                        intl.formatMessage({ id: "consultancyemail" })
                                    },
                                  })}
                                >
                                </Form.Control>
                                {errors.email && (
                                  <p className="text-danger oom p-0 m-1">
                                    {errors.email.message}
                                  </p>
                                )}
                              </Form.Group>
                            ) : ("")
                        }
                        {/* if email is not selected than document upload fields are displayed. */}
                        {
                          isDropdownSelect && isDropdownSelect == 2 ? (
                            <>
                            {/* Commenting Document Number field */}

                               {/* <Form.Group controlId="documentNumber">
                                <Form.Label className="d-flex justify-content-start llbl">
                                  <FormattedMessage id="documentNumber" defaultMessage="Document Number" />
                                  <font className="text-danger ml-1">*</font>
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  autoComplete="off"
                                  placeholder={intl.formatMessage({ id: "enterDocumentNumber" })}
                                  name="doc_number"
                                  onInput={(e) => { e.target.value = ("" + e.target.value).toUpperCase() }}
                                  ref={register}
                                >
                                </Form.Control>
                                {errors.doc_number && (
                                  <p className="text-danger oom p-0 m-0">
                                    {errors.doc_number.message}
                                  </p>
                                )}
                              </Form.Group>  */}

                              <Form.Group controlId="upload_document">
                                <Form.Label className="d-flex justify-content-start llbl">
                                  <FormattedMessage id="uploadDocument" defaultMessage="Upload Your Document" />
                                  <font className="text-danger ml-1">*</font>
                                </Form.Label>
                                <input
                                  name="documents"
                                  type="file"
                                  className=""
                                  id="upload-document"
                                  accept="image/*,.pdf"
                                  onChange={(e) => documentUpload(e.target.files[0])}
                                  style={{ width: "100%" }}
                                  ref={register({
                                    required: intl.formatMessage({ id: "pleaseUploadDocument" }),
                                  })}
                                />
                                {errors.documents && (
                                  <p className="text-danger oom p-0 m-0">
                                    {errors.documents.message}
                                  </p>
                                )}
                              </Form.Group>
                            </>
                          ) : ("")
                        }
                        {userDetails && userDetails.verification_status === "Approved" ? (
                          <button className="common_btn common_btn2 mt-4" type="submit" disabled>
                            <FormattedMessage id="pleaseUploadDocument" />
                          </button>
                        ) : (
                          <button className="common_btn common_btn2 mt-4" type="submit">
                            <FormattedMessage id="submit" defaultMessage="Submit" />
                          </button>
                        )}
                      </>
                    ) : ("")}
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
