import React from "react";
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from "react-redux";
import { IoIosCloseCircle } from "react-icons/io";
import { BsCheck } from "react-icons/bs";
import storage from "utils/storage";
import * as JobActions from "redux/actions/JobActions";
export const InterviewDetailsModal = (props) => {
  const val = props.modalData;
  const dispatch = useDispatch();
  let userId = storage.get("humlog_user");
  // API to update interview details
  const handleAppliedButton = async (id, status) => {
    try {
      let body = {
        status: status
      }
      let query = {
        _id: id
      }
      let res = await dispatch(JobActions.updateInterviewDetails(body, query));
      if (res.value.success) {
        console.log(res.value.message);
        dispatch(JobActions.getInterviewByUserId(userId));
        props.handelCloseModal(); // calling close modal function
      }
    } catch (error) {
      console.log(error);
    }
  }
  return (
    <>
      <div className="py-2 px-2" >
        <div className="Interview_heading"><h5><FormattedMessage id="interviewDetails" defaultMessage="Interview Details"></FormattedMessage></h5></div>
        <div className="p-0 m-0">
          <div className="row row_padding_bottom">
            <div className="col-6"><h6 className="font-weight-bold ml-1 "><FormattedMessage id="title" defaultMessage="Title" /> - </h6></div>
            <div className="col-6"><h6 className="font-weight-bold warp_text ">{val.title} </h6></div>
          </div>
          <div className="row row_padding_bottom">
            <div className="col-6"><h6 className="font-weight-bold ml-1"><FormattedMessage id="contactPersonName" defaultMessage="Contact Person Name" /> - </h6></div>
            <div className="col-6"><h6 className=" font-weight-bold warp_text ">{val.contact_person}</h6></div>
          </div>
          <div className="row row_padding_bottom">
            <div className="col-6"><h6 className="font-weight-bold ml-1"><FormattedMessage id="status" defaultMessage="Status" /> - </h6></div>
            <div className="col-6"><h6 className=" font-weight-bold warp_text ">{val.status}</h6></div>
          </div>
          <div className="row row_padding_bottom">
            <div className="col-6"><h6 className="font-weight-bold ml-1 "><FormattedMessage id="startTime" defaultMessage="Start-Time" /> - </h6></div>
            <div className="col-6"><h6 className=" font-weight-bold warp_text ">{moment(val.start_time, ["HH.mm"]).format("hh:mm a")}</h6></div>
          </div>
          <div className="row row_padding_bottom">
            <div className="col-6"><h6 className="font-weight-bold ml-1"><FormattedMessage id="endTime" defaultMessage="End Time" /> - </h6></div>
            <div className="col-6"><h6 className=" font-weight-bold warp_text ">{moment(val.end_time, ["HH.mm"]).format("hh:mm a")} </h6></div>
          </div>
          <div className="row row_padding_bottom">
            <div className="col-6"><h6 className="font-weight-bold ml-1"><FormattedMessage id="date" defaultMessage="Date" /> - </h6></div>
            <div className="col-6"><h6 className=" font-weight-bold warp_text "> {moment(val.date).format('DD-MM-YYYY')} </h6></div>
          </div>
          <div className="row row_padding_bottom">
            <div className="col-6"><h6 className="font-weight-bold ml-1"><FormattedMessage id="locationText" defaultMessage="Location" /> - </h6></div>
            <div className="col-6"><h6 className=" font-weight-bold warp_text "> {val.location} </h6></div>
          </div>
          <div className="row row_padding_bottom">
            <div className="col-6"><h6 className="font-weight-bold ml-1"><FormattedMessage id="address" defaultMessage="District" /> - </h6></div>
            <div className="col-6"><h6 className=" font-weight-bold warp_text "> {val.district ? val.district : ''} </h6></div>
          </div>
          <div className="row row_padding_bottom">
            <div className="col-6"><h6 className="font-weight-bold ml-1"><FormattedMessage id="description" defaultMessage="Description" /> - </h6></div>
            <div className="col-6"><h6 className=" font-weight-bold warp_text "> {val.description ? val.description : ''} </h6></div>
          </div>
          {/* Accept-notaccept section start from here */}
          <div class="row">
            <div className="col-12">
              <div className="mt-3 mb-3 d-flex justify-content-around">
                <button
                  className={val.status === "pending" ? "btn_vc shadow" : "disabled-button shadow"}
                  onClick={() => handleAppliedButton(val._id, "Accept")}
                  disabled={val.status === "pending" ? false : true}
                >
                  <BsCheck className={val.status === "pending" ? "box_ib job_pb" : "box_ib disabled-icon"} />
                  <div className="jobResponse ml-2 mr-2">
                    <FormattedMessage
                      id="accept"
                      defaultMessage="Accept"
                    />
                  </div>
                </button>
                <button
                  className={val.status === "pending" ? "btn_va shadow" : "disabled-button shadow"}
                  onClick={() => handleAppliedButton(val._id, "Not Accept")}
                  disabled={val.status === "pending" ? false : true}
                >
                  <IoIosCloseCircle className="cross_icon" />
                  <div className="jobResponse">
                    <FormattedMessage id="notAccept" defaultMessage="Not Accept" />
                  </div>
                </button>
              </div>
            </div>
          </div>
          {/* Accept-notaccept end */}
        </div>
      </div>
    </>
  )
}