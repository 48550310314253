import React, { useEffect, useState } from "react";
import "./community.scss";
import ShareIcon from "components/shared/share-icons/index";
import * as userActions from "redux/actions/UserActions";
import { useDispatch } from "react-redux";
import { FormattedMessage } from 'react-intl';

export const Communitypage = () => {
  // const translate = useIntl();
  // const YOUTUME_PLAYLIST_ITEMS_API = "https://www.googleapis.com/youtube/v3/playlistItems";
  const [getList, setGetList] = useState([]);
  const dateOptions = { year: 'numeric', month: 'long', day: 'numeric' };
  const dispatch = useDispatch();

  useEffect(() => {
    // getServerSideProps();
    fetchData();
  }, []);
  // commented code for future use
  // const getServerSideProps = async () => {
  //   const res = await fetch(`${YOUTUME_PLAYLIST_ITEMS_API}?part=snippet&playlistId=PLWKt-I_DOMk5tA0FJqyzaTWtyKjlFxBDT&maxResults=50&key=AIzaSyAosmRQfALyjadqW9ggzwL5KGuyYbCvZdc`);
  //   const data = await res.json();
  //   setGetList(data);
  //   // console.log('data', data);
  //   return {
  //     props: {
  //       data
  //     }
  //   }
  // }

  const fetchData = () => {
    let query = {};
    // commented code for future use
    // if (page >= 0) {
    //   query.page = page;
    // }
    // if (searched && searched.length) {
    //   query.search = searched;
    // }
    // if (sort) {
    //   query.sort = sort;
    // }
    dispatch(userActions.getCommunityVideoList(query))
      .then((response) => {
        if (response && response.value.success) {
          setGetList(response.value.data);
          // commented code for future use
          // console.log("response", getList);
          // setVideoCount(response.value.data.length);
          // setTableCount(response.value.data.length);
          // setTotalCount(response.value.data.count); // get and set Total count
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  }
  // commented code for future use
  // const [modal, setModal] = useState({ open: false, type: "" });
  // const onToggleModal = (type) => {
  //   setModal({ open: !modal.open, type: type });
  // };
  const playVideo = (type) => {
    const VID_REGEX =
      /(?:youtube(?:-nocookie)?\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    let url = type.match(VID_REGEX)[1];
    return  (`https://www.youtube-nocookie.com/embed/${url}?autoplay=0&modestbranding=1&cc_load_policy=1&iv_load_policy=3&mode=opaque&rel=0&autohide=1&showinfo=0&wmode=transparent`)
  };
  // commented code for future use
  // const shareVideoURL = (type) => {
  //   const VID_REGEX =
  //     /(?:youtube(?:-nocookie)?\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
  //   let url = type.match(VID_REGEX)[1];
  //   return  (`https://youtu.be/${url}`)
  //   // setVideoModal({ open: true, type: url });
  // };
  
  return (
    <>

      <div className="community_main">
        <div className="row">
          <div className="col-sm-12">
            <div className="community_inner">
            <h5 className="page_title pt-3">
              <FormattedMessage id="community" defaultMessage="Community" />
            </h5>
              <div className="playlist_box_main">
                {/* <h2>HumlogJobs Community</h2> */}
                <div className="playlist_box">
                  <ul>
                    {getList && getList.map((item) => {
                      console.log('item', item);
                      const { id, title, description, video_url, created_at, community_id = {}, } = item;
                      return (
                        <li key={id} className="videoBox">
                          <div className="videoTopBox">
                            <h3>{title}</h3>
                          </div>
                          <div className="videoMiddleBox">
                            <iframe src={playVideo(video_url)}
                              width="100%"
                              height="315"
                              title="YouTube video player"
                              frameBorder="0"
                              allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                              allowFullScreen>
                            </iframe>
                            {/* commented code for future use */}
                            {/* <a className="video_play_box" onClick={() => {
                              onToggleModal(`https://www.youtube.com/embed/${resourceId.videoId}`);
                            }}>
                              <img className="video_thumb" width={medium.width} height={medium.height} src={medium.url} alt="" />
                              <span className="play_icon"><FaPlay /></span>
                            </a> */}
                          </div>
                          <div className="videoBottomBox">
                            <p className="videoDescription">{description}</p>
                            <div className="videoDetailBox">
                              <span className="videoDate">Upload At: <i>{new Date(created_at).toLocaleDateString('en-GB', dateOptions)}</i></span>
                              <span className="sharebox">
                                Share:
                                {/* commented code for future use */}
                                {/* <ShareIcon className="modal_share_icon" url={shareVideoURL(ite)} text="Apply for Jobs & Get Interview Calls. All the best." /> */}
                                <ShareIcon className="modal_share_icon" url={`${window.location.origin}/shared/community?id=${community_id}`} text="Humlogjobs Community Video" />
                              </span>
                            </div>
                          </div>
                        </li>
                      )
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Dialog
        open={modal.open}
        onClose={onToggleModal}
        maxWidth="sm"
        fullWidth={true}
      >
        <button className="close_btn" onClick={onToggleModal}><FaTimes /></button>
        <DialogContent style={{ padding: "0px" }}>
          <iframe
            width="100%"
            height="315"
            src={modal.type+'?autoplay=1&modestbranding=1&showinfo=0&rel=0&cc_load_policy=1&iv_load_policy=3'}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </DialogContent>
      </Dialog> */}
      {/* <div className="community_main px-lg-0 st">
        <div className="row no-gutters">
          <div className="col-12   ">
            <div className="zz">
              <div className="d-flex px-3 px-lg-0 pt-2">
                <div className="">
                  <img src={men} width="30px" alt="user" className="rounded-circle" />
                </div>

                <div className="m_input ml-2">
                  <input placeholder={translate.formatMessage({ id: "whatsYourMind" })} className="inpt" />
                </div>
              </div>

              <div className="d-flex justify-content-between px-4 bo mt-4 pt-2 pb-2">
                <Link to="/community/live">
                  <div className="d-flex">
                    <BsCameraVideo className="comm_aa" />
                    <p className="comm_ab ml-1"><FormattedMessage id="live" /></p>
                  </div>
                </Link>
                |
                <Link to="/community/photos">
                  <div className="d-flex">
                    <BsFillImageFill className="comm_aa" />
                    <p className="comm_ab ml-1"><FormattedMessage id="photo" /></p>
                  </div>
                </Link>
                |
                <Link to="/community/event">
                  <div className="d-flex">
                    <RiCalendarEventLine className="comm_aa" />
                    <p className="comm_ab ml-1"><FormattedMessage id="event" /></p>
                  </div>
                </Link>
              </div>
            </div>
            <div className="scrll">
              <Userpost />
              <Userpost />
              <Userpost />
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};
