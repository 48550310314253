import React from "react";
import {  FacebookShareButton, TwitterShareButton, EmailShareButton, LinkedinShareButton, WhatsappShareButton } from "react-share";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faTwitter, faLinkedinIn, faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { FaShareAlt, FaEnvelope } from "react-icons/fa";
import './shareIcon.scss';


const ShareIcon = (props) => {

  const toggleShareIcon = (e) => {
    const dropdowns = document.getElementsByClassName('shareList');
    const thisDropdown = e.currentTarget.children[1];
    if (!thisDropdown.classList.contains('submenu-active')) {
      let i;
      for (i = 0; i < dropdowns.length; i++) {
        dropdowns[i].classList.remove('submenu-active');
      }
    }
    thisDropdown.classList.toggle('submenu-active');
  }
  return (
    <div>
      <ul className="menu topRight">
        <li className="share bottom" onClick={(e) => toggleShareIcon(e)}>
          <FaShareAlt className="icon share_icon"/>
          <div className="shareList">
          <ul id="shareList" className="shareListInner">
            <li>
            <FacebookShareButton
                className='fb_button'
                url={props.url}
                quote={props.text}>
                <FontAwesomeIcon className='icon fb_icon' icon={faFacebookF} />
            </FacebookShareButton>
            </li>
            <li>
            <TwitterShareButton
                className='twitter_button'
                url={props.url}
                title={props.text}>
                <FontAwesomeIcon className='icon twitter_icon' icon={faTwitter} />
            </TwitterShareButton>
            </li>
            <li>
            <EmailShareButton
                className='email_button'
                subject={`Check out this fantastic job`}
                body={`${props.text}: ${props.url}`}>
                <FaEnvelope className='icon email_icon' />
            </EmailShareButton>
            </li>
            <li>
            <LinkedinShareButton
                className='linkedin_button'
                url={props.url}
                >
                <FontAwesomeIcon className='icon linkedin_icon' icon={faLinkedinIn} />
            </LinkedinShareButton>
            </li>
            <li>
            <WhatsappShareButton
                className='whatsapp_button'
                url={props.url}
                title={props.text}
                separator=" ">
                <FontAwesomeIcon className='icon whatsapp_icon' icon={faWhatsapp} />
            </WhatsappShareButton>
            </li>
          </ul>
          </div>
        </li>
      </ul>
    </div>
  );
};
export default ShareIcon;