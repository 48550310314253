import "../../mainpage/chooseplan.scss";
import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {FormattedMessage, FormattedDate, FormattedNumber, FormattedPlural, FormattedTime} from 'react-intl';
import { AiFillCheckCircle } from "react-icons/ai";
import { AiFillCloseCircle } from "react-icons/ai";

import * as UserActions from "redux/actions/UserActions";
import { useDispatch, useSelector } from "react-redux";
import storage from "utils/storage";
import company_logo from "../../../assets/images/resume_logo.svg";

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { FaTimes } from "react-icons/fa";
import { useForm } from "react-hook-form";
import config from "../../../config/index";
import PremiumMemberImage from '../../../assets/images/premium-member-star.png'; // Premium Members Image
import { AiOutlineCloseCircle } from "react-icons/ai"; // Cross Image

export const JobSeekerPlans = () => {
  const history = useHistory();
  const { register, handleSubmit,  errors, watch ,reset, setError} = useForm();
  const dispatch = useDispatch();
  const { userDetails } = useSelector((state) => state.userReducer);
  const [paymentStatus, setpaymentStatus] = useState({isOpen: false, id: ''});
  const [saarthicodemodal, setSaarthiCodeModal] = useState(false);
  const [disable, setDisable] = React.useState(false);
  let transactionID = "";
  useEffect(() => {
    if (userDetails.is_premium == true) {
      setDisable(true);
    }
  });
  // open saarthi code modal
  const openSaarthiModal = () => {
    setSaarthiCodeModal(true);
  };
  // close saarthi code modal
  const closeSaarthiModal = () => {
    setSaarthiCodeModal(false);
  };

  /**
   * @description method to open razorpay payment popup
   * @param {string} value amount
   * @param {event} e event
   * @returns 
   */
   const saarthiCodeSubmit = async (value, e) => {
    setSaarthiCodeModal(false);
     try {
      const res = await loadScript(
        "https://checkout.razorpay.com/v1/checkout.js"
        );
        // creating a new order
        const orderDetails = await createPaymentOrder(value.saarthiCode.toUpperCase());
        transactionID = orderDetails.transaction_id;
      // Getting the order details back
      const option = buildPaymentOption(orderDetails);
      initiatePayment(option, e);
      setSaarthiCodeModal(false);
    }
    catch (err) {
      console.log(err);
    }
  }

  /**
   * @description method to create script tag in body for loading the razorpay script loading at runtime
   * @param {sting} src
   * @returns 
   */
  function loadScript(src) {
    return new Promise((resolve, reject) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        reject(false);
        console.log('failed to load razarpay script');
      };
      document.body.appendChild(script);
    });
  }

  /**
   * @description method to create payment order
   * @param {string} saarthi_code 
   * @returns order_id, transaction_id
   */
  const createPaymentOrder = async (saarthi_code) => {
    try {
      let body = {
        amount: config['SUBSCRIPTION'].job_seeker_plan['Premium'].amount,
        plan_code: config['SUBSCRIPTION'].job_seeker_plan['Premium'].plan_code,
        saarthi_code : saarthi_code
      }
      let res = await dispatch(UserActions.createPaymentOrder(body))
      if (res.value.success) {
        return {
          order_id: res.value.data.order_id,
          transaction_id: res.value.data.transaction_id,
          key_id : res.value.data.key_id,
          description : res.value.data.description,
          amount: body.amount
        }
      } else {
        return Promise.reject(res); 
      }
    }
    catch (err) {
      if (err.data.error_code == "INVALID_SAARTHI_CODE") {
        setSaarthiCodeModal(true);
        setError("saarthiCode", {
          type: "manual",
          message: "Please enter valid code"
        })
      }
      console.log('order failed',err);
      return Promise.reject(err);
    }
  }
  /**
   * @description method to create options for razorpay
   * @param {object} data order_id, transaction_id
   * @param {number} value amount
   * @returns options for razorpay
   */
  const buildPaymentOption = (data) => {
    const options = {
      key: data.key_id, // Enter the Key ID generated from the Dashboard
      amount: ((data.amount)*config['RAZORPAY'].currency_unit), // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      currency: config['RAZORPAY'].currency,
      name: config['RAZORPAY'].company_name,
      description: data.description,
      image: company_logo,
      order_id: data.order_id, //This SAARTHI100is a sample Order ID. Pass the `id` obtained in the response of Step 1
      handler: verifyPayment,
      prefill: {
        name: userDetails.name,
        email: userDetails.email ? userDetails.email : "",
        contact: userDetails.contact ? userDetails.contact : ""
      },
      theme: {
        "color": config['RAZORPAY'].theme
      },
      readonly: {
        contact: true,
        email: true
      },
      allow_rotation: true,
      retry: {
        enabled: false,
        max_count: 0
      },
      modal: {
        escape: false,
        ondismiss: () => {
          history.push("/chooseplan");
        }
      },
      timeout: 600
    };
    return options;
  }
  /**
   * @description method to initialize payment
   * @param {object} option options created in 'buildPaymentOption' method
   * @param {event} e event
   */
  const initiatePayment = (option, e) => {
    const rzp = new window.Razorpay(option);
    rzp.open();
    e.preventDefault();
    rzp.on('payment.failed', function (response) {
      _handleEventPopup('PAYMENT_FAILED');
      console.log('payment failed', response);
    });
  }
  /**
   * @description method to verify payment which is supposed to be call in create option method after handler success
   * @param {object} response 
   */
  const verifyPayment = async (response) => {
    try {
      let body = {
        order_id: response.razorpay_order_id,
        payment_id: response.razorpay_payment_id,
        payment_signature: response.razorpay_signature,
      }
      let res = await dispatch(UserActions.verifyPayment(body, transactionID))
      if (res.value.success) {
        setTimeout(() => {
          history.push("/profile");
        }, 4000);
        _handleEventPopup('PAYMENT_SUCCESS');
      } else {
        _handleEventPopup('VERIFICATION_FAILED');
      }
    }
    catch (err) {
      _handleEventPopup('VERIFICATION_FAILED');
      console.log('VERIFICATION_FAILED',err);
    }
  }

  const _handleEventPopup = (event) => {
    switch (event) {
      case 'PAYMENT_SUCCESS': {
        openEventModal('SUCCESS');
        break;
      }
      case 'PAYMENT_FAILED': {
        openEventModal('FAILED');
      break;
      }
      case 'VERIFICATION_FAILED': {
        openEventModal('FAILED');
        break;
      }
      default: {
        console.log('invalid event', event);
      }
    }
  }
  const openEventModal = (eventType) => {
    switch (eventType) {
      case 'SUCCESS': {
        setpaymentStatus({isOpen: true, id: 'success'});
        break;
      }
      case 'FAILED': {
        setpaymentStatus({isOpen: true, id: 'failed'});
        break;
      }
      default: {
        console.log('invalid event type', eventType);
      }
    }
  }
  /**
   * @description method to close the payment status popup
   */
  const handleClose = () => {
    setpaymentStatus((prev)=> !prev.isOpen);
  };

  return (
    <>
      <div className="chooseplans_main">
        <div className="container">
          <div className="chooseplans_inner">
            <div className="plans_title">
              <h3><FormattedMessage id="humlogjobsPremiumMembership"></FormattedMessage></h3>
            </div>
            <div className="plans_heading">
              <p><FormattedMessage id="premimumPlanPurchased"/></p>
            </div>
            <div className="plans_details_list">
              <div className="plans_details_inner">
                <table>
                <thead>
                  <tr>
                    <th className="feature"><FormattedMessage id="feature"/></th>
                    <th className="basic"><FormattedMessage id="basic"/></th>
                    <th className="premium"><FormattedMessage id="premium"/></th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td><FormattedMessage id="saarthiSupport"/></td>
                    <td><span className="icon"><AiFillCloseCircle className="unavailable"/></span></td>
                    <td><span className="icon"><AiFillCheckCircle className="available"/></span></td>
                  </tr>
                  <tr>
                    <td><FormattedMessage id="audioJobDescription"/></td>
                    <td><span className="icon"><AiFillCheckCircle className="available" /></span></td>
                    <td><span className="icon"><AiFillCheckCircle className="available" /></span></td>
                  </tr>
                  <tr>
                    <td><FormattedMessage id="animatedJobDescription"/></td>
                    <td><span className="icon"><AiFillCheckCircle className="available" /></span></td>
                    <td><span className="icon"><AiFillCheckCircle className="available" /></span></td>
                  </tr>
                  <tr>
                    <td><FormattedMessage id="videoResumeCreation"/></td>
                    <td><span className="icon"><AiFillCheckCircle className="available" /></span></td>
                    <td><span className="icon"><AiFillCheckCircle className="available" /></span></td>
                  </tr>
                  <tr>
                    <td><FormattedMessage id="videoResumeCreationSupport"/></td>
                    <td><span className="icon"><AiFillCloseCircle className="unavailable" /></span></td>
                    <td><span className="icon"><AiFillCheckCircle className="available" /></span></td>
                  </tr>
                  <tr>
                    <td><FormattedMessage id="completeAccess"/></td>
                    <td><span className="icon"><AiFillCheckCircle className="available" /></span></td>
                    <td><span className="icon"><AiFillCheckCircle className="available" /></span></td>
                  </tr>
                  <tr>
                    <td><FormattedMessage id="increaseChances"/></td>
                    <td><span className="icon"><AiFillCloseCircle className="unavailable" /></span></td>
                    <td><span className="icon"><AiFillCheckCircle className="available" /></span></td>
                  </tr>
                  <tr>
                    <td><FormattedMessage id="unLimitedAccess"/></td>
                    <td><span className="icon"><AiFillCloseCircle className="unavailable" /></span></td>
                    <td><span className="icon"><AiFillCheckCircle className="available" /></span></td>
                  </tr>
                  </tbody>
                </table>
                <div className="buynowbtn"><button type="button" disabled={disable} onClick={openSaarthiModal}><FormattedMessage id="buyNow"/></button></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Dialog
        open={saarthicodemodal}
        onClose={closeSaarthiModal}
        className="saarthi_modal"
      >
        <DialogContent className="saarthi_modal_content">
          <p>
            <FormattedMessage id="enterSaarthiCode"/>
          </p>
          <form onSubmit={handleSubmit(saarthiCodeSubmit)}>
            <div className="form-group">
            <label className="">
                <input 
                  autoComplete="off" 
                  type="text" 
                  className="form-control" 
                  name="saarthiCode"
                  ref={register({
                    required: " Please enter saarthi code",
                  })}
                />
              </label>
              {errors.saarthiCode && (
                <p className="text-danger">
                  {errors.saarthiCode.message}
                </p>
              )}
            </div>
            <div className="buynowbtn">
              <button type="submit"><FormattedMessage id="submit"/></button>
            </div>
          </form>
        </DialogContent>
      </Dialog>
      {/* Payment Successfull Modal Start from here */}
      <Dialog
        open={paymentStatus.isOpen}
        onClose={handleClose}
        className="premium-modal"
      >
        <div className="close-icon">
          <AiOutlineCloseCircle onClick={handleClose} className="cross-image" />
        </div>
        <DialogContent className="premium-member-modal">
        {(paymentStatus.id === 'success') ? (
          <DialogContent className="premium-member-modal">
          <div className="premium-member-container">
            <div className="premium-member-image">
              <img src={PremiumMemberImage} width="20%" height="20%" alt="image" />
              <p><FormattedMessage
                id="premiumMembership"
                defaultMessage="Premium Membership"
              /></p>
            </div>
            <div className="congrats-message">
              <p><FormattedMessage
                id="congratsPremiumMembership"
                defaultMessage="Congrats!! You are a Premium Member now"
              /></p>
            </div>
          </div>
        </DialogContent>
        ) : ('') }
        {/* Payment Successfull Modal End */}
        {(paymentStatus.id === 'failed') ? (
          <div className="payment_content failed">
            <span><AiFillCloseCircle /></span>
            <h3>Payment Failed!</h3>
            <p>If any amount deducted it will be refunded in the next 5-7 working days.</p>
            <button type="button" onClick={handleClose}>GOT IT</button>
          </div>
        ) : ('') }
        </DialogContent>
      </Dialog>
    </>
  )
}