export const jobCategoryData = [
    "Cook / Waiter / Hotel Staff",
    "Delivery",
    "Electrician / Fitter / Welder",
    "Front Office / Retail",
    "Sales & Marketing",
    "Security Guard",
    "Accountant",
    "Tele-Caller / BPO",
    "Nurse / Ward Boy / Hospital Staff",
    "House Keeping",
    "Beautician / Spa / Wellness",
    "Driver",
    "Automobile Service",
    "Bakery",
    "Manufacturing (Machine Operator, Linesman etc.)",
    "Medical Representative / Pharma",
    "Airlines",
    "Dairy",
    "Garment Making / Tailor",
    "IT / Hardware / Network Engineer", 
    "Paint Shop / Automobile Factory",
    "Petrol Pump",
    "Gardening / Landscape / Nursery",
    "Solar & Battery",
    "Telecom  / Tower Technician",
    "Back Office / Data Entry",
    "Domestic Help (maid, nanny, caretaker etc.)",
    "Teacher",
    "Legal",
    "Event Management",
    "Lab technician",
    "Admin / Office Help / Peon",
    "HR / Receptionist",
    "Construction (Masons, Electrician, Welders, Architect etc.)",
    "Lab Technician"
]