import React, { useState, useEffect } from "react";
import { FormattedMessage, FormattedDate, FormattedNumber, FormattedPlural, FormattedTime } from 'react-intl';
import "../jobmatch/jobmatch.scss";
import { VscBriefcase, VscBell } from "react-icons/vsc";
import { useDispatch, useSelector } from "react-redux";
import genderi from "../../../assets/images/genderi.png";
import pencili from "../../../assets/images/pencili.png";
import sallaryi from "../../../assets/images/salary.png";
import storage from "utils/storage";
import dummylogo from "../../../assets/images/dummylogo.png"
import { FaRegFileAudio, FaRegFileVideo } from "react-icons/fa";
import { Image } from "components/shared/imagecomponent/image";
import { Player } from "components/shared/videoplayer/player";
import ShareIcon from "components/shared/share-icons/index";
import { RiGroupLine } from "react-icons/ri"; // Icon for job opening
import * as UserActions from "redux/actions/UserActions";
import { IoIosCloseCircle } from "react-icons/io";
import { HiOutlineStar } from "react-icons/hi";
import { BsCheck } from "react-icons/bs";
import "../JobDetailsmodal/jobdetailsmodal.scss";
import phoneImage from "../../../assets/images/calli.png";

export const JobDetailModal = (props) => {
  const dispatch = useDispatch();
  const { userDetails } = useSelector((state) => state.userReducer);
  const { company_logo, company_name, description, gender, job_location, job_title, openings, salary_from, salary_to, shift, skills, job_experience, job_timings, job_type, job_id, audio_description, animated_description, status, company_contact, district } = props.modalData;
  const [modal, setModal] = useState({ open: false, src: "" });
  const userRole = storage.get("humlog_user_role"); // get humlog user role
  /* method to open and close player */
  const onToggleModal = (src) => {
    setModal({ open: !modal.open, src: src });
  };
  // Show-Hide Job Opening according to user roles
  const _handleJobOpening = () => {
    if (userRole === 2) {
      return (
        <div className="jobdetailsfield">
          <div className="d-flex mt-2">
            <RiGroupLine className="_job_ua" />
            <p className="_job_ub ml-1">
              <FormattedMessage id="jobOpening" defaultMessage="Job Opening" />
            </p>
          </div>
          <h6 className="ch">{openings}</h6>
        </div>
      );
    }
  };

  /**
   * method to change the status of the job
   * @param {Array} job
   * @param {String} status status of job [Applied, Saved, Visited]
   */
   const changeJobStatus = (job, status) => {
    const selectedJob = {
      company_logo: job.company_logo,
      company_name: job.company_name,
      job_id: job._id,
      job_title: job.job_title,
      salary_from: job.salary_from,
      salary_to: job.salary_to,
      job_location: job.job_location,
      category: job.category,
      jobStatus: job.status,
      openings: job.openings,
      skills: job.skills,
      job_experience: job.experience,
      job_timings: job.job_timings,
      job_type: job.job_type,
      description: job.description,
      animated_description: job.animated_description,
      audio_description: job.audio_description,
      status: status,
      district: job.district // get job district
    };
    let data = {
      _id: userDetails._id,
      jobs: selectedJob
    };
    dispatch(UserActions.updateUserData(data))
      .then((res) => {
        if (res.value.success) {
          props.handelClose();
          props.onHide();
        }
      })
      .catch((err) => console.log(err));
  };
  // when both salary value is null (Max and Min) then shown Disclosed
  const _handleSalary = (salary_from, salary_to) => {
    if (salary_from && salary_to) {
      return (
        <h6 className="ca">
          ₹ {salary_from}  - ₹ {salary_to}
        </h6>
      )
    } else if (salary_from && !salary_to) {
      return (
        <h6 className="ca">
          <FormattedMessage id="moreThan" defaultMessage="More Than" values={{more : salary_from}}/>  
        </h6>
      )
    } else if (salary_to && !salary_from) {
      return (
        <h6 className="ca">
          <FormattedMessage id="upTo" defaultMessage="Upto" values={{upto : salary_to}}/>
        </h6>
      )

    } else  {
      return (
        <h6 className="ca">
          <FormattedMessage id="disClosed" defaultMessage="Disclosed"/>
        </h6>
      )
    }
  }
  // Showing Employer contact Number
  const _handleEmployerNumber = (status, company_contact) => {
    if (status === 'Shortlisted' && company_contact) {
      return (
        <div className="jobdetailsfield">
          <div className="d-flex mt-2">
            <img src={phoneImage} className="phone-icon" />
            <p className="_job_ub ml-1"><FormattedMessage
              id="contactNumber"
              defaultMessage="Contact Number"
            /></p>
          </div>
          <h6 className="ch">
            {company_contact}
          </h6>
        </div>
      )
    }
  }
  return (
    <>
    <div className="main-jobdetails-container">
      <div className="main_job ">
        <ShareIcon className="modal_share_icon" url={`${window.location.origin}/shared/job?id=${job_id}`} text="Apply for Jobs & Get Interview Calls. All the best." />
        <Player open={modal.open} toggleModal={onToggleModal} src={modal.src} />
        <div className="row p-0 m-0 no-gutters">
          <div className="col-12">
            <div className="d-flex justify-content-around">
              <button className="audio-video_btn" onClick={() => { onToggleModal(audio_description) }} disabled={!audio_description || audio_description.length === 0 ? true : false}><FaRegFileAudio /> </button>
              {company_logo && company_logo.length > 0 ? (
                <div style={{ width: "100px", height: "100px" }}>
                  <Image picture={`${company_logo}?${Date.now()}`} alt="user_profile"></Image>
                </div>
              ) : (<div style={{ width: "100px", height: "100px" }}>
                <Image picture={dummylogo} alt="company logo"></Image>
              </div>)}
              <button className="audio-video_btn" onClick={() => { onToggleModal(animated_description) }} disabled={!animated_description || animated_description.length === 0 ? true : false}><FaRegFileVideo /> </button>
            </div>
            <div className="d-flex justify-content-center">
              <div>
                <h4 className="Job_ta mt-2 text-capitalize text-center">
                  {job_title}{" "}
                </h4>
                <h4 className="Job_ttb mt-1 text-capitalize text-center">
                  {company_name}{" "}
                </h4>
                <h4 className="Job_ttb mt-1 text-capitalize text-center job_Location">
                  {district ? district : ""}
                </h4>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row p-0 m-0 no-gutters">
            <div className="col-7">
              {" "}
              <div className="jobdetailsfield">
                <div className="d-flex mt-2">
                  <img
                    src={sallaryi}
                    className="_job_ua"
                    width="16px"
                    height="16px"
                  />
                  <p className="_job_ub ml-1"><FormattedMessage
                    id="salary"
                    defaultMessage="Salary"
                  /></p>
                </div>
                {/* salary-section */}
                {_handleSalary(salary_from, salary_to)}
              </div>
              <div className="jobdetailsfield">
                <div className="d-flex mt-2">
                  <VscBriefcase className="_job_ua" />
                  <p className="_job_ub ml-1"><FormattedMessage
                    id="experienceRequired"
                    defaultMessage="Experience Required"
                  /></p>
                </div>
                <h6 className="ch">
                  {" "}
                  {job_experience == 0
                    ? "Fresher"
                    : job_experience == 1
                      ? "Less than 1 year"
                      : job_experience == 2
                        ? "1-2 years"
                        : job_experience == 3
                          ? "2-4 years"
                          : job_experience == 4
                            ? "4-6 years"
                            : job_experience == 5
                              ? "6-8 years"
                              : job_experience == 6
                                ? "8-10 years"
                                : job_experience == 7
                                  ? "10+ years"
                                  : ""}
                </h6>
              </div>
              <div className="jobdetailsfield">
                <div className="d-flex mt-2">
                  <img src={genderi} className="_job_ua" />
                  <p className="_job_ub ml-1"><FormattedMessage
                    id="gender"
                    defaultMessage="Gender"
                  /></p>
                </div>
                <h6 className="ch">
                  {" "}
                  {gender === 0
                    ? "All"
                    : gender == 1
                      ? "Female"
                      : gender == 2
                        ? "Male"
                        : gender === 3
                          ? "Others"
                          : ""}
                </h6>
              </div>
              {/* Mobile Number of employer section start from here */}
              {_handleEmployerNumber(status, company_contact)}
              {/* Mobile Number of employer end */}
              {/* Showing Job Opening */}
              {_handleJobOpening()}
            </div>

            <div className="col-5">
              {" "}
              <div className="jobdetailsfield">
                <div className="d-flex mt-2 ">
                  <VscBriefcase className="_job_ua" />
                  <p className="_job_ub ml-1">
                    <FormattedMessage id="jobType" defaultMessage="Job Type" />
                  </p>
                </div>
                <h6 className="ch">
                  {job_type === 0
                    ? "Office"
                    : job_type === 1
                      ? "Field"
                      : job_type === 2
                        ? "Shift"
                        : job_type === 3
                          ? "Part Time"
                          : ""}
                </h6>
              </div>
              <div className="jobdetailsfield">
                <div className="d-flex mt-2 ">
                  <VscBell className="_job_ua" />
                  <p className="_job_ub ml-1">
                    <FormattedMessage
                      id="jobTimings"
                      defaultMessage="Job Timings"
                    />
                  </p>
                </div>
                <h6 className="ch skiils_width">
                  {" "}
                  {job_timings === 0
                    ? "General"
                    : job_timings === 1
                      ? "Rotation"
                      : ""}
                </h6>
              </div>
              <div className="jobdetailsfield">
                <div className="d-flex mt-2 ">
                  <img src={pencili} className="_job_ua" />
                  <p className="_job_ub ml-1"><FormattedMessage
                    id="skillRequired"
                    defaultMessage="Skill Required"
                  /></p>
                </div>
                <h6 className="ch skiils_width">{skills} </h6>{" "}
              </div>
            </div>
            {/* job-location-section start from here */}
            <div className="col-12 d-flex">
              <div className="jobdetailsfield">
                <div className="mt-2">
                  <p className="_job_ub">
                    <FormattedMessage id="jobLocation" defaultMessage="Job Location" />
                  </p>
                </div>
                <h6 className="ch jd job-details-text">
                  {job_location ? job_location : ""}
                </h6>
              </div>
            </div>
            {/* job-location-section end */}
            <div className="col-12 d-flex">
              <div className="jobdetailsfield">
                <div className="d-flex mt-2">
                  <p className="_job_ub">
                    <FormattedMessage id="jobDescription" defaultMessage="Job Description" />
                  </p>
                </div>
                <h6 className="ch jd job-details-text">
                  {description}
                </h6>
              </div>
            </div>
            {props.jobOption ? (
              <div className="col-12">
                <div className="mt-3 mb-3 d-flex justify-content-around">
                  <button
                    className="btn_va shadow"
                    onClick={() => changeJobStatus(props.modalData, "Visited")}
                  >
                    <IoIosCloseCircle className="cross_icon" />
                    <div className="jobResponse">
                      <FormattedMessage id="notApply" defaultMessage="Not Apply" />
                    </div>
                  </button>
                  <button
                    className="btn_vb shadow"
                    onClick={() => changeJobStatus(props.modalData, "Saved")}
                  >
                    <HiOutlineStar className="box_ia job_pa" />
                    <div className="jobResponse">
                      <FormattedMessage
                        id="applyLater"
                        defaultMessage="Apply Later"
                      />
                    </div>
                  </button>
                  <button
                    className="btn_vc shadow"
                    onClick={() => changeJobStatus(props.modalData, "Applied")}
                  >
                    <BsCheck className="box_ib job_pb" />
                    <div className="jobResponse">
                      <FormattedMessage
                        id="applyNow"
                        defaultMessage="Apply Now"
                      />
                    </div>
                  </button>
                </div>
              </div>
            ):("")}
          </div>
        </div>
      </div>
      </div>
    </>
  );
};
