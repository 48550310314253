import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as JobActions from "redux/actions/JobActions";
import storage from "utils/storage";
import { checkTimeExpiry } from "utils/dateTime";
import moment from 'moment';
import CommonModal from "components/shared/ui-components/common-modal";
import { InterviewDetailsModal } from "./interviewDetailsModal";
import "./schdule.scss";
import { FormattedMessage } from "react-intl";
import { FiMoreHorizontal } from "react-icons/fi";
import { IoIosCloseCircle } from "react-icons/io";
import { BsCheck } from "react-icons/bs";

export const ScheduleInterview = () => {
  const dispatch = useDispatch();
  const [loaded, setLoaded] = useState(false);
  const [modalData, setModalData] = useState(false);
  const [open, setOpen] = useState(false);
  let userId = storage.get("humlog_user");
  useEffect(() => {
    if (!loaded) {
      setLoaded(true);
      dispatch(JobActions.getInterviewByUserId(userId));
    }
  }, [loaded, dispatch, userId]);

  /**
   * @description function to close modal
   */
   const handelCloseModal = () => {
    setOpen(false);
  };
  /**
   * @description function to open modal
   * @param {param} data 
   */
  const handelOpenModal = (data) => {
    setModalData(data)
    setOpen(true);
  };
  // Show-hide Content of toggle menu
  const toggleMenu = (e) => {
    const dropdowns = document.getElementsByClassName('dropdown-content');
    const thisDropdown = e.target.nextSibling;
    if (!thisDropdown.classList.contains('show')) {
      let i;
      for (i = 0; i < dropdowns.length; i++) {
        dropdowns[i].classList.remove('show');
      }
    }
    thisDropdown.classList.toggle('show');
  }
  // API to update interview details
  const handleAppliedButton = async (id, status) => {
    try {
      let body = {
        status: status
      }
      let query = {
        _id: id
      }
      let res = await dispatch(JobActions.updateInterviewDetails(body, query));
      if (res.value.success) {
        console.log(res.value.message);
        dispatch(JobActions.getInterviewByUserId(userId))
      }
    } catch (error) {
      console.log(error);
    }
  }
  const { interviewList } = useSelector((state) => state.jobReducer);
  return (
    <>
      <div className="">
        {
          interviewList.map(val => {
            const isExpired = checkTimeExpiry(val);

            return (
              <>
                <div className="main_notification py-2 border px-2 interviewList">
                  <div className="p-0 m-0">
                    <div className="row mb-1">
                      <div className="col-6"><h6 className="font-weight-bold ml-1 "><FormattedMessage id="title" defaultMessage="Title" /> - </h6></div>
                      <div className="col-4"><h6 className="font-weight-bold warp_text ">{val.title} </h6></div>
                      {/* Toggle-button-section start from here */}
                      <div className="col-2">
                        <div className="viewoptionsbtn">
                          <div className="dropdownbox">
                            {!isExpired &&
                              <button type="button" className="moreoptns dropbtn" onClick={toggleMenu}>
                                <FiMoreHorizontal />
                              </button>
                            }
                            <div className="dropdownmenu option_menu dropdown-content">
                              <ul className={val.status === "pending" ? '' : 'mouse-pointer'}>
                                <li>
                                  <span>
                                  <button disabled={val.status === "pending" ? false : true} onClick={() => handleAppliedButton(val._id, "Accept")}>
                                    <BsCheck className={val.status === "pending" ? "view_menu_icons applynow" : "view_menu_icons disabled-appybutton"}/>
                                    <FormattedMessage id="accept" defaultMessage="Accept"/>
                                  </button>
                                  </span>
                                </li>
                                <li>
                                  <span>
                                    <button disabled={val.status === "pending" ? false : true} onClick={() => handleAppliedButton(val._id, "Not Accept")} >
                                    <IoIosCloseCircle className={val.status === "pending" ? "view_menu_icons notapply" : "view_menu_icons disabled-appybutton"}/>
                                    <FormattedMessage id="notAccept" defaultMessage="Not Accept"/>
                                    </button>
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* Toggle-button-section end */}
                    </div>
                    <div className="row mb-1">
                      <div className="col-6"><h6 className="font-weight-bold ml-1"><FormattedMessage id="contactPersonName" defaultMessage="Contact Person Name"/> - </h6></div>
                      <div className="col-6"><h6 className=" font-weight-bold warp_text ">{val.contact_person}</h6></div>
                    </div>
                    <div className="row mb-1">
                      <div className="col-6"><h6 className="font-weight-bold ml-1 "><FormattedMessage id="startTime" defaultMessage="Start-Time"/> - </h6></div>
                      <div className="col-6"><h6 className=" font-weight-bold warp_text ">{moment(val.start_time, ["HH.mm"]).format("hh:mm a")}</h6></div>
                    </div>
                    <div className="row mb-1">
                      <div className="col-6"><h6 className="font-weight-bold ml-1"><FormattedMessage id="endTime" defaultMessage="End Time"/> - </h6></div>
                      <div className="col-6"><h6 className=" font-weight-bold warp_text ">{moment(val.end_time, ["HH.mm"]).format("hh:mm a")} </h6></div>
                    </div>
                    <div className="row mb-1">
                      <div className="col-6"><h6 className="font-weight-bold ml-1"><FormattedMessage id="date" defaultMessage="Date"/> - </h6></div>
                      <div className="col-6"><h6 className=" font-weight-bold warp_text "> {moment(val.date).format('DD-MM-YYYY')} </h6></div>
                    </div>
                    {/* status-section start from here */}
                    <div className="row mb-1">
                      <div className="col-6"><h6 className="font-weight-bold ml-1"><FormattedMessage id="status" defaultMessage="Status" /> - </h6></div>
                      <div className="col-6"><h6 className=" font-weight-bold warp_text ">
                       {!isExpired ? val.status : (<FormattedMessage id="expired" defaultMessage="Expired"/>)}
                      </h6></div>
                    </div>
                    {/* status-section start end */}
                  </div>
                  {
                    !isExpired && (<div className="viewdetailsbtn"><button className="viewdetails" type="button" onClick={() => handelOpenModal(val)}><FormattedMessage id="viewDetail" /></button></div>)
                  }
                </div>
              </>
            )
          })
        }
        <CommonModal open={open} handelClose={handelCloseModal}>
          <InterviewDetailsModal modalData={modalData} handelCloseModal={handelCloseModal}/>
        </CommonModal>
      </div>
    </>
  )
}