import axios from 'config';
import {API_VIRSION } from "config"
// Auth api
export const getOTP = (body) => axios.post(`/login/user`, body.data);
export const recruiterLogin = (body) => axios.post(`/login`, body.data);
export const recruiterSignup = (body) => axios.post(`/register`, body.data);
export const sarthiSignup = (body) => axios.post(`/register/saarthi`, body.data);
export const sarthiLogin = (body) => axios.post(`/login/saarthi`, body.data);
export const varifyOTP = (body) => axios.post(`/login/confirm`, body.data);
export const refreshToken = () =>  axios.post(`${API_VIRSION}/auth/refreshtoken`);
export const getRecruiterOTP = (body) => axios.post(`/login/v2`, body);
export const verifyOTP = (body) => axios.post(`/login/v2/confirm`, body);
export const verifyCaptcha = (body) => axios.post(`/login/query-feedback`, body);

// User api
export const getUser = (id) => axios.get(`/user/`, {params: {_id: id }});
export const getUserByJobID = (query) => axios.get(`/user/`, {params: query});
export const getAllUser = () => axios.get(`/user/all`);
export const getUserCreatedBy = (id) => axios.get(`/user/`, {params: {_id: id }});
export const updateUser = (body) => axios.put(`/user/v2/profile`, body);
export const updateWhatsApp = (body, userId) => axios.put(`/user/whatsapp/opt/${userId}`, body);
export const scheduleInterview = (body) => axios.post(`/job/interview`, body.data);
export const getUserLogout = () => axios.get(`/user/logout`); // get API for user Logout
export const filterUser = (body, page, jobId) => axios.post(`user/emp/job/${jobId}/js/filter`, { filters:body, page });

// Jobs Api
export const createJob = (body) => axios.post(`/job/v2/post`, body);
export const updateJob = (body) => axios.put(`/job/post`, body.formData,{ headers: {'Content-Type': 'multipart/form-data' }});
export const getJobById = (id) => axios.get(`/job/one`, {params: {_id: id }});
export const getJobListDataById = (id) => axios.get(`/job/recruiter/`, {params: {created_by: id }});
export const getAllJobs = (id, page) => axios.get(`/job/all`,{params: {user_id: id, page: page }});
export const getFilterJobs = (body, page) => axios.post(`/job/filter`,{ body, page: page });
export const getUserListByJobId = (id) => axios.get(`/job/users`,{params: {_id: id}});
export const getInterviewByUserId = (id) => axios.get(`/job/interviews-by-seeker/${id}`);
export const updateInterviewDetails = (body, query) => axios.put(`/job/interview`, body, {params: query});
export const getInterviewByJobId = (id) => axios.get(`/job/interviews-by-job/${id}`);
export const getJobsByTitle = (title) => axios.get("/job/titles", { params: { title } });

// JobCategory Api
export const getJobCategoryById = (id) => axios.get(`/job-category/one`, {params: {_id: id }});
export const getAllJobCategories = () => axios.get(`/job-category`);

// Sarthi Api
export const createUserBySarthi = (body) => axios.post(`/user/saarthi`, body.formData,{ headers: {'Content-Type': 'multipart/form-data' }});
export const sendOtp = (body) => axios.post(`user/verify`);
export const confirmUser = (body) => axios.post(`user/confirm`);

// social login api
export const SocialiLogin = (body) => axios.post(`/login/v2/social`,body);

// enquiry form api
export const EnquiryForm = (body) => axios.post(`/contact/us`, body);
// File upload api
export const preUpload = (body, userId) => axios.post(`/file/${userId}/pre/upload`, body);
export const postUpload = (body, userId) => axios.post(`/file/${userId}/post/upload`, body);
export const downloadFile = (id, userId) => axios.get(`/file/${userId}`, {params: {file_id: id }});

// Notification api
export const getNotificaitonsList = (page) => axios.get(`/notification`, {params: {page: page}}); // fetch notification list
export const updateNotification = (id) => axios.put(`/notification/${id}`); 
// create payment order api
export const createPaymentOrder = (body) => axios.post(`/payment/order`, body);
export const verifyPayment = (body, transactionID) => axios.post(`/payment/${transactionID}/verify`, body);
// user subscription details api
export const getSubscriptionDetails = () => axios.get(`user/subscription`);
// user job details by id public api
export const getSharedJobDetailsById = (jobId) => axios.get(`fetch/job/${jobId}`);
// user job details by id
export const getJobDetailsById = (jobId) => axios.get(`job/fetch/detail/${jobId}`);
// post job view count for call icon
export const postJobViewCount = (body) => axios.post(`job/record/view`, body);
// get community video list
export const getCommunityVideoList = (query) => axios.get(`community/video-list`,{params: query});
// get community video by id
export const getCommunityVideoById = (communityId) => axios.get(`community/video/${communityId}`);
