import "./blogdetails.scss"
import { Link, useHistory } from "react-router-dom";
import { HeaderV1 } from '../header/headerv1';
import { FooterV1 } from '../footer/footerv1';
import initiatives from "../../../assets/images/homepage/initiatives.png";
export const CovidInitiative = (activeblog) => {
const history = useHistory();
  return(
    <>
      <HeaderV1 activeblog = {activeblog}/>
      <section className="blogs_main">
        <div className="container">
          <div className="blogs_main_inner">
            <div className="blogs_heading">
              <div className="blog_heading_img">
                <img src={initiatives} alt="blog" className="img-fluid d-block" />
              </div>
              <div className="blogs_heading_inner">
                <h3>Covid-19: UNDP initiative helps those who lost jobs to pandemic find re-employment</h3>
                <p>United Nations Development Programme’s (UNDP) recovery measures have seen at least 1,468 people placed in private sector jobs, mainly in Bengaluru and Mysuru, across sectors like e-commerce, logistics, manufacturing, telecom and retail.</p>
                <p>The highest salary that people were placed at was Rs 25,000 per month.</p>
              </div>
            </div>
            <div className="blogs_content">
              <p>By March 31, the recovery program aims at placing 2,500 young people in Karnataka, and 6,000 people across the country.</p>
              <p>The program is being carried out in collaboration with the state government and district administration, Karnataka Small Scale Industries Association (KASSIA), Peenya Industries Association (PIA), Mysore Industries Associations (MIA) and other MSMEs in Karnataka.</p>
              <p>Our platform <span><Link to="/homepage">Humlogjobs.com</Link></span> is also helping both individuals and small business get back to the pre-pandemic normal. We are starting with a focus on the NCR region and will cover pan India soon!</p>
              <p><span><a href="https://www.linkedin.com/feed/hashtag/?keywords=jobseeksers&highlightedUpdateUrns=urn%3Ali%3Aactivity%3A6782340543369265152" target="_blank">#jobseeksers #unitednations #humlog #humlogjobs #indiabusiness #bluecollarjobs</a></span></p>
            </div>
          </div>
        </div>
      </section>
      <FooterV1 activeblog = {activeblog}/>
    </>
  )
}


