import React,{useState, useEffect, useRef} from "react";
import {FormattedMessage, FormattedDate, FormattedNumber, FormattedPlural, FormattedTime} from 'react-intl';
import "./notification.scss";
import { ImSwitch } from "react-icons/im";
import {useHistory} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as userActions from "redux/actions/UserActions";
import { BsBell } from "react-icons/bs";
import { FaAngleDown } from "react-icons/fa";
import storage from "utils/storage";
import * as Notification from "utils/notificationService";
export const Notificationpage = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { count } = useSelector((state) => state.userReducer);
  const [page, setPage] =useState(0);
  const [notificationState, setnotificationState] = useState([]);
  const userRole = storage.get("humlog_user_role");
  const [notificationCount, setNotificationCount] = useState();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [choice,setchoice] = useState("")
  const history =useHistory();
  const handle=()=>{
      history.push('/profile')
  }
 const dispatch = useDispatch();
  useEffect(() => {
    dispatch(userActions.getNotificationsList(page))
    .then((response) => {
      if(response && response.value.success) {
        setNotificationCount(response.value.data.data.length); // get notification counts
        setnotificationState((prev) => prev.concat(response.value.data.data)); // concat notification here
      }
    })
  }, [page]);

  const update=(e)=>{
    console.log(e.target.value);
  }
  // change page number for load more notification
  const loadNotifications = () => {
    setPage((prev) => prev + 1 );
  }
  /**
   * Method for changing notification status and redirecting to defined path
   * on clicking notification
   * @param {String} notification_type
   * @param {String} notification_id
   */
  const onClickNotification = (notification_type, notification_id) => {
    document.getElementById(notification_id).classList.add('noti_row_viewed');
    // update notification status
    Notification.updateNotification(notification_id);
    let path = Notification.handleNotification(notification_type, userRole);
    if(path) {
      history.push(path);
    } else {
      return;
    }
  }
  return (
    <>
      <div className="st p_bottom">
        <h5 className="page_title">
          <FormattedMessage id="notification" defaultMessage="Notification" />
        </h5>
        <div className="container notification_container">
          <div className="main_notification border notification_box">
          {notificationState.map((notification, index) => {
            return(
              <div id={notification._id} className={`noti_row ${notification.status === 'viewed' ? 'noti_row_viewed' : ''}`} onClick={()=>{onClickNotification(notification.notification_type, notification._id)}}>
                <div className="d-flex pt-3 pb-3 ">
                <div className="">
                <BsBell className="bell_icon"/>
              </div>
                  <div>
                    <p className="noti_user ml-3">
                      {notification.payload}{" "}
                    </p>
                  </div>
                </div>
              </div>
            )
          })}
          </div>
          {notificationCount === 30 && <div className="load_more" onClick={loadNotifications}><p>Load More Notifications <FaAngleDown/></p></div>}
        </div>
      </div>
    </>
  );
};
