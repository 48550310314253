import React, { useEffect, useState } from "react";
import "./community.scss";
import * as userActions from "redux/actions/UserActions";
import { useDispatch } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";

export const SharedCommunityVideo = () => {
  const [loaded, setLoaded] = useState(false);
  const [getList, setGetList] = useState([]);
  const dateOptions = { year: 'numeric', month: 'long', day: 'numeric' };
  const dispatch = useDispatch();
  let query = new URLSearchParams(useLocation().search);
  let videoId = query.get("id");
  const history = useHistory();

  useEffect(() => {
    if (!videoId) {
      history.push("/homepage");
      return;
    }
    if (!loaded) {
      setLoaded(true);
      fetchData(videoId);
    }
  }, [loaded, dispatch]);

  const fetchData = (videoId) => {
    dispatch(userActions.getCommunityVideoById(videoId))
      .then((response) => {
        if (response && response.value.success) {
          setGetList(response.value.data);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  const playVideo = (type) => {
    console.log('type', type);
    const VID_REGEX =
      /(?:youtube(?:-nocookie)?\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    let url = type.match(VID_REGEX)[1];
    return `https://www.youtube-nocookie.com/embed/${url}?autoplay=0&modestbranding=1&cc_load_policy=1&iv_load_policy=3&mode=opaque&rel=0&autohide=1&showinfo=0&wmode=transparent`;
  };

  return (
    <>
      <div className="community_main">
        <div className="row">
          <div className="col-sm-12">
            <div className="community_inner">
              <div className="playlist_box_main">
                <h2>HumlogJobs Community</h2>
                <div className="playlist_box">
                  <ul>
                    <li key={getList && getList._id ? getList._id : ''} className="videoBox">
                      <div className="videoTopBox">
                        <h3>{getList && getList.title ? getList.title : ''}</h3>
                      </div>
                      <div className="videoMiddleBox">
                        <iframe src={getList && getList.video_url ? playVideo(getList.video_url) : ''}
                          width="100%"
                          height="206"
                          title="YouTube video player"
                          frameBorder="0"
                          allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen>
                        </iframe>
                      </div>
                      <div className="videoBottomBox">
                        <p className="videoDescription">{getList && getList.description ? getList.description : ''}</p>
                        <div className="videoDetailBox">
                          <span className="videoDate">Upload At: <i>{new Date(getList && getList.created_at ? getList.created_at : '').toLocaleDateString('en-GB', dateOptions)}</i></span>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
