import React, { useState, useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { FormattedMessage, FormattedDate, FormattedNumber, FormattedPlural, FormattedTime } from "react-intl";
import facebook from "assets/images/face.png";
import google from "assets/images/google.png";
import Alert from "@material-ui/lab/Alert";
import classNames from "classnames";
import "./recruiterlogin.scss";
import { useHistory } from "react-router-dom";
import logoa from "../../../../assets/images/fnlogo.jpeg";
import { Context } from "Languages/Wrapper/Wrapper";
import * as userAuthAction from "redux/actions/AuthActions";
import { useCookies } from "react-cookie";
import { Employesocialbtn } from "components/commoncomponent/socialbtn/Employesocialbtn";
import storage from "utils/storage";
import { regexValidation } from "utils/regexValidation";
import errorMessages from "utils/errorMessages";
import { useIntl } from "react-intl";
import userIcon from "../../../../assets/images/employerIcon.svg" // Employer Icon on Login Screen

export const RecruiterLogin = () => {
  const [passwordShown, setPasswordShown] = useState(false);
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };
  const context = useContext(Context);
  const [localCookies, setCookie] = useCookies(["email", "password"]);
  const [loginForm, setLoginForm] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [isChecked, setIsChecked] = useState(true); // variable use for checkbox is checked or not
  const [userOptIn, setWhatsappStatus] = useState(true); // used to set unset whatsapp checkbox variable
   const { register, handleSubmit, errors } = useForm({
    mode: "onChange",
  });
  const dispatch = useDispatch();
  const history = useHistory();
  const translate = useIntl();

  const changeLang = (lang) => {
    storage.set("lang", lang);
    context.selectLanguage(lang);
  };
  /**
   * method to login/register user using mobile number or email
   * @param {Object} data user login data
   */
  const login = async (data) => {
    try {
      data.user_name = data.user_name.toLowerCase();
      if (regexValidation.PHONE.test(data.user_name)) {
        data.login_type = "contact";
      } else {
        data.login_type = "email";
      }
      storage.set("user_tnc", data.tnc);
      storage.set("user_name", data.user_name);
      storage.set("login_type", data.login_type);
      delete data.tnc;
      let res = await dispatch(userAuthAction.getRecruiterOTP(data))
      if (res.value.success) {
        history.push("recruiter/VerifyOTP");
      }
    }catch(err) {
      if(err.data && err.data.hasOwnProperty('error_code')) {
        if(err.data.error_code === 'ALREADY_REGISTERED')
        setErrorMessage(errorMessages.AUTH_ERROR.ALREADY_REGISTERED_JS);
      }
      setTimeout(()=>{setErrorMessage("")},5000)
      console.log("error in employer login",err);
    }
  };
  // Handling checkbox is checked or not
  const handleOnChange = () => {
    setIsChecked(!isChecked);
  };
  const handleWhatsappCheckStatus =()=> {
    setWhatsappStatus(!userOptIn);
  }
  useEffect(() => {
    changeLang("en") // Setting language to english on loading of recruiter login page
  }, [])
  return (
    <>
      <div>
        <div className="main_login">
          <div className="login_main_inner">
            <div className="row no-gutters">
              <div className="col-md-4 p-0"></div>
              <div className="col-md-4 sha p-3">
                <div className=" login_aa ">
                  <div className="d-flex justify-content-center ">
                    <img src={logoa} width="50%" alt="logo" />
                  </div>
                  <div className="d-flex justify-content-center mt-3">
                    <h2>
                      <strong
                        className={
                          context.locale === "hi" ? "logo_cc" : "logo_bb"
                        }
                        onClick={() => changeLang("hi")}
                      >
                        हिन्दी/
                      </strong>
                      <strong
                        className={context.locale == "en" ? "logo_cc" : "logo_bb"}
                        onClick={() => changeLang("en")}
                      >
                        English
                      </strong>
                    </h2>
                  </div>
                  {/* Start of Employer Icon on Login Screen */}
                  <div className="d-flex justify-content-center my-2">
                      <img height="150px" src={userIcon} />
                  </div>
                  {/* End of Employer Icon on Login Screen */}
                  <div className="mt-1">
                    <div className="pxx ">
                      <div
                        className={
                          history.location.pathname === "/recruiter"
                            ? "btn_a mr-1"
                            : "btn_b mr-1 text-center"
                        }
                        id="bto"
                        onClick={() => history.push("/recruiter")}
                      >
                        <FormattedMessage id="employerText"/>
                      </div>
                    </div>
                  </div>
                  <div className="mt-3">
                    {/* ----------- Commenting Code for future reference [ Login with Social Network ]----------- */}
                    {/* <h2 className="login_ba text-center mt-1"><FormattedMessage
                      id="logIn"
                      defaultMessage="Login"
                    /></h2>
                    <h2 className="login_ba text-center mt-1">
                      {" "}
                      <FormattedMessage
                        id="socialNetwork"
                        defaultMessage="With your social network"
                      />
                    </h2>
                    <Employesocialbtn isChecked={isChecked}/>
                    <div className="text-center mt-1">
                      <p className="fnt">
                        <FormattedMessage id="or" defaultMessage="or" />
                      </p>{" "}
                    </div> */}
                    <form onSubmit={handleSubmit(login)}>
                      <div className="login_details">
                        {/* <label for="exampleInputEmail1" className="form-label mb-1">
                          <FormattedMessage id="emailPhone" />
                        </label> */}
                        <h2 className="login_ba text-center mb-3">
                          <FormattedMessage
                            id="loginEmailPhone"
                            defaultMessage="Login with Your Email/Phone"
                          />
                        </h2>
                        <input
                          type="text"
                          required
                          autocomplete="off"
                          placeholder={translate.formatMessage({id: "enterEmailPhone"})}
                          className={classNames("form-control", {
                            "is-invalid": errors.user_name,
                          })}
                          id="exampleInputEmail1"
                          name="user_name"
                          ref={register({
                            required: translate.formatMessage({id: "required"}),
                            validate: {
                              validation: (value) =>
                                regexValidation.PHONE.test(value) ||
                                regexValidation.EMAIL.test(value) ||
                                translate.formatMessage({id: "enterValidEmail"})
                            },
                          })}
                        />
                        {errors.user_name && (
                          <p className="text-danger ">
                            {errors.user_name.message}
                          </p>
                        )}
                        <div id="emailHelp" className="form-text"></div>
                      </div>
                      <div className="form-check mt-1">
                        <input
                          className="form-check-input mtt"
                          autocomplete="off"
                          type="checkbox"
                          id="gridCheck"
                          checked={isChecked}
                          onChange={handleOnChange}
                          ref={register({
                            required:
                            translate.formatMessage({id: "pleaseTickCheckbox"})
                          })}
                          name="tnc"
                        />
                        <label
                          className="form-check-label "
                          htmlFor="gridCheck  otp_tc"
                        >
                          <p className="  tc p-0 m-0">
                            {" "}
                            <FormattedMessage id="privacyPolicy"
                              defaultMessage="I hereby agree to the T&C and the Privacy Policy"
                              values={{
                                tnc: <a className="text-decoration-none text-underline bbg"
                                  onClick={() => history.push("/terms&conditions")}>
                                  {" "} T&C </a>,
                                privacy: <a className="text-decoration-none  text-underline bbg"
                                  onClick={() => history.push("/privacypolicy")}>
                                  {" "}Privacy Policy{" "}</a>
                              }} />

                          </p>{" "}
                        </label>
                        {errors.tnc && (
                          <p className="text-danger p-0 m-0 tnc_alrt">
                            {errors.tnc.message}
                          </p>
                        )}
                      </div>
                      <div className="form-check mt-1">
                        <input
                          className="form-check-input mtt"
                          autocomplete="off"
                          type="checkbox"
                          id="gridChecks"
                          checked={userOptIn}
                          onChange={handleWhatsappCheckStatus}
                          ref={register()}
                          name="opt_in"
                        />
                        <label
                          className="form-check-label "
                        >
                          <p className="  tc p-0 m-0">
                            <FormattedMessage
                              id="optInMessage"
                              defaultMessage="Connect on WhatsApp for latest updates"
                            />
                          </p>
                        </label>
                      </div> 
                      <div className="mt-3">
                        <button className="common_btn login_btn" type="submit">
                          {" "}
                          <FormattedMessage
                            id="sendOtp"
                            defaultMessage="Send OTP"
                          />{" "}
                        </button>
                      </div>
                    </form>
                    <div className="mt-2">
                      {errorMessage && errorMessage.length > 0 && (
                        <Alert severity="error">{errorMessage} !</Alert>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
