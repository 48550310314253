import React from "react";
import {
  FormattedMessage,
  FormattedDate,
  FormattedNumber,
  FormattedPlural,
  FormattedTime,
} from "react-intl";

import { NavLink } from "react-router-dom";
import "./footer.scss";
import men from "assets/images/men.jpg";
import { Nav } from "react-bootstrap";
import { RiGroupLine } from "react-icons/ri";
import { BsBriefcase, BsBell } from "react-icons/bs";
import dashboard from "assets/images/dashboard.png";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as userActions from "redux/actions/UserActions";
import * as JobActions from "redux/actions/JobActions";
import * as authActions from "redux/actions/AuthActions";
import { CgTemplate } from "react-icons/cg";
import avatar from "../../../assets/images/male_avatar.png";
import { Image } from "components/shared/imagecomponent/image";
import storage from "utils/storage";

export const HeadFooter = (props) => {
  const userRole = storage.get("humlog_user_role"); // get humlog user role
  const location = useLocation();
  const history = useHistory();
  const { userDetails } = useSelector((state) => state.userReducer);
  const { jobList } = useSelector((state) => state.jobReducer);
  return (
    <>
      {location.pathname === `/user/basicdetail` ||
      location.pathname === `/companydetail` ||
      location.pathname === `/user/basicdetail/jobinterested` ? (
        <div></div>
      ) : (
        <header className=" fixed-bottom bottom_navigation">
          <div className="row no-gutters">
            <div className="col-md-4"> </div>
            <div className="col-md-4 main_headfooter py-2 px-2">
              <div className="">
                <Nav className="d-flex justify-content-around">
                  <NavLink
                    to="/dashboard"
                    activeClassName="menu_active"
                    className="nav_aa"
                  >
                    <CgTemplate className="mx-auto d-flex justify-content-center icon_uu" />

                    <p
                      className={
                        location.pathname === "/dashboard"
                          ? "dashboard_menu_one text-center"
                          : "dashboard_menu text-center"
                      }
                      activeClassName="menu_active"
                    >
                      <FormattedMessage
                        id="dashboard"
                        defaultMessage="Dashboard"
                      />
                    </p>
                  </NavLink>

                  <NavLink
                    to="/community"
                    activeClassName="menu_active"
                    className="nav_aa"
                  >
                    <div className="">
                      <RiGroupLine className="mx-auto d-flex justify-content-center icon_uu" />
                      <p className="dashboard_menu text-center">
                        <FormattedMessage
                          id="community"
                          defaultMessage="Community"
                        />
                      </p>
                    </div>
                  </NavLink>

                  <NavLink
                    to="/jobs"
                    activeClassName="menu_active"
                    className="nav_aa"
                  >
                    <BsBriefcase className="mx-auto d-flex justify-content-center icon_uu" />{" "}
                    <p className="dashboard_menu text-center">
                      {
                        userRole === 0 ? <FormattedMessage id="jobs" defaultMessage="Jobs" /> : <FormattedMessage id="jobPost" defaultMessage="jobPost" />
                      }
                    </p>{" "}
                  </NavLink>

                  <NavLink
                    to="/Notification"
                    activeClassName="menu_active"
                    className="nav_aa notification_link"
                  >
                    <BsBell className="mx-auto d-flex justify-content-center icon_uu" />
                    {props.count ? <p className="notification_count">{props.count < 10 ? props.count : "9+"}</p> : ""}
                    <p className="dashboard_menu text-center">
                      {" "}
                      <FormattedMessage
                        id="notification"
                        defaultMessage="Notifications"
                      />
                    </p>
                  </NavLink>

                  <NavLink
                    to="/profile"
                    activeClassName="menu_active"
                    className="nav_aa"
                  >
                    <div style={{height: "18px", width:"18px"}} className="mx-auto d-flex justify-content-center Icon_uu rounded-circle">
                    {userDetails.profile_pic &&
                    userDetails.profile_pic.length > 0 ? (
                      <Image picture={`${userDetails.profile_pic}?${Date.now()}`} alt="Profile Picture"></Image>
                    ): (
                      <Image picture={avatar} alt="Profile Picture"></Image>
                    )}
                    </div>

                    <p className="dashboard_menu text-center">
                      <FormattedMessage id="profile" defaultMessage="Profile" />
                    </p>
                  </NavLink>
                </Nav>
              </div>
            </div>
            <div className="col-md-4"> </div>
          </div>
        </header>
      )}
    </>
  );
};
