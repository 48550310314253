import "./landing.scss";
import "./landingmedia.scss";
import { HeaderV1 } from "../header/headerv1";
import { FooterV1 } from "../footer/footerv1";
import "../../../../node_modules/slick-carousel/slick/slick.css";
import "../../../../node_modules/slick-carousel/slick/slick-theme.css";
import simpleicon from "../../../assets/images/simpleicc.png";
import supporticon from "../../../assets/images/supportcc.png";
import socialicon from "../../../assets/images/socialcc.png";
import wantjob from "../../../assets/images/job_want.png";
import staffjob from "../../../assets/images/staff_want.png";
import electronics from "../../../assets/images/homepage/electronics.png";
import need_jobs from "../../../assets/images/homepage/need_jobs.png";
import young_peoples from "../../../assets/images/homepage/young_peoples.png";
import empower_women from "../../../assets/images/homepage/empower_women.png";
import initiatives from "../../../assets/images/homepage/initiatives.png";
import arrowdown from "../../../assets/images/homepage/arrow-down-blue.svg";
import whatsapp from "../../../assets/images/whatsapp-logo.png";
import { useContext } from "react";
import { Context } from "Languages/Wrapper/Wrapper";
import { Link, useLocation, useHistory } from "react-router-dom";
import React, { useRef, Component, useState, setState } from "react";
import Slider from "react-slick";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { HashLink } from "react-router-hash-link";
import { NavHashLink } from "react-router-hash-link";
import { FormattedMessage, FormattedDate, FormattedNumber, FormattedPlural, FormattedTime,} from "react-intl";
import storage from "utils/storage";
import ChatBox from '../../ChatBot/ChatBox.js'
import { whatsappUrl } from 'config/app.constant';

export const Landingpage = () => {
  const history = useHistory();
  const context = useContext(Context);

  const lang = storage.get('lang');

  /* Slick slider Blog section  */
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  /* material expand faq section  */
  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: "33.33%",
      flexShrink: 0,
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
    },
  }));

  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  /* scroll with offset function */
  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -71;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
  };
  return (
    <>
      <HeaderV1 />
      <section className="landing_main" id="home">
      <a className="tollfree_number" href="tel:18005479500"><FormattedMessage id="tollFreeNumber"/><span>1800-547-9500</span></a>
      <a className="landing_whatsapp_icon" href={whatsappUrl.WHATSAPP_URL}><img src={whatsapp} alt="whatsapp-icon" /></a>
        <div className="container"> 
          <div className="landing_inner">
            <NavHashLink className="scrollbtn" smooth to="/homepage#jobexplorer" scroll={(el) => scrollWithOffset(el)}>
              <img src={arrowdown} alt="arrowdown" className="img-fluid d-block" />
            </NavHashLink>
          </div>
        </div>
      </section>

      <section className="jobexplorer" id="jobexplorer">
        <div className="container">
          <div className="je_inner">
            <h2>
              <FormattedMessage
                id="jobheadline"
                defaultMessage="Humara {worldVal} Portal"
                values={{ worldVal: <span>{lang === 'hi' ? 'जॉब': 'Job'}</span> }}
              />
            </h2>
            <div className="row">
              <div className="col-6">
                <div className="je_lft">
                  <div className="je_content">
                    <img src={wantjob} className="img-fluid d-block img_job" />
                    <div>
                      <h3><FormattedMessage id="jobSeekerText"/></h3>
                      <a className="custombtn custombtn_orange" onClick={() => history.push("/JobSeekerlogin")}>
                        <FormattedMessage
                          id="jobSeeker"
                          defaultMessage="I Want Job"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-6">
                <div className="je_ryt">
                  <div className="je_content marginbtn">
                    <img src={staffjob} className="img-fluid d-block img_job" />
                    <div>
                      <h3><FormattedMessage id="employerText"/></h3>
                      <a className="custombtn" onClick={() => history.push("/recruiter")}>
                        <FormattedMessage
                          id="recruiter"
                          defaultMessage="I need Staff"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="work" id="work">
        <div className="container">
          <div className="work_inner">
            <h2>
              <FormattedMessage
                id="workheadline"
                defaultMessage="How {worldVal} Work"
                values={{ worldVal: <span>{lang === 'en' ? 'We': 'काम'}</span> }}
              />
            </h2>
            <div className="row">
              <div className="col-md-6 d-flex align-items-center">
                <div className="videobox">
                  <iframe
                    width="100%"
                    height="315"
                    src="https://www.youtube.com/embed/SEyKIyAdk8c"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </div>
              </div>
              <div className="col-md-6">
                <div className="wi_content_box">
                  <div className="row">
                    <div className="col-12">
                      <div className="work_content">
                        <img src={simpleicon} className="img_staff workicon" />
                        <h3>
                          <FormattedMessage
                            id="simple"
                            defaultMessage="Simple"
                          />
                        </h3>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="work_content">
                        <img src={socialicon} className="img_staff workicon" />
                        <h3>
                          <FormattedMessage
                            id="social"
                            defaultMessage="Social"
                          />
                        </h3>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="work_content">
                        <img src={supporticon} className="img_staff workicon" />
                        <h3>
                          <FormattedMessage
                            id="support"
                            defaultMessage="Support"
                          />
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="blogs" id="blogs">
        <div className="blogs_overlay"></div>
        <div className="container">
          <div className="blogs_inner">
            <h2><FormattedMessage id="blogs"/></h2>
            <div className="row">
              <div className="col-sm-12">
                <div className="slider">
                  <Slider {...settings}>
                    <div className="slider_inner col-sm-12">
                      <Link to="/blogs/indian-electronics" className="slideLink">
                        <span className="slideLink_hover">Read More</span>
                        <div className="blog_img">
                          <span>
                            <img src={electronics} alt="blog" className="img-fluid d-block" />
                          </span>
                        </div>
                        <div className="b_content">
                          <h3>Indian electronic repair market can create over 5M jobs</h3>
                          <p>
                          The electronics repair industry has the potential of becoming an emerging sector for employment growth in India.
                          </p>
                        </div>
                      </Link>
                    </div>
                    <div className="slider_inner col-sm-12">
                      <Link to="/blogs/new-jobs" className="slideLink">
                        <span className="slideLink_hover">Read More</span>
                        <div className="blog_img">
                          <span>
                            <img src={need_jobs} alt="blog" className="img-fluid d-block" />
                          </span>
                        </div>
                        <div className="b_content">
                          <h3>We Need New Jobs in India</h3>
                          <p>
                          India aspires to make the economy 5 trillion USD in the next few years. We are blessed with a demographic advantage that we must leverage to achieve this goal.
                          </p>
                        </div>
                      </Link>
                    </div>
                    <div className="slider_inner col-sm-12">
                      <Link to="/blogs/ensure-jobs-for-young-people" className="slideLink">
                        <span className="slideLink_hover">Read More</span>
                        <div className="blog_img">
                          <span>
                            <img src={young_peoples} alt="blog" className="img-fluid d-block" />
                          </span>
                        </div>
                        <div className="b_content">
                          <h3>What India Needs to Correct to Ensure Jobs For Young People</h3>
                          <p>
                          India, through the center government, the state governments and the private sector - has spent billions on skills training and development.
                          </p>
                        </div>
                      </Link>
                    </div>
                    <div className="slider_inner col-sm-12">
                      <Link to="/blogs/empower-women" className="slideLink">
                        <span className="slideLink_hover">Read More</span>
                        <div className="blog_img">
                          <span>
                            <img src={empower_women} alt="blog" className="img-fluid d-block" />
                          </span>
                        </div>
                        <div className="b_content">
                          <h3>Empower Women, Boost Growth</h3>
                          <p>
                          On average women do 75 percent of the world’s total unpaid care work, including childcare, caring for the elderly, cooking and cleaning.
                          </p>
                        </div>
                      </Link>
                    </div>
                    <div className="slider_inner col-sm-12">
                      <Link to="/blogs/covid-19-undp-initiative" className="slideLink">
                        <span className="slideLink_hover">Read More</span>
                        <div className="blog_img">
                          <span>
                            <img src={initiatives} alt="blog" className="img-fluid d-block" />
                          </span>
                        </div>
                        <div className="b_content">
                          <h3>Covid-19: UNDP initiative helps those who lost jobs to pandemic find re-employment</h3>
                          <p>
                          The highest salary that people were placed at was Rs 25,000 per month.
                          </p>
                        </div>
                      </Link>
                    </div>
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="faq" id="faq">
        <div className="container">
          <div className="faq_inner">
            <h2>
              <FormattedMessage
                id="faqheadline"
                defaultMessage="Frequently {worldVal} Questions"
                values={{ worldVal: <span>{lang === 'hi' ? 'पूछे जाने वाले': 'Asked'}</span> }}
              />
            </h2>
            <div className="faq_list">
              <div className={classes.root}>
                <Accordion expanded={expanded === "panel1"} onChange={handleChange("panel1")}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel4bh-content" id="panel4bh-header">
                    <Typography className={classes.heading}>
                      <FormattedMessage
                        id="faqQuestionOne"
                        defaultMessage="What is Humlogjobs?"
                      />
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <FormattedMessage
                        id="faqAnswerOne"
                        defaultMessage="Humlogjobs is a simple social platform facilitating employment connect and community engagement for accelerating 'Work-Life Happiness' among existing and aspirants workforce preferably in blue-collar and entry level jobs"
                      />
                    </Typography>
                  </AccordionDetails>
                </Accordion>

                <Accordion expanded={expanded === "panel2"} onChange={handleChange("panel2")}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel4bh-content" id="panel4bh-header">
                    <Typography className={classes.heading}>
                      <FormattedMessage
                        id="faqQuestionTwo"
                        defaultMessage="Who can use Humlogjobs?"
                      />
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <FormattedMessage
                        id="faqAnswerTwo"
                        defaultMessage="On the Employer side broadly all the businesses, looking to hire security guard, technical helpers, office staff, delivery executives and other blue collar staff can post their jobs."
                      />
                    </Typography>
                    <Typography>
                      <FormattedMessage
                        id="faqAnswerParaTwo"
                        defaultMessage="On the Job Seeker side, all those looking for jobs can checkout the jobs option after registering into Humlogjobs."
                      />
                    </Typography>
                  </AccordionDetails>
                </Accordion>

                <Accordion expanded={expanded === "panel3"} onChange={handleChange("panel3")}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel4bh-content" id="panel4bh-header">
                    <Typography className={classes.heading}>
                      <FormattedMessage
                        id="faqQuestionThree"
                        defaultMessage="How Humlogjobs is unique?"
                      />
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <FormattedMessage
                        id="faqAnswerThree"
                        defaultMessage="Each Job Seeker registered, is mobile verified which makes them 100% genuine, and with sarthi helping right person for right job makes Humlogjobs unique."
                      />
                    </Typography>
                  </AccordionDetails>
                </Accordion>

                <Accordion expanded={expanded === "panel4"} onChange={handleChange("panel4")}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel4bh-content" id="panel4bh-header">
                    <Typography className={classes.heading}>
                      <FormattedMessage
                        id="faqQuestionFour"
                        defaultMessage="How can Employers hire best people for their requirement?"
                      />
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <FormattedMessage
                        id="faqAnswerFour"
                        defaultMessage="On Humlogjobs app, numerous profiles are shown suitable to your job post and our technology ensures that only the best fit is able to apply to your job post."
                      />
                    </Typography>
                  </AccordionDetails>
                </Accordion>

                <Accordion expanded={expanded === "panel5"} onChange={handleChange("panel5")}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel4bh-content" id="panel4bh-header">
                    <Typography className={classes.heading}>
                      <FormattedMessage
                        id="faqQuestionFive"
                        defaultMessage="How Job Seekers can check for job openings?"
                      />
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <FormattedMessage
                        id="faqAnswerFive"
                        defaultMessage="Job Seekers can register in simple steps with mobile number followed by the OTP. Post completing basic profile details, multiple job openings matching to interest of Job Seekers will be shown at jobs tab."
                      />
                    </Typography>
                  </AccordionDetails>
                </Accordion>

                <Accordion expanded={expanded === "panel6"} onChange={handleChange("panel6")}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel4bh-content" id="panel4bh-header">
                    <Typography className={classes.heading}>
                      <FormattedMessage
                        id="faqQuestionSix"
                        defaultMessage="How can Job Seekers check the status of the job once applied?"
                      />
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <FormattedMessage
                        id="faqAnswerSix"
                        defaultMessage="Post submitting job applications, Job Seekers can check the status of his jobs from Dashboard tab and can also get details of interview calls, timings, selection & other details."
                      />
                    </Typography>
                  </AccordionDetails>
                </Accordion>

                <Accordion expanded={expanded === "panel7"} onChange={handleChange("panel7")}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel4bh-content" id="panel4bh-header">
                    <Typography className={classes.heading}>
                      <FormattedMessage
                        id="faqQuestionSeven"
                        defaultMessage="How can Job Seekers get job as per his interest?"
                      />
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <FormattedMessage
                        id="faqAnswerSeven"
                        defaultMessage="Job Seekers once registered & completed profile details from My Profile tab with right information, job categories & other details, auto matched job opening & details will be shown at Jobs tab."
                      />
                    </Typography>
                  </AccordionDetails>
                </Accordion>

                <Accordion expanded={expanded === "panel8"} onChange={handleChange("panel8")}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel4bh-content" id="panel4bh-header">
                    <Typography className={classes.heading}>
                      <FormattedMessage
                        id="faqQuestionEight"
                        defaultMessage="How can Job Seekers download their resume?"
                      />
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <FormattedMessage
                        id="faqAnswerEight"
                        defaultMessage="Job Seekers can download resume once they has filled all the mandatory details of My Profile tab."
                      />
                    </Typography>
                  </AccordionDetails>
                </Accordion>

                <Accordion expanded={expanded === "panel9"} onChange={handleChange("panel9")} >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel4bh-content" id="panel4bh-header">
                    <Typography className={classes.heading}>
                      <FormattedMessage
                        id="faqQuestionNine"
                        defaultMessage="Why Job posting is shown not active post successful completion of profile & job posting section?"
                      />
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <FormattedMessage
                        id="faqAnswerNine"
                        defaultMessage="Dear Employer, we understand your concern. Humlogjobs will activate the job post once you have completed one time authentication process. To complete this process please follow the below steps:"
                      />
                    </Typography>
                    <ul className="points">
                      <li>
                        <FormattedMessage
                          id="faqPoints1"
                          defaultMessage="1. Go to the Dashboard"
                        />
                      </li>
                      <li>
                        <FormattedMessage
                          id="faqPoints2"
                          defaultMessage="2. Click on your job post. Upon clicking an authentication window will appear"
                        />
                      </li>
                      <li>
                        <FormattedMessage
                          id="faqPoints3"
                          defaultMessage="3. Fill all the required details"
                        />
                      </li>
                      <li>
                        <FormattedMessage
                          id="faqPoints4"
                          defaultMessage="4. Upload requested documents"
                        />
                      </li>
                    </ul>
                    <Typography>
                      <FormattedMessage
                        id="faqTenPara"
                        defaultMessage="And there you go. Once authentication is done from our end, your job post will be active within 2-4 hours"
                      />
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </section>
      <FooterV1 />
	  <ChatBox />
    </>
  );
};
