import React, { useEffect, useState } from "react";
import axios from "axios";
import { BASE_URL } from "../../config";
import storage from "utils/storage";
import { userType, errorMessage, endPoint } from "config/app.constant";

const Contact = (props) => {
    const [contactNumber, setContactNumber] = useState(props && props.steps.contactNumber ? props.steps.contactNumber.value : '')
    const [selectedUser, setSelectedUser] = useState(props && props.steps.userSelection ? props.steps.userSelection.value : '')
    const [message, setMessage] = useState('')
    const URL = BASE_URL();

    // Function to API call for phone number verification
    const verifyPhone = () => {
        if (selectedUser === userType.JOB_SEEKER) { // for Jobseeker
            const userDetails = { contact: contactNumber };
            axios
                .post(`${URL}${endPoint.JOB_SEEKER_LOGIN}`, userDetails)
                .then((res) => {
                    storage.set("humlog_user_contact", contactNumber);
                    storage.set("user_tnc", true);
                    res.status ? setMessage(res && res.data.message ? res.data.message : errorMessage.SOMETHING_WRONG) : setMessage(errorMessage.SOMETHING_WRONG);
                })
                .catch((error) => {
                    setMessage(errorMessage.ALREADY_REGISTERED);
                });
        } else if (selectedUser === userType.EMPLOYER) { // for Employer
            const userDetails = {
                login_type: "contact",
                user_name: contactNumber,
            };
            axios
                .post(`${URL}${endPoint.EMPLOYER_LOGIN}`, userDetails)
                .then((res) => {
                    delete res.data.tnc;
                    res.status ? setMessage(res && res.data.message ? res.data.message : errorMessage.SOMETHING_WRONG) : setMessage(errorMessage.SOMETHING_WRONG);
                })
                .catch((error) => {
                    setMessage(errorMessage.ALREADY_REGISTERED);
                });
        }
    }

    useEffect(() => {
        verifyPhone();
    }, []);
    return (
        <>{message ? message : errorMessage.SOMETHING_WRONG}</>
    )
}

export default Contact