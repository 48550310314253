export const UserActionTypes = {
    GET_User: "GET_USER",
    GET_USER_PENDING: "GET_USER_PENDING",
    GET_USER_FULFILLED: "GET_USER_FULFILLED",

    GET_ALL_User: "GET_ALL_User",
    GET_ALL_USER_PENDING: "GET_ALL_USER_PENDING",
    GET_ALL_USER_FULFILLED: "GET_ALL_USER_FULFILLED",

    GET_User_CREATED_BY: "GET_User_CREATED_BY",
    GET_USER_CREATED_BY_PENDING: "GET_USER_CREATED_BY_PENDING",
    GET_USER_CREATED_BY_FULFILLED: "GET_USER_CREATED_BY_FULFILLED",


    GET_USER_BY_SARTHI: "GET_USER_BY_SARTHI",
    GET_USER_BY_SARTHI_PENDING: "GET_USER_BY_SARTHI_PENDING",
    GET_USER_BY_SARTHI_FULFILLED: "GET_USER_BY_SARTHI_FULFILLED",

    UPDATE_USER_DATA: "UPDATE_USER_DATA",
    UPDATE_USER_DATA_PENDING: "UPDATE_USER_DATA_PENDING",
    UPDATE_USER_DATA_FULFILLED: "UPDATE_USER_DATA_FULFILLED",

    UPDATE_SOCIAL_USER_DATA: "UPDATE_USER_DATA",
    UPDATE_SOCIAL_USER_DATA_PENDING: "UPDATE_USER_DATA_PENDING",
    UPDATE_SOCIAL_USER_DATA_FULFILLED: "UPDATE_USER_DATA_FULFILLED",


    UPSERT_USER_DATA: "UPSERT_USER_DATA",
    UPSERT_USER_DATA_PENDING: "UPSERT_USER_DATA_PENDING",
    UPSERT_USER_DATA_FULFILLED: "UPSERT_USER_DATA_FULFILLED",

    CONFIRM_OTP: "CONFIRM_OTP",
    CONFIRM_OTP_PENDING: "CONFIRM_OTP_PENDING",
    CONFIRM_OTP_FULFILLED: "CONFIRM_OTP_FULFILLED",

    GET_NOTIFICATIONS_LIST: "GET_NOTIFICATIONS_LIST",
    GET_NOTIFICATIONS_LIST_PENDING: "GET_NOTIFICATIONS_LIST_PENDING",
    GET_NOTIFICATIONS_LIST_FULFILLED: "GET_NOTIFICATIONS_LIST_FULFILLED",

    CREATE_PAYMENT_ORDER: "CREATE_PAYMENT_ORDER",
    VERIFY_PAYMENT: "VERIFY_PAYMENT",
    GET_SUBSCRIPTION_DETAILS: "GET_SUBSCRIPTION_DETAILS",
    GET_SUBSCRIPTION_DETAILS_PENDING: "GET_SUBSCRIPTION_DETAILS_PENDING",
    GET_SUBSCRIPTION_DETAILS_FULFILLED: "GET_SUBSCRIPTION_DETAILS_FULFILLED",

    JOB_VIEW_COUNT: "JOB_VIEW_COUNT",
    JOB_VIEW_COUNT_PENDING: "JOB_VIEW_COUNT_PENDING",
    JOB_VIEW_COUNT_FULFILLED: "JOB_VIEW_COUNT_FULFILLED",

    GET_COMMUNITY_VIDEOS_LIST: "GET_COMMUNITY_VIDEOS_LIST",
    GET_COMMUNITY_VIDEOS_LIST_PENDING: "GET_COMMUNITY_VIDEOS_LIST_PENDING",
    GET_COMMUNITY_VIDEOS_LIST_FULFILLED: "GET_COMMUNITY_VIDEOS_LIST_FULFILLED",

    GET_COMMUNITY_VIDEO_BY_ID: "GET_COMMUNITY_VIDEO_BY_ID",
    GET_COMMUNITY_VIDEO_BY_ID_PENDING: "GET_COMMUNITY_VIDEO_BY_ID_PENDING",
    GET_COMMUNITY_VIDEO_BY_ID_FULFILLED: "GET_COMMUNITY_VIDEO_BY_ID_FULFILLED",

    UPDATE_WHATSAPP_DATA: "UPDATE_WHATSAPP_DATA",
    UPDATE_WHATSAPP_DATA_PENDING: "UPDATE_WHATSAPP_DATA_PENDING",
    UPDATE_WHATSAPP_DATA_FULFILLED: "UPDATE_WHATSAPP_DATA_FULFILLED",

    USER_FILTER: "USER_FILTER",
    USER_FILTER_PENDING: "USER_FILTER_PENDING",
    USER_FILTER_FULFILLED: "USER_FILTER_FULFILLED",
    USER_FILTER_REJECTED: "USER_FILTER_REJECTED"
}
export const HistoryActionTypes = {
    ADD_HISTORY: "ADD_HISTORY"
}