import React, { Component } from "react";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { FaTimes } from "react-icons/fa";

export const Player = (props) => {
    return (
        <>
        <Dialog
        open={props.open}
        onClose={props.toggleModal}
        maxWidth="md"
      >
        <button className="close_btn" onClick={props.toggleModal}><FaTimes/></button>
        <DialogContent style={{padding: "0px"}}>
        <video width="100%" height="100%" style={{display: "block", maxHeight: "Calc(100vh - 64px)"}} controls>
        <source src={props.src} type="video/mp4" />
        <source src={props.src} type="video/ogg" />
        <source src={props.src} type="video/webm" />
        <source src={props.src} type="video/mkv" />
        Your browser does not support the video tag.
        </video>
        </DialogContent>
      </Dialog>
        </>
    )
}