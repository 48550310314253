import "./blogdetails.scss"
import { Link, useHistory } from "react-router-dom";
import { HeaderV1 } from '../header/headerv1';
import { FooterV1 } from '../footer/footerv1';
import need_jobs from "../../../assets/images/homepage/need_jobs.png";
export const NewJobs = (activeblog) => {
const history = useHistory();
  return(
    <>
      <HeaderV1 activeblog = {activeblog}/>
      <section className="blogs_main">
        <div className="container">
          <div className="blogs_main_inner">
            <div className="blogs_heading">
              <div className="blog_heading_img">
                <img src={need_jobs} alt="blog" className="img-fluid d-block" />
              </div>
              <div className="blogs_heading_inner">
                <h3>We Need New Jobs in India </h3>
                <p>India aspires to make the economy 5 trillion USD in the next few years. We are blessed with a demographic advantage that we must leverage to achieve this goal.</p>
                <p>Every year we have more than 10 million young people joining our labor force. If we can find suitable work opportunities for them, we will be on a strong ground to scale new peaks.</p>
              </div>
            </div>
            <div className="blogs_content">
              <p>At the same time, our enterprises have to improve their productivity to stay competitive in the global arena, and hence, they will contribute more to the economy without a proportionate increase in their workforce.</p>
              <ul>
                <li>The agriculture sector could shed 30 million jobs</li>
                <li>Manufacturing, Mining, Construction, Utilities, and Power can add 40 million jobs</li>
                <li>Services can add 50 million jobs</li>
              </ul>
              <p>Our schools and colleges are falling below the expectations of the industry in terms of the skills and knowledge that the young aspirants bring to their table.</p>
              <p>Many thanks to the author <span><a href="https://www.linkedin.com/in/ACoAAAAr9asBOuev15b4WfKEraaqzxLkM8PcGt0" target="_blank">Aditya Mishra</a></span></p>
              <p>Our platform <span><Link to="/homepage">Humlogjobs</Link></span> will contribute to this growth story by connecting not just the SUPPLY and DEMAND for blue-collar workforce but also the SKILLS DEVELOPMENT efforts by public, private and NGO institutions.</p>
              <p><span><a href="https://www.linkedin.com/feed/hashtag/?keywords=indiabusiness&highlightedUpdateUrns=urn%3Ali%3Aactivity%3A6788653635808108544" target="_blank">#humlogjobs #indiabusiness #bluecollarjobs</a></span></p>
            </div>
          </div>
        </div>
      </section>
      <FooterV1 activeblog = {activeblog}/>
    </>
  )
}


