import "./privacy.scss"
import { Link, useHistory } from "react-router-dom";
import { HeaderV1 } from '../../commoncomponent/header/headerv1'
import { FooterV1 } from '../../commoncomponent/footer/footerv1';
// import backgroundimg from  "../../../assets/images/privacyimg.png"
export const PrivacyPage=(hideMenuItems)=>{
const history= useHistory();
  return(
    <>
      <HeaderV1 hideMenuItems={hideMenuItems}/>
      <section className="privacy">
        <div className="container">
          <div className="privacy_inner">
          <div className="privacy_heading">
            <h3>Privacy Policy</h3>
          </div>
          <div className="privacy_content">
            <h3>PERSONAL IDENTIFICATION INFORMATION:</h3>
            <p>We may collect personal identification information from Users in a variety of ways, including, but not limited to, when Users visit our site, register on the site, post requirement, subscribe to the newsletter, respond to a survey, fill out a form, and in connection with other activities, services, features or resources we make available on our Site. Users may be asked for, as appropriate, name, email address, qualification certificate copy, mailing address, phone number, credit and debit card information. Users may, however, visit our Site anonymously. We will collect personal identification information from Users only if they voluntarily submit such information to us. Users can always refuse to supply personally identification information, except that it may prevent them from engaging in certain Site related activities.</p>

            <h3>NON-PERSONAL IDENTIFICATION INFORMATION:</h3>
            <p>We may collect non-personal identification information about Users whenever they interact with our Site. Non-personal identification information may include the browser name, the type of computer and technical information about Users means of connection to our Site, such as the operating system and the Internet service providers utilized including IP address and other similar information.</p>

            <h3>WEB BROWSER COOKIES:</h3>
            <p>Our Site may use "cookies" to enhance User experience. User's web browser places cookies on their hard drive for record-keeping purposes and sometimes to track information about them. User may choose to set their web browser to refuse cookies, or to alert you when cookies are being sent. If they do so, note that some parts of the Site may not function properly.</p>

            <h3>HOW WE USE COLLECTED INFORMATION?</h3>
            <p><span><Link to="/homepage">Humlogjobs.com</Link></span> collects and uses Users personal information for the following purposes:</p>
            <ul>
              <li><span>To personalize user experience:</span> We may use information in the aggregate to understand how our Users as a group use the services and resources provided on our Site</li>
              <li><span>To improve our Site:</span> We continually strive to improve our website offerings based on the information and feedback we receive from you.</li>
              <li><span>To improve customer service:</span> Your information helps us to more effectively respond to your customer service requests and support needs.</li>
              <li><span>To process transactions:</span> We may use the information Users provide about themselves when placing an order only to provide service to that order. We do not share this information with outside parties except to the extent necessary to provide the service by our registered and premium members.</li>
              <li><span>To administer content, promotion, survey or other Site feature:</span> To send Users information they agreed to receive about topics we think will be of interest to them.</li>
              <li><span>To send periodic emails:</span> The email address Users provide for registering with Humlogjobs.com, will only be used to send them information and updates pertaining to their order. It may also be used to respond to their inquiries, and/or other requests or questions. If User decides to opt-in to our mailing list, they will receive emails that may include company news, updates, offers, related product or service information, etc. If at any time the User would like to unsubscribe from receiving future emails, they can submit unsubscribe request. We've included unsubscribe option at the bottom of each email.</li>
            </ul>

            <h3>HOW WE PROTECT YOUR INFORMATION?</h3>
            <p>
            We adopt appropriate data collection, storage and processing practices and security measures to protect against unauthorized access, alteration, disclosure or destruction of your personal information, username, password, transaction information and data stored on our Site. We have physical, electronic, and procedural safeguards that comply with the laws prevalent in India to protect personal information about you. We seek to ensure compliance with the requirements of the Information Technology Act, 2000 as amended and rules made thereunder to ensure the protection and preservation of your privacy.
            </p>
            <h3>UNLESS YOU REQUEST THAT WE DELETE CERTAIN INFORMATION, WE RETAIN THE INFORMATION WE COLLECT FOR AT LEAST 5 YEARS AND MAY RETAIN THE INFORMATION FOR AS LONG AS NEEDED FOR OUR BUSINESS AND LEGAL PURPOSES.</h3>

            <h3>SHARING YOUR PERSONAL INFORMATION</h3>
            <p>We do not sell, trade, or rent personal identification information about you with other persons (save with your consent) or non-affiliated companies except to provide services you have requested, when we have your permission or under the following circumstances.</p>
            <ul>
              <li>We provide the information to trusted partners who work on behalf of or with us under confidentiality agreements. These companies may use your personal information to help us communicate with you about our offers and our marketing partners. However, these companies do not have any independent right to share this information.</li>
              <li>We transfer information about you if we are acquired by or merged with another company. In this event, we will notify you before information about you is transferred and becomes subject to a different privacy policy.</li>
              <li>We may share generic aggregated demographic information not linked to any personal identification information regarding visitors and users with our business partners, trusted affiliates and advertisers for the purposes outlined above.</li>
              <li>We share sensitive personal information to any third party without obtaining the prior consent of the User</li>
              <li>When it is requested or required by law or by any court or governmental agency or authority to disclose, for the purpose of verification of identity, or for the prevention, detection, investigation including cyber incidents, or for prosecution and punishment of offences.</li>
              <li>When we respond to subpoenas, court orders, or legal process, or to establish or exercise our legal rights or defend against legal claims.</li>
              <li>When we believe it is necessary to share information in order to investigate, prevent, or take action regarding illegal activities, suspected fraud, situations involving potential threats to the physical safety of any person, violations of our terms of use, or as otherwise required by law.</li>
            </ul>
            <p>These disclosures are made in good faith and belief that such disclosure is reasonably necessary for enforcing the Privacy Policy and for complying with the applicable laws and regulations.</p>

            <h3>THIRD PARTY WEBSITES</h3>
            <p>Users may find advertising or other content on our Site that link to the sites and services of our partners, institutes, advertisers, sponsors and other third parties. We do not control the content or links that appear on these sites and are not responsible for the practices employed by websites linked to or from our Site. In addition, these sites or services, including their content and links, may be constantly changing. These sites and services may have their own privacy policies and customer service policies. Browsing and interaction on any other website, including websites which have a link to our Site, is subject to that website's own terms and policies.</p>

            <h3>ADVERTISING</h3>
            <p>Advertisements appearing on our site may be delivered to Users by advertising partners, who may set cookies. These cookies allow the ad server to recognize your computer each time they send you an online advertisement to compile non-personal identification information about you or others who use your computer. This information allows ad networks to, among other things, deliver targeted advertisements that they believe will be of most interest to you. This privacy policy does not cover the use of cookies by any advertisers.</p>

            <h3>GOOGLE ADSENSE</h3>
            <p>Some of the ads may be served by Google. Google's use of the DART cookie enables it to serve ads to Users based on their visit to our Site and other sites on the Internet. DART uses "non personally identifiable information" and does NOT track personal information about you, such as your name, email address, physical address, etc. You may opt out of the use of the DART cookie by visiting the Google ad and content network privacy policy at www.google.com/privacy_ads.html</p>

            <h3>CHANGES TO THIS PRIVACY POLICY</h3>
            <p><span><Link to="/homepage">Humlogjobs.com</Link></span> has the discretion to update this privacy policy at any time. We encourage Users to frequently check this page for any changes to stay informed about how we are helping to protect the personal information we collect. You acknowledge and agree that it is your responsibility to review this privacy policy periodically and become aware of modifications.</p>

            <h3>YOUR ACCEPTANCE OF THESE TERMS</h3>
            <p>By using this Site, you signify your acceptance of this policy. If you do not agree to this policy, please do not use our Site. Your continued use of the Site following the posting of changes to this policy will be deemed your acceptance of those changes.</p>

            <h3>CHANGING YOUR INFORMATION OR CLOSING YOUR ACCOUNT</h3>
            <p>
            Upon request <span><Link to="/homepage">Humlogjobs.com</Link></span> will provide you with information about whether we hold any of your personal information. You are responsible for maintaining the accuracy of the information you submit to us, such as your contact information. You may access, correct, or request deletion of your personal information by making updates to that information or by contacting us through your online account. If you request to access all personal information you’ve submitted, we will respond to your request to access within 30 days. If you completely delete all such information, then your account may become deactivated. If your account is deactivated or you ask to close your account, you will no longer be able to use the Service. If you would like us to delete your account in our system, you can do so through the your account <span>(once you logged in, visit settings/ user settings, and then click on the close my account link)</span>.
            </p>
            <p>
            We will use commercially reasonable efforts to honor your request; however, certain information will actively persist on the Service even if you close your account, including information in your Work Diaries and messages you posted to the Service. In addition, your personal information may remain in our archives and information you update or delete, or information within a closed account, may persist internally or for our administrative purposes. It is not always possible to completely remove or delete information from our databases. In addition, we typically will not remove information you posted publicly through or on the Service. Bear in mind that neither you nor we can delete all copies of information that has been previously shared with others on the Service. <span>CHOICE/OPT OUTS</span>
            </p>
            <p>
            We give you the choice regarding the collection and usage of your personally identifiable information. During registration for “joining our mailing list,” we request for contact information in order to send bulletins and for advertising purposes. You may therefore choose to opt out of providing such information.
            </p>
            <p>
            Further, once you are registered with us, you will have the option at any stage to inform us that you no longer wish to receive future e-mails and you may “unsubscribe” by contacting on unsubscribe link. Further, as per Rule 5(7) of the Information Technology (Reasonable security practices and procedures and sensitive personal data or information) Rules, 2011 of Information Technology Act, 2000 amended through Information Technology Amendment Act, 2008, you have an option to withdraw your consent for use of your sensitive personal data given earlier to us. Such withdrawal of consent shall be sent in writing to our registered address.
            </p>

            <h3>REASONABLE SECURITY PRACTICES AS PER INFORMATION TECHNOLOGY ACT, 2000 AND ITS RULES</h3>
            <p>
            We have implemented reasonable security practices as per Rule 8 of the Information Technology (Reasonable security practices and procedures and sensitive personal data or information) Rules, 2011 of Information Technology Act, 2000 amended through Information Technology Amendment Act, 2008. Access to your personal account online is password protected. We will not release your account password to any person. In the event that you forget your password, you may generate an on-line request for your password to be sent to you by e-mail at the e-mail address used during registration.
            </p>
            <p>
            We have implemented stringent, internationally acceptable standards of technology, managerial security, technical security, operational security and physical security in order to protect personally identifiable information from loss, misuse, disclosure, alteration or destruction. The data resides behind a firewall, with access restricted to our authorized personnel. We have implemented “Reasonable Security Practices” as required by the Information Technology Act, 2000 rules including any amendment in the said Act and its rules. By complying with such provisions, we assure you proper care and control over our Information Technology and Security operations under sections 43, 43A, 45, 66,72A & 85 of I.T. Act, 2000 and I.T.A.A, 2008 including related rules and therefore you agree that we shall not be held responsible for any activity in your account which results from your failure to keep your own password/mobile secured.
            </p>
            <p>
            By using this service you agree that we shall not be held responsible for any uncontrollable security attacks and in such cases you agree that we shall not be held responsible for any type of financial losses, loss of opportunity, legal cost, business losses, reputation loss, direct and indirect losses that may occur to you as per the Provisions of Section 43, 43A and 45 of Information Technology Act, 2000 including any amendments in the said Act and any other laws of India for the time being in force. You further agree that our management shall not be held responsible directly or indirectly for any cybercrime related criminal liabilities under I.T. Act, 2000 relating to your information as you have agreed and acknowledged that our management complies with due diligence (care & controls) requirements of I.T. Act, 2000 including its rules and amendments.
            </p>
            <p>
            GoDaddy provides complete list of information security controls of server on which our service is hosted. Further, you also agree and acknowledge that our management shall never be held responsible regarding privacy of your sensitive personal data in case of sharing your sensitive personal data to any authorized cyber investigation agency of appropriate government authorities under sections 67C, 69, 69A, 69B, 70B, 79 and 80 of I.T.Act,2000 including its amendments and rules.
            </p>

            <h3>GRIEVANCE REDRESSAL</h3>
            <p>
            If you have any questions or grievances regarding the privacy statement, practices of the site, or any other transaction issue, please contact our grievance officer on [insert details]. We have appointed our grievance officer as per Rule 5 (9) of the Information Technology of Information Technology Act, 2000 amended through Information Technology Amendment Act, 2008. Any grievance or complaint, in relation to processing of information, should be sent to us in writing with a thorough description, to following contact email ID. Grievance shall be redressed as expeditiously as possible and you can contact him on below details
            </p>
            <p>We request you to please provide the following information in your complaint:</p>
            <ul>
              <li>Identification of the information provided by you</li>
              <li>Clear statement as to whether the information is personal information or sensitive personal information</li>
              <li>Your address, telephone number or e-mail address.</li>
              <li>A statement that you have a good-faith belief that the information has been processed incorrectly or disclosed without authorization, as the case may be.</li>
              <li>A statement, under penalty of perjury, that the information in the notice is accurate, and that the information being complained about belongs to you.</li>
            </ul>

            <h3>CONTACTING US</h3>
            <p>
            If you have any questions about this Privacy Policy, the practices of this site, or your dealings with this site, please contact us at: <span><a href="mailto:info@humlogjobs.com">info@humlogjobs.com</a></span>
            </p>

            <h3>SECURITY</h3>
            <p>
            <span><Link to="/homepage">Humlogjobs.com</Link></span> takes industry standard protocols and technology to protect registered user information. <span><Link to="/homepage">Humlogjobs.com</Link></span> also protects registered user information offline. All registered user information is restricted within our offices. Servers used to store personally identifiable information are housed in a secure, supervised environment. In addition, only our employees who need specific information to perform a task are granted access to personally identifiable information. <span><Link to="/homepage">Humlogjobs.com</Link></span> takes commercially reasonable steps to help protect and secure the information it collects and stores about our Users.
            </p>
            <p>
            All access to the Site is encrypted using industry-standard transport layer security technology (TLS). When you enter sensitive information (such as tax identification number), we encrypt the transmission of that information using secure socket layer technology (SSL). We also use HTTP strict transport security to add an additional layer of protection for our Users. But remember that no method of transmission over the Internet, or method of electronic storage, is 100% secure. Thus, while we strive to protect your personal data, we cannot ensure and do not warrant the security of any information you transmit to us. We maintain physical, electronic and procedural safeguards in connection with the collection, storage and disclosure of personal information (including sensitive personal information). Our security procedures mean that we may occasionally request proof of identity before we disclose personal information to you. It is important for you to protect against unauthorised access to your password and to your computer. Be sure to sign off when you finish using a shared computer. Click here for more information on how to sign off.
            </p>
          </div>
          </div>
        </div>
      </section>
      <FooterV1 />
    </>
  )
}


