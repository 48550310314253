import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import "./../jobmatch/jobmatch.scss";
import { VscBriefcase, VscBell } from "react-icons/vsc";
import { IoIosCloseCircle } from "react-icons/io";
import { HiOutlineStar } from "react-icons/hi";
import { BsCheck } from "react-icons/bs";
import * as JobActions from "redux/actions/JobActions";
import * as UserActions from "redux/actions/UserActions";
import * as AuthActions from "redux/actions/AuthActions"
import { useDispatch, useSelector } from "react-redux";
import genderi from "../../../assets/images/genderi.png";
import pencili from "../../../assets/images/pencili.png";
import sallaryi from "../../../assets/images/salary.png";
import storage from "utils/storage";
import dummylogo from "../../../assets/images/dummylogo.png";
import { FaRegFileAudio, FaRegFileVideo } from "react-icons/fa";
import { Image } from "components/shared/imagecomponent/image";
import { Player } from "components/shared/videoplayer/player";
import { HeaderV1 } from "../../commoncomponent/header/headerv1";
import { FooterV1 } from "../../commoncomponent/footer/footerv1";

export const Job = () => {
  const [loaded, setLoaded] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const [jobIndex, setJobIndex] = useState(0);
  const { jobDetails } = useSelector((state) => state.jobReducer);
  const { sharedJobDetails } = useSelector((state) => state.jobReducer);
  const { userDetails } = useSelector((state) => state.userReducer);
  const [modal, setModal] = useState({ open: false, src: "" });
  const [isViewDes, setViewDes] = useState(false);
  const [jobData, setJobData] = useState({});
  const token = storage.get("humlog_authToken");
  let query = new URLSearchParams(useLocation().search);
  let jobId = query.get("id");
  const changeview = () => {
    setViewDes((prev) => !prev);
  };
  const val = token ? jobDetails : sharedJobDetails;
  /* method to open and close player */
  const onToggleModal = (src) => {
    setModal({ open: !modal.open, src: src });
  };
  /**
   * method to change the status of the job
   * @param {Array} job
   * @param {String} status status of job [Applied, Saved, Visited]
   */
  const changeJobStatus = async (job, status) => {
    try{
      if(!token) {
        history.push({pathname : '/JobSeekerlogin', state : history.location});
        return;
      }
      if(storage.get('humlog_user_role')) {
        await dispatch(AuthActions.logout);
        window.location.href = `${window.location.origin}/JobSeekerlogin`;
        return;
      }
      const selectedJob = {
        company_logo: job.company_logo,
        company_name: job.company_name,
        job_id: job._id,
        job_title: job.job_title,
        salary_from: job.salary_from,
        salary_to: job.salary_to,
        job_location: job.job_location,
        category: job.category,
        jobStatus: job.status,
        openings: job.openings,
        skills: job.skills,
        job_experience: job.experience,
        job_timings: job.job_timings,
        job_type: job.job_type,
        description: job.description,
        animated_description: job.animated_description,
        status: status,
      };
      let data = {
        _id: userDetails._id,
        jobs: selectedJob,
      };
      const res = await dispatch(UserActions.updateUserData(data));
      if (res.value.success) {
        history.push('/dashboard');
      }
    }catch(err){
      console.log(err);
    }
  };
  /**
   * method to fetch details of job by its job_id
   * @param {String} jobId job id of job searched by user
   */
  const fetchJobDetails = async (jobId) => {
    try {
      let job_id = "";
      const sharedJobResponse = await dispatch(
        JobActions.getSharedJobDetailsById(jobId)
      );
      if (sharedJobResponse && sharedJobResponse.value.success) {
        job_id = sharedJobResponse.value.data._id;
      }else {
        return Promise.reject(sharedJobResponse); 
      }
      if (token) {
        await dispatch(JobActions.getJobDetailsById(job_id));
      }
    } catch (e) {
      console.log("Error in fetching job details,", e);
    }
  };

  useEffect(() => {
    if (!loaded) {
      setLoaded(true);
      fetchJobDetails(jobId);
    }
  }, [loaded, dispatch]);

  // when both salary value is null (Max and Min) then shown Disclosed
  const _handleSalary = (salary_from, salary_to) => {
    if (salary_from && salary_to) {
      return (
        <h6 className="ca">
          ₹ {salary_from}  - ₹ {salary_to} /month
        </h6>
      )
    } else if (salary_from && !salary_to) {
      return (
        <h6 className="ca">
          <FormattedMessage id="moreThan" defaultMessage="More Than" values={{more : salary_from}}/> /month
        </h6>
      )
    } else if (salary_to && !salary_from) {
      return (
        <h6 className="ca">
          <FormattedMessage id="upTo" defaultMessage="Upto" values={{upto : salary_to}}/> /month
        </h6>
      )

    } else  {
      return (
        <h6 className="ca">
          <FormattedMessage id="disClosed" defaultMessage="Disclosed"/>
        </h6>
      )
    }
  }
  return (
    <>
      {!token && <HeaderV1 />}
      <div className={!token ? "public_job_share" : ""}>
        <div className="main_job main_job_container job_share">
          <button type="button" className="viewdetails" onClick={changeview}>
            View <span>{isViewDes ? "Less" : "More"}</span>
          </button>
          <Player
            open={modal.open}
            toggleModal={onToggleModal}
            src={modal.src}
          />
          <div className="row p-0 m-0 no-gutters">
            <div className="col">
              {" "}
              <div className="">
                <div className=" pt-2 ">
                  <div className="d-flex justify-content-around">
                    <button
                      className="audio-video_btn"
                      onClick={() => {
                        onToggleModal(
                          val && val.audio_description
                            ? val.audio_description
                            : ""
                        );
                      }}
                      disabled={
                        val &&
                        val.audio_description &&
                        val.audio_description.length === 0
                          ? true
                          : false
                      }
                    >
                      <FaRegFileAudio />{" "}
                    </button>
                    {val && val.company_logo && val.company_logo.length > 0 ? (
                      <div style={{ width: "100px", height: "100px" }}>
                        <Image
                          picture={`${val.company_logo}?${Date.now()}`}
                          alt="user_profile"
                        ></Image>
                      </div>
                    ) : (
                      <div style={{ width: "100px", height: "100px" }}>
                        <Image picture={dummylogo} alt="user_profile"></Image>
                      </div>
                    )}
                    <button
                      className="audio-video_btn"
                      onClick={() => {
                        onToggleModal(
                          val && val.animated_description
                            ? val.animated_description
                            : ""
                        );
                      }}
                      disabled={
                        val &&
                        val.audio_description &&
                        val.animated_description.length === 0
                          ? true
                          : false
                      }
                    >
                      <FaRegFileVideo />{" "}
                    </button>
                  </div>
                  <div className="d-flex justify-content-center">
                    <div>
                      <h4 className="Job_ta mt-2 text-capitalize text-center">
                        {val && val.job_title ? val.job_title : ''}
                      </h4>
                      <h4 className="Job_ttb mt-1 text-capitalize text-center">
                        {val && val.company_name ? val.company_name : ''}
                      </h4>
                      <h4 className="Job_ttb mt-1 text-capitalize text-center job_Location">
                        {val.district ? val.district : ""}
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row p-0 m-0 no-gutters">
              <div className="col-7 ">
                {" "}
                <div className="jobdetailsfield">
                  <div className="d-flex mt-2">
                    <img
                      src={sallaryi}
                      className="_job_ua"
                      width="16px"
                      height="16px"
                    />
                    <p className="_job_ub ml-1">
                      {" "}
                      <FormattedMessage id="salary" defaultMessage="Salary" />
                    </p>
                  </div>
                  {/* salary-section */}
                  {_handleSalary(val.salary_from, val.salary_to)}
                </div>
                <div className="jobdetailsfield">
                  <div className="d-flex mt-2">
                    <VscBriefcase className="_job_ua" />
                    <p className="_job_ub ml-1">
                      {" "}
                      <FormattedMessage
                        id="experienceRequired"
                        defaultMessage="Experience Required"
                      />
                    </p>
                  </div>
                  <h6 className="ch">
                    {" "}
                    {val && val.experience === 0
                      ? "Fresher"
                      : val && val.experience === 1
                      ? "Less than 1 year"
                      : val && val.experience === 2
                      ? "1-2 years"
                      : val && val.experience === 3
                      ? "2-4 years"
                      : val && val.experience === 4
                      ? "4-6 years"
                      : val && val.experience === 5
                      ? "6-8 years"
                      : val && val.experience === 6
                      ? "8-10 years"
                      : val && val.experience === 7
                      ? "10+ years"
                      : ""}
                  </h6>
                </div>
                <div className="jobdetailsfield">
                  <div className="d-flex mt-2">
                    <VscBell className="_job_ua" />
                    <p className="_job_ub ml-1">
                      {" "}
                      <FormattedMessage
                        id="jobTimings"
                        defaultMessage="Job Timings"
                      />
                    </p>
                  </div>
                  <h6 className="ch">
                    {" "}
                    {val && val.job_timings === 0
                      ? "General"
                      : val && val.job_timings === 1
                      ? "Rotation"
                      : ""}
                  </h6>
                </div>
              </div>

              <div className="col-5 ">
                {" "}
                <div className="jobdetailsfield">
                  <div className="d-flex mt-2">
                    <img src={genderi} className="_job_ua" />
                    <p className="_job_ub ml-1">
                      {" "}
                      <FormattedMessage id="gender" defaultMessage="Gender" />
                    </p>
                  </div>
                  <h6 className="ch">
                    {" "}
                    {val && val.gender === 0
                      ? "All"
                      : val && val.gender === 1
                      ? "Female"
                      : val && val.gender === 2
                      ? "Male"
                      : val && val.gender === 3
                      ? "Others"
                      : ""}
                  </h6>
                </div>
                <div className="jobdetailsfield">
                  <div className="d-flex mt-2 ">
                    <img src={pencili} className="_job_ua" />
                    <p className="_job_ub ml-1">
                      <FormattedMessage
                        id="skillRequired"
                        defaultMessage="Skill Required"
                      />
                    </p>
                  </div>
                  <h6 className="ch skiils_width">{val.skills} </h6>{" "}
                </div>
                <div className="jobdetailsfield">
                  <div className="d-flex mt-2 ">
                    <VscBriefcase className="_job_ua" />
                    <p className="_job_ub ml-1">
                      {" "}
                      <FormattedMessage
                        id="jobType"
                        defaultMessage="Job Type"
                      />
                    </p>
                  </div>
                  <h6 className="ch">
                    {" "}
                    {val && val.job_type === 0
                      ? "Office"
                      : val && val.job_type === 1
                      ? "Field"
                      : val && val.job_type === 2
                      ? "Shift"
                      : val && val.job_type === 3
                      ? "Part Time"
                      : ""}
                  </h6>
                </div>
              </div>
            </div>
          </div>
          {isViewDes ? (
            <div className="container">
              <div className="row p-0 m-0 no-gutters">
                {/* job-location-section start from here */}
                <div className="col-12 d-flex">
                  <div className="jobdetailsfield">
                    <div className="mt-2">
                      <p className="_job_ub">
                        <FormattedMessage id="jobLocation" defaultMessage="Job Location" />
                      </p>
                    </div>
                    <h6 className="ch jd job-details-text">
                      {val.job_location ? val.job_location : ""}
                    </h6>
                  </div>
                </div>
                {/* job-location-section end */}
                <div className="col-12 d-flex">
                  <div className="d-flex mt-2">
                    <p className="_job_ub">
                      {" "}
                      <FormattedMessage
                        id="jobDescription"
                        defaultMessage="Job Description"
                      />
                    </p>
                  </div>
                </div>
                <div className="col-12 d-flex">
                  <h6 className="ch jd">
                    {" "}
                    {val && val.description ? val.description : ""}
                  </h6>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}

          <div className="container">
            <div className="row p-0 m-0 no-gutters">
              <div className="col-12  mt-3  d-flex justify-content-around">
                <button
                  className="btn_va  shadow"
                  onClick={() => changeJobStatus(val, "Visited")}
                >
                  <IoIosCloseCircle className="cross_icon" />
                  <FormattedMessage id="notApply" defaultMessage="Not Apply" />
                </button>
                <button
                  className="btn_vb d-flex shadow"
                  onClick={() => changeJobStatus(val, "Saved")}
                >
                  <HiOutlineStar className="box_ia job_pa" />
                  <div className="jobResponse">
                    <FormattedMessage
                      id="applyLater"
                      defaultMessage="Apply Later"
                    />
                  </div>
                </button>
                <button
                  className="btn_vc d-flex shadow"
                  onClick={() => changeJobStatus(val, "Applied")}
                >
                  <BsCheck className="box_ib job_pb " />
                  <div className="jobResponse">
                    <FormattedMessage
                      id="applyNow"
                      defaultMessage="Apply Now"
                    />
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!token && <FooterV1 />}
    </>
  );
};
