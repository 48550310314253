import React from 'react';
import storage from "utils/storage";
import { getUserToken } from '../../utils/CommonService';
export const User = () => {
  const fetchToken = getUserToken(); // getting source from where user comes
  setTimeout(() => {
    if (storage.get("humlog_authToken")) {
      window.location.reload();
    }
  }, 100);

  return (
    <div></div>
  );
}

