import React, { useState } from "react";
import { Form } from "react-bootstrap";
import "./updateEmail.scss";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { regexValidation } from "utils/regexValidation";
import classNames from "classnames";
import storage from "utils/storage";
import * as userActions from "redux/actions/UserActions";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import Alert from "@material-ui/lab/Alert";
export const UpdateEmail = () => {
  const { userDetails } = useSelector((state) => state.userReducer);
  const dispatch = useDispatch();
  const history = useHistory();
  const translate = useIntl();
  const [successToast, setSuccessToast] = useState(false);
  const { register, handleSubmit, errors, reset } = useForm({
    mode: "onBlur",
  });
  /**
   * @description method to update email address
   * @param {Object} val new email address
   */
  const updateEmailAddress = (val) => {
    try {
      let data = {
        _id: storage.get("humlog_user"),
        update_email_request: "Open",
        new_email: val.newEmail,
      };
      dispatch(userActions.updateUserData(data))
        .then((res) => {
          if (res.value.success) {
            setSuccessToast(true); // show Toast
            setTimeout(() => {
              reset(); // reset Input value
              history.push("/profile"); // if response is true when redirect to Profile page
            }, 2000);
          }
        })
        .catch((err) => console.log(err));
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <>
      {/* success-toast-section start from here */}
      {successToast && (
        <Alert severity="success" className="alert-toast">
          <FormattedMessage id="emailChangeToastSuccesfully" />
        </Alert>
      )}
      {/* Update-email-address-section start from here */}
      <div className="main-container">
        <div class="container">
          <div className="inner-container">
            {/* form-section start from here */}
            <Form onSubmit={handleSubmit(updateEmailAddress)}>
              {/* old-email-section start from here */}
              <Form.Group className="mt-3">
                <Form.Label className="lbl_a row_padding_bottom">
                  <h2 className="prnl_head_aa">
                    <FormattedMessage
                      id="enterOldEmail"
                      defaultMessage="enterOldEmail"
                    />
                  </h2>
                </Form.Label>
                <Form.Control
                  type="email"
                  require
                  autocomplete="off"
                  placeholder={translate.formatMessage({ id: "emailAdd" })}
                  name="email"
                  disabled
                  defaultValue={userDetails.email}
                />
              </Form.Group>
              {/* old-email-section end */}
              {/* new-email-section start from here */}
              <Form.Group className="mt-3">
                <Form.Label className="lbl_a row_padding_bottom">
                  <h2 className="prnl_head_aa">
                    <FormattedMessage
                      id="enterNewEmail"
                      defaultMessage="enterNewEmail"
                    />
                  </h2>
                </Form.Label>
                <Form.Control
                  type="email"
                  require
                  autocomplete="off"
                  placeholder={translate.formatMessage({ id: "emailAdd" })}
                  name="newEmail"
                  className={classNames("form-control", {
                    "is-invalid": errors.newEmail,
                  })}
                  ref={register({
                    required: translate.formatMessage({
                      id: "enterEmailAddress",
                    }),
                    pattern: {
                      value: regexValidation.EMAIL,
                      message: translate.formatMessage({ id: "emailValid" }),
                    },
                  })}
                />
                {errors.newEmail && (
                  <p className="text-danger txt">{errors.newEmail.message}</p>
                )}
              </Form.Group>
              {/* new-email-section end */}
              {/* update-button-section start from here */}
              <div className="py-2 px-2 mb-5">
                <button className="common_btn">
                  <FormattedMessage id="update" defaultMessage="Update" />
                </button>
              </div>
              {/* update-button-section end */}
            </Form>
            {/* form-section end */}
          </div>
        </div>
      </div>
      {/* Update-email-address-section start from here */}
    </>
  );
};
