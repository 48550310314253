import axios from "axios";
import * as API from "api";
import storage from "utils/storage";
import * as commonService from "utils/CommonService";
const preUploadData = (file, file_category) => {
  // remove spaces from name
  let name = file.name.replace(/\s/g, '');
  // form pre upload data
  const uploadData = {
          data : {file_name : name,
              content_type : file.type,
              file_size : file.size,
              file_category: file_category
          },
          userId : storage.get("humlog_user")
      };
  return uploadData;
}
const uploadFileOnS3 = async (data, file) => {
  commonService.isLoading.onNext(true);
  // form data for s3 upload
  let formData = new FormData();
  formData.append('key', data.fields.key);
  formData.append('acl', data.file_acl);
  formData.append('X-Amz-Credential', data.fields['X-Amz-Credential']);
  formData.append('X-Amz-Algorithm', data.fields['X-Amz-Algorithm']);
  formData.append('X-Amz-Date', data.fields['X-Amz-Date']);
  formData.append('Policy', data.fields.Policy);
  formData.append('X-Amz-Signature', data.fields['X-Amz-Signature']);
  formData.append('Content-Type', data.fields['Content-Type']);
  formData.append('file', file);
  // post data to s3 bucket
  await axios.post(data.url, formData)
    .then( (res) => {
      commonService.isLoading.onNext(false);
      return res;
    })
    .catch ( (err) => {
      commonService.isLoading.onNext(false);
      return Promise.reject(err);
    })
}
export const uploadFile = (file,file_category,file_type) => {
  // get pre upload data
  let imageUrl = "";
  let userId = "";
  return Promise.resolve()
  .then (()=> {
    const uploadData = preUploadData(file,file_category);
    userId = uploadData.userId;
    return API.preUpload(uploadData.data, userId);
  })
  // post pre upload api here
  .then( (res) => {
    if(res.success) {
      const url = new URL(res.data.url).hostname;
      if(file_type === "private"){
        imageUrl = res.data.file_id;
      } else {
        imageUrl = `https://${res.data.fields.bucket}.${url}/${res.data.fields.key}`;
      }
      return res.data;
    }
  })
  .then ( async (data) => {
    // upload file on s3
    await uploadFileOnS3(data, file);
    return data.file_id;
  })
  .then ( (fileId) => {
    // acknowledge upload file
    return API.postUpload({ file_id : fileId },userId);
  })
  .then ( (data) => {
    return imageUrl;
  })
  .catch( (err) => {
    commonService.isLoading.onNext(false);
    console.log("error in pre upload", err);
    return Promise.reject(err);
  })
}