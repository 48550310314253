import "./aboutpage.scss";
import vission from "../../../assets/images/about/vission.png";
import mission from "../../../assets/images/about/mission.png";
import { HeaderV1 } from '../header/headerv1';
import { FooterV1 } from '../footer/footerv1';
import { useContext } from 'react';
import { useHistory } from "react-router-dom";
import { Context } from "Languages/Wrapper/Wrapper";
import React, { useRef, Component, useState, useEffect } from 'react';
import $ from 'jquery';
import { FormattedMessage, FormattedDate, FormattedNumber, FormattedPlural, FormattedTime, } from "react-intl";

export const Aboutpage = () => {
  const history = useHistory();
  const context = useContext(Context);

  /* Mission Section move from below to up at screen 575 */
  $(window).resize(function () {
    changeDiv();
  });
  useEffect(() => {
    changeDiv();
  });
  function changeDiv() {
    if ($(window).width() <= 575) {
      $('.before').insertBefore($('.after'));
    }
    else {
      $('.before').insertBefore($('.myContent'));
    }
  }

  return (
    <>
      <HeaderV1 />
      <section className="aboutus">
        <div className="container">
          <div className="aboutus_inner">
            <div className="row">
              <div className="col-sm-12">
                <div className="aboutus_banner_content">
                  <p className="about-text"><FormattedMessage id="aboutText"/> <span><FormattedMessage id="us"/></span></p>
                  <p className="about-tag">
                    <FormattedMessage
                      id="aboutTagLine"
                      defaultMessage="Your Sarthi in Journey of Job Success & Worklife Happiness"
                    />
                  </p>
                  <p>
                    <FormattedMessage
                      id="aboutPara"
                      defaultMessage="Humlogjobs is providing simple & social digital platform blended with supportive physical ecosystem for facilitating employment connect to jobseekers preferably for blue collar & entry level workforce. We are majorly focusing on identifying and connecting new workforce supply from both rural, urban and non-urban areas. Our unique value proposition is handholding to both job seekers & employers at each stage of job search to joining and from hiring requirement to onboarding of workforce differentiate among all existings channels in the market place."
                    />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="vision_mission">
        <div className="container">
          <div className="vm_inner">
            <div className="row">
              <div className="col-sm-6">
                <div className="vm_img">
                  <img src={vission} alt="" className="img-fluid d-block" />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="vm_content">
                  <h3><FormattedMessage id="our"/> <span><FormattedMessage id="vision"/></span></h3>
                  <p>
                    <FormattedMessage
                      id="aboutVision"
                      defaultMessage="To connect people & businesses on their journey of economic growth & work life happiness"
                    />
                  </p>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="vm_content">
                <div class="after"></div>
                  <h3><FormattedMessage id="our"/><span> <FormattedMessage id="mission"/></span></h3>
                  <p>
                    <FormattedMessage
                      id="aboutMission"
                      defaultMessage="To provide simple, social & supportive channel for employment connect to job seekers & employers"
                    />
                  </p>
                </div>
              </div>
              <div className="col-sm-6">
                <div class="myContent"></div>
                <div className="vm_img before">
                  <img src={mission} alt="" className="img-fluid d-block" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <FooterV1 />
    </>
  );
};
