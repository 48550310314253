import React, {  useState, useContext, useEffect } from "react";
import {FormattedMessage, FormattedDate, FormattedNumber, FormattedPlural, FormattedTime} from 'react-intl';
import { useForm } from "react-hook-form";
import logo from "../../../../assets/images/fnlogo.jpeg";
import { useDispatch, useSelector } from "react-redux";
import "../otp/otp/otp.scss";
import * as commonService from "utils/CommonService.js";
import storage from "utils/storage";
import classNames from "classnames";
import Alert from "@material-ui/lab/Alert";
import { BsPencil } from "react-icons/bs";
import * as userActions from "redux/actions/UserActions";
import * as authActions from "redux/actions/AuthActions";
import { useIntl } from "react-intl";
// import { getToken } from "../../../../firebase";
import { getSource } from '../../../../utils/CommonService';

const RecruiterVerifyOTP = ({ history }) => {
  const [loaded, setLoaded] = useState(false);
  const [counter, setCounter] = useState(60); // timeout for OTP to 60 seconds
  const userName = storage.get("user_name");
  const dispatch = useDispatch();
  const translate = useIntl();
  const [errorMessage, setErrorMessage] = useState("");
  const { userDetails } = useSelector((state) => state.authReducer);
  const { register, handleSubmit, errors } = useForm({
    mode: "onChange",
  });

  const update = async (val) => {
    try {
      // commonService.isLoading.onNext(true); // show loader
      const loginSource = getSource(); // getting source from where user comes
      // const firebaseToken = await getToken();
      let user_name = storage.get("user_name");
      const login_type = storage.get("login_type");
      if (login_type === "contact") {
        user_name = JSON.stringify(storage.get("user_name"));
      }
      let data = { 
        ...val,
        login_type: login_type,
        user_name: user_name,
        lead_source: loginSource
      };
      // if (firebaseToken) {
      //   data.firebase_token = firebaseToken;
      // }
      dispatch(authActions.verifyOTP(data))
        .then((res) => {
          if (res.value.success) {
            // set value of profile status in storage
            storage.set("profile_status", res.value.data.profile_status);
            if (res.value.data.profile_status) {
              history.push("/dashboard");
            } else {
              history.push("/companydetail");
            }
            commonService.forSuccess("res.value.message", "Success");
          }
        })
        .catch((err) => setErrorMessage(err.data.message));
    } catch (err) {
      console.log("otp error,", err);
    }
  };

  useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);

  const resendOtp = () => {
    let data = { user_name: storage.get("user_name"),
                 login_type: storage.get("login_type")
                };
    dispatch(authActions.getRecruiterOTP(data))
      .then((res) => {
        if (res.value.success) {
        }
      })
      .catch((err) => console.log(err));
    // set counter to 60 sec after requesting otp
    setCounter(60);
  };
  return (
    <>
      <div className="top_main">
        <div className="verify_otp_main">
          <div className="row no-gutters">
            <div className="col-md-4 otp_ra"></div>
            <div className="col-md-4  otp_rb sha p-4">
              <div className="px-3 px-lg-5 pt-3">
                <div className="d-flex justify-content-center">
                  <img src={logo} width="200px" alt="logo" className="mx-auto" />
                </div>
                <p className="otp_ver text-center mt-3 mb-3 font-bold">
                  {" "}
                  <FormattedMessage
                          id = "otpVerification"
                          defaultMessage="OTP Verfication"
                      />
                </p>
                <div>
                  <form onSubmit={handleSubmit(update)}>
                    <div className="login_details">
                      <div className="otp_b text-bold p-0 m-0 mb-1 text-center ">
                        <p >
                          {" "}
                          {storage.get("login_type") === 'email'? (
                              <FormattedMessage
                                id = "enterOtpEmail"
                                defaultMessage="Enter OTP sent to {number}"
                                values={{number: userName}}
                              />
                            ) : (
                              <FormattedMessage
                                id = "enterOtp"
                                defaultMessage="Enter OTP sent to +91 {number}"
                                values={{number: userName}}
                              />
                            )}
                          <BsPencil
                            className="pencil"
                            onClick={history.goBack}
                            style={{float:'right'}}
                          />
                        </p>
                      </div>
                      <input
                        name="otp"
                        className="otp_input py-2"
                        autocomplete="off"
                        className={classNames("form-control", {
                          "is-invalid": errors.otp,
                        })}
                        ref={register({
                              required: translate.formatMessage({
                                id: "mustEnterOtp",
                              }),
                              minLength: {
                                value: 4,
                                message: translate.formatMessage({
                                  id: "otpLength",
                                }),
                              },
                              maxLength: {
                                value: 4,
                                message: translate.formatMessage({
                                  id: "mustFourDigit",
                                }),
                              },
                          })
                        }
                      />
                      {errors.otp && (
                        <p className="text-danger tnc_alrt ">
                          {errors.otp.message}
                        </p>
                      )}
                      <div>{counter ?
                        (<div className="mt-2 d-flex justify-content-center mb-3 mt-2text-capitalize">
                          {" "}
                          <p className="text-center" ><FormattedMessage id="resendOtpIn"/> <span className="text-success">{counter} </span> secs </p>{" "}
                        </div>
                        ) :
                        (<div className="mt-2 d-flex justify-content-center mb-3 mt-2text-capitalize">
                          <FormattedMessage id="notReceiveOtp"/>
                          <a className="text-decoration-none ml-1 cursor_otp" onClick={(e) => resendOtp()}>
                          <FormattedMessage id="resendOtp"/>
                          </a>{" "}
                        </div>)
                      }
                      </div>
                      <button className="common_btn login_btn" type="submit">
                      <FormattedMessage id="verifyAndProceed"/>
                      </button>
                    </div>
                  </form>
                  <div className="mt-3">
                    {errorMessage && errorMessage.length > 0 && (
                      <Alert severity="error">{errorMessage} !</Alert>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4"></div>
          </div>
        </div>
      </div>
    </>
  );
};
export default RecruiterVerifyOTP;
