import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import * as authActions from "redux/actions/AuthActions";
import { useDispatch } from "react-redux";
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import { FaTimes } from "react-icons/fa";
import { common, errorMessage, successMessage, selectedType, queryType, _authKey, userType } from "config/app.constant";

const Captcha = (props) => {
    const { steps } = props
    const dispatch = useDispatch();
    const recaptchaRef = React.createRef();
    const [captchaModal, setCaptchaModal] = useState(true);
    const [success, setSuccess] = useState(); // For showing error message and other success events
    const [name, setName] = useState(steps.submitFeedbackName ? steps.submitFeedbackName.value : steps.submitQueryName ? steps.submitQueryName.value : ''); // Name from Query or Feedback chat
    const [contact, setContact] = useState(steps.submitFeedbackContact ? steps.submitFeedbackContact.value : steps.submitQueryContact ? steps.submitQueryContact.value : ''); // Contact Number from Query or Feedback chat
    const [type, setType] = useState(steps.question4 ? steps.question4.value : ''); // 0: Review/Feedback, 1: Querry
    const [response, setResponse] = useState(steps.submitReview ? steps.submitReview.value : steps.submitQuery ? steps.submitQuery.value : ''); //  Query or Feedback response from chat
    const [selectionType, setSelectionType] = useState(steps.userSelection.value ? steps.userSelection.value : ''); // User selection Type  From employer or Jobseeker
    const grecaptchaObject = window.grecaptcha;

    const onSubmit = async () => {
        try {
            const recaptchaValue = recaptchaRef.current.getValue();
            // User data to be submitted for query/feedback/review
            const data = {
                name: name,
                contact: contact,
                type: queryType[type],
                user_type: selectionType === userType.EMPLOYER ? userType.EMPLOYER_ROLE : userType.JOB_SEEKER_ROLE,
                response: response,
                token: recaptchaValue,
            };
            let res = await dispatch(authActions.verifyCaptcha(data));
            if (res.value.success || res.data.success) {  //  response on submitting captcha 
                setCaptchaModal(false); // Closing Captcha modal on success
                setSuccess(true)
            } else {
                setSuccess(false); // for showing error message
            }
        } catch (error) {
            setSuccess(false); // for showing error message
        }
    };
    // Handle close Verify Captcha Modal
    const closeCaptchaModal = () => {
        setCaptchaModal(false);
    };
    return (
        <>
            {/* Success and Error message of Captcha Code */}
            {success ? (<p>{successMessage.CAPTCHA_VERIFIED},</p>) : (<p>{errorMessage.INVALID_CAPTCHA}</p>)}
            {/* For Submitting a Review/Feedback */}
            {success && type === selectedType.FEEDBACK ? (<p>{successMessage.SUBMIT_REVIEW}</p>) : ('')}
            {/* For Submitting a Query */}
            {success && type === selectedType.QUERY ? (<p>{successMessage.SUBMIT_QUERY}</p>) : ('')}

            {/*---------- Verify captcha modal-section start from here ----------*/}
            <Dialog open={captchaModal} onClose={closeCaptchaModal}>
                <DialogTitle className="page_title">{errorMessage.INVALID_CAPTCHA}</DialogTitle>
                <button className="modal_close_btn" onClick={closeCaptchaModal}>
                    <FaTimes />
                </button>
                <DialogContent>
                    <section>
                        <ReCAPTCHA
                            ref={recaptchaRef}
                            sitekey={_authKey.SITE_KEY}
                            grecaptcha={grecaptchaObject}
                        />
                        {success === false ? (
                            <p className="text-center my-2 text-danger">
                                {common.TICK_CHECKBOX}
                            </p>
                        ) : ("")
                        }
                        <form>
                            <div className="text-center my-1">
                                <button className="btn btn-success" type="button" id="submit" onClick={() => onSubmit()}>
                                    {common.SUBMIT_CAPTCHA}
                                </button>
                            </div>
                        </form>
                    </section>
                </DialogContent>
            </Dialog>
            {/* ---------- Verify captcha modal-section modal end ----------*/}
        </>
    );
};

export default Captcha;