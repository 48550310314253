import React, { useState, useEffect } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import * as JobActions from "redux/actions/JobActions";
import "./recruiterpost.scss";
import { Redirect, useHistory } from "react-router-dom";
import { FiMoreHorizontal } from "react-icons/fi";
import storage from "utils/storage";
import dummylogo from "../../../assets/images/dummylogo.png";
import ConfirmDialog from "components/shared/ui-components/common-dialog";
import { Image } from "components/shared/imagecomponent/image";
import { JobDetailModal } from "components/jobseeker/JobDetailsmodal/JobDetailModal.js";
import CommonModal from "components/shared/ui-components/common-modal";
import { FormattedMessage } from "react-intl";
import { useIntl } from "react-intl";
import ReactTooltip from "react-tooltip";
export const Recruiterjobpost = () => {
  const [choice, setchoice] = useState("");
  const history = useHistory();
  const intl = useIntl();
  const [dialog, setDialog] = useState({
    open: false,
    message: "",
    type: "",
    confirm_text: "",
    cancel_text: "",
  });
  const [jobStatus, setJobStatus] = useState("pending");
  const [feedback, setFeedback] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { jobList } = useSelector((state) => state.jobReducer);
  const { userDetails } = useSelector((state) => state.userReducer);

  const [modalData, setModalData] = useState(false);
  const [open, setOpen] = useState(false);
  const [state, setState] = useState({ open: false });

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const update = (e) => {
    console.log(e.target.value);
  };
  const redirectToVerification = () => {
    if (
      userDetails &&
      (userDetails.verification_status === "Not Initiated" ||
        userDetails.verification_status === "Declined" ||
        !userDetails.verification_status)
    ) {
      history.push("/companyverification");
    }
    setDialog({ open: false, message: "" });
  };
  const openDialogBox = (jobStatus) => {
    if (jobStatus == 4) {
      setDialog({
        open: true,
        message: "Your job is deactivated. Please proceed to post new job.",
        type: "Deactivated",
        confirm_text: intl.formatMessage({ id: "okay" }),
      });
      return;
    }
    if (
      userDetails &&
      (userDetails.verification_status === "Not Initiated" ||
        !userDetails.verification_status)
    ) {
      setDialog({
        open: true,
        message: intl.formatMessage({ id: "pleasecompleteAuthentication" }),
        type: "Not_Initiated",
        confirm_text: intl.formatMessage({ id: "authenticateNow" }),
        cancel_text: intl.formatMessage({ id: "authenticateLater" }),
      });
    } else if (
      userDetails &&
      userDetails.verification_status === "In Progress"
    ) {
      setDialog({
        open: true,
        message: intl.formatMessage({ id: "yourVerification" }),
        type: "In_Progress",
        confirm_text: intl.formatMessage({ id: "okay" }),
      });
    } else if (userDetails && userDetails.verification_status === "Declined") {
      setDialog({
        open: true,
        message: `${intl.formatMessage(
          { id: "yourDocumentDeclined" },
          { reason: userDetails && userDetails.verification_feedback }
        )}`,
        type: "Declined",
        confirm_text: intl.formatMessage({ id: "reSubmitText" }),
        cancel_text: intl.formatMessage({ id: "authenticateLater" }),
      });
    } else {
      setDialog({
        open: true,
        message: intl.formatMessage({ id: "yourVerficationApproved" }),
        type: "Approved",
        confirm_text: intl.formatMessage({ id: "okay" }),
      });
    }
  };

  const [hasError, setErrors] = useState(false);
  const [planets, setPlanets] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!loaded) {
      setLoaded(true);
      dispatch(JobActions.getJobListDataById(storage.get("humlog_user")));
    }
    if (userDetails && userDetails.verification_status !== "Approved") {
      setJobStatus("pending");
      setFeedback(userDetails.verification_feedback);
    } else {
      setJobStatus("active");
    }
  }, [loaded, dispatch]);

  const getUserbyJob = (id, val) => {
    history.push({ pathname: "/userapplied", search: "?id=" + id, jobData: val });
  };

  /**
   * @description function to close modal
   */
  const handelCloseModal = () => {
    setOpen(false);
    setState({ open: false });
  };

  /**
   * @description function to open modal
   * @param {param} data
   */
  const handelOpenModal = (data) => {
    let jobData = {
      ...data,
      job_experience: data.experience,
    };
    setModalData(jobData);
    setOpen(true);
  };

  return (
    <>
      {dialog.open && (
        <ConfirmDialog
          open={dialog.open}
          noCancelbtn={
            ["In_Progress", "Approved", "Deactivated"].includes(dialog.type)
              ? true
              : false
          }
          confirm_text={dialog.confirm_text}
          cancel_text={dialog.cancel_text}
          setState={setDialog}
          message={dialog.message}
          confirm={redirectToVerification}
        ></ConfirmDialog>
      )}
      {jobList && jobList.length == 0 ? (
        <div className="font-weight-bold card text-primary mt-5 shadow px-1">
          <FormattedMessage id="thanksForCreating" />
        </div>
      ) : (
        <div className="App">
            <h5 className="page_title">
              <FormattedMessage id="dashboard" defaultMessage="Dashboard" />
            </h5>
          {jobList.map((val) => {
            return (
              <>
                <div className="border uu">
                  <div className="px-3 py-3">
                    <div className="d-flex justify-content-between">
                      <div className="d-flex">
                        {/* Job-profile-section start from here */}
                        <div style={{ width: "68px", height: "68px" }} onClick={() => getUserbyJob(val._id, val)}>
                          {val.company_logo && val.company_logo.length > 0 ? (
                            <Image
                              picture={`${val.company_logo}?${Date.now()}`}
                              alt="company_logo"
                            ></Image>
                          ) : (
                            <Image
                              picture={dummylogo}
                              alt="company_logo"
                            ></Image>
                          )}
                        </div>
                        {/* Job-profile-section end */}
                        <div>
                          <h6 className="ji text-capitalize mt-2 ml-3 Text_title_size">
                            {" "}{val.job_title}{" "}
                          </h6>
                          <h6 className="jk mt-2 ml-3 text-capitalize Text_location_size">
                            {val && val.district ? val.district : "N/A"}
                          </h6>
                          <h6 className="jk mt-2 ml-3 text-capitalize Text_location_size">
                            {" "}status:
                            <span className="jobstatus_txt">
                              {val.status === 0
                                ? " Open"
                                : val.status === 1
                                ? " Complete"
                                : val.status === 2
                                ? " Closed"
                                : val.status === 3
                                ? " Pending"
                                : val.status === 4
                                ? " Deactivated"
                                : ""}
                            </span>
                            {" "}
                          </h6>
                        </div>
                      </div>
                      <div className="">
                        <h6 className="post_time mt-1">
                          {moment(val.created_at).fromNow()}{" "}
                        </h6>

                        <div className="">
                        
                          {val.status === 2 || val.status === 3 || val.status === 4 ? (
                            <div className="">
                              {
                                userDetails && userDetails.verification_status ?
                                  (
                                    <div className="chipps" onClick={() => openDialogBox(val.status)}>
                                    {val.status === 4 ? "Deactivated" : (
                                      <>
                                      {
                                        userDetails.verification_status == "Not Initiated"
                                        ? "Authenticate Now"
                                        : userDetails.verification_status == "In Progress"
                                        ? "Authetication InProgress"
                                        : userDetails.verification_status == "Approved"
                                        ? "Authetication Approved"
                                        : userDetails.verification_status == "Declined"
                                        ? "Authetication Declined"
                                        : ""
                                      }
                                      </>
                                    )}
                                    </div>
                                  )
                                  :
                                  ""
                              }
                            </div>
                          ) : (
                            <div
                              className="chipps"
                              onClick={() => getUserbyJob(val._id, val)}
                            >
                              {
                                val.status === 0
                                ? "Applicants"
                                : val.status === 1
                                ? "Complete"
                                : val.status === 2
                                ? "Closed"
                                : val.status === 3
                                ? "Pending"
                                : val.status === 4
                                ? "Deactivated"
                                : ""
                              }
                            </div>
                          )}
                        </div>

                        <div className="view_job_details_btn">
                          <button
                            type="button"
                            className="vjd_btn_icon"
                            onClick={() => handelOpenModal(val)}
                          >
                            <FormattedMessage id="viewJobDetails" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      )}
      <CommonModal open={open} handelClose={handelCloseModal}>
        <JobDetailModal modalData={modalData} />
      </CommonModal>
    </>
  );
};
