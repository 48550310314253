import React, { useState } from "react";
import { FormattedMessage, FormattedDate, FormattedNumber, FormattedPlural, FormattedTime } from 'react-intl';
import { useDispatch, useSelector } from "react-redux";
import "./applieduser.scss";
import CommonModal from "components/shared/ui-components/common-modal";
import { JobDetailModal } from "../JobDetailsmodal/JobDetailModal";
import dummylogo from "../../../assets/images/dummylogo.png";
import * as UserActions from "redux/actions/UserActions";
import { Image } from "components/shared/imagecomponent/image";
import { FiMoreHorizontal } from "react-icons/fi";
import { IoIosCloseCircle } from "react-icons/io";
import { BsCheck } from "react-icons/bs";

export const Applieduser = (props) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [modalData, setModalData] = useState(false);
  const [state, setState] = useState({ open: false });
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handelCloseModal = () => {
    setOpen(false);
    setState({ open: false });
  };
  const handelOpenModal = (data) => {
    setModalData(data)
    setOpen(true);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const { userDetails } = useSelector((state) => state.userReducer);
  const update = (e) => {
    console.log(e.target.value);
  };

  /**
   * @description method to change the status of the job
   * @param {Array} job
   * @param {String} status status of job [Applied, Visited]
   */
  const changeJobStatus = async (job, status) => {
    try {
      let jobData = job;
      jobData.status = status;
      let data = {
        _id: userDetails._id,
        jobs: jobData
      }
      let res = await dispatch(UserActions.updateUserData(data));
      if (res.value.success) {
        console.log(res.value.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * @description method to open/close the menu options (Apply Now, Apply Later, Not Apply)
   * @param {param} e event 
   */
   const toggleMenu = (e) => {
    const dropdowns = document.getElementsByClassName('dropdown-content');
    const thisDropdown = e.target.nextSibling;
    if (!thisDropdown.classList.contains('show')) {
      let i;
      for (i = 0; i < dropdowns.length; i++) {
        dropdowns[i].classList.remove('show');
      }
    }
    thisDropdown.classList.toggle('show');
  }

  /**
   * @description method to close the dropdown when clicked outside
   * @param {param} event 
   */
  window.onclick = function (event) {
    if (!event.target.matches('.dropbtn')) {
      var dropdowns = document.getElementsByClassName('dropdown-content');
      var i;
      for (i = 0; i < dropdowns.length; i++) {
        var openDropdown = dropdowns[i];
        if (openDropdown.classList.contains('show')) {
          openDropdown.classList.remove('show');
        }
      }
    }
  }
  return (
    <>
      <CommonModal open={open} handelClose={handelCloseModal}>
        <JobDetailModal modalData={modalData} />
      </CommonModal>
      <div className="main_notification border">
        <div className="container">
          {userDetails && userDetails.jobs && userDetails.jobs.length > 0 ? (
            userDetails.jobs.map((jobData) => {
              if (jobData.status == props.status && jobData.job_status == 0)
                return (
                  <div className="row border-bottom">
                    <div className="col-12 p-0">
                      <div className="common-card-padding d-flex justify-content-between">
                        <div className="d-flex">
                          <div >
                            {jobData.company_logo ? (
                              <div style={{ width: "80px", height: "80px" }}>
                                <Image picture={`${jobData.company_logo}?${Date.now()}`} alt="user_profile"></Image>
                              </div>
                            ) : (
                              <div style={{ width: "80px", height: "80px" }}>
                                <Image picture={dummylogo} alt="user_profile"></Image>
                              </div>
                            )}
                          </div>
                          <div className="ml-3 padryt">
                            <h2 className="user_nme mt-1 text-capitalize" >
                              {jobData.job_title}
                            </h2>
                            <p className="user_ua mt-2 ">{jobData.company_name}</p>
                            <p className="user_ub">{jobData.job_location} </p>
                            <p className="user_uc"> {jobData.status} </p>
                            {jobData.status==="Shortlisted" && jobData.company_contact?(<p className="user_uc d-flex"> Contact:<span className="contact_number_color">&nbsp;{jobData.company_contact} </span>
                            </p>):("")}
                          </div>
                        </div>
                        {props.status == 'Saved' &&
                          <div className="viewoptionsbtn">
                            <div className="dropdownbox">
                              <button type="button" className="moreoptns dropbtn"
                                onClick={toggleMenu}>
                                <FiMoreHorizontal />
                              </button>
                              <div className="dropdownmenu option_menu dropdown-content">
                                <ul>
                                  <li>
                                    <span onClick={() => changeJobStatus(jobData, "Applied")}>
                                      <BsCheck className="view_menu_icons applynow" />
                                      Apply Now
                                    </span>
                                  </li>
                                  <li>
                                    <span onClick={() => changeJobStatus(jobData, "Visited")}>
                                      <IoIosCloseCircle className="view_menu_icons notapply" />
                                      Not Apply
                                    </span>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        }
                      </div>
                    </div>
                    {/* View-details button start from here */}
                    <div className="viewdetailsbtn"><button className="viewdetails" type="button" onClick={() => handelOpenModal(jobData)}><FormattedMessage id="viewDetail" /></button></div>
                    {/* View-details button end */}
                  </div>
                );
            })
          ) : (
            <div />
          )}
        </div>
        
      </div>
    </>
  );
};
