const list = [
  "New Delhi",
  "Delhi",
  "East Delhi",
  "West Delhi",
  "Central Delhi",
  "South Delhi",
  "Gautam Budh Nagar (NOIDA)",
  "Gautam Budh Nagar (Greater NOIDA)",
  "Ghaziabad",
  "Gurgaon",
  "Faridabad",
  "Ambala",
  "Karnal",
  "Panipat",
  "Sonipat",
  "Rohtak",
  "Palwal",
  "Rewari",
  "Hisar",
  "Agra",
  "Lucknow",
  "Meerut",
  "Aligarh",
  "Hapur",
  "Kanpur",
  "Firozabad",
  "Moradabad",
  "Bareilly",
  "Gorakhpur",
  "Mathura",
  "Jaipur",
  "Alwar",
  "Ajmer",
  "Jodhpur",
  "Bikaner",
  "Kota",
  "Haridwar",
  "Dehradun",
  "Haldwani",
  "Bareilly",
  "Chandigarh",
  "Ludhiana",
  "Amritsar",
  "Jalandhar",
  "Mohali",
  "Gwalior",
  "Indore",
  "Bhopal",
  "Shimla",
  "Solan",
  "Jammu",
];

export default list;
