import React from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import { Form } from "react-bootstrap";
import { getJobsByTitle } from "redux/actions/JobActions";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";

function JobTitleField(props) {
  const { errors, register, onTitleSelect } = props;

  const [jobs, setJobs] = React.useState([]);
  const dispatch = useDispatch();

  const intl = useIntl();

  const ref = register({
    required: intl.formatMessage({
      id: "enterJobOpeningName",
    }),
    minLength: {
      value: 3,
      message: intl.formatMessage({
        id: "minDigitJobTitle",
      }),
    },
    maxLength: {
      value: 40,
      message: intl.formatMessage({ id: "maximumFourtyDigit" }),
    },
    pattern: {
      value: /^[A-Za-z0-9\d\s._,-:;'"()*]*$/,
      message: intl.formatMessage({ id: "specialCharactersNotAllowed" }),
    },
  });

  const onTitleChange = async (title) => {
    if (!title) {
      setJobs([]);
    } else {
      try {
        let response = await dispatch(getJobsByTitle(title));
        const _jobs = response.value?.data || [];
        const selectedDescription = _jobs.find(
          (job) => job.job_title === title
        );
        setJobs(_jobs);
        if (selectedDescription) onTitleSelect(selectedDescription);
      } catch (e) {
        console.log(e);
      }
    }
  };

  const getFormControlTemplate = (params) => (
    <TextField
      name="job_title"
      placeholder={intl.formatMessage({ id: "enterJobOpeningName" })}
      inputRef={ref}
      {...params}
      onChange={(e) => onTitleChange(e.target.value)}
      variant="outlined"
    />
  );

  return (
    <>
      <Form.Label className="d-flex justify-content-start llbl">
        <FormattedMessage id="jobTitle" defaultMessage="Job Title" />{" "}
        <font className="text-danger ml-1">*</font>{" "}
      </Form.Label>
      <Autocomplete
        id="jobTitle"
        name="job_title"
        ref={register}
        options={jobs}
        defaultValue={""}
        getOptionLabel={(option) => option.job_title}
        style={{ width: "100%" }}
        className="mt-2 p-0 m-0"
        renderInput={(params) => getFormControlTemplate(params)}
        onInputChange={(e, sel) => {
          onTitleChange(sel);
        }}
      />

      {errors.job_title && (
        <p className="text-danger  oom p-0 m-0">{errors.job_title.message}</p>
      )}
    </>
  );
}

export default JobTitleField;
