import "./userDetail/userdetail.scss";
import { AiOutlineUser, AiOutlineHome } from "react-icons/ai";
import { BiBookOpen, BiCertification } from "react-icons/bi";
import { FaPlayCircle, FaPauseCircle } from "react-icons/fa";
import { CgGenderFemale } from "react-icons/cg";
import age from "assets/images/age.png";
import React, { useRef, useState, useEffect } from "react";
import { FormattedMessage, FormattedDate, FormattedNumber, FormattedPlural, FormattedTime } from 'react-intl';
import { useIntl } from 'react-intl';
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useForm } from "react-hook-form";
import classNames from "classnames";
import { useDispatch } from "react-redux";
import * as userActions from "redux/actions/UserActions";
import * as authActions from "redux/actions/AuthActions";
import storage from "utils/storage";
import { DistrictList } from "../DistrictList";
import Alert from "@material-ui/lab/Alert";
import ConfirmDialog from "components/shared/ui-components/common-dialog";

export const BasicDetails = ({ history }) => {
  const dispatch = useDispatch();
  const { register, handleSubmit, errors } = useForm({ mode: 'onBlur' });
  const [graduate, setGraduate] = useState({ open: false });
  const [isSuccess, setIssuccess] = useState(false);
  const intl = useIntl();
  const [dialog, setDialog] = useState({ open: false, message: "" });
  const [finishStatus, setfinishStatus] = useState(false);
  // back button workflow
  const onBackButtonEvent = (e) => {
    e.preventDefault();
    if (!finishStatus) {
      setDialog({ open: true, message: "Are you sure, you want to exit without creating profile?" });
    }
  }

  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname);
    // listen event of back button pressed
    window.addEventListener('popstate', onBackButtonEvent);
    return () => {
      window.removeEventListener('popstate', onBackButtonEvent);
    };
  }, []);
  // onConfirm logout
  const onConfirm = () => {
    console.log("onconfirm");
    setfinishStatus(true)
    dispatch(authActions.logout);
    setTimeout(() => {
      window.location.reload();
      history.push('/');
      // clear local storage on log out
      storage.clear();
    }, 200);
  }
  //oncancel remain on the same page
  const onCancel = () => {
    window.history.pushState(null, null, window.location.pathname);
    setfinishStatus(false)
  }
  const DistrictListt = DistrictList.sort()
  // method to open graduation and above tab
  const openGraduation = (event) => {
    setGraduate({ open: !graduate.open });
    document.getElementById("Graduate & above").classList.toggle('graduation');
  }
  // method to close graduation and above tab
  const closeGraduation = (event) => {
    setGraduate({ open: false });
    document.getElementById("Graduate & above").classList.remove('graduation');
  }
  /**
   * Method to update basic details of user
   * @param {Object} val Basic details entered by user
   */
  const update = (val) => {
    val.technical_cert = [{ cert: val.technical_cert, branch: "" }];
    let data = { ...val, profile_status: 1, _id: storage.get("humlog_user") };
    const redirect_url = history.location.state;
    dispatch(userActions.updateUserData(data))
      .then((res) => {
        if (res.value.success) {
          setIssuccess(true);
          // set value of profile status in storage
          storage.set("profile_status", data.profile_status);
          history.push({ pathname: "/user/basicdetail/jobinterested", state: redirect_url });
        }
      })
      .catch((err) => console.log(err));
  };
  /**
	 * THIS CODE COMMENTED BECAUSE USE FOR FUTURE REFERENCE START FROM HERE
   * ADD AUDIO For Reference
	 */
  // method to explain all the registeration steps: 
  // default audio playing at the first time
  // @param {URL} deafult audio file
  // const useAudio = URL => {
    // const [audio] = useState(new Audio(URL));
    // const [playing, setPlaying] = useState(true);
    // const togglePlay = () => setPlaying(!playing);
    // method to playing audio when the page load
    // useEffect(() => {
    //   playing ? audio.play() : audio.pause();
    // }, [playing]);
    
  //   useEffect(() => {
  //     audio.addEventListener('ended', () => setPlaying(false));
  //     return () => {
  //       audio.removeEventListener('ended', () => setPlaying(false));
  //     };
  //   }, []);
  //   return [playing, togglePlay];
  // };
  // const [playing, togglePlay] = useAudio(sample);
  /**
	 * THIS CODE COMMENTED BECAUSE USE FOR FUTURE REFERENCE END HERE
   * 
	 */
  return (
    <>
      {dialog.open && (
        <ConfirmDialog
          open={dialog.open}
          // val={state.val}
          setState={setDialog}
          message={dialog.message}
          confirm={onConfirm}
          cancel={onCancel}
        ></ConfirmDialog>
      )}
      <div className="basicdetail">
        <div className="container">
          <div className="row no-gutters">
            <div className="col-12 sst">
              <div className="instruction_button">
                {/* <button type="button" className={playing ? "animationOn" : "animationOff"} onClick={togglePlay} title="Registeration Steps Audio">{playing ? (<FaPauseCircle />) : (<FaPlayCircle />)}</button> */}
              </div>
              <form onSubmit={handleSubmit(update)}>
                <div className="form-group">
                  <label htmlFor="name" className="control-label">
                    <AiOutlineUser className="detail_icon_user" />
                    <b className="p-0 m-0">
                      <FormattedMessage id="name" defaultMessage="Name" />
                      {" "}
                      <font className="text-danger">*</font>
                    </b>
                  </label>
                  <div>
                    <div className="input-group">
                      <span className="input-group-addon"></span>
                      <input
                        type="text"
                        autoComplete="off"
                        className={classNames("form-control", {
                          "is-invalid": errors.name,
                        })}
                        name="name"
                        id="name"
                        placeholder={intl.formatMessage({ id: 'enterFullName' })}
                        ref={register({
                          required: intl.formatMessage({ id: 'pleaseEnterFullName' }),
                          pattern: {
                            value: /^[a-zA-Z\s]*$/,
                            message: intl.formatMessage({ id: "notAllowedNumeric" }),
                          },
                          maxLength: {
                            value: 30,
                            message: intl.formatMessage({ id: "maximumThirtyCharacterEntered" }),
                          },
                        })}
                      />
                    </div>
                    {errors.name && (
                      <p className="text-danger  oom">{errors.name.message}</p>
                    )}
                  </div>
                </div>

                <div className="form-group">
                  <label htmlFor="email" className="cols-sm-2 control-label" ref={register}>
                    <CgGenderFemale className="detail_icon_user" />
                    <b>
                      <FormattedMessage id="gender" defaultMessage="Gender" />
                      {" "}
                      <font className="text-danger">*</font>
                    </b>
                  </label>
                  <div className="radio_box_container">
                    <div className="form-check detail_btn_a_margin text-center aaa">
                      <input
                        className="form-check-input d-none aaa"
                        type="radio"
                        name="gender"
                        id="male"
                        value="1"
                        ref={register({
                          required: intl.formatMessage({ id: 'pleaseSelectGender' }),
                        })}
                      />
                      <label className="form-check-label" htmlFor="male">
                        <FormattedMessage id="male" defaultMessage="Male" />
                      </label>
                    </div>

                    <div className=" detail_btn_b text-center aaa">
                      <input
                        className="form-check-input d-none"
                        type="radio"
                        name="gender"
                        value="0"
                        id="female"
                        ref={register({
                          required: intl.formatMessage({ id: 'pleaseSelectGender' }),
                        })}
                      />
                      <label className="form-check-label" htmlFor="female">
                        <FormattedMessage id="female" defaultMessage="Female" />
                      </label>
                    </div>

                    {/* <div className="detail_btn_b text-center aaa">
                      <input
                        className="form-check-input d-none"
                        type="radio"
                        name="gender"
                        value="2"
                        id="Others"
                        ref={register({
                          required: intl.formatMessage({ id: 'pleaseSelectGender' }),
                        })}
                      />
                      <label className="form-check-label" htmlFor="Others">
                        <FormattedMessage id="others" defaultMessage="Others" />
                      </label>
                    </div> */}
                  </div>
                  {errors.gender && (
                    <p className="text-danger oom">{errors.gender.message}</p>
                  )}
                </div>

                <div className="form-group">
                  <label htmlFor="email" className="cols-sm-2 control-label">
                    <img src={age} width="20px" className="detail_icon_user" />
                    <b>
                      <FormattedMessage id="age" defaultMessage="Age" />
                      {" "}
                      <font className="text-danger">*</font>
                    </b>
                  </label>

                  <div className="radio_box_container justify-content-between">
                    <div className="detail_btn_ccaa text-center">
                      <input
                        className="form-check-input d-none"
                        type="radio"
                        name="age"
                        id="18-24"
                        value="0"
                        ref={register({
                          required: intl.formatMessage({ id: 'pleaseSelectAge' }),
                        })}
                      />
                      <label className="form-check-label" htmlFor="18-24">
                        18-24 Yrs
                      </label>
                    </div>

                    <div className="form-check detail_btn_ccaa text-center mr-2">
                      <input
                        className="form-check-input d-none"
                        type="radio"
                        name="age"
                        id="25-30"
                        value="1"
                        ref={register({
                          required: intl.formatMessage({ id: 'pleaseSelectAge' }),
                        })}
                      />
                      <label className="form-check-label" htmlFor="25-30">
                        25-30 Yrs
                      </label>
                    </div>

                    <div className="form-check  detail_btn_ccaa text-center mr-2">
                      <input
                        className="form-check-input d-none "
                        type="radio"
                        name="age"
                        id="31-35"
                        value="2"
                        ref={register({
                          required: intl.formatMessage({ id: 'pleaseSelectAge' }),
                        })}
                      />
                      <label className="form-check-label text-capitalize" htmlFor="31-35">
                        31-35 Yrs
                      </label>
                    </div>

                    <div className="form-check detail_btn_ccaa text-center mr-2">
                      <input
                        className="form-check-input d-none"
                        type="radio"
                        name="age"
                        id="36+ yrs"
                        value="2"
                        ref={register({
                          required: intl.formatMessage({ id: 'pleaseSelectAge' }),
                        })}
                      />
                      <label className="form-check-label text-capitalize" htmlFor="36+ yrs">
                        36+ Yrs
                      </label>
                    </div>
                  </div>
                  {errors.age && (
                    <p className="text-danger oom">{errors.age.message}</p>
                  )}
                </div>

                <div className="form-group">
                  <label htmlFor="email" className="cols-sm-2 control-label">
                    <BiBookOpen className="detail_icon_user" />
                    <b>
                      <FormattedMessage id="educationQualification" defaultMessage="Education & Qualification" />
                      {" "}
                      <font className="text-danger">*</font>
                    </b>
                  </label>

                  <div className="radio_box_container justify-content-between">
                    <div className="form-check  detail_btn_ccaa text-center">
                      <input
                        className="form-check-input d-none"
                        type="radio"
                        name="qualification"
                        value="0"
                        onClick={(event) => { closeGraduation(event); }}
                        id="below 10th"
                        ref={register({
                          required: intl.formatMessage({ id: 'pleaseSelectQualification' }),
                        })}
                      />
                      <label className="form-check-label text-capitalize" htmlFor="below 10th" >
                        below 10th
                      </label>
                    </div>

                    <div className="form-check detail_btn_ccaa text-center">
                      <input
                        className="form-check-input d-none"
                        type="radio"
                        name="qualification"
                        id="10th pass"
                        onClick={(event) => { closeGraduation(event); }}
                        value="1"
                        ref={register({
                          required: intl.formatMessage({ id: 'pleaseSelectQualification' }),
                        })}
                      />
                      <label className="form-check-label text-capitalize" htmlFor="10th pass" >
                        10th Pass
                      </label>
                    </div>

                    <div className="form-check detail_btn_ccaa text-center">
                      <input
                        className="form-check-input d-none"
                        type="radio"
                        name="qualification"
                        id="12th Pass"
                        onClick={(event) => { closeGraduation(event); }}
                        ref={register}
                        value="2"
                        ref={register({
                          required: intl.formatMessage({ id: 'pleaseSelectQualification' }),
                        })}
                      />
                      <label className="form-check-label text-capitalize" htmlFor="12th Pass">
                        12th Pass
                      </label>
                    </div>

                    <div className="form-check detail_btn_ccaa text-center">
                      <input
                        className="form-check-input d-none"
                        type="radio"
                        id="Graduate & above"
                        name="qualification"
                        value='Other'
                        ref={register({
                          required: intl.formatMessage({ id: 'pleaseSelectQualification' }),
                        })}
                      />
                      <label className="form-check-label text-capitalize" htmlFor="Graduate & above">
                        <p className="grad"> Graduate & above </p>
                      </label>
                    </div>
                  </div>
                  {errors.qualification && (
                    <p className="text-danger  oom">
                      {errors.qualification.message}
                    </p>
                  )}
                </div>

                <div className="form-group">
                  <label htmlFor="email" className="cols-sm-2 control-label">
                    <BiCertification className="detail_icon_user" />
                    <b>
                      <FormattedMessage id="technicalCertification" defaultMessage="Technical Certification" />
                      {" "}
                      <font className="text-danger">*</font>
                    </b>
                  </label>

                  <div className="radio_box_container justify-content-between">
                    <div className="form-check  detail_btn_ccaa text-center">
                      <input
                        className="form-check-input d-none"
                        type="radio"
                        name="technical_cert"
                        value="ITI"
                        id="ITI"
                        ref={register({
                          required: intl.formatMessage({ id: 'pleaseSelectCertification' }),
                        })}
                      />
                      <label className="form-check-label text-capitalize" htmlFor="ITI">
                        ITI
                      </label>
                    </div>

                    <div className="form-check  detail_btn_ccaa text-center">
                      <input
                        className="form-check-input d-none"
                        type="radio"
                        name="technical_cert"
                        id="Diploma"
                        value="Diploma"
                        ref={register({
                          required: intl.formatMessage({ id: 'pleaseSelectCertification' }),
                        })}
                      />
                      <label className="form-check-label text-capitalize" htmlFor="Diploma">
                        Diploma
                      </label>
                    </div>

                    <div className="form-check detail_btn_ccaa text-center">
                      <input
                        className="form-check-input d-none"
                        type="radio"
                        name="technical_cert"
                        id="B.tech/B.E"
                        ref={register}
                        value="B.tech/B.E"
                        ref={register({
                          required: intl.formatMessage({ id: 'pleaseSelectCertification' }),
                        })}
                      />
                      <label className="form-check-label text-capitalize" htmlFor="B.tech/B.E">
                        B.tech/B.E
                      </label>
                    </div>

                    <div className="form-check detail_btn_ccaa text-center">
                      <input
                        className="form-check-input d-none"
                        type="radio"
                        id="otherTech"
                        name="technical_cert"
                        value="other"
                        ref={register({
                          required: intl.formatMessage({ id: 'pleaseSelectCertification' }),
                        })}
                      />
                      <label className="form-check-label text-capitalize" htmlFor="otherTech">
                        <p className="grad"> Other </p>
                      </label>
                    </div>
                  </div>
                  {errors.technical_cert && (
                    <p className="text-danger oom">
                      {errors.technical_cert.message}
                    </p>
                  )}
                </div>

                <div className="form-group">
                  <label htmlFor="email" className="cols-sm-2 control-label">
                    <AiOutlineUser className="detail_icon_user" />
                    <b>
                      <FormattedMessage id="workExp" defaultMessage="Work Experience (Years)" />
                      {" "}
                      <font className="text-danger">*</font>
                    </b>
                  </label>

                  <div className="radio_box_container justify-content-between">
                    <div className="form-check text-center detail_btn_ccaa">
                      <input
                        className="form-check-input d-none"
                        type="radio"
                        name="experience"
                        id="Fresher"
                        value="0"
                        ref={register({
                          required: intl.formatMessage({ id: 'pleaseSelectWorkExp' }),
                        })}
                      />
                      <label className="form-check-label" htmlFor="Fresher">
                        Fresher
                      </label>
                    </div>

                    <div className="form-check detail_btn_ccaa text-center">
                      <input
                        className="form-check-input d-none"
                        type="radio"
                        name="experience"
                        id="Less than 1"
                        value="1"
                        ref={register({
                          required: intl.formatMessage({ id: 'pleaseSelectWorkExp' }),
                        })}
                      />
                      <label className="form-check-label" htmlFor="Less than 1">
                        <p className="grad">Less than 1</p>
                      </label>
                    </div>

                    <div className="form-check detail_btn_ccaa text-center">
                      <input
                        className="form-check-input d-none"
                        type="radio"
                        name="experience"
                        id="1-2"
                        value="2"
                        ref={register({
                          required: intl.formatMessage({ id: 'pleaseSelectWorkExp' }),
                        })}
                      />
                      <label className="form-check-label" htmlFor="1-2">
                        1-2
                      </label>
                    </div>

                    <div className="form-check detail_btn_ccaa text-center">
                      <input
                        className="form-check-input d-none"
                        type="radio"
                        name="experience"
                        id="2-4"
                        value="3"
                        ref={register({
                          required: intl.formatMessage({ id: 'pleaseSelectWorkExp' }),
                        })}
                      />
                      <label className="form-check-label" htmlFor="2-4">
                        2-4
                      </label>
                    </div>
                  </div>

                  <div className="radio_box_container mt-2 justify-content-between">
                    <div className="form-check detail_btn_ccaa text-center">
                      <input
                        className="form-check-input d-none"
                        type="radio"
                        name="experience"
                        id="4-6"
                        value="4"
                        ref={register({
                          required: intl.formatMessage({ id: 'pleaseSelectWorkExp' }),
                        })}
                      />
                      <label className="form-check-label" htmlFor="4-6">
                        4-6
                      </label>
                    </div>
                    <div className="form-check detail_btn_ccaa text-center">
                      <input
                        className="form-check-input d-none"
                        type="radio"
                        name="experience"
                        id="6-8"
                        value="5"
                        ref={register({
                          required: intl.formatMessage({ id: 'pleaseSelectWorkExp' }),
                        })}
                      />
                      <label className="form-check-label" htmlFor="6-8">
                        6-8
                      </label>
                    </div>

                    <div className="form-check detail_btn_ccaa text-center">
                      <input
                        className="form-check-input d-none"
                        type="radio"
                        name="experience"
                        id="8-10"
                        value="6"
                        ref={register({
                          required: intl.formatMessage({ id: 'pleaseSelectWorkExp' }),
                        })}
                      />
                      <label className="form-check-label" htmlFor="8-10">
                        8-10
                      </label>
                    </div>

                    <div className="form-check  detail_btn_ccaa text-center">
                      <input
                        className="form-check-input d-none"
                        type="radio"
                        name="experience"
                        id="10+"
                        value="7"
                        ref={register({
                          required: intl.formatMessage({ id: 'pleaseSelectWorkExp' }),
                        })}
                      />
                      <label className="form-check-label" htmlFor="10+">
                        10+
                      </label>
                    </div>
                  </div>
                  {errors.experience && (
                    <p className="text-danger oom">
                      {errors.experience.message}
                    </p>
                  )}
                </div>

                <div className="form-group">
                  <label htmlFor="name" className=" control-label text-capitalize">
                    <AiOutlineHome className="detail_icon_user" />
                    <b className="p-0 m-0">
                      <FormattedMessage id="homeDistrict" defaultMessage="Home District" />
                      {" "}
                      <font className="text-danger">*</font>
                    </b>
                  </label>
                  <Autocomplete
                    id="combo-box-demo"
                    name="address_home"
                    ref={register}
                    options={DistrictListt}
                    getOptionLabel={(option) => option}
                    style={{ width: "100%" }}
                    className="mt-2 p-0 m-0"
                    renderInput={(params) => (
                      <TextField
                        placeholder={intl.formatMessage({ id: 'homeDistrict' })}

                        name="address_home"
                        inputRef={register({
                          required: intl.formatMessage({ id: 'district' }),
                        })}
                        {...params}
                        variant="outlined"
                        onKeyPress={(event)=> event.key === 'Enter' && event.preventDefault()}
                      />
                    )}
                  />
                  {errors.address_home && <p className="text-danger oom">{errors.address_home.message}</p>}
                </div>

                <div className="mt-3">
                  <button className="common_btn" type="submit">
                    <FormattedMessage id="next" defaultMessage="Next" />
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
