import React, { useState, useEffect } from 'react';
import packageJson from '../package.json';
global.appVersion = packageJson.version;
/**
 * Description : Compare the latest version and the current version
 * @param {String} latestVersion Application's latest version
 * @param {String} currentVersion User's Current version
 * @returns Boolen value
 */
const isVersionGreater = (latestVersion, currentVersion) => {
    const latestVersionNumber = latestVersion.split(/-/g)[0];
    const currentVersionNumber = currentVersion.split(/-/g)[0];
    const latestVersions = latestVersionNumber.split(/\./g);
    const currentVersions = currentVersionNumber.split(/\./g);
    while (latestVersions.length || currentVersions.length) {
      const a = Number(latestVersions.shift());
      const b = Number(currentVersions.shift());
      if (a === b) continue;
      return a > b;
    }
    return false;
  };

export const ClearCache = (props) => {
    const [state,setState] = useState({loading:true,isLatestVersion:false});
    const [refreshCacheAndReload] = useState(() => () => {
      if (caches) {
        // Delete Service worker cache
        caches.keys().then(function(names) {
          for (let name of names) caches.delete(name);
        });
      }
      // delete browser cache and hard reload
      window.location.reload(true);
    });
    const meta = require('./meta.json');
    useEffect( () => {
        const latestVersion = meta.version;
        const currentVersion = global.appVersion;
        // compare latest version and user's current version
        const shouldForceRefresh = isVersionGreater(latestVersion, currentVersion);
        if (shouldForceRefresh) {
          // latest version is greater therefore have to do hard refresh
          setState({ loading: false, isLatestVersion: false });
        } else {
          // user have the latest version therefore no hard refresh needed
          setState({ loading: false, isLatestVersion: true });
        }
    }, []);
    return props.children({ state, refreshCacheAndReload });
}