import React from "react";
import { Recruiterjobpost } from "../recruiterjobpost/Recruiterjobpost";
export const Recruiterdashboard = () => {
    return (
        <>
            <div className="st">
                <Recruiterjobpost />
            </div>
        </>
    )
}