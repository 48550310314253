import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import React, { useContext, useState } from "react";
import logoa from "../../../../assets/images/fnlogo.jpeg";
import Alert from "@material-ui/lab/Alert";
import Loadable from "react-loadable";
import {FormattedMessage, FormattedDate, FormattedNumber, FormattedPlural, FormattedTime} from 'react-intl';
import {Context} from "Languages/Wrapper/Wrapper";
import * as authActions from "redux/actions/AuthActions";
import "../Login/Loginscss/login.scss";
import { Userrollbtn } from "components/auth/Userrollbtn.js/Userrollbtn";
import storage from "utils/storage";
import classNames from "classnames";
import { Socialbtn } from "components/commoncomponent/socialbtn/Socialbtn";
import { regexValidation } from "utils/regexValidation";
import errorMessages from "utils/errorMessages";
import { useIntl } from "react-intl";
import userIcon from "../../../../assets/images/jobseekerIcon.svg" // Jobseeker Icon on Login Screen

export const JobSeekerlogin = ({ history }) => {
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState("");
  const [isChecked, setIsChecked] = useState(true); // variable use for checkbox is checked or notconst translate = useIntl();
  const [userOptIn, setWhatsappStatus] = useState(true); // used to set unset whatsapp checkbox variable
  const translate = useIntl();
  const { register, handleSubmit, errors } = useForm({
    mode: "onChange",
  });
  /**
   * method to login/register user using mobile number
   * @param {Object} data user login data
   */
  const login = async (data) => {
    try{
      storage.set("humlog_user_contact", data.contact);
      storage.set("user_tnc", data.tnc);
      delete data.tnc;
      const res = await dispatch(authActions.getOTP({ data }))
      if (res.value.success) {
        if(history.location.state) {
          history.push({pathname : "login/VerifyOTP", state : history.location.state});
          return;
        }
        history.push("login/VerifyOTP");
      }
    } catch(err) {
      if(err.data && err.data.hasOwnProperty('error_code')) {
        if(err.data.error_code === 'ALREADY_REGISTERED')
        setErrorMessage(errorMessages.AUTH_ERROR.ALREADY_REGISTERED_EMP);
      }
      setTimeout(()=>{setErrorMessage("")},5000)
      console.log("error in job seeker login",err);
    }
  };

  const context = useContext(Context);

  const changeLang = (lang) => {
    storage.set("lang",lang);
    context.selectLanguage(lang)
  }
  // Handing checkbox is checked or not
  const handleOnChange = () => {
    setIsChecked(!isChecked);
  };
  const handleWhatsappCheckStatus =()=> {
    setWhatsappStatus(!userOptIn);
  }
  return (
    <>
      <div>
        <div className="main_login">
          <div className="login_main_inner">
            <div className="row  no-gutters">
              <div className="col-md-4 p-0"></div>
              <div className="col-md-4 sha p-3">
                <div className=" login_aa ">
                  <div className="d-flex justify-content-center ">
                    <img src={logoa} width="50%" alt="logo" />
                  </div>
                  <div className="d-flex justify-content-center mt-3">
                    <h2 >
                      <strong className={context.locale == 'hi' ? "logo_cc" : "logo_bb"} onClick={() => changeLang('hi')}>हिन्दी/</strong><strong className={context.locale == 'en' ? "logo_cc" : "logo_bb"} onClick={() => changeLang('en')}>English</strong>
                    </h2>
                  </div>
                  {/* Start of Jobseeker Icon on Login Screen */}
                  <div className="d-flex justify-content-center my-2">
                      <img height="150px" src={userIcon} />
                  </div>
                  {/* End of Jobseeker Icon on Login Screen */}
                  <div className="mt-1">
                    <div className="pxx ">
                      <div
                        className={
                          history.location.pathname == "/JobSeekerlogin"
                            ? "btn_a mr-1"
                            : "btn_b mr-1 text-center"
                        }
                        id="bto"
                        onClick={() => history.push("/JobSeekerlogin")}
                      >
                        <FormattedMessage id="jobSeekerText"/>
                      </div>
                    </div>
                  </div>
                  <div className=" mt-3">
                    {/* ----------- Commenting Code for future reference [ Login with Social Network ] ----------- */}
                    {/* <h2 className="login_ba text-center mt-1"><FormattedMessage
                      id="logIn"
                      defaultMessage="Login"
                    /></h2>
                    <h2 className="login_ba text-center ">
                      {" "}
                      <FormattedMessage
                        id="socialNetwork"
                        defaultMessage="With your social network"
                      />
                    </h2>
                    <Socialbtn  isChecked={isChecked}/>
                    <div className="text-center mt-3">
                      <p className="fnt"><FormattedMessage
                        id="or"
                        defaultMessage="or"
                      /> </p>{" "}
                    </div> */}
                    <div className="mt-2 ">
                      <h2 className="login_ba text-center  ">
                        {" "}
                        <FormattedMessage
                          id="loginMobileNumber"
                          defaultMessage="Login with mobile number"
                        />{" "}
                      </h2>
                      <div className="mt-3">
                        <form onSubmit={handleSubmit(login)}>
                          <div className="login_details">
                            <p className="text-center p-0 mb-1">
                              {" "}
                              <FormattedMessage
                                id="enterTenDigit"
                                defaultMessage="Enter 10 digit mobile number"
                              />{" "}
                            </p>
                            <input
                              placeholder="+91 xxx xxx xx xx"
                              name="contact"
                              autocomplete="off"
                              className={classNames("py-2 form-control", {
                                "is-invalid": errors.contact,
                              })}
                              ref={register({
                                required: translate.formatMessage({
                                  id: "enterMobileNumber",
                                }),
                                pattern: {
                                  value: regexValidation.PHONE,
                                  message: translate.formatMessage({
                                    id: "enterMobileNumber",
                                  }),
                                },
                              })}
                            />
                            {errors.contact && (
                              <p className="text-danger p-0 mt-1 tnc_alrt">
                                {errors.contact.message}
                              </p>
                            )}
                            <div className="form-check mt-1">
                              <input
                                className="form-check-input mtt"
                                autocomplete="off"
                                type="checkbox"
                                id="gridCheck"
                                checked={isChecked}
                                onChange={handleOnChange}
                                ref={register({
                                  required:
                                  translate.formatMessage({
                                    id: "pleaseTickCheckbox",
                                  })
                                })}
                                name="tnc"
                              />
                              <label
                                className="form-check-label "
                                htmlFor="gridCheck  otp_tc"
                              >
                                <p className="  tc p-0 m-0">
                                  {" "}
                                  <FormattedMessage id="privacyPolicy"
                                    defaultMessage="I hereby agree to the T&C and the Privacy Policy"
                                    values={{
                                      tnc: <a className="text-decoration-none text-underline bbg"
                                        onClick={() => history.push("/terms&conditions")}>
                                        {" "} T&C </a>,
                                      privacy: <a className="text-decoration-none  text-underline bbg"
                                        onClick={() => history.push("/privacypolicy")}>
                                        {" "}Privacy Policy{" "}</a>
                                    }} />
                                </p>{" "}
                              </label>
                              {errors.tnc && (
                                <p className="text-danger p-0 m-0 tnc_alrt">
                                  {errors.tnc.message}
                                </p>
                              )}
                            </div>
                            <div className="form-check mt-1">
                              <input
                                className="form-check-input mtt"
                                autocomplete="off"
                                type="checkbox"
                                id="gridChecks"
                                checked={userOptIn}
                                onChange={handleWhatsappCheckStatus}
                                  ref={register()}
                                name="opt_in"
                              />
                              <label
                                className="form-check-label "
                              >
                                <p className="  tc p-0 m-0">
                                  <FormattedMessage 
                                    id="optInMessage"
                                    defaultMessage="Connect on WhatsApp for latest updates"
                                  />
                                </p>
                              </label>
                            </div> 
                            <div className="mt-3">
                              <button className="common_btn login_btn" type="submit">
                                <FormattedMessage
                                  id="sendOtp"
                                  defaultMessage="Send OTP"
                                />
                              </button>
                            </div>
                          </div>
                        </form>
                        <div className="mt-2">
                          {errorMessage && errorMessage.length > 0 && (
                            <Alert severity="error">{errorMessage} !</Alert>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4"> </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
