import React, { useState } from "react";
import ChatBot from "react-simple-chatbot";
import { ThemeProvider } from "styled-components";
import steps from "./Steps"; // question-answer steps for chat
import botAvatar from "../../assets/images/hlj_chatbot_avatar.png"; // Bot profile Avatar
import { endPoint, selectedType, userType } from "config/app.constant";

const ChatBox = () => {
    const [open, setOpen] = useState(false);
    // Toggle chat box Open/close
    const toggleChat = () => {
        setOpen(!open);
    };
    // Function trigger on chat end i.e last message submit
    const pushEnd = (e) => {
        let redirect = true;
        if (e.values[1] === selectedType.FEEDBACK || e.values[1] === selectedType.QUERY) { // if selected 0: Review  1: Customer Support 
            redirect = false // if signup option is not selected
        }
        if (redirect) {
            setTimeout(() => {
                if (e.values[0] === userType.JOB_SEEKER) { // for Jobseeker
                    window.location.href = endPoint.JOB_SEEKER_BASIC_DETAILS;
                } else if (e.values[0] === userType.EMPLOYER) { // for employer
                    window.location.href = endPoint.EMPLOYER_BASIC_DETAILS;
                }
            }, 3000);
        }
    }

    // Customize theme for Chat bot UI
    const theme = {
        background: "#f5f8fb",
        fontFamily: "Helvetica Neue",
        headerBgColor: "#ED7724",
        headerFontColor: "#fff",
        headerFontSize: "15px",
        botBubbleColor: "#127fcc",
        botFontColor: "#fff",
        userBubbleColor: "#ED7724",
        userFontColor: "white",
    };
    // ***** Commenting this code for future reference *****
    // useEffect(() => {
    // Open chat automatically after 3 seconds on Landing on Home page
    // setTimeout(() => {
    //     setOpen(true);
    // }, 3000);
    // }, []);
    return (
        <>
            <ThemeProvider theme={theme}>
                <ChatBot
                    handleEnd={(e) => { pushEnd(e) }}
                    steps={steps}
                    floating={true}
                    opened={open}
                    toggleFloating={toggleChat}
                    botAvatar={botAvatar}
                    headerTitle={'HumlogJobs Support Team'}
                />
            </ThemeProvider>
        </>
    );
};

export default ChatBox;