import { JobView } from "components/jobseeker/jobmatch/jobview"
import { JobDetails } from "components/recruiter/jobformdetails/JobDetails";
import storage from "utils/storage";

export const Jobs = () => {
  if (storage.get("humlog_user_role") === 0) {
    return (
      <JobView />
    );
  } else return <JobDetails />
}