import React, { useEffect, useState } from "react";
import avatar from "../../../assets/images/male_avatar.png";
export const Image = (props) => {
  return (
    <>
      <img
        className="playerProfilePic_home_tile rounded-circle"
        src={props.picture}
        width="100%"
        height="100%"
        onError={(e) => {
          e.target.onError = null;
          e.target.src = avatar;
        }}
        alt={props.alt}
      />
    </>
  );
};
