import "./companyprofile.scss";
import React, { useEffect, useState } from "react";
import {FormattedMessage, FormattedDate, FormattedNumber, FormattedPlural, FormattedTime} from 'react-intl';
import { useIntl } from 'react-intl';
import { Form } from "react-bootstrap";
import classNames from "classnames";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import * as userActions from "redux/actions/UserActions";
import * as JobActions from "redux/actions/JobActions";
import { useHistory } from "react-router-dom";
import storage from "utils/storage";
import Alert from "@material-ui/lab/Alert";
import { DistrictList } from "components/jobseeker/DistrictList";
import { Player } from "components/shared/videoplayer/player";
import * as commonService from "utils/CommonService.js";
import { regexValidation } from "utils/regexValidation";
import { uploadFile } from "../../../utils/fileService";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { CompanyVerification } from "../companyverification/CompanyVerification";
import ConfirmDialog from "components/shared/ui-components/common-dialog";
import uploadi from "../../../assets/images/uploadi.png";
import industry1 from "../../../assets/images/industry.svg";
import { FaIndustry, FaRegPlayCircle, FaMapPin, FaRegEdit, FaTimesCircle } from "react-icons/fa";
import { AiOutlineMail } from "react-icons/ai";
import { BsPerson, BsPencil, BsBriefcase, BsBook, BsFillCameraVideoFill } from "react-icons/bs";
import { MdPhoneInTalk } from "react-icons/md";
// import { emailicon } from "../../../assets/images/email.png";
// import industry from "../../../assets/images/industry.png";
// import { AiOutlineHome } from "react-icons/ai";
// import { HiOutlineMail } from "react-icons/hi";
// import { FaBuilding } from "react-icons/fa";
// import { serialize } from "object-to-formdata";
// import { registerRoute } from "workbox-routing";
// import { IndustryData } from "../IndustryData";
// import TextField from "@material-ui/core/TextField";
// import Autocomplete from "@material-ui/lab/Autocomplete";


export const CompanyProfileform = (props) => {
  const DistrictListt = DistrictList.sort();
  const [count, setCount] = useState(500);
  const history = useHistory();
  const dispatch = useDispatch();
  const { userDetails } = useSelector((state) => state.userReducer);
  const [errorMessage, setErrorMessage] = useState("");
  const [vedioErr,setVedioErr] = useState(null)
  const [companyVideo, setCompanyVideo] = useState();
  const [modal, setModal] = useState(false);
  const [value, setValue] = useState();
  const [inputValue, setInputValue] = useState();
  const [isError, setisError] = useState({open: false, message: ''});
  const { register, handleSubmit, errors, reset, control } = useForm({
    mode: "onSubmit",
  });
  const [organizationType, setOrganizationType] = useState("");
  const [companyName, setCompnayName] = useState("");
  const [loaded, setLoaded] = useState(false);
  const [editOrganization, setEditOrganization] = useState(false);
  const [dialog, setDialog] = useState({
    open: false,
    message: "",
    type: "",
    confirm_text: "",
    cancel_text: "",
  });
  const [jobDetails, setJobDetails] = useState([]);
  const intl = useIntl();

  useEffect(() => {
    setOrganizationType(userDetails && userDetails.organization_type ? userDetails.organization_type : "");
    setValue(userDetails.address_home);
    loadProfile();
    if (!loaded) {
      setLoaded(true);
      /**
       * @description if company name not found in user details then company name will fetch by 1st job post from job post API
       * @function getJobDetails()
       */
      if (userDetails && !userDetails.company_name) {
        getJobDetails();
      }
    }
  },[loaded, userDetails, dispatch]);

  /**
   * @description get the job details from API
   */
  const getJobDetails = async () => {
    try {
      let response = await dispatch(JobActions.getJobListDataById(storage.get("humlog_user")));
      if (response && response.value.success) {
        setJobDetails(response.value.data);
      }
    } catch (error) {
      console.log("failed to get Job Details",error)
    }
  }

  const loadProfile = () => {
    let data = userDetails;
    data.industry = String(userDetails.industry);
    reset(data);
  };  

 // save company video
  const uploadVedioResume = async (e) => {
    let video = e.target.files[0];
    if(!commonService.validateFileContentTypeAndSize(video.type, 'video')){
      setisError({open: true, message : 'Please upload the correct format file.'});
      setTimeout( () => {setisError(false)}, 5000);
      return;
    }
    if(!commonService.validateFileSize(video.size, 200)){
      setisError({open: true, message : 'File size should be less than 200 MB'});
      setTimeout( () => {setisError(false)}, 5000);
      return;
    }
    setCompanyVideo(video);
  };
  /**
   * @description update profile function
   * @param {object} val 
   */
  const update = async (val) => { 
    if(inputValue) {
      val.address_home = inputValue;
    }
    try {
      let imageUrl;
      let videoUrl;
      // if about company available then send 
      // if not then delete from data
      if(val.hasOwnProperty('about_company') && !val.about_company.length) {
        delete val.about_company
      }
      // if organization email available then send 
      // if not then delete from data
      if(val.hasOwnProperty('organization_email') && !val.organization_email.length) {
        delete val.organization_email
      }
      let data = { ...val, _id: storage.get("humlog_user"), profile_status: 2 };
    // upload company_logo
    if(props.company_logo) {
      imageUrl = await uploadFile(props.company_logo,'profile');
      data.profile_pic = imageUrl;
    }
    //upload company video
    if(companyVideo){
      videoUrl = await uploadFile(companyVideo,'video');
      data.video_resume = videoUrl;
    }
    dispatch(userActions.updateUserData(data))
      .then((res) => {
        if (res.value.success) {
          if (userDetails && (userDetails.verification_status === "Not Initiated" ||
            !userDetails.verification_status)) {
            history.push("/companyverification");
          } else {
            history.push("/dashboard");
          }
        }
      })
      .catch((err) => setErrorMessage(err.data.message));
    }catch (e) {
      setErrorMessage(e);
      console.log("failed to update profile",e)
    }
  };

  const onToggleModal = () => {
    setModal(!modal);
  };

  /**
   * @description method to open company verification modal
   */
  const [companyVerificationModal, setCompanyVerificationModal] = useState(false);
  const openVerificationModal = (val) => {
    setCompanyVerificationModal(true);
  };
  const closeCompanyVerificationModal = () => {
    setCompanyVerificationModal(false);
  };

  /**
   * @description method to check Hiring type
   * @param {string} val 
   */
  const organizationTypeChange = (val) => {
    if (val == "company") {
      setOrganizationType("company");
      if (userDetails && !userDetails.company_name && !userDetails.organization_type) {
        setCompnayName(userDetails && userDetails.company_name ? userDetails.company_name : jobDetails && jobDetails.length ? jobDetails[0].company_name : "");
        openVerificationModal(val);
        // open company verification modal when user not verified 
        // and click on radio buttons to choose organization type
      }
    } else {
      setOrganizationType("consultancy");
      if (userDetails && !userDetails.company_name && !userDetails.organization_type) {
        setCompnayName(userDetails && userDetails.company_name ? userDetails.company_name : jobDetails && jobDetails.length ? jobDetails[0].company_name : "");
        openVerificationModal(val);
        // open company verification modal when user not verified 
        // and click on radio buttons to choose organization type
      }
    }
  }

  // CODE COMMENTED FOR FUTURE REFERENCE
  // const checkIsVerify = () => {
  //   if (userDetails && userDetails.verification_status === "In Progress" && !editOrganization) {
  //     return (
  //       setDialog({
  //         open: true,
  //         message: intl.formatMessage({ id: "yourVerification" }),
  //         type: "In_Progress",
  //         confirm_text: intl.formatMessage({ id: "okay" }),
  //       })
  //     )
  //   } else if (userDetails && userDetails.verification_status === "Approved" && !editOrganization) {
  //     return (
  //       setDialog({
  //         open: true,
  //         message: "Please Click on Edit Button",
  //         type: "Approved",
  //         confirm_text: intl.formatMessage({ id: "okay" }),
  //       })
  //     )
  //   }
  //   if (userDetails && userDetails.organization_type !== "") { // new code for if wanted to show popup that it is not editable
  //     return (
  //       setDialog({
  //         open: true,
  //         message: "This cannot be edited once filled",
  //         type: "nonEditable",
  //         confirm_text: intl.formatMessage({ id: "okay" }),
  //       })
  //     )
  //   }
  // }
  
  /**
   * @description method to edit organization type
   */
  const editOrganizationType = () => {
    setDialog({
      open: true,
      message: (<p>Do you want to change <br/>"I am Hiring for" ?</p>),
      type: "change",
      confirm_text: intl.formatMessage({ id: "okay" }),
    })
  }

  /**
   * @description method to cancel edit organization type
   */
  const cancleEditOrganization = () => {
    if (editOrganization) {
      setEditOrganization(false);
    }
  }
  /**
   * @description method to confirm modal button
   */
  const confirmDialogBox = () => {
    setDialog({ open: false, message: "" });
    // if dialog type is change than edit option of organization type is visible
    if (dialog.type == "change") {
      setEditOrganization(true);
    }
  };

  /**
   * @description method to check the validation for email in which 
   * gmail, yahoo, hotmail is not included
   * @param {string} val 
   * @returns true or false
   */
  const emailValidtion = (val) => {
    return !(val.match(regexValidation.EMAILFILTERS));
  }
  
  return (
    <>
      {dialog.open && (
        <ConfirmDialog
          open={dialog.open}
          noCancelbtn={
            ["In_Progress", "Approved", "Deactivated", "nonEditable"].includes(dialog.type)
              ? true
              : false
          }
          confirm_text={dialog.confirm_text}
          cancel_text={dialog.cancel_text}
          setState={setDialog}
          message={dialog.message}
          confirm={confirmDialogBox}
        ></ConfirmDialog>
      )}
      <div className="employer_profile_box">
        <div className="row no-gutters">
          <div className="col-12">
            <div className="mt-3">
              <Form onSubmit={handleSubmit(update)}>
                {/* name field start */}
                <Form.Group controlId="name">
                  <Form.Label className="emp_profile_label">
                    <BsPerson className="iconn_pro" />
                    <FormattedMessage id="name" defaultMessage="Name" />
                    <font className="text-danger">*</font>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    autoComplete="off"
                    placeholder={intl.formatMessage({ id: 'addPersonName' })}
                    defaultValue={userDetails && userDetails.name ? userDetails.name : ''}
                    className={classNames("form-control", {
                      "is-invalid": errors.name,
                    })}
                    ref={register({
                      required: intl.formatMessage({ id: 'pleaseEnterPersonName' }),
                      pattern: {
                        value: regexValidation.PERSON_NAME,
                        message: intl.formatMessage({ id: "specialCharacterNotAllowed" }),
                      },
                      maxLength: {
                        value: 25,
                        message: intl.formatMessage({ id: "maximumSeventyFiveDigit" }),
                      },
                    })}
                    name="name"
                    readOnly
                  />
                  {errors.name && (
                    <p className="text-danger txt">
                      {errors.name.message}
                    </p>
                  )}
                </Form.Group>
                {/* name field end */}

                {/* email field start */}
                <Form.Group controlId="email">
                  <Form.Label className="emp_profile_label">
                    <AiOutlineMail className="iconn_pro" />
                    <FormattedMessage id="email" defaultMessage="Email" />
                    <font className="text-danger">*</font>
                  </Form.Label>
                  <Form.Control
                    type="email"
                    require
                    autoComplete="off"
                    placeholder={intl.formatMessage({ id: "enterEmailAddress" })}
                    defaultValue={userDetails && userDetails.email ? userDetails.email : ''}
                    className={classNames("form-control", {
                      "is-invalid": errors.email,
                    })}
                    ref={register({
                      required: intl.formatMessage({ id: "enterEmail" }),
                      pattern: {
                        value: regexValidation.EMAIL,
                        message: intl.formatMessage({ id: "emailValid" }),
                      }
                    })}
                    name="email"
                    readOnly
                  />
                  {errors.email && (
                    <p className="text-danger txt">
                      {errors.email.message}
                    </p>
                  )}
                </Form.Group>
                {/* email field end */}

                {/* contact field start */}
                <Form.Group controlId="number">
                  <Form.Label className="emp_profile_label">
                    <MdPhoneInTalk className="iconn_pro mr-1" />
                    <FormattedMessage id="mobilenumber" defaultMessage="Mobile Number" />
                    <font className="text-danger">*</font>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    autoComplete="off"
                    placeholder={intl.formatMessage({ id: "employerMobileNumber", defaultMessage:"Enter your mobile number" })}
                    defaultValue={userDetails && userDetails.contact ? userDetails.contact : ''}
                    className={classNames("form-control", {
                      "is-invalid": errors.contact,
                    })}
                    ref={register({
                      required: intl.formatMessage({ id: "enterContactNumber" }),
                      pattern: {
                        value: regexValidation.PHONE,
                        message:
                          intl.formatMessage({ id: "enterValidContact" }),
                      },
                    })}
                    name="contact"
                    readOnly
                  />
                  {errors.contact && (
                    <p className="text-danger txt">
                      {errors.contact.message}
                    </p>
                  )}
                </Form.Group>
                {/* contact field end */}

                {/* hiring field start */}
                <div className="hiringbox">
                  <p className="hiringfor">
                    <FormattedMessage id="hiringfor" defaultMessage="I am hiring staff for ?" />
                    <font className="text-danger ml-1">*</font>
                    {/* CODE COMMENTED FOR FUTURE REFERENCE */}
                    {/* {!editOrganization ? (
                      <span className="editOrganizationType" onClick={editOrganizationType}><FaRegEdit /></span>
                    ) : <span className="editOrganizationType" onClick={cancleEditOrganization}><FaTimesCircle /></span>} */}
                  </p>

                  <div className={"hiringInner " + ((userDetails && userDetails.organization_type && !editOrganization) ? 'readOnly' : '')}>
                    {/* CODE COMMENTED FOR FUTURE REFERENCE */}
                    {/* <Form.Group controlId="company" className="form_radio_box company_radio_box" onClick={checkIsVerify}> */}
                    <Form.Group controlId="company" className="form_radio_box company_radio_box">
                      <Form.Control 
                        type="radio" 
                        autoComplete="off" 
                        name="organization_type" 
                        value="company" 
                        key="company" 
                        className={classNames("form-control-radio", {
                          "is-invalid": errors.organization_type,
                        },
                        {"readOnly" : userDetails && userDetails.organization_type && !editOrganization}
                        )}
                        onChange={(e) => {
                          organizationTypeChange(e.target.value)
                        }}
                        ref={register({
                          required: intl.formatMessage({ id: "enterorganizationtype" }),
                        })}
                      />
                      <Form.Label className="emp_profile_label">
                        <FormattedMessage id="mycompany" defaultMessage="My Company" />
                      </Form.Label>
                      {errors.organization_type && (
                        <p className="text-danger txt">
                          {errors.organization_type.message}
                        </p>
                      )}
                    </Form.Group>

                    <Form.Group controlId="client" className="form_radio_box">
                      <Form.Control 
                        type="radio" 
                        autoComplete="off" 
                        name="organization_type" 
                        value="consultancy" 
                        key="consultancy" 
                        className={classNames("form-control-radio", {
                          "is-invalid": errors.organization_type,
                        },
                        {"readOnly" : userDetails && userDetails.organization_type && !editOrganization}
                        )}
                        onChange={(e) => {
                          organizationTypeChange(e.target.value)
                        }}
                        ref={register({
                          required: intl.formatMessage({ id: "enterorganizationtype" }),
                        })}
                      />
                      <Form.Label className="emp_profile_label">
                        <FormattedMessage id="myclient" defaultMessage="My Client" />
                      </Form.Label>
                      {errors.organization_type && (
                        <p className="text-danger txt">
                          {errors.organization_type.message}
                        </p>
                      )}
                    </Form.Group>
                  </div>
                </div>
                {/* hiring field end */}

                
                {/* show if company name and organization type is found in userdetails */}
                {
                  userDetails && userDetails.company_name && userDetails.organization_type ?
                    (
                      <>
                        {/* company/consultancy name field start */}
                        <Form.Group controlId="company_name">
                          <Form.Label className="emp_profile_label">
                            <FaIndustry className="iconn_pro mr-1" />
                            {organizationType && organizationType == "company" ?
                              (<FormattedMessage id="companyname" defaultMessage="Company Name" />) :
                              (<FormattedMessage id="consultancyname" defaultMessage="Consultancy Name" />)
                            }
                            <font className="text-danger">*</font>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            autoComplete="off"
                            placeholder=
                            {organizationType && organizationType == "company" ?
                              (intl.formatMessage({ id: "addCompanyName" })) :
                              (intl.formatMessage({ id: "addConsultancyName" }))
                            }
                            className={classNames("form-control", {
                              "is-invalid": errors.company_name,
                            })}
                            ref={register({
                              required: intl.formatMessage({ id: "enterCompanyName" }),
                              minLength: {
                                value: 5,
                                message: intl.formatMessage({ id: "minimumDigitFive" }),
                              },
                              maxLength: {
                                value: 50,
                                message: intl.formatMessage({ id: "maximumFiftyDigit" }),
                              },
                              pattern: {
                                value: regexValidation.COMPANY_NAME,
                                message: intl.formatMessage({ id: "allowSpecialCharacter" }),
                              },
                            })}
                            name="company_name"
                            defaultValue={userDetails && userDetails.company_name ? userDetails.company_name : jobDetails && jobDetails.length ? jobDetails[0].company_name : ""}
                            readOnly
                          />
                          {errors.company_name && (
                            <p className="text-danger txt">
                              {errors.company_name.message}
                            </p>
                          )}
                        </Form.Group>
                        {/* company/consultancy name field end */}

                        {/* organization email field start */}
                        <Form.Group controlId="organization_email">
                          <Form.Label className="emp_profile_label">
                            <FaIndustry className="iconn_pro mr-1" />
                              <FormattedMessage id="organizaionEmail" defaultMessage="Organization Email" />
                            {/* <font className="text-danger">*</font> */}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            autoComplete="off"
                            placeholder={intl.formatMessage({ id: "addOrganizationEmail" })}
                            className={classNames("form-control", {
                              "is-invalid": errors.organization_email,
                            })}
                            ref={register({
                              // required: intl.formatMessage({ id: "emailValid" }),
                              validate: {
                                validation: (value) =>
                                  emailValidtion(value) ||
                                  intl.formatMessage({ id: "consultancyemail" })
                              },
                            })}
                            name="organization_email"
                            defaultValue={userDetails && userDetails.organization_email ? userDetails.organization_email : ""}
                            readOnly={userDetails && userDetails.organization_email && ((userDetails.verification_status) && userDetails.verification_status == "Approved")}
                          />
                          {errors.organization_email && (
                            <p className="text-danger txt">
                              {errors.organization_email.message}
                            </p>
                          )}
                        </Form.Group>
                        {/* organization email field end */}

                        {/* about field start */}
                        <Form.Group controlId="about">
                          <Form.Label className="d-flex justify-content-start llbl">
                            <img src={industry1} alt="industry" width="16px" height="20px" className="mr-1" />
                            {organizationType && organizationType == "company" ? (
                              <FormattedMessage id="aboutcompany" defaultMessage="About Company" />
                            ) : (
                              <FormattedMessage id="aboutconsultancy" defaultMessage="About Consultancy" />
                            )}
                          </Form.Label>
                          <Form.Control
                            as="textarea"
                            autoComplete="off"
                            maxLength={500}
                            placeholder=
                            {organizationType && organizationType == "company" ?
                              (
                                intl.formatMessage({ id: "companyDescription" })
                              ) : (
                                intl.formatMessage({ id: "consultancyDescription" })
                              )
                            }
                            onChange={(e) =>
                              setCount(500 - e.target.value.length)
                            }
                            className={classNames("form-control aboutmecompany", {
                              "is-invalid": errors.about_company,
                            })}
                            ref={register({
                              maxLength: {
                                value: 500,
                                message:
                                  "Desscription should be less than 500 characters",
                              },
                            })}
                            defaultValue={userDetails && userDetails.about_company ? userDetails.about_company : ''}
                            name="about_company"
                          />
                          <div className="text-right">
                            <p className="p-0 m-0">
                              {!errors.about_company && `${count}`}
                            </p>
                          </div>

                          {errors.about_company && (
                            <p className="text-danger oom p-0 m-0">
                              {errors.about_company.message}
                            </p>
                          )}
                        </Form.Group>
                        {/* about field end */}

                        {/* video field start */}
                        {/* video field shows if hiring for my company */}
                        {organizationType && organizationType == "company" ?
                          (
                            <>
                              <div className="profile_field_box">
                                <div className="label_box">
                                  <h6 className="user_one">
                                    <img src={uploadi} className="user_icon mt-1" />
                                    <FormattedMessage id="companyVideo" defaultMessage="Company Video" />
                                  </h6>
                                  <div>
                                    <input type="file" accept="video/*" className="d-none" id="vedio-resume" onChange={uploadVedioResume} />
                                    <label htmlFor="vedio-resume" className="mt-2">
                                      <BsPencil className="user_icon pencil" />
                                    </label>
                                  </div>
                                </div>
                              </div>
                              {vedioErr?.length > 0 && <div className="text-danger">{vedioErr}</div>}
                              {
                                userDetails && userDetails.video_resume
                                  ? <div className="text-left">
                                    <p className="user_tt video_box">
                                      {" "}
                                      {userDetails && userDetails.company_name && userDetails.company_name.length > 0
                                        ? userDetails.company_name
                                        : ("")}
                                    </p>
                                    <button type="button" className="video_btn" onClick={onToggleModal} disabled={userDetails && userDetails.video_resume ? false : true}><FaRegPlayCircle className="btn_icon" /></button>
                                  </div>
                                  : ("")
                              }
                              <Player open={modal} toggleModal={onToggleModal} src={`${userDetails.video_resume}?${Date.now()}`} />
                            </>
                          ) : ("")
                        }
                        {/* video field end */}

                        <button className="common_btn mt-4 mb-2" type="submit">
                          <FormattedMessage id="submit" defaultMessage="Submit" />
                        </button>
                      </>
                    ) : ("")
                }

                
              </Form>
            </div>
          </div>
        </div>
      </div>

      <Dialog
        open={companyVerificationModal}
        onClose={closeCompanyVerificationModal}
        className=""
      >
        <DialogContent className="">
          <div className="company_verification_modal">
            <CompanyVerification organization_type={organizationType} company_name={companyName} />
          </div>
        </DialogContent>
      </Dialog>

      {/*THIS CODE COMMENTED BECAUSE USE FOR FUTURE REFERENCE START FROM HERE*/}
      {/* <div className="mt-">
        <div className="row no-gutters">
          <div className="col-12 ">
            <div className="">
              <div className="text-center">
                <div>
                  <div className="mt-3">
                    <Form onSubmit={handleSubmit(update)}>
                      <Form.Group controlId="exampleForm.ControlInput1 ">
                        <Form.Label className="d-flex justify-content-start llbl">
                          {" "}
                          <img
                            src={industry}
                            alt="industry"
                            width="16px"
                            height="20px"
                            className="mr-1"
                          />{" "}
                          <FormattedMessage id = "industry" defaultMessage="Industry" />
                          <font className="text-danger">*</font>
                        </Form.Label>
                        <Form.Control
                          type='text'
                          ref={register}
                          name="company_category"
                          defaultValue={userDetails && userDetails.company_category ? userDetails.company_category : ''}
                          readOnly
                        >
                        </Form.Control>
                      </Form.Group>

                      <Form.Group controlId="exampleForm.ControlSelect1">
                        <Form.Label className="d-flex justify-content-start llbl">
                          <FaBuilding className="iconn_pro mr-1" />
                          <FormattedMessage
                            id="companyAddress"
                            defaultMessage="Company Address"
                          />
                          <font className="text-danger">*</font>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          autoComplete="off"
                          defaultValue={userDetails && userDetails.company_address ? userDetails.company_address : ''}
                          placeholder={intl.formatMessage({
                            id: "addCompanyAddress",
                            defaultMessage: "Add your company address"
                          })}
                          name="company_address"
                          className={classNames("form-control", {
                            "is-invalid": errors.company_address,
                          })}
                          ref={register({
                            required: intl.formatMessage({id: "enterCompanyAddress"}),
                            minLength: {
                              value: 4,
                              message: intl.formatMessage({id: "minimumFourDigit"}),
                            },
                            maxLength: {
                              value: 100,
                              message: intl.formatMessage({id: "maximumHundredCharacter"}),
                            },
                            pattern: {
                              value: regexValidation.COMPANY_ADDRESS,
                              message: "Company Address can contain alphanumeric characters and special character ('.', ',', '-'). Company Address can not start with special characters",
                            },
                          })}
                        />
                        {errors.company_address && (
                          <p className="text-danger  txt">
                            {errors.company_address.message}
                          </p>
                        )}
                      </Form.Group>

                      <Form.Group controlId="exampleForm.ControlSelect1">
                        <Form.Label className="d-flex justify-content-start llbl">
                          <AiOutlineHome className="iconn_pro mr-1" />
                          <FormattedMessage
                            id="address"
                            defaultMessage="Address"
                          /><font className="text-danger">*</font>
                        </Form.Label>
                        <Controller
                          as={({ onChange, ...props }) => (
                            <autoComplete
                              options={DistrictList}
                              inputValue={value}
                              disableClearable
                              style={{ width: "100%" }}
                              className="p-0 m-0"
                              defaultValue={value}
                              onInputChange={(event, newInputValue) => {
                                setInputValue(newInputValue);
                              }}
                              id="combo-box-demo"
                              onChange={(event, newValue) => {
                                setValue(newValue);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  placeholder={intl.formatMessage({id: "addAddress"})}
                                  variant="outlined"
                                />
                              )}
                            />
                          )}
                          name="address_home"
                          control={control}
                        />
                        {errors.address_home && (
                          <p className="text-danger txt">
                            {errors.address_home.message}
                          </p>
                        )}
                      </Form.Group>
                      <Form.Group controlId="exampleForm.ControlSelect1">
                        <Form.Label className="d-flex justify-content-start llbl">
                          <FaMapPin className="iconn_pro mr-1" />
                          <FormattedMessage
                            id="pinCode"
                            defaultMessage="Pincode"
                          />
                          <font className="text-danger">*</font>
                        </Form.Label>
                        <Form.Control
                          type="number"
                          autoComplete="off"
                          placeholder={intl.formatMessage({
                            id: "addPincode",
                            defaultMessage: "Enter Pincode"
                          })}
                          defaultValue={userDetails && userDetails.pincode ? userDetails.pincode : ''}
                          name="pincode"
                          className={classNames("form-control", {
                            "is-invalid": errors.pincode,
                          })}
                          ref={register({
                            required: intl.formatMessage({id:"enterPincode"}),
                            minLength: {
                              value: 6,
                              message: intl.formatMessage({id: "minimumSixDigit"}),
                            },
                            maxLength: {
                              value: 6,
                              message: intl.formatMessage({id: "maximumSixDigit"}),
                            },
                            pattern: {
                              value: /^\d/,
                              message: intl.formatMessage({id: "enterNumericValue"})
                            }
                          })}
                        />
                        {errors.pincode && (
                          <p className="text-danger  txt">
                            {errors.pincode.message}
                          </p>
                        )}
                      </Form.Group>

                      <Form.Group controlId="exampleForm.ControlSelect1">
                        <Form.Label className="d-flex justify-content-start llbl">
                          <BsPerson className="iconn_pro mr-1" />
                          <FormattedMessage
                          id = "personName"
                          defaultMessage="Contact Person Name"
                      />
                          <font className="text-danger">*</font>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          autoComplete="off"
                          placeholder={intl.formatMessage({ id: 'addPersonName' })}
                          defaultValue={userDetails && userDetails.name ? userDetails.name : ''}
                          className={classNames("form-control", {
                            "is-invalid": errors.name,
                          })}
                          ref={register({
                            required: intl.formatMessage({ id: 'pleaseEnterPersonName' }),

                            pattern: {
                              value: regexValidation.PERSON_NAME,
                              message: intl.formatMessage({id: "specialCharacterNotAllowed"}),
                            },

                            maxLength: {
                              value: 25,
                              message: intl.formatMessage({id: "maximumSeventyFiveDigit"}),
                            },
                          })}
                          name="name"
                          readOnly
                        />

                        {errors.name && (
                          <p className="text-danger  txt">
                            {errors.name.message}
                          </p>
                        )}
                      </Form.Group>

                      <Form.Group controlId="exampleForm.ControlSelect1">
                        <Form.Label className="d-flex justify-content-start llbl">
                          <AiOutlineMail className="iconn_proo mr-1 mt-1" />
                          <FormattedMessage
                          id = "emailAdd"
                          defaultMessage="Email Address"
                    /><font className="text-danger">*</font>
                        </Form.Label>
                        <Form.Control
                          type="email"
                          require
                          autoComplete="off"
                          placeholder={intl.formatMessage({id: "enterEmailAddress"})}
                          className={classNames("form-control", {
                            "is-invalid": errors.email,
                          })}
                          ref={register}
                          name="email"
                          disabled
                          defaultValue={userDetails && userDetails.email ? userDetails.email : ''}
                        />

                        {errors.email && (
                          <p className="text-danger txt">
                            {errors.email.message}
                          </p>
                        )}
                      </Form.Group>

                      <Form.Group controlId="exampleForm.ControlTextarea1">
                        <Form.Label className="d-flex justify-content-start llbl">
                          <img src={industry1} alt="industry" width="16px" height="20px" className="mr-1" />
                          <FormattedMessage id="aboutcompany" defaultMessage="About Company" />
                        </Form.Label>
                        <Form.Control
                          as="textarea"
                          autoComplete="off"
                          defaultValue={userDetails && userDetails.about_company ? userDetails.about_company : ''}
                          name="about_company"
                          placeholder={intl.formatMessage({
                            id: "companyDescription",
                          })}
                          onChange={(e) =>
                            setCount(500 - e.target.value.length)
                          }
                          className={classNames("form-control aboutmecompany", {
                            "is-invalid": errors.about_company,
                          })}
                          ref={register({
                            maxLength: {
                              value: 500,
                              message:
                                "Desscription should be less than 500 characters",
                            },
                          })}
                        />
                        <div className="text-right">
                          <p className="p-0 m-0">
                            {!errors.about_company && `${count}`}
                          </p>
                        </div>

                        {errors.about_company && (
                          <p className="text-danger oom p-0 m-0">
                            {errors.about_company.message}
                          </p>
                        )}
                      </Form.Group>

                      <div className="profile_field_box">
                        <div className="label_box">
                          <h6 className="user_one">
                            <img src={uploadi} className="user_icon mt-1" />
                            <FormattedMessage id = "companyVideo" defaultMessage="Company Video"/>
                          </h6>
                          <div>
                            <input type="file" accept="video/*" className="d-none" id="vedio-resume" onChange={uploadVedioResume} />
                            <label htmlFor="vedio-resume" className="mt-2">
                              <BsPencil className="user_icon pencil" />
                            </label>
                          </div>
                        </div>
                      </div>
                      {vedioErr?.length > 0 &&<div className="text-danger">{vedioErr}</div>}
                      {
                        userDetails && userDetails.video_resume
                          ? <div className="text-left">
                            <p className="user_tt video_box">
                              {" "}
                              {userDetails && userDetails.company_name && userDetails.company_name.length > 0
                                ? userDetails.company_name
                                : ""}
                            </p>
                            <button type="button" className="video_btn" onClick={onToggleModal} disabled={userDetails && userDetails.video_resume ? false : true}><FaRegPlayCircle className="btn_icon" /></button>
                          </div>
                          : ""
                      }
                      <Player open={modal} toggleModal={onToggleModal} src={`${userDetails.video_resume}?${Date.now()}`}/>
                      <button className="common_btn mt-4 mb-2" type="submit">
                        <FormattedMessage id = "submit" defaultMessage="Submit" />
                      </button>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {isError.open && <Alert severity="error" className="alert-toast">{isError.message}</Alert>}
    </>
  );
};

