import React, { useEffect, useState, useRef } from "react";
import { FormattedMessage } from "react-intl";
import man from "../../../assets/images/men.jpg";
import "./videoresume.scss";
import { Recorder } from "./Recorder";
import { PreviewScreen } from "./previewScreen";
import { Player } from "components/shared/videoplayer/player";
export const VideoResume = (props) => {
    const [isRecording ,setIsRecording] = useState(false);
    const [isUploading ,setIsUploading] = useState(false);
    const [fileUpload, setFileUpload] = useState();
    const [fileUploadUrl, setFileUploadUrl] = useState();
    const [modal, setModal] = useState(false);
    const videoRef = useRef(null);
    let mediaRecorderRef = null;
    const [src, setSRC] = useState("");
    const sampleVideo = "https://dev-hlj-filestorage-s3.s3.ap-south-1.amazonaws.com/public/sample_resume/Image+from+iOS.MP4";
    const guideVideo = "https://dev-hlj-filestorage-s3.s3.ap-south-1.amazonaws.com/public/video_resume_guide.mp4";
    useEffect(()=> {
        async function getMedia() {
            if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
              await navigator.mediaDevices
                .getUserMedia({
                  audio: true,
                  video: true,
                })
                // Success
                .then(function (stream) {
                    mediaRecorderRef = stream;
                  // display stream in video tag
                  videoRef.current.srcObject = stream;
                })
                // Error
                .catch(function (err) {
                  console.log("The following getUserMedia error occurred: " + err);
                });
            } else {
              console.log("getUserMedia not supported on your browser!");
            }
          }
          getMedia();
    },[]);
    useEffect(() => {
        return () => {
            mediaRecorderRef.getTracks().forEach((track) => track.stop());
        };
      }, []);
      /**
       * @description video modal URL according to button click
       * @param {param} src video URL
       */
      const onGuideModal = (src) => {
        setSRC(src);
        onToggleModal();
      };
      /**
       * @description open video modal
       */
      const onToggleModal = () => {
        setModal(!modal);
      };
    // Go to recording video resume
    const createResume = () => {
        setIsRecording(true);
    }
    // upload video resume and go to preview
    const uploadResume = (e) => {
        setFileUpload(e.target.files[0]);
        const URL = window.URL.createObjectURL(e.target.files[0])
        setFileUploadUrl(URL);
        setIsUploading(true);
    }
    if(isRecording) {
        return(<Recorder/>)
    }
    if(isUploading) {
        return(<PreviewScreen src={fileUploadUrl} file={fileUpload}/>)
    }
    return (
        <>
        <Player open={modal} toggleModal={onToggleModal} src={src}/>
        <div className="main_user st p_bottom">
          <h5 className="page_title">
            <FormattedMessage id="videoResume" defaultMessage="Video Resume" />
          </h5>
            <div className="resume">
                <div className="container_btn">
                    <button className="control_btn sample_btn" onClick={()=> onGuideModal(sampleVideo)}>
                        <FormattedMessage id="sample" defaultMessage="Sample" />
                    </button>
                    <button className="control_btn guide_btn" onClick={()=> onGuideModal(guideVideo)}>
                        <FormattedMessage id="guide" defaultMessage="Guide" />
                    </button>
                     {props.onSkip && <button className="control_btn guide_btn" onClick={props.onSkip}>
                        <FormattedMessage id="skip" defaultMessage="Skip" />
                    </button>}
                </div>
                <div className="template">
                    <div className="template_inner">
                    <video
                        ref={videoRef}
                        autoPlay
                        muted
                        className="video_frame"
                        id="videoPlayer"
                    />
                    </div>
                </div>
                <div className="container_btn">
                    <button className="control_btn sample_btn" onClick={createResume}><FormattedMessage
                  id="createResume"
                  defaultMessage="Create Resume"
                /></button>
                    <input
                  type="file"
                  className="d-none "
                  id="vedio-resume"
                  accept="video/*"
                  onChange={uploadResume}
                />
                <label for="vedio-resume">
                <FormattedMessage
                  id="uploadResume"
                  defaultMessage="Upload Resume"
                />
                </label>
                </div>
            </div>
        </div>
        </>
    )
}