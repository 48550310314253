import React, { useState, useEffect } from "react";
import { JobSeekerPlans } from "components/jobseeker/chooseplans/jobseekerplans"
import { RecruiterPlans } from "components/recruiter/chooseplans/recruiterplans"
import storage from "utils/storage";

export const ChoosePlan = () => {
  if (storage.get("humlog_user_role") === 0) {
    return (
      <JobSeekerPlans />
    );
  } else return (
    <RecruiterPlans />
  )
}