import React, { useState,useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as userActions from "redux/actions/UserActions";
import * as jobCategoryActions from "redux/actions/jobcategory";
import { Header } from "components/commoncomponent/header/Header";
import {HeadFooter} from "components/commoncomponent/footer/HeadFooter.js"
import { ClearCache } from './ClearCache';
import * as commonService from "utils/CommonService.js";
import storage from "utils/storage";
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import FacebookLogin from 'react-facebook-login';
import "./App.css";
import { getToken, onMessageListener } from "./firebase";
import { Button, Row, Col, Toast } from 'react-bootstrap';
import "bootstrap/dist/css/bootstrap.min.css";
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));
const App = ({ children }) => {
  const { history} = children.props;
  const { title} = children.props;
  const dispatch = useDispatch();
  const [loaded, setLoaded] = useState(false);
  let userId = storage.get("humlog_user");
  useEffect(() => {
    commonService.forSuccess();
    if (!loaded) {
      setLoaded(true);
      dispatch(userActions.getUserData(userId));
      dispatch(userActions.getNotificationsList());
      dispatch(jobCategoryActions.getJobCategoryListData());
    }
  }, [loaded, dispatch, userId]);
  const { userDetails } = useSelector((state) => state.userReducer);
  const { count } = useSelector((state) => state.userReducer);
  const [ resSidebarToggled, setResSidebarToggle] = useState(false);
  const [currentTheme, setCurrentTheme] = useState("light-theme");
  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState({ title: "", body: "" });
  /**
   * @description method for showing push notification when received 
   */
  // onMessageListener()
  // .then((payload) => {
  //   setShow(true);
  //   setNotification({
  //     title: payload.notification.title,
  //     body: payload.notification.body,
  //   });
  // })
  // .catch((err) => console.log("failed: ", err));

  return (
    <ClearCache>
        {({ state, refreshCacheAndReload }) => {
          if (state.loading) return null;
          if (!state.loading && !state.isLatestVersion) {
            // hard refresh if not latest version
            refreshCacheAndReload();  
          }
          return (
            <> 
              <div>
                <Toast className="toastposition" onClose={() => setShow(false)} show={show} delay={3000} autohide animation>
                  <Toast.Header>
                    <strong className="mr-auto">{notification.title.replace(/_/g, " ")}</strong>
                    <small>just now</small>
                  </Toast.Header>
                  <Toast.Body>{notification.body}</Toast.Body>
                </Toast>
                <div className="row p-0 m-0">
                  <div className="col-md-4 p-0 m-0"></div>
                  <div className="col-md-4 p-0 m-0">  
                    <Header title={title}/>
                      {children}
                    <HeadFooter count={count}/>
                  </div>
                  <div className="col-md-4 p-0 m-0"></div>
                </div>
              </div>
            </>
          )
        }}
    </ClearCache>
  );
};

export default App;